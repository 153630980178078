import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AddNewTeach from "./AddNewTeach";
import { ITeachScreen } from "../../../../../@types/teachscreen.type";
import { formatDate } from "@fullcalendar/core";

type Props = {
  clinicalRecordId: number;
  teachScreen: ITeachScreen;
  showCLForm: boolean;
  getTeachScreenRecords: (teachScreenId: number, clinicalId: number) => void;
};

const Teach = ({
  clinicalRecordId,
  teachScreen,
  showCLForm,
  getTeachScreenRecords,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const handleClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {(showCLForm ||
        !teachScreen ||
        Object.keys(teachScreen).length === 0) && (
        <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
          * There's no Teach Screen added for this clinical record, please add
          and save!
        </Typography>
      )}
      <Box
        sx={{
          margin: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
        <Typography variant="h6">Teach</Typography>
        {!teachScreen ||
          (Object.keys(teachScreen).length === 0 && (
            <Button onClick={handleClick}>Add New Teach</Button>
          ))}
      </Box>

      <>
        <Card sx={{ margin: "1% 2%", cursor: "pointer" }} onClick={handleClick}>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}>
            {formatDate(teachScreen?.createdDate)}
          </Typography>
          <Typography
            sx={{ color: "text.secondary", marginTop: 1, marginLeft: 2 }}>
            <strong>Teach By:</strong> {teachScreen?.teachBy}
          </Typography>
          <Typography
            sx={{ color: "text.secondary", marginTop: 1, marginLeft: 2 }}>
            <strong>Date:</strong> {formatDate(teachScreen?.date)}
          </Typography>
        </Card>
      </>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openModal}
        onClose={handleCloseModal}>
        <DialogTitle>
          {teachScreen ? "View Teach" : "Add New Teach"}
        </DialogTitle>
        <AddNewTeach
          clinicalRecordId={clinicalRecordId}
          setOpenModal={(openModal: boolean) => setOpenModal(openModal)}
          getTeachScreenRecords={getTeachScreenRecords}
          teachScreen={teachScreen}
          showCLForm={showCLForm}
        />
      </Dialog>
    </>
  );
};

export default Teach;
