import { Box, Checkbox, Typography } from "@mui/material";
import Axis from "../../Patient/Axis";
import RefractionTable from "./RefractionTable";
import HandoverAndOrderingNotes from "./HandoverAndOrderingNotes";
import { LoadingButton } from "@mui/lab";
import FramesAndLenses from "./FramesAndLenses";
import Lenses from "./Lenses";
import { ClinicalRecords } from "../../../../api/clinicalRecords.service";
import { useEffect, useState } from "react";
import { IRefractionSchema } from "../../../../@types/refraction";
import { IHandoverSchema } from "../../../../@types/handover";
import { PatientService } from "../../../../api/patient.service";
import { IAxisSchema } from "../../../../@types/specsHistory";
import { AxisService } from "../../../../api/axis.service";
import FormProvider from "../../../../components/hook-form/FormProvider";
import { RHFAutocomplete, RHFSelect } from "../../../../components/hook-form";
import { Controller, useForm } from "react-hook-form";
import { DispensaryService } from "../../../../api/dispensary.service";
import { enqueueSnackbar } from "notistack";
import { IPatientSearchSchema } from "../../../../@types/calendar";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

type FormValueProps = {
  specType: string;
  status?: string;
  patientId: number | IPatientSearchSchema;
  isExternal?: boolean;
  voucherId?: number;
  orderingNotes?: string;
  totalPrice?: number;
  productId?: number;
};

const distance = "Distance";
const near = "Near";
const intermediate = "Intermediate";
const bifocal = "Bifocal";
const varifocal = "Varifocal";

export default function Specs() {
  const [axisData, setAxisData] = useState({} as IAxisSchema);
  const [refractionData, setRefractionData] = useState({} as IRefractionSchema);
  const [handoverData, setHandoverData] = useState({} as IHandoverSchema);
  const [patients, setPatients] = useState<IPatientSearchSchema[]>([]);
  const [patientId, setPatientId] = useState<number>();
  const [isExternalChecked, setIsExternalChecked] = useState(false);
  const [specType, setSpecType] = useState<string>("");

  const methods = useForm<FormValueProps>();
  const { handleSubmit } = methods;

  const handlePatientOnChange = (event: any, value: any) => {
    if (value) {
      const selectedPatientId: number = value.id;
      setPatientId(selectedPatientId);
      const clinicalRecords = new ClinicalRecords();
      clinicalRecords.getAllClinicalRecords(selectedPatientId).then((res) => {
        setAxisData(res.data[0]?.prescreening?.axis);
        setHandoverData(res.data[0]?.handover);
        setRefractionData(res.data[0]?.refraction);
      });
      const axisService = new AxisService();
      axisService.getAllAxis();
    }
  };

  const handleCheckboxChange = (event: any) => {
    setIsExternalChecked(event.target.checked);
  };

  const getAllPatients = () => {
    new PatientService()
      .getAllPatients()
      .then((res) => {
        setPatients(
          res.data.map((item: any) => {
            return {
              id: item.id,
              name: `${item.firstName} ${item.surName}`,
            };
          })
        );
      })
      .catch(() => {
        setPatients([]);
      });
  };

  const onSubmit = async (data: FormValueProps) => {
    data.patientId = patientId!;
    const dispenseService = new DispensaryService();
    dispenseService
      .createDispense(data)
      .then((res) => {
        enqueueSnackbar("Dispense Created Successfully!", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.message[0], {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getAllPatients();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
        <Typography variant="h5" sx={{ mr: 2 }}>
          Info
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <RHFAutocomplete
              name="patientId"
              label="Patient Name"
              sx={{ width: "100%", mr: 1 }}
              multiple={false}
              options={patients}
              getOptionLabel={(option: any) => option.name}
              onChange={handlePatientOnChange}
            />
            <Controller
              name="specType"
              control={methods.control}
              defaultValue=""
              render={({ field }: any) => (
                <RHFSelect
                  {...field}
                  native
                  id="grouped-native-select"
                  label="Spec Type"
                  value={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    setSpecType(event.target.value);
                  }}>
                  <option aria-label="None" value="" />
                  <optgroup label="Single Vision">
                    <option value={distance}>Distance</option>
                    <option value={near}>Near</option>
                    <option value={intermediate}>Intermediate</option>
                  </optgroup>
                  <option value={bifocal}>Bifocal</option>
                  <option value={varifocal}>Varifocal</option>
                </RHFSelect>
              )}
            />
          </Box>
        </Box>
        {/* <Box sx={{ mr: 2, display: "flex", flexDirection: "row" }}>
          <Checkbox {...label} onChange={handleCheckboxChange} />
          <Typography sx={{ mt: 1 }}>External</Typography>
        </Box> */}
      </Box>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Specs
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Axis axisData={axisData} isExternalChecked={isExternalChecked} />
        <RefractionTable
          refractionData={refractionData}
          isExternalChecked={isExternalChecked}
        />
      </Box>
      <HandoverAndOrderingNotes handoverData={handoverData} />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <FramesAndLenses specType={specType} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Lenses />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row-reverse", width: "100%" }}>
        <LoadingButton type="submit" variant="outlined" sx={{ marginRight: 2 }}>
          Save Draft
        </LoadingButton>
      </Box>
    </FormProvider>
  );
}
