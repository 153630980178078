import { Grid, Typography, Box } from "@mui/material";
import RHFTextField from "../../../../../components/hook-form/RHFTextField";
import { ITrail } from "../../../../../@types/trail.type";

const RxFinal = () => {
  return (
    <>
      <Box mt={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={1}>
            <Typography align="center">Right</Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <Typography align="center">BC</Typography>
                <RHFTextField
                  type="text"
                  sx={{ width: "70px" }}
                  name="rightBC"
                />
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    width: "1px",
                    height: "40px",
                    background: "black",
                    position: "relative",
                    top: "30px",
                    transform: "rotate(14deg)",
                  }}></Box>
              </Grid>
              <Grid item>
                <Typography align="center">TD</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightTD"
                />
              </Grid>
              <Grid item>
                <Typography align="center">SPH</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightSPH"
                />
              </Grid>
              <Grid item>
                <Typography align="center">CYL</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightCYL"
                />
              </Grid>
              <Grid item>
                <Typography align="center">AXIS</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightAxis"
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 180,
                    onInput: (e: any) => {
                      if (e.target.value.length > 3) {
                        e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                      }
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography align="center">VA</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightVA"
                />
              </Grid>
              <Grid item>
                <Typography align="center">ADD</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightAdd"
                />
              </Grid>
              <Grid item sx={{ marginLeft: "40px" }}>
                <Typography align="center">Over Refr</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightOverRefraction"
                />
              </Grid>
              <Grid item>
                <Typography align="center">VA</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightVA2"
                />
              </Grid>
              <Grid item>
                <Typography align="center">NV</Typography>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightNV2"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box mt={1} mb={2} mr={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={1}>
            <Typography align="center">Left</Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <RHFTextField
                  type="text"
                  sx={{ width: "70px" }}
                  name="leftBC"
                />
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    width: "1px",
                    height: "40px",
                    background: "black",
                    position: "relative",
                    top: "9px",
                    transform: "rotate(14deg)",
                  }}></Box>
              </Grid>
              <Grid item>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftTD"
                />
              </Grid>
              <Grid item>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftSPH"
                />
              </Grid>
              <Grid item>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftCYL"
                />
              </Grid>
              <Grid item>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftAxis"
                />
              </Grid>
              <Grid item>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftVA"
                />
              </Grid>
              <Grid item>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftAdd"
                />
              </Grid>
              <Grid item sx={{ marginLeft: "40px" }}>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftOverRefraction"
                />
              </Grid>
              <Grid item>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftVA2"
                />
              </Grid>
              <Grid item>
                <RHFTextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftNV2"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RxFinal;
