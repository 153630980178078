import axios from "../utils/axios";

export class ExaminationService {
  getAllExaminations(patientId: any) {
    return axios.get(`/patient/${patientId}/examination`);
  }
  createExamination(patientId: any, data: any) {
    return axios.post(`/patient/${patientId}/examination`, data);
  }

}
