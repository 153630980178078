import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { ISpecsHistorySchema } from "../../../@types/specsHistory";

type Props = {
  handleOpen: any;
  data: ISpecsHistorySchema;
};

const SpecsHistoryBox = ({ handleOpen, data }: Props) => {
  return (
    <Box
      p={3}
      mt={2}
      sx={{
        borderRadius: "15px",
        border: "1px solid #6373813b",
        position: "relative",
      }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Specs History/ Lens Meter
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}>
        <Typography sx={{ color: "text.secondary" }}>
          <strong>Condition:</strong> {data.condition}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          <strong>Notes:</strong> {data.notes}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          <strong>Specs Type:</strong> {data.specifications}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          mt: 5,
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          mt={1}>
          <Typography>Right</Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              Sph
              <TextField
                disabled
                sx={{ width: "70px" }}
                value={
                  data?.rightSphericalPower
                    ? parseFloat(data.rightSphericalPower) >= 0
                      ? `+${parseFloat(data.rightSphericalPower).toFixed(2)}`
                      : parseFloat(data.rightSphericalPower).toFixed(2)
                    : ""
                }
                name="specificationHistories.rightSphericalPower"
                type="string"
                inputProps={{
                  step: 0.25,
                  min: -10,
                  max: 10,
                  onChange: (e: any) => {
                    let value = parseFloat(e.target.value).toFixed(2);
                    if (parseFloat(value) > 0) {
                      value = `+${value}`;
                    }
                    e.target.value = value;
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: "1px",
                margin: "0 10px",
                height: "40px",
                background: "black",
                position: "relative",
                top: "30px",
                transform: "rotate(14deg)",
              }}></Box>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              Cyl
              <TextField
                type="number"
                disabled
                sx={{ width: "90px" }}
                value={data?.rightCylinder}
                name="specificationHistories.rightCylinder"
                inputProps={{
                  step: 0.25,
                  onChange: (e: any) => {
                    const value = parseFloat(e.target.value).toFixed(2);
                    e.target.value = value;
                  },
                }}
              />
            </Box>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              Axis
              <TextField
                type="number"
                sx={{ width: "90px" }}
                disabled
                value={data?.rightAxis}
                name="specificationHistories.rightAxis"
              />
            </Box>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              Dist PD
              <TextField
                type="number"
                disabled
                sx={{ width: "90px" }}
                value={data?.rightDistancePD}
                name="specificationHistories.rightDistancePD"
              />
            </Box>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              BVD
              <TextField
                type="number"
                disabled
                sx={{ width: "90px" }}
                value={data?.rightBVD}
                name="specificationHistories.rightBVD"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          mt={1}>
          <Typography>Left</Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <TextField
                disabled
                sx={{ width: "70px" }}
                value={
                  data?.leftSphericalPower
                    ? parseFloat(data.leftSphericalPower) >= 0
                      ? `+${parseFloat(data.leftSphericalPower).toFixed(2)}`
                      : parseFloat(data.leftSphericalPower).toFixed(2)
                    : ""
                }
                name="specificationHistories.leftSphericalPower"
                type="string"
                inputProps={{
                  step: 0.25,
                  min: -10,
                  max: 10,
                  onChange: (e: any) => {
                    let value = parseFloat(e.target.value).toFixed(2);
                    if (parseFloat(value) > 0) {
                      value = `+${value}`;
                    }
                    e.target.value = value;
                  },
                }}
              />
            </Box>
            <Box
              className="line1"
              sx={{
                width: "1px",
                margin: "0 10px",
                height: "40px",
                background: "black",
                position: "relative",
                top: "9px",
                transform: "rotate(14deg)",
              }}></Box>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <TextField
                type="number"
                disabled
                sx={{ width: "90px" }}
                value={data?.leftCylinder}
                name="specificationHistories.leftCylinder"
                inputProps={{
                  step: 0.25,
                  onChange: (e: any) => {
                    const value = parseFloat(e.target.value).toFixed(2);
                    e.target.value = value;
                  },
                }}
              />
            </Box>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <TextField
                type="number"
                sx={{ width: "90px" }}
                disabled
                value={data?.leftAxis}
                name="specificationHistories.leftAxis"
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 180,
                  onInput: (e: any) => {
                    if (e.target.value.length > 3) {
                      e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                    }
                  },
                }}
              />
            </Box>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <TextField
                type="number"
                sx={{ width: "90px" }}
                disabled
                value={data?.leftDistancePD}
                name="specificationHistories.leftDistancePD"
              />
            </Box>
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <TextField
                type="number"
                disabled
                sx={{ width: "90px" }}
                value={data?.leftBVD}
                name="specificationHistories.leftBVD"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SpecsHistoryBox;
