import { Stack, Box } from "@mui/material";
import FormProvider from "../../../components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { RHFTextField } from "../../../components/hook-form";

export default function PatientInfo(patientInfo: any) {
  const methods = useForm({
    defaultValues: {
      firstName: patientInfo?.patientInfo?.firstName,
      surName: patientInfo?.patientInfo?.surName,
      postCode: patientInfo?.patientInfo?.postCode,
      email: patientInfo?.patientInfo?.email,
    },
  });

  return (
    <FormProvider methods={methods}>
      <Stack spacing={3} sx={{ px: 3, mt: 3 }}>
        <Box sx={{ display: "flex" }}>
          <RHFTextField
            error={false}
            name="firstName"
            label="First Name"
            sx={{ mt: "20px", width: "50%" }}
            disabled
          />
          <RHFTextField
            error={false}
            name="surName"
            label="Surname"
            sx={{ mt: "20px", ml: "15px", width: "50%" }}
            disabled
          />
        </Box>

        <Box sx={{ display: "flex" }}>
          <RHFTextField
            error={false}
            name="postCode"
            label="Post Code"
            disabled
            sx={{ width: "50%" }}
          />
          <RHFTextField
            error={false}
            name="email"
            label="Email"
            disabled
            sx={{ ml: "15px", width: "50%" }}
          />
        </Box>
      </Stack>
    </FormProvider>
  );
}
