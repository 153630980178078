import { IBrandRequest, Brands } from "../@types/brand.type";
import axios from "../utils/axios";

export class BrandService {
  getAllBrands() {
    return axios.get<Brands[]>("/brand");
  }
  createBrand(payload: IBrandRequest) {
    return axios.post<Brands>("/brand", payload);
  }
  updateBrand(id: number, payload: IBrandRequest) {
    return axios.patch<Brands>(`/brand/${id}`, payload);
  }

  deleteBrand(id: number) {
    return axios.delete<string>(`/brand/${id}`);
  }
}
