import { ICLHistoryAndSymptoms } from "../../@types/clHistoryAndSymptoms.type";
import axios from "../../utils/axios";

export class CLHistoryAndSymptomsService {
  createNewHistoryAndSymptoms(patientId: string, data: ICLHistoryAndSymptoms) {
    return axios.post(
      `/patient/${patientId}/contact-lens-history-and-symptoms`,
      data
    );
  }

  getHistoryAndSymptoms(patientId: string, clHistoryId?: number) {
    const queryString = clHistoryId ? `?clHistoryId=${clHistoryId}` : "";

    return axios.get(
      `/patient/${patientId}/contact-lens-history-and-symptoms${queryString}`
    );
  }
}
