import { formatDate } from "@fullcalendar/core";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { TableHeadCustom, TableNoData } from "../../../../../components/table";
import { CustomAvatar } from "../../../../../components/custom-avatar";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";

const TABLE_HEAD = [
  {
    id: "patientId",
    label: "Patient Id",
    align: "left",
  },
  { id: "type", label: "Type", align: "center" },
  { id: "id", label: "Clinical ID", align: "left" },
  { id: "createdDate", label: "Created Date", align: "center" },
  { id: "patientName", label: "Patient Name", align: "right" },
];

type Props = {
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
  getCLAftercareRecords: (anteriorEyeId: number, clinicalId: number) => void;
};
export const CLAftercarePreviousRecords = ({
  selectedRow,
  clinicalRecords,
  getCLAftercareRecords,
}: Props) => {
  const [previousRecordsRow, setPreviousRecordsRow] =
    useState<number>(selectedRow);
  const filteredRecords = clinicalRecords.filter((record) => {
    return record.type === "Contact Lenses";
  });

  const filteredRow = filteredRecords.filter((record) => {
    return record.id !== selectedRow;
  });

  const handleRowSelection = (item: number) => {
    setPreviousRecordsRow(item);
  };
  return (
    <Table sx={{ minWidth: 800 }}>
      <TableHeadCustom headLabel={TABLE_HEAD} />
      <TableBody>
        {filteredRow.map((item: any) => (
          <TableRow
            key={item.id}
            sx={{
              cursor: "pointer",
              backgroundColor:
                previousRecordsRow === item.id ? "#e0e0e0" : "inherit",
            }}
            onClick={() => {
              getCLAftercareRecords(item.contactLensAfterCareId, item.id);
              handleRowSelection(item.id);
            }}>
            <TableCell align="left">
              <Stack direction="row" alignItems="center" spacing={2}>
                <CustomAvatar name={`${item.id}`} />
                <div>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    style={{ cursor: "pointer" }}></Typography>
                </div>
              </Stack>
            </TableCell>
            <TableCell align="center">{item.type}</TableCell>

            <TableCell align="left">{item.id}</TableCell>
            <TableCell align="center">{formatDate(item.createdDate)}</TableCell>
            <TableCell align="right">{item.patient.firstName}</TableCell>
          </TableRow>
        ))}

        <TableNoData isNotFound={filteredRecords.length <= 0} />
      </TableBody>
    </Table>
  );
};
