import { ITrail } from "../../@types/trail.type";
import axios from "../../utils/axios";

export class TrailsService {
  createTrail(patientId: string, data: { trailsRecord: ITrail }) {
    return axios.post(`/patient/${patientId}/trails`, data);
  }

  getTrails(patientId: string, clinicalRecordId?: number) {
    const queryString = clinicalRecordId
      ? `?clinicalRecordId=${clinicalRecordId}`
      : "";

    return axios.get(`/patient/${patientId}/trails${queryString}`);
  }
}
