/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useMemo, useState } from "react";
import { enqueueSnackbar } from "../../../../components/snackbar";
import FormProvider from "../../../../components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { HistoryAndSymptomsService } from "../../../../api/historyAndSymptoms.service";
import { TagsService } from "../../../../api/tags.service";
import { Tags } from "../../../../@types/tags.type";
import ConfirmDialog from "../../../../components/confirm-dialog/ConfirmDialog";
import { IPatientSymptomsSchema } from "../../../../@types/patientSymptoms";

type Props = {
  history: IPatientSymptomsSchema;
  clinicalRecordId: string;
  tab: (type: string) => void;
  getAllHistoryAndSymptoms: (symptomsId: number, clinicalId: number) => void;
};

export default function AddHistoryAndSymptoms({
  history,
  clinicalRecordId,
  tab,
  getAllHistoryAndSymptoms,
}: Props) {
  const [selectedChips, setSelectedChips] = useState<{
    [key in keyof IPatientSymptomsSchema]: string[];
  }>({
    reasonForVisit: [],
    additionalComplaints: [],
    ocularHistory: [],
    generalHealth: [],
    familyHistory: [],
    lifestyleAndOccupation: [],
  });
  const [availableChips, setAvailableChips] = useState<Tags[]>([]);
  const [currentFreeSoloValue, setCurrentFreeSoloValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [inputValues, setInputValues] = useState<IPatientSymptomsSchema>({
    reasonForVisit: "",
    additionalComplaints: "",
    ocularHistory: "",
    generalHealth: "",
    familyHistory: "",
    lifestyleAndOccupation: "",
  });
  const [currentFieldName, setCurrentFieldName] = useState<
    keyof IPatientSymptomsSchema | null
  >(null);

  const defaultValues = useMemo(
    () => ({
      reasonForVisit: history?.reasonForVisit || "",
      additionalComplaints: history?.additionalComplaints || "",
      ocularHistory: history?.ocularHistory || "",
      generalHealth: history?.generalHealth || "",
      familyHistory: history?.familyHistory || "",
      lifestyleAndOccupation: history?.lifestyleAndOccupation || "",
    }),
    [history]
  );
  const methods = useForm({
    // resolver: yupResolver<any>(HistorySchema),
    defaultValues,
  });

  const {
    setValue,
    formState: { isSubmitting, isLoading },
  } = methods;

  const { patientId } = useParams();

  const clearFields = () => {
    methods.reset();
    for (const fieldName in selectedChips as any) {
      setSelectedChips({ ...selectedChips, [fieldName]: [] });
      methods.setValue(fieldName as any, "");
    }
  };

  const onSubmit = async (data: any) => {
    const convertedChips = {
      reasonForVisit: selectedChips?.reasonForVisit.join(", "),
      additionalComplaints: selectedChips?.additionalComplaints.join(", "),
      ocularHistory: selectedChips?.ocularHistory.join(", "),
      generalHealth: selectedChips?.generalHealth.join(", "),
      familyHistory: selectedChips?.familyHistory.join(", "),
      lifestyleAndOccupation: selectedChips?.lifestyleAndOccupation.join(", "),
    };

    const historyService = new HistoryAndSymptomsService();

    try {
      const response = await historyService.createHistoryAndSymptoms(
        patientId,
        {
          ...convertedChips,
          clinicalRecordId,
        }
      );

      enqueueSnackbar("History Created Successfully!", {
        variant: "success",
      });
      setHistoryData();
      const symptomsId = response.data.id;
      const clinicalId = response.data.clinicalRecordId;
      getAllHistoryAndSymptoms(symptomsId, clinicalId);
      tab("refraction");
    } catch (error: any) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  const setHistoryData = () => {
    if (history)
      setValue(
        "reasonForVisit",
        history?.reasonForVisit ? history?.reasonForVisit : ""
      );
    setValue(
      "additionalComplaints",
      history?.additionalComplaints ? history?.additionalComplaints : ""
    );
    setValue(
      "ocularHistory",
      history?.ocularHistory ? history?.ocularHistory : ""
    );
    setValue(
      "generalHealth",
      history?.generalHealth ? history?.generalHealth : ""
    );
    setValue(
      "familyHistory",
      history?.familyHistory ? history?.familyHistory : ""
    );
    setValue(
      "lifestyleAndOccupation",
      history?.lifestyleAndOccupation ? history?.lifestyleAndOccupation : ""
    );
  };

  const getAllChips = async () => {
    try {
      const category = "History and Symptoms";
      const tagsService = new TagsService();
      const res = await tagsService.getAllTags(category);
      setAvailableChips(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllChips();
  }, []);

  useEffect(() => {
    if (history) setHistoryData();
  }, [history]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    fieldName: keyof IPatientSymptomsSchema
  ) => {
    setInputValues((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    fieldName: keyof IPatientSymptomsSchema
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const isFreeSolo = !availableChips.some(
        (chip) => chip.tagName === inputValues[fieldName]
      );
      if (inputValues[fieldName] && isFreeSolo) {
        setCurrentFreeSoloValue(inputValues[fieldName]);
        setCurrentFieldName(fieldName);
        setOpenModal(true);
      }
    }
  };

  useEffect(() => {
    if (history) {
      setSelectedChips({
        reasonForVisit: history?.reasonForVisit
          ? history?.reasonForVisit.split(", ")
          : [],
        additionalComplaints: history?.additionalComplaints
          ? history?.additionalComplaints.split(", ")
          : [],
        ocularHistory: history?.ocularHistory
          ? history?.ocularHistory.split(", ")
          : [],
        generalHealth: history?.generalHealth
          ? history?.generalHealth.split(", ")
          : [],
        familyHistory: history?.familyHistory
          ? history?.familyHistory.split(", ")
          : [],
        lifestyleAndOccupation: history?.lifestyleAndOccupation
          ? history?.lifestyleAndOccupation.split(", ")
          : [],
      });
    } else {
      setSelectedChips({
        reasonForVisit: [],
        additionalComplaints: [],
        ocularHistory: [],
        generalHealth: [],
        familyHistory: [],
        lifestyleAndOccupation: [],
      });
      setInputValues({
        reasonForVisit: "",
        additionalComplaints: "",
        ocularHistory: "",
        generalHealth: "",
        familyHistory: "",
        lifestyleAndOccupation: "",
      });
    }
  }, [history]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddTag = async (
    value: string,
    fieldName: keyof IPatientSymptomsSchema
  ) => {
    const category = "History and Symptoms";
    const tagName = value;
    const data = {
      tagName: tagName,
      category: category,
    };
    const tagsService = new TagsService();
    try {
      await tagsService.createTag(data);
      setInputValues((prev) => ({ ...prev, [fieldName]: "" }));
      getAllChips();
      enqueueSnackbar("New Tag Added Successfully", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(
        error?.message?.length ? error?.message[0] : error?.message,
        { variant: "error" }
      );
    }
    handleCloseModal();
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        {Object.keys(history).length === 0 && (
          <Typography sx={{ ml: 1 }} color={"red"}>
            * There's no History and Symptoms added for this clinical record,
            please add and save!
          </Typography>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Grid item xs={12} md={4} width="50%">
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Reason For Visit</Typography>
              </Box>
              <Box mt={2}>
                <Stack sx={{ position: "relative" }}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    disableCloseOnSelect
                    options={availableChips.map((chip) => chip.tagName)}
                    freeSolo
                    value={selectedChips?.reasonForVisit}
                    onChange={(event, newValue) => {
                      setSelectedChips((prev) => ({
                        ...prev,
                        reasonForVisit: newValue as string[],
                      }));
                    }}
                    filterOptions={(options, state) => {
                      return state.inputValue
                        ? options.filter((item) =>
                            item
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          )
                        : [];
                    }}
                    inputValue={inputValues.reasonForVisit}
                    onInputChange={(event, value) =>
                      handleInputChange(event, value, "reasonForVisit")
                    }
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            variant="outlined"
                            label={option}
                            key={key}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event as React.KeyboardEvent<HTMLInputElement>,
                            "reasonForVisit"
                          )
                        }
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Additional Complaints</Typography>
              </Box>
              <Box mt={2}>
                <Stack sx={{ position: "relative" }}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={availableChips.map((chip) => chip.tagName)}
                    freeSolo
                    disableCloseOnSelect
                    value={selectedChips?.additionalComplaints}
                    onChange={(event, newValue) => {
                      setSelectedChips((prev) => ({
                        ...prev,
                        additionalComplaints: newValue as string[],
                      }));
                    }}
                    filterOptions={(options, state) => {
                      return state.inputValue
                        ? options.filter((item) =>
                            item
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          )
                        : [];
                    }}
                    inputValue={inputValues.additionalComplaints}
                    onInputChange={(event, value) =>
                      handleInputChange(event, value, "additionalComplaints")
                    }
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            variant="outlined"
                            label={option}
                            key={key}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(event) =>
                          handleKeyDown(
                            event as React.KeyboardEvent<HTMLInputElement>,
                            "additionalComplaints"
                          )
                        }
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Ocular History</Typography>
              </Box>
              <Box mt={2}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={availableChips.map((chip) => chip.tagName)}
                  freeSolo
                  disableCloseOnSelect
                  value={selectedChips?.ocularHistory}
                  onChange={(event, newValue) => {
                    setSelectedChips((prev) => ({
                      ...prev,
                      ocularHistory: newValue as string[],
                    }));
                  }}
                  filterOptions={(options, state) => {
                    return state.inputValue
                      ? options.filter((item) =>
                          item
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        )
                      : [];
                  }}
                  inputValue={inputValues.ocularHistory}
                  onInputChange={(event, value) =>
                    handleInputChange(event, value, "ocularHistory")
                  }
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={key}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(event) =>
                        handleKeyDown(
                          event as React.KeyboardEvent<HTMLInputElement>,
                          "ocularHistory"
                        )
                      }
                      name="ocularHistory"
                    />
                  )}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} width="50%">
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">General Health</Typography>
              </Box>
              <Box mt={2}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={availableChips.map((chip) => chip.tagName)}
                  freeSolo
                  disableCloseOnSelect
                  value={selectedChips?.generalHealth}
                  onChange={(event, newValue) => {
                    setSelectedChips((prev) => ({
                      ...prev,
                      generalHealth: newValue as string[],
                    }));
                  }}
                  filterOptions={(options, state) => {
                    return state.inputValue
                      ? options.filter((item) =>
                          item
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        )
                      : [];
                  }}
                  inputValue={inputValues.generalHealth}
                  onInputChange={(event, value) =>
                    handleInputChange(event, value, "generalHealth")
                  }
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={key}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(event) =>
                        handleKeyDown(
                          event as React.KeyboardEvent<HTMLInputElement>,
                          "generalHealth"
                        )
                      }
                    />
                  )}
                />
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Family History</Typography>
              </Box>
              <Box mt={2}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={availableChips.map((chip) => chip.tagName)}
                  freeSolo
                  disableCloseOnSelect
                  value={selectedChips?.familyHistory}
                  onChange={(event, newValue) => {
                    setSelectedChips((prev) => ({
                      ...prev,
                      familyHistory: newValue as string[],
                    }));
                  }}
                  inputValue={inputValues.familyHistory}
                  onInputChange={(event, value) =>
                    handleInputChange(event, value, "familyHistory")
                  }
                  filterOptions={(options, state) => {
                    return state.inputValue
                      ? options.filter((item) =>
                          item
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        )
                      : [];
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={key}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(event) =>
                        handleKeyDown(
                          event as React.KeyboardEvent<HTMLInputElement>,
                          "familyHistory"
                        )
                      }
                    />
                  )}
                />
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Lifestyle and Occupation</Typography>
              </Box>
              <Box mt={2}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  disableCloseOnSelect
                  options={availableChips.map((chip) => chip.tagName)}
                  freeSolo
                  value={selectedChips?.lifestyleAndOccupation}
                  onChange={(event, newValue) => {
                    setSelectedChips((prev) => ({
                      ...prev,
                      lifestyleAndOccupation: newValue as string[],
                    }));
                  }}
                  filterOptions={(options, state) => {
                    return state.inputValue
                      ? options.filter((item) =>
                          item
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        )
                      : [];
                  }}
                  inputValue={inputValues.lifestyleAndOccupation}
                  onInputChange={(event, value) =>
                    handleInputChange(event, value, "lifestyleAndOccupation")
                  }
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={key}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(event) =>
                        handleKeyDown(
                          event as React.KeyboardEvent<HTMLInputElement>,
                          "lifestyleAndOccupation"
                        )
                      }
                    />
                  )}
                />
              </Box>
            </Stack>
          </Grid>
        </Box>
        <Stack direction="row-reverse" mt="40px" gap="30px" mr="17px">
          <Button type="submit" variant="contained">
            Exit
          </Button>

          {Object.keys(history).length === 0 && (
            <LoadingButton type="submit" loading={isSubmitting || isLoading}>
              Save and Next
            </LoadingButton>
          )}
          <Button onClick={clearFields}>Clear</Button>
        </Stack>
      </FormProvider>

      <ConfirmDialog
        open={openModal}
        onClose={handleCloseModal}
        title="Add a New Tag"
        content={
          <>
            Are you sure want to add <strong> {currentFreeSoloValue} </strong>{" "}
            to tags{" "}
          </>
        }
        action={
          <Button
            variant="contained"
            color="success"
            onClick={() =>
              currentFreeSoloValue &&
              currentFieldName &&
              handleAddTag(currentFreeSoloValue, currentFieldName)
            }>
            Add
          </Button>
        }
      />
    </>
  );
}
