import axios from "../utils/axios";

export class TagsService {
  getAllTags(category?: string) {
    const url = category ? `/tags?category=${category}` : "/tags";
    return axios.get(url);
  }
  createTag(data: any) {
    return axios.post("/tags", data);
  }
  updateTag(id: string, data: string) {
    return axios.put(`/tags/${id}`, data);
  }
  deleteTag(id: string, index?: string) {
    return axios.delete(`/tags/${id}`);
  }
}
