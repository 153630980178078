import { ICLAftercare } from "../../@types/clAftercare.type";
import axios from "../../utils/axios";

export class CLAftercareService {
  createCLAftercare(data: ICLAftercare, patientId: string) {
    return axios.post(`/patient/${patientId}/contact-lens-aftercare`, data);
  }
  getCLAftercare(patientId: string, clAftercareId?: number) {
    const queryString = clAftercareId ? `?clAftercareId=${clAftercareId}` : "";

    return axios.get(
      `/patient/${patientId}/contact-lens-aftercare${queryString}`
    );
  }
}
