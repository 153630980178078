import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { RHFTextField } from "../../../../components/hook-form";
import { useFormContext } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import AddCanvas from "./AddCanvas";
import { ClinicalRecords } from "../../../../api/clinicalRecords.service";
import { useParams } from "react-router-dom";
import { PreviousRecords } from "./PreviousRecords";

type Props = {
  clinicalRecordId: number;
};
const Ophthalmoscopy = ({ clinicalRecordId }: Props) => {
  const [openCanvasSide, setOpenCanvasSide] = useState<string>("");
  const { setValue, getValues } = useFormContext();
  const [leftCanvas, setLeftCanvas] = useState("");
  const [rightCanvas, setRightCanvas] = useState("");
  const [openRecordsModal, setOpenRecordsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [clinicalRecords, setClinicalRecords] = useState<any>([]);
  const [examination, setExamination] = useState<any>();

  const id = window.location.pathname.split("/")[2];

  const clearExternalData = () => {
    const fieldNames = [
      "ophthalmoscopy.leftLens",
      "ophthalmoscopy.leftVitreous",
      "ophthalmoscopy.leftDiscColor",
      "ophthalmoscopy.leftCDRatio",
      "ophthalmoscopy.leftDiscMargins",
      "ophthalmoscopy.leftAVRatio",
      "ophthalmoscopy.leftMacula",
      "ophthalmoscopy.leftPeriphery",
      "ophthalmoscopy.leftFundusApp",
      "ophthalmoscopy.leftMedia",
      "ophthalmoscopy.leftAdditionalNotes",
      "ophthalmoscopy.rightLens",
      "ophthalmoscopy.rightVitreous",
      "ophthalmoscopy.rightDiscColor",
      "ophthalmoscopy.rightCDRatio",
      "ophthalmoscopy.rightDiscMargins",
      "ophthalmoscopy.rightAVRatio",
      "ophthalmoscopy.rightMacula",
      "ophthalmoscopy.rightPeriphery",
      "ophthalmoscopy.rightFundusApp",
      "ophthalmoscopy.rightMedia",
      "ophthalmoscopy.rightAdditionalNotes",
    ];

    const clearedValues = { ...getValues() };
    fieldNames.forEach((fieldName) => {
      clearedValues[fieldName] = "";
    });

    Object.keys(clearedValues).forEach((fieldName) => {
      setValue(fieldName, clearedValues[fieldName]);
    });
  };

  const copyData = () => {
    const leftLens = getValues("ophthalmoscopy.leftLens");
    const leftVitreous = getValues("ophthalmoscopy.leftVitreous");
    const leftDiscColor = getValues("ophthalmoscopy.leftDiscColor");
    const leftCDRatio = getValues("ophthalmoscopy.leftCDRatio");
    const leftDiscMargins = getValues("ophthalmoscopy.leftDiscMargins");
    const leftAVRatio = getValues("ophthalmoscopy.leftAVRatio");
    const leftMacula = getValues("ophthalmoscopy.leftMacula");
    const leftPeriphery = getValues("ophthalmoscopy.leftPeriphery");
    const leftFundusApp = getValues("ophthalmoscopy.leftFundusApp");
    const leftMedia = getValues("ophthalmoscopy.leftMedia");
    const leftAdditionalNotes = getValues("ophthalmoscopy.leftAdditionalNotes");

    setValue("ophthalmoscopy.rightLens", leftLens);
    setValue("ophthalmoscopy.rightVitreous", leftVitreous);
    setValue("ophthalmoscopy.rightDiscColor", leftDiscColor);
    setValue("ophthalmoscopy.rightCDRatio", leftCDRatio);
    setValue("ophthalmoscopy.rightDiscMargins", leftDiscMargins);
    setValue("ophthalmoscopy.rightAVRatio", leftAVRatio);
    setValue("ophthalmoscopy.rightMacula", leftMacula);
    setValue("ophthalmoscopy.rightPeriphery", leftPeriphery);
    setValue("ophthalmoscopy.rightFundusApp", leftFundusApp);
    setValue("ophthalmoscopy.rightMedia", leftMedia);
    setValue("ophthalmoscopy.rightAdditionalNotes", leftAdditionalNotes);
  };

  const handleCanvasModal = (side: string) => {
    setOpenCanvasSide(side);
  };

  const handleCloseCanvasModal = () => {
    setOpenCanvasSide("");
  };

  useEffect(() => {
    const leftVisualImage = getValues("ophthalmoscopy.leftVisualImage");
    const rightVisualImage = getValues("ophthalmoscopy.rightVisualImage");

    setLeftCanvas(leftVisualImage);
    setRightCanvas(rightVisualImage);
  }, []);

  useEffect(() => {
    if (rightCanvas) {
      setValue("ophthalmoscopy.rightVisualImage", rightCanvas);
    }
    if (leftCanvas) {
      setValue("ophthalmoscopy.leftVisualImage", leftCanvas);
    }
    // canvasResult({
    //   rightVisualImage: rightCanvas === "" ? null : rightCanvas,
    //   leftVisualImage: leftCanvas === "" ? null : leftCanvas,
    // });
  }, [rightCanvas, leftCanvas]);

  const defaultValues = () => {
    setValue("ophthalmoscopy.leftLens", "Clear");
    setValue("ophthalmoscopy.leftVitreous", "Clear");
    setValue("ophthalmoscopy.leftDiscColor", "Pink");
    setValue("ophthalmoscopy.leftCDRatio", "");
    setValue("ophthalmoscopy.leftDiscMargins", "Healthy");
    setValue("ophthalmoscopy.leftAVRatio", "2/3");
    setValue("ophthalmoscopy.leftMacula", "Clear");
    setValue("ophthalmoscopy.leftPeriphery", "Clear");
    setValue("ophthalmoscopy.leftFundusApp", "");
    setValue("ophthalmoscopy.leftMedia", "Clear");
    setValue("ophthalmoscopy.leftAdditionalNotes", "");
    setValue("ophthalmoscopy.rightLens", "Clear");
    setValue("ophthalmoscopy.rightVitreous", "Clear");
    setValue("ophthalmoscopy.rightDiscColor", "Pink");
    setValue("ophthalmoscopy.rightCDRatio", "2");
    setValue("ophthalmoscopy.rightDiscMargins", "Healthy");
    setValue("ophthalmoscopy.rightAVRatio", "2/3");
    setValue("ophthalmoscopy.rightMacula", "Clear");
    setValue("ophthalmoscopy.rightPeriphery", "Clear");
    setValue("ophthalmoscopy.rightFundusApp", "");
    setValue("ophthalmoscopy.rightMedia", "Clear");
    setValue("ophthalmoscopy.rightAdditionalNotes", "");
  };

  const getAllClinicsRecords = () => {
    const clinicalRecords = new ClinicalRecords();
    clinicalRecords.getAllClinicalRecords(+id).then((res) => {
      setClinicalRecords(res.data);
      const examinationId = res.data[0]?.examinationId;
      const clinicalId = res.data[0]?.id;
      setSelectedRow(clinicalId);
      getExaminationRecords(examinationId, clinicalId);
    });
  };

  const { patientId } = useParams();

  const getExaminationRecords = (examinationId: number, clinicalId: number) => {
    const clinicalRecords = new ClinicalRecords();
    if (examinationId !== null) {
      clinicalRecords
        .getExaminationByClinicalRecordId(patientId, examinationId)
        .then((res) => {
          setExamination(res.data[0]);
          setPreviousData();
        });
    } else {
      setExamination([]);
    }
  };

  const setPreviousData = useCallback(() => {
    if (!examination) return;
    setValue(
      "ophthalmoscopy.rightDisc",
      examination?.ophthalmoscopy?.rightDisc
    );
    setValue("ophthalmoscopy.rightAV", examination?.ophthalmoscopy?.rightAV);
    setValue(
      "ophthalmoscopy.rightMacula",
      examination?.ophthalmoscopy?.rightMacula
    );
    setValue(
      "ophthalmoscopy.rightCDRatio",
      examination?.ophthalmoscopy?.rightCDRatio
    );
    setValue(
      "ophthalmoscopy.rightFundus",
      examination?.ophthalmoscopy?.rightFundus
    );
    setValue("ophthalmoscopy.leftDisc", examination?.ophthalmoscopy?.leftDisc);
    setValue("ophthalmoscopy.leftAV", examination?.ophthalmoscopy?.leftAV);
    setValue(
      "ophthalmoscopy.leftMacula",
      examination?.ophthalmoscopy?.leftMacula
    );
    setValue(
      "ophthalmoscopy.leftCDRatio",
      examination?.ophthalmoscopy?.leftCDRatio
    );
    setValue(
      "ophthalmoscopy.leftFundus",
      examination?.ophthalmoscopy?.leftFundus
    );
    setLeftCanvas(examination?.ophthalmoscopy?.leftVisualImage);
    setRightCanvas(examination?.ophthalmoscopy?.rightVisualImage);
  }, [examination]);

  const handleOpenRecordModal = () => {
    setOpenRecordsModal(true);
  };

  const handleCloseRecordModal = () => {
    setOpenRecordsModal(false);
  };

  useEffect(() => {
    getAllClinicsRecords();
  }, []);

  return (
    <>
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="right"
        ml={1}
        mr={1}
        spacing={2}>
        <Button variant="contained" onClick={clearExternalData}>
          Clear
        </Button>
        <Button variant="contained" onClick={defaultValues}>
          Default
        </Button>
        <Button variant="contained" onClick={copyData}>
          Copy R to L
        </Button>
        <Button variant="contained" onClick={handleOpenRecordModal}>
          Previous Records
        </Button>
      </Stack>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        p={5}>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Right
          </Typography>
          <RHFTextField
            name="ophthalmoscopy.leftLens"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftVitreous"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftDiscColor"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftCDRatio"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftDiscMargins"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftAVRatio"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftMacula"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftPeriphery"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftFundusApp"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftMedia"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.leftAdditionalNotes"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <Button
            variant="outlined"
            onClick={() => handleCanvasModal("left")}
            sx={{ width: "90%", marginBottom: "10px" }}>
            Images
          </Button>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mt={2}></Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Lens
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Vitreous
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Disc Color
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            C:D Ratio
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Disc Margins
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            A/V Ratio
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Macula
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Periphery
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Fundus App
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Media
          </Typography>
          <Typography color={"#637381"} variant="h6" mb={9} mt={3}>
            Additional Notes
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Left
          </Typography>
          <RHFTextField
            name="ophthalmoscopy.rightLens"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightVitreous"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightDiscColor"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightCDRatio"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightDiscMargins"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightAVRatio"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightMacula"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightPeriphery"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightFundusApp"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightMedia"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ophthalmoscopy.rightAdditionalNotes"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "10px" }}
          />

          <Button
            variant="outlined"
            onClick={() => handleCanvasModal("right")}
            sx={{ width: "90%", marginBottom: "10px" }}>
            Images
          </Button>
        </Box>
      </Box>

      {openCanvasSide !== "" && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={Boolean(openCanvasSide)}
          onClose={handleCloseCanvasModal}>
          <DialogTitle>Add Canvas</DialogTitle>
          <AddCanvas
            canvas={
              openCanvasSide === "left"
                ? leftCanvas
                : openCanvasSide === "right"
                ? rightCanvas
                : undefined
            }
            side={openCanvasSide}
            setCanvas={(value: string) =>
              openCanvasSide === "left"
                ? setLeftCanvas(value)
                : openCanvasSide === "right"
                ? setRightCanvas(value)
                : null
            }
            type="ophthalmoscopy"
          />
        </Dialog>
      )}

      <Dialog
        fullWidth
        maxWidth="md"
        open={openRecordsModal}
        onClose={handleCloseRecordModal}>
        <DialogTitle>Records</DialogTitle>

        <PreviousRecords
          selectedRow={selectedRow}
          clinicalRecords={clinicalRecords}
          getExaminationRecords={getExaminationRecords}
          setSelectedRow={setSelectedRow}
          previousRecordsRow={clinicalRecordId}
        />
      </Dialog>
    </>
  );
};

export default Ophthalmoscopy;
