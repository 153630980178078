import React from "react";
import { IAutoRefractorSchema } from "../../../@types/specsHistory";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

type Props = {
  data: IAutoRefractorSchema;
};
const AutoRefractor = ({ data }: Props) => {
  return (
    <Stack
      sx={{
        width: "50%",
        borderRadius: "15px",
        marginTop: "10px",
        minHeight: "300px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      }}
      direction="column"
      p={2}
      mr={2}>
      <Typography variant="h6">Auto Refractor</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}>
        <LoadingButton type="button" variant="outlined" sx={{ mr: 2 }}>
          Transpose
        </LoadingButton>
        <LoadingButton type="button" variant="outlined">
          Update RX
        </LoadingButton>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}>
        {data && Object.keys(data).length ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mt={1}>
              <Typography>Right</Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  Sph
                  <TextField
                    disabled
                    sx={{ width: "70px" }}
                    value={
                      data?.rightSphericalPower
                        ? parseFloat(data.rightSphericalPower) >= 0
                          ? `+${parseFloat(data.rightSphericalPower).toFixed(
                              2
                            )}`
                          : parseFloat(data.rightSphericalPower).toFixed(2)
                        : ""
                    }
                    name="autoRefractor.rightSphericalPower"
                    type="string"
                    inputProps={{
                      step: 0.25,
                      min: -10,
                      max: 10,
                      onChange: (e: any) => {
                        let value = parseFloat(e.target.value).toFixed(2);
                        if (parseFloat(value) > 0) {
                          value = `+${value}`;
                        }
                        e.target.value = value;
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "1px",
                    margin: "0 10px",
                    height: "40px",
                    background: "black",
                    position: "relative",
                    top: "30px",
                    transform: "rotate(14deg)",
                  }}></Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  Cyl
                  <TextField
                    disabled
                    type="number"
                    sx={{ width: "90px" }}
                    value={data?.rightCylinder}
                    name="autoRefractor.rightCylinder"
                    inputProps={{
                      step: 0.25,
                      onChange: (e: any) => {
                        const value = parseFloat(e.target.value).toFixed(2);
                        e.target.value = value;
                      },
                    }}
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  Axis
                  <TextField
                    type="number"
                    disabled
                    sx={{ width: "90px" }}
                    value={data?.rightAxis}
                    name="autoRefractor.rightAxis"
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 180,
                      onInput: (e: any) => {
                        if (e.target.value.length > 3) {
                          e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                        }
                      },
                    }}
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  Dist PD
                  <TextField
                    disabled
                    type="number"
                    sx={{ width: "90px" }}
                    value={data?.rightDistancePD}
                    name="autoRefractor.rightDistancePD"
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  BVD
                  <TextField
                    disabled
                    type="number"
                    sx={{ width: "90px" }}
                    value={data?.rightBVD}
                    name="autoRefractor.rightBVD"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mt={1}>
              <Typography>Left</Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <TextField
                    disabled
                    sx={{ width: "70px" }}
                    value={
                      data?.leftSphericalPower
                        ? parseFloat(data.leftSphericalPower) >= 0
                          ? `+${parseFloat(data.leftSphericalPower).toFixed(2)}`
                          : parseFloat(data.leftSphericalPower).toFixed(2)
                        : ""
                    }
                    name="autoRefractor.leftSphericalPower"
                    type="string"
                    inputProps={{
                      step: 0.25,
                      min: -10,
                      max: 10,
                      onChange: (e: any) => {
                        let value = parseFloat(e.target.value).toFixed(2);
                        if (parseFloat(value) > 0) {
                          value = `+${value}`;
                        }
                        e.target.value = value;
                      },
                    }}
                  />
                </Box>
                <Box
                  className="line1"
                  sx={{
                    width: "1px",
                    margin: "0 10px",
                    height: "40px",
                    background: "black",
                    position: "relative",
                    top: "9px",
                    transform: "rotate(14deg)",
                  }}></Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <TextField
                    type="number"
                    disabled
                    sx={{ width: "90px" }}
                    value={data?.leftCylinder}
                    name="autoRefractor.leftCylinder"
                    inputProps={{
                      step: 0.25,
                      onChange: (e: any) => {
                        const value = parseFloat(e.target.value).toFixed(2);
                        e.target.value = value;
                      },
                    }}
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <TextField
                    disabled
                    type="number"
                    sx={{ width: "90px" }}
                    value={data?.leftAxis}
                    name="autoRefractor.leftAxis"
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 180,
                      onInput: (e: any) => {
                        if (e.target.value.length > 3) {
                          e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                        }
                      },
                    }}
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <TextField
                    disabled
                    type="number"
                    sx={{ width: "90px" }}
                    value={data?.leftDistancePD}
                    name="autoRefractor.leftDistancePD"
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <TextField
                    disabled
                    type="number"
                    sx={{ width: "90px" }}
                    value={data?.leftBVD}
                    name="autoRefractor.leftBVD"
                  />
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}>
            No Records Found
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default AutoRefractor;
