import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { IDispense } from "../../../@types/dispense";
import MenuPopover from "../../../components/menu-popover";
import Iconify from "../../../components/iconify";
import { useState } from "react";
import ConfirmDialog from "../../../components/confirm-dialog/ConfirmDialog";

type Props = {
  dispense: IDispense;
  onDeleteRow: VoidFunction;
};
export default function DispenseTableRow({ dispense, onDeleteRow }: Props) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const navigate = useNavigate();

  const dispenseClickView = () => {
    navigate(PATH_DASHBOARD.dispense.click(dispense.id));
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => {
            dispenseClickView();
          }}>
          <Typography variant="subtitle2" noWrap>
            {dispense.patient?.firstName}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {dispense.id}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {dispense.patient?.phone}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {dispense.patient?.email}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {dispense.status}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton
            color={openPopover ? "inherit" : "default"}
            onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}>
        <MenuItem
          onClick={() => {
            // onEditRow();
            handleClosePopover();
          }}>
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: "error.main" }}>
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
