import { Box, Typography, TextField, Stack } from "@mui/material";
import { IRefractionSchema } from "../../../../@types/refraction";
import { LoadingButton } from "@mui/lab";

type Props = {
  refractionData: IRefractionSchema;
  isExternalChecked: boolean;
};
export default function RefractionTable({
  refractionData,
  isExternalChecked,
}: Props) {
  const fetchLatestRefraction = () => {
    //todo: get latest records
  };

  return (
    <Stack
      sx={{
        marginLeft: "10px",
        width: "49%",
        borderRadius: "15px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      }}
      direction="column"
      p={2}
      mr={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}>
        <LoadingButton
          type="button"
          variant="outlined"
          onClick={fetchLatestRefraction}>
          Fetch Latest
        </LoadingButton>
      </Box>
      <Typography variant="h6" sx={{ mt: -3 }}>
        Refraction
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mt={1}>
        <Typography>Right</Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            D PD
            <TextField
              disabled={!isExternalChecked}
              type="text"
              sx={{ width: "70px" }}
              // value={refractionData?.rightDistancePD}
            />
          </Box>
          <Box
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              background: "black",
              position: "relative",
              top: "30px",
              transform: "rotate(14deg)",
            }}></Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            N PD
            <TextField
              disabled={!isExternalChecked}
              type="number"
              sx={{ width: "90px" }}
              // value={refractionData?.rightNearPD}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            Height
            <TextField
              disabled={!isExternalChecked}
              type="number"
              sx={{ width: "90px" }}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            BVD{" "}
            <TextField
              disabled={!isExternalChecked}
              type="number"
              sx={{ width: "90px" }}
              // value={refractionData?.rightBVD}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            BVD
            <TextField
              disabled={!isExternalChecked}
              type="number"
              sx={{ width: "90px" }}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mt={1}>
        <Typography>Left</Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <TextField
              disabled={!isExternalChecked}
              type="text"
              sx={{ width: "70px" }}
              // value={refractionData?.leftDistancePD}
            />
          </Box>
          <Box
            className="line1"
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              background: "black",
              position: "relative",
              top: "9px",
              transform: "rotate(14deg)",
            }}></Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <TextField
              disabled={!isExternalChecked}
              type="number"
              sx={{ width: "90px" }}
              // value={refractionData?.leftNearPD}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <TextField
              disabled={!isExternalChecked}
              type="number"
              sx={{ width: "90px" }}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <TextField
              disabled={!isExternalChecked}
              type="number"
              sx={{ width: "90px" }}
              // value={refractionData?.leftBVD}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <TextField
              disabled={!isExternalChecked}
              type="number"
              sx={{ width: "90px" }}
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
