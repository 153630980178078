import {
  Typography,
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import Iconify from "../../../../components/iconify/Iconify";
import { LoadingButton } from "@mui/lab";
import { ChangeEvent, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ProductService } from "../../../../api/product.service";
import { RHFTextField } from "../../../../components/hook-form";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 150 },
  { field: "type", headerName: "Type", width: 150 },
  {
    field: "price",
    headerName: "Price",
    type: "number",
    width: 150,
  },
];

type Row = { id: number; name: string; type?: string; price: number };

interface TextFieldItem {
  id: number;
  textField: JSX.Element;
}

interface PageProps {
  specType: string;
}

export default function FramesAndLenses({ specType }: PageProps) {
  const [formModal, setFormModal] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  // const [textFields, setTextFields] = useState<TextFieldItem[]>([
  //   {
  //     id: 0,
  //     textField: (
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           justifyContent: "space-between",
  //           width: "100%",
  //         }}>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             justifyContent: "space-between",
  //             width: "100%",
  //             marginBottom: 3,
  //           }}>
  //           <RHFTextField
  //             key={0}
  //             label="Frame Name"
  //             multiline
  //             fullWidth
  //             name="productId"
  //           />
  //           <RHFTextField
  //             key={0}
  //             label="Frame Type"
  //             sx={{ marginLeft: "10px" }}
  //             multiline
  //             fullWidth
  //             name="productId"
  //           />
  //           <RHFTextField
  //             key={0}
  //             label="Frame Price"
  //             sx={{ marginLeft: "10px" }}
  //             multiline
  //             fullWidth
  //             name="productId"
  //           />
  //         </Box>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             justifyContent: "space-between",
  //             width: "100%",
  //           }}>
  //           <RHFTextField
  //             key={1}
  //             label="Lens Name"
  //             multiline
  //             fullWidth
  //             name="lensId"
  //           />
  //           <RHFTextField
  //             key={1}
  //             label="Lens Type"
  //             sx={{ marginLeft: "10px" }}
  //             multiline
  //             fullWidth
  //             name="lensId"
  //           />
  //           <RHFTextField
  //             key={1}
  //             label="Lens Price"
  //             sx={{ marginLeft: "10px" }}
  //             multiline
  //             fullWidth
  //             name="lensId"
  //           />
  //         </Box>
  //       </Box>
  //     ),
  //   },
  // ]);
  const [frameAccordionExpanded, setFrameAccordionExpanded] = useState(true);
  const [lensAccordionExpanded, setLensAccordionExpanded] = useState(false);
  const [differentRL, setDifferentRL] = useState(false);
  const [frames, setFrames] = useState<Row[]>([]);
  const [lens, setLens] = useState<Row[]>([]);
  const [lensFilter, setLensFilter] = useState<Row[]>([]);
  const [chooseFieldIndex, setChooseFieldIndex] = useState<number | null>();
  // const [framesData, setFramesData] = useState({} as Row);
  // const [lensData, setLensData] = useState({} as Row);
  const defaultField = {
    id: 0,
    name: "",
    type: "",
    price: "",
  };
  const [newFields, setNewFields] = useState<any[]>([defaultField]);

  const getAllProducts = async () => {
    const productService = new ProductService();
    try {
      const { data } = await productService.getAllProducts();

      const productFrames = data.filter((row) => row.category === "frames");
      setFrames(
        productFrames.map((frame) => ({
          id: frame.id,
          name: frame.name,
          type: frame.type,
          price: frame.price,
        }))
      );

      const productLens = data.filter((row) => row.category === "lens");
      if (specType) {
        setLens(
          productLens
            .filter((data) => data.type === specType)
            .map((lens) => ({
              id: lens.id,
              name: lens.name,
              type: lens.type,
              price: lens.price,
            }))
        );
      } else {
        setLens(
          productLens.map((lens) => ({
            id: lens.id,
            name: lens.name,
            type: lens.type,
            price: lens.price,
          }))
        );
        setLensFilter(
          productLens.map((lens) => ({
            id: lens.id,
            name: lens.name,
            type: lens.type,
            price: lens.price,
          }))
        );
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleCheckboxChange = () => {
    setDifferentRL(!differentRL);
  };

  const handleFrameAccordionChange = () => {
    setFrameAccordionExpanded(!frameAccordionExpanded);
    setLensAccordionExpanded(false);
  };

  const handleLensAccordionChange = () => {
    setLensAccordionExpanded(!lensAccordionExpanded);
    setFrameAccordionExpanded(false);
  };

  const handleOpenModal = () => {
    setFormModal(true);
  };

  const handleCloseModal = () => {
    setFormModal(false);
  };

  const handleAddFrame = () => {
    setNewFields([
      ...newFields,
      {
        ...defaultField,
        id: newFields.length,
      },
    ]);
    // setTextFields((prevTextFields: TextFieldItem[]) => [
    //   ...prevTextFields,
    //   {
    //     id: prevTextFields.length,
    //     textField: (
    //       <div
    //         key={prevTextFields.length}
    //         style={{
    //           marginBottom: "8px",
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             width: "100%",
    //           }}
    //         >
    //           <RHFTextField
    //             fullWidth
    //             name="productId"
    //             value={framesData?.name || ""}
    //           />
    //           <RHFTextField
    //             fullWidth
    //             sx={{
    //               marginLeft: "10px",
    //             }}
    //             name="productId"
    //             value={framesData?.type || ""}
    //           />
    //           <RHFTextField
    //             fullWidth
    //             sx={{
    //               marginLeft: "10px",
    //             }}
    //             name="productId"
    //             value={framesData?.price || ""}
    //           />
    //         </Box>
    //         <LoadingButton
    //           variant="outlined"
    //           sx={{ marginLeft: 1, marginTop: 2 }}
    //           onClick={handleOpenFrameModal}
    //         >
    //           Choose
    //         </LoadingButton>
    //         <Button
    //           startIcon={<Iconify icon="eva:trash-fill" />}
    //           sx={{ marginLeft: 1, marginTop: 2 }}
    //           onClick={() => handleDeleteFrame(prevTextFields.length)}
    //         >
    //           Delete
    //         </Button>
    //       </div>
    //     ),
    //   },
    // ]);
  };

  const handleLensChange = (event: any) => {
    setFilterSearch(event);
    const inputValue = event.trim();
    const productLens = lens.filter((row) =>
      row?.name?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setLens(
      productLens.map((lens) => ({
        id: lens.id,
        name: lens.name,
        type: lens.type,
        price: lens.price,
      }))
    );
  };

  // const handleDeleteFrame = (idToDelete: number) => {
  //   setTextFields((prevTextFields: TextFieldItem[]) =>
  //     prevTextFields.filter((item) => item.id !== idToDelete)
  //   );
  // };

  // const handleFrameChangeSelection = (selection: any) => {
  //   const result = frames.find((x: any) => x.id === selection[0]);
  //   console.log(result);
  //   setFramesData(result !== undefined ? result : ({} as Row));
  //   const text = [...textFields] as TextFieldItem[];
  //   text[0].textField = (
  //     <Box
  //       sx={{
  //         display: "grid",
  //         gridTemplateColumns: "400px 400px 400px",
  //         marginTop: "10px",
  //       }}>
  //       <RHFTextField key={0} value={result?.name} fullWidth name="productId" />
  //       <RHFTextField
  //         key={0}
  //         sx={{
  //           marginLeft: "10px",
  //         }}
  //         value={result?.type}
  //         fullWidth
  //         name="productId"
  //       />
  //       <RHFTextField
  //         key={0}
  //         sx={{
  //           marginLeft: "20px",
  //         }}
  //         value={result?.price}
  //         fullWidth
  //         name="productId"
  //       />
  //     </Box>
  //   );
  //   setTextFields(text);
  // };

  // const handleLensChangeSelection = (selection: any) => {
  //   const result = lens.find((x: any) => x.id === selection[0]);
  //   setLensData(result !== undefined ? result : ({} as Row));

  //   const text = [...textFields] as TextFieldItem[];
  //   text[0].textField = (
  //     <Box
  //       sx={{
  //         display: "grid",
  //         gridTemplateColumns: "400px 400px 400px",
  //         marginTop: "10px",
  //       }}>
  //       <RHFTextField key={1} value={result?.name} fullWidth name="lensId" />
  //       <RHFTextField
  //         key={1}
  //         sx={{
  //           marginLeft: "10px",
  //         }}
  //         value={result?.type}
  //         fullWidth
  //         name="lensId"
  //       />
  //       <RHFTextField
  //         sx={{
  //           marginLeft: "20px",
  //         }}
  //         key={1}
  //         value={result?.price}
  //         fullWidth
  //         name="lensId"
  //       />
  //     </Box>
  //   );
  //   setTextFields(text);
  // };

  const handleChangeSelection = (selection: any, type: string) => {
    const result: any =
      type === "lens"
        ? lens.find((x: any) => x.id === selection[0])
        : type === "frames"
        ? frames.find((x: any) => x.id === selection[0])
        : {};
    newFields[chooseFieldIndex!] = result;
    setNewFields(newFields);

    // setNewFields([...newFields, { ...result, type }]);
    // const text = [...textFields] as TextFieldItem[];
    // text[0].textField = (
    //   <Box
    //     sx={{
    //       display: "grid",
    //       gridTemplateColumns: "400px 400px 400px",
    //       marginTop: "10px",
    //     }}>
    //     <RHFTextField key={1} value={result?.name} fullWidth name="lensId" />
    //     <RHFTextField
    //       key={1}
    //       sx={{
    //         marginLeft: "10px",
    //       }}
    //       value={result?.type}
    //       fullWidth
    //       name="lensId"
    //     />
    //     <RHFTextField
    //       sx={{
    //         marginLeft: "20px",
    //       }}
    //       key={1}
    //       value={result?.price}
    //       fullWidth
    //       name="lensId"
    //     />
    //   </Box>
    // );
    // setTextFields(text);
  };

  useEffect(() => {
    getAllProducts();
  }, [specType]);

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      direction="column"
      p={2}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Frames & Lenses</Typography>
        {/* condition if last index is empty then disable and show tooltip choose above frames and lens */}
        <Button
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleAddFrame}
        >
          Add
        </Button>
      </Stack>

      {/* {textFields.map(({ id, textField }) => (
        <div key={id} style={{ marginBottom: "8px" }}>
          {textField}
        </div>
      ))} */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {newFields?.map((selected, index) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: 3,
            }}
          >
            <RHFTextField
              key={`name-${index}`}
              label="Name"
              fullWidth
              name="name"
              value={selected.name}
            />
            <RHFTextField
              key={`type-${index}`}
              label="Type"
              sx={{ marginLeft: "10px" }}
              fullWidth
              value={selected.type}
              name="type"
            />
            <RHFTextField
              key={`price-${index}`}
              label="Price"
              sx={{ marginLeft: "10px" }}
              fullWidth
              value={selected.price}
              name="price"
            />
            <LoadingButton
              key={`btn-${index}`}
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => {
                handleOpenModal();
                setChooseFieldIndex(index);
              }}
            >
              Choose
            </LoadingButton>
          </Box>
        ))}
      </Box>
      <Dialog fullWidth open={formModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Accordion
              expanded={frameAccordionExpanded}
              onChange={handleFrameAccordionChange}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="h5">Choose Frame</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={frames}
                    columns={columns}
                    onRowSelectionModelChange={(e) =>
                      handleChangeSelection(e, "frames")
                    }
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={lensAccordionExpanded}
              onChange={handleLensAccordionChange}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant="h5">Choose Lense</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  spacing={2}
                  alignItems="center"
                  direction={{
                    xs: "column",
                    md: "row",
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder="Search Lens By Name"
                    value={filterSearch}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleLensChange(event.target.value)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon="eva:search-fill"
                            sx={{ color: "text.disabled" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Box>
                  <Checkbox
                    {...label}
                    checked={differentRL}
                    onChange={handleCheckboxChange}
                  />
                  Different R&L
                </Box>

                <div style={{ height: 400, width: "100%" }}>
                  {differentRL && <Typography variant="h6">Right</Typography>}
                  <DataGrid
                    rows={lens}
                    columns={columns}
                    onRowSelectionModelChange={(e) =>
                      handleChangeSelection(e, "lens")
                    }
                  />
                </div>

                {differentRL && (
                  <>
                    <Typography variant="h6" sx={{ marginTop: "10px" }}>
                      Left
                    </Typography>
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={lens}
                        columns={columns}
                        onRowSelectionModelChange={(e) =>
                          handleChangeSelection(e, "lens")
                        }
                      />
                    </div>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
      </Dialog>
    </Stack>
  );
}
