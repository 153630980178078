import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
// utils
// import localStorageAvailable from "../utils/localStorageAvailable";
//
import { isValidToken, setSession } from "./utils";
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  JWTContextType,
} from "./types";
import { AuthService } from "../api/auth.service";
import { IRegisterSchema } from "../@types/register.type";
import Cookies from "universal-cookie";
import cookiesStorageAvailable from "../utils/cookiesStorageAvailable";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOGOUT = "LOGOUT",
  GET_PROFILE = "GET_PROFILE",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.GET_PROFILE]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const isCookiesStorageAvailable = cookiesStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const cookies = new Cookies();
      let accessToken = isCookiesStorageAvailable
        ? cookies.get(process.env.REACT_APP_TOKEN!)
        : "";
      let refreshToken = isCookiesStorageAvailable
        ? cookies.get(process.env.REACT_APP_REFRESH_TOKEN!)
        : "";
      if (accessToken && refreshToken) {
        if (!isValidToken(accessToken) && isValidToken(refreshToken)) {
          try {
            const authService = new AuthService();
            const refreshResponse = await authService.getRefreshToken(
              refreshToken
            );
            accessToken = refreshResponse.data?.accessToken;
            refreshToken = refreshResponse.data?.refreshToken;
          } catch (error) {
            console.log("error occurred on refresh token");
          }
        }
        if (
          accessToken &&
          isValidToken(accessToken) &&
          refreshToken &&
          isValidToken(refreshToken)
        ) {
          setSession(accessToken, refreshToken);
          const authService = new AuthService();
          const response = await authService.getProfile();
          const { user } = response.data;

          const isActiveOrganization = window.location.hostname.includes(
            "optopro.co"
          )
            ? true
            : user?.organization?.isActive
            ? true
            : false;
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              user: { ...user, isActiveOrganization },
            },
          });
        }
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [cookiesStorageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const authService = new AuthService();
    const response = await authService.login(email, password);

    const { accessToken, refreshToken, user } = response?.data;
    const isActiveOrganization = window.location.hostname.includes("optopro.co")
      ? true
      : user?.organization?.isActive
      ? true
      : false;
    setSession(accessToken, refreshToken);
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: { ...user, isActiveOrganization },
      },
    });
    if (accessToken) getProfile();
  }, []);

  const getProfile = useCallback(async () => {
    const authService = new AuthService();
    const { data: response } = await authService.getProfile();
    dispatch({
      type: Types.GET_PROFILE,
      payload: {
        user: response?.data,
      },
    });

    return response?.data;
  }, []);

  // REGISTER
  const register = useCallback(async (payload: IRegisterSchema) => {
    const authService = new AuthService();
    const response = await authService.demoRegister(payload);
    const { accessToken, refreshToken, user } = response.data;
    setSession(accessToken, refreshToken);

    const isActiveOrganization = window.location.hostname.includes("optopro.co")
      ? true
      : user?.organization?.isActive
      ? true
      : false;
    dispatch({
      type: Types.REGISTER,
      payload: {
        user: { ...user, isActiveOrganization },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null, null);
    localStorage.clear();
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: "jwt",
      login,
      loginWithGoogle: () => {},
      loginWithGithub: () => {},
      loginWithTwitter: () => {},
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
