import { Box } from "@mui/material";
import Type from "./Type";
import LastEyeExam from "./LastEyeExam";
import General from "./General";
import FormProvider from "../../../../../components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { ICLHistoryAndSymptoms } from "../../../../../@types/clHistoryAndSymptoms.type";
import { LoadingButton } from "@mui/lab";
import { CLHistoryAndSymptomsService } from "../../../../../api/contact-lenses/cl-history-and-symptoms.service";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "../../../../../components/snackbar";
import { useEffect, useState } from "react";
import { ClinicalRecords } from "../../../../../api/clinicalRecords.service";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";

type Props = {
  selectedType: string;
  clHistorySymptoms: ICLHistoryAndSymptoms;
  setSelectedType: (type: string) => void;
  showCLForm: boolean;
  tab: (type: string) => void;
  onUpdate: VoidFunction;
  setClinicalRecordId: (id: number) => void;
  getAllCLHistoryAndSymptoms: (clHistoryId: number, clinicalId: number) => void;
  setSelectedRow: (selectedRow: string) => void;
  finalData: any;
};

const CLHistoryAndSymptoms = ({
  selectedType,
  clHistorySymptoms,
  setSelectedType,
  showCLForm,
  tab,
  onUpdate,
  setClinicalRecordId,
  getAllCLHistoryAndSymptoms,
  setSelectedRow,
  finalData,
}: Props) => {
  const [previousRecords, setPreviousRecords] = useState(
    {} as IClinicalRecordSchema
  );

  const methods = useForm<ICLHistoryAndSymptoms>();

  const { handleSubmit } = methods;

  const { patientId } = useParams();

  const onSubmit = async (data: ICLHistoryAndSymptoms) => {
    const clHistoryAndSymptomsService = new CLHistoryAndSymptomsService();

    try {
      const response =
        await clHistoryAndSymptomsService.createNewHistoryAndSymptoms(
          patientId!,
          data
        );
      enqueueSnackbar("CL History and Symptoms Created Successfully!", {
        variant: "success",
      });
      onUpdate();
      getAllCLHistoryAndSymptoms(
        response.data?.id,
        response.data?.clinicalRecordId
      );
      setClinicalRecordId(response.data?.clinicalRecordId);
      setSelectedRow(response.data?.clinicalRecordId);
      tab("anterior_eye");
    } catch (error: any) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  const getPreviousRecords = () => {
    if (!patientId) return;
    const clinicalRecordService = new ClinicalRecords();
    clinicalRecordService.getLastRecord(patientId!).then((res) => {
      setPreviousRecords(res.data);
    });
  };
  useEffect(() => {
    getPreviousRecords();
  }, [setPreviousRecords]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}>
        <Type
          selectedType={selectedType}
          setSelectedType={(selectedType: string) =>
            setSelectedType(selectedType)
          }
        />
        <LastEyeExam previousRecords={previousRecords} finalData={finalData} />
        <General
          clHistorySymptoms={clHistorySymptoms}
          showCLForm={showCLForm}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            mr: 5,
            mt: 5,
          }}>
          <LoadingButton type="submit" variant="contained">
            Save And Next
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default CLHistoryAndSymptoms;
