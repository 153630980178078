import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { DistanceVision } from "./DistanceVision";
import { NearVision } from "./NearVision";
import { IntermediateVision } from "./IntermediateVision";
import { useFormContext } from "react-hook-form";

type Props = {
  tab: any;
};

export const Final = ({ tab }: Props) => {
  const [fullView, setFullView] = useState(false);

  const handleFullView = () => {
    setFullView(true);
  };

  const handleCompactView = () => {
    setFullView(false);
  };

  const { setValue } = useFormContext();

  const clearFields = () => {
    const fields = [
      "final.distanceRightUnaidedVA",
      "final.distanceRightSPH",
      "final.distanceRightCYL",
      "final.distanceRightAxis",
      "final.distanceRightAidedVA",
      "final.distanceRightPrism",
      "final.distanceRightDistancePD",
      "final.distanceBothUnaidedVA",
      "final.distanceBothAidedVA",
      "final.distanceBothPrism",
      "final.distanceLeftUnaidedVA",
      "final.distanceLeftSPH",
      "final.distanceLeftCYL",
      "final.distanceLeftAxis",
      "final.distanceLeftAidedVA",
      "final.distanceLeftPrism",
      "final.distanceLeftDistancePD",
      "final.nearRightUnaidedVA",
      "final.nearRightAdd",
      "final.nearRightSPH",
      "final.nearRightCYL",
      "final.nearRightAxis",
      "final.nearRightAidedVA",
      "final.nearRightPrism",
      "final.nearRightNearPD",
      "final.nearBothUnaidedVA",
      "final.nearBothAidedVA",
      "final.nearBothPrism",
      "final.nearLeftUnaidedVA",
      "final.nearLeftAdd",
      "final.nearLeftSPH",
      "final.nearLeftCYL",
      "final.nearLeftAxis",
      "final.nearLeftAidedVA",
      "final.nearLeftPrism",
      "final.nearLeftNearPD",
      "final.intermediateRightUnaidedVA",
      "final.intermediateRightAdd",
      "final.intermediateRightSPH",
      "final.intermediateRightCYL",
      "final.intermediateRightAxis",
      "final.intermediateRightAidedVA",
      "final.intermediateRightPrism",
      "final.intermediateRightInterPD",
      "final.intermediateBothAidedVA",
      "final.intermediateBothUnaidedVA",
      "final.intermediateBothPrism",
      "final.intermediateLeftAdd",
      "final.intermediateLeftUnaidedVA",
      "final.intermediateLeftSPH",
      "final.intermediateLeftCYL",
      "final.intermediateLeftAxis",
      "final.intermediateLeftAidedVA",
      "final.intermediateLeftPrism",
      "final.intermediateLeftInterPD",
    ];

    fields.forEach((field) => setValue(field, ""));
  };

  return (
    <Box>
      <DistanceVision fullView={fullView} />
      <NearVision fullView={fullView} />
      <IntermediateVision fullView={fullView} />
      <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: 5 }}>
        <LoadingButton variant="contained">Exit</LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => tab("summary")}>
          Next
        </LoadingButton>
        <LoadingButton variant="outlined" sx={{ mr: 2 }} onClick={clearFields}>
          Clear
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={fullView ? handleCompactView : handleFullView}>
          {fullView ? "Compact View" : "Full View"}
        </LoadingButton>
      </Box>
    </Box>
  );
};
