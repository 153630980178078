import { Stack, Box, Typography, Button } from "@mui/material";
import FormProvider from "../../../../../components/hook-form/FormProvider";
import { Controller, useForm } from "react-hook-form";
import { ITeachScreen } from "../../../../../@types/teachscreen.type";
import RHFTextField from "../../../../../components/hook-form/RHFTextField";
import { TeachScreenService } from "../../../../../api/contact-lenses/teachscreen.service";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "../../../../../components/snackbar";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { useAuthContext } from "../../../../../auth/useAuthContext";

type Props = {
  clinicalRecordId: number;
  setOpenModal: (openModal: boolean) => void;
  getTeachScreenRecords: (teachScreenId: number, clinicalId: number) => void;
  teachScreen: ITeachScreen | null;
  showCLForm: boolean;
};

const AddNewTeach = ({
  clinicalRecordId,
  setOpenModal,
  getTeachScreenRecords,
  teachScreen,
  showCLForm,
}: Props) => {
  const methods = useForm<ITeachScreen>();
  const { handleSubmit, control, reset } = methods;

  const { patientId } = useParams();

  const onSubmit = async (data: ITeachScreen) => {
    try {
      const formattedData: ITeachScreen = {
        ...data,
        date: data.date,
      };
      const teachScreenService = new TeachScreenService();
      const response = await teachScreenService.createTeachScreen(patientId!, {
        ...formattedData,
        clinicalRecordId,
      });
      setOpenModal(false);
      enqueueSnackbar("Teach Screen Created Successfully!", {
        variant: "success",
      });
      getTeachScreenRecords(response.data?.id, clinicalRecordId);
    } catch (error) {
      enqueueSnackbar("Internal Server Error", {
        variant: "error",
      });
    }
  };

  const { user } = useAuthContext();

  useEffect(() => {
    if (showCLForm || !teachScreen || Object.keys(teachScreen).length === 0) {
      reset({
        date: new Date(),
        teachBy: user?.username,
        proficiency: "",
        insertion: "",
        removal: "",
        handling: "",
        cleaningUnderstood: "",
        instructionLeafletGiven: "",
        buildUpTime: "",
        teachNotes: "",
      });
    } else {
      reset({
        date: teachScreen?.date ? new Date(teachScreen.date) : new Date(),
        teachBy: user?.username || "",
        proficiency: teachScreen?.proficiency || "",
        insertion: teachScreen?.insertion || "",
        removal: teachScreen?.removal || "",
        handling: teachScreen?.handling || "",
        cleaningUnderstood: teachScreen?.cleaningUnderstood || "",
        instructionLeafletGiven: teachScreen?.instructionLeafletGiven || "",
        buildUpTime: teachScreen?.buildUpTime || "",
        teachNotes: teachScreen?.teachNotes || "",
      });
    }
  }, [showCLForm, teachScreen, reset]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        ml={1}
        mr={1}
        spacing={2}>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "60px",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}></Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Date
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Teach By
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Proficiency
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Insertion
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Removal
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Handling
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Cleaning Understood
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Instruction Leaflet Given
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Build Up Time Explained
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={10} mb={8}>
            Teach Notes
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                onChange={(date) => field.onChange(date)}
                label="Date"
                sx={{ width: "90%", marginBottom: "10px" }}
              />
            )}
          />
          <RHFTextField
            name="teachBy"
            size="small"
            value={user?.username}
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="proficiency"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="insertion"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="removal"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="handling"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="cleaningUnderstood"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="instructionLeafletGiven"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="buildUpTime"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="teachNotes"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
        </Box>
      </Stack>
      <Box sx={{ display: "flex", flexDirection: "row-reverse", gap: 2, m: 2 }}>
        <Button variant="contained" type="submit">
          Add
        </Button>
        <Button variant="contained">Print</Button>
      </Box>
    </FormProvider>
  );
};

export default AddNewTeach;
