import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Image from "../../../../components/image";
import CanvasDrawer from "./CanvasDrawer";

type Props = {
  canvas?: string;
  side: string;
  setCanvas: (value: string) => void;
  type?: string;
};

const lensImage =
  "https://dev-optopro.s3.eu-west-2.amazonaws.com/development/assets/lens.jpg";
const eyeImage =
  "https://dev-optopro.s3.eu-west-2.amazonaws.com/development/assets/eye.jpg";

const AddCanvas = ({ canvas, side, setCanvas, type }: Props) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const selectedImage = type === "external" ? eyeImage : lensImage;
  const canvasName = `${side}-${type === "external" ? "eye" : "lens"}`;
  const handleUpload = (url: string) => {
    setCanvas(url);
  };

  return (
    <Box
      sx={{
        borderRadius: "15px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        width: "600px",
        margin: "0 auto 20px auto",
      }}
    >
      <CanvasDrawer
        selectedImage={selectedImage}
        canvasName={canvasName}
        uploadResult={(url: string) => {
          handleUpload(url);
        }}
        uploading={uploading}
        setUploading={(value: boolean) => setUploading(value)}
      />
      {canvas && (
        <Stack
          display="flex"
          direction="column"
          justifyContent="center"
          alignContent="center"
          textAlign="center"
        >
          <Typography>Preview</Typography>
          <Image disabledEffect src={canvas} alt="canvas_img" />
        </Stack>
      )}
    </Box>
  );
};

export default AddCanvas;
