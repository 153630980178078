// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components;
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "management",
    items: [
      // INVOICE
      {
        title: "diary",
        path: PATH_DASHBOARD.diary.list,
        icon: ICONS.calendar,
        // children: [{ title: "Appointments", path: PATH_DASHBOARD.diary.list }],
      },
      {
        title: "patient",
        path: PATH_DASHBOARD.patients.list,
        icon: ICONS.kanban,
        // children: [{ title: "list", path: PATH_DASHBOARD.patients.list }],
      },
      {
        title: "dispense",
        path: PATH_DASHBOARD.dispense.list,
        icon: ICONS.invoice,
        // children: [{ title: "list", path: PATH_DASHBOARD.dispense.list }],
      },
      {
        title: "product",
        path: PATH_DASHBOARD.product.list,
        icon: ICONS.ecommerce,
        // children: [
        //   { title: "list", path: PATH_DASHBOARD.product.list },
        //   { title: "new", path: PATH_DASHBOARD.product.new },
        // ],
      },
    ],
  },
];

export default navConfig;
