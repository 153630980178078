import { ITeachScreen } from "../../@types/teachscreen.type";
import axios from "../../utils/axios";

export class TeachScreenService {
  createTeachScreen(patientId: string, data: ITeachScreen) {
    return axios.post(`/patient/${patientId}/teachscreen`, data);
  }

  getTeachScreen(patientId: string, teachScreenId: number) {
    const queryString = teachScreenId ? `?teachscreenId=${teachScreenId}` : "";

    return axios.get(`/patient/${patientId}/teachscreen${queryString}`);
  }
}
