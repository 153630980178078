// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "";

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  page404: "/404",
  home: "/",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  blank: path(ROOTS_DASHBOARD, "/blank"),
  general: {
    app: path(ROOTS_DASHBOARD, ""),
    user: path(ROOTS_DASHBOARD, "/user"),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, "/settings"),
    users: path(ROOTS_DASHBOARD, "/settings/users"),
    appointments: path(ROOTS_DASHBOARD, "/settings/appointments"),
    stock: path(ROOTS_DASHBOARD, "/settings/stock"),
  },
  diary: {
    root: path(ROOTS_DASHBOARD, "/diary"),
    list: path(ROOTS_DASHBOARD, "/diary/appointments"),
  },
  patients: {
    root: path(ROOTS_DASHBOARD, "/patients"),
    list: path(ROOTS_DASHBOARD, "/patients/list"),
    click: (patientId: string) =>
      path(ROOTS_DASHBOARD, `/patients/${patientId}`),
  },
  dispense: {
    root: path(ROOTS_DASHBOARD, "/dispense"),
    list: path(ROOTS_DASHBOARD, "/dispense/list"),
    click: (dispenseID: number) =>
      path(ROOTS_DASHBOARD, `/dispense/${dispenseID}`),
    new: path(ROOTS_DASHBOARD, "/dispense/new"),
  },
  product: {
    root: path(ROOTS_DASHBOARD, "/product"),
    list: path(ROOTS_DASHBOARD, "/product/list"),
    new: path(ROOTS_DASHBOARD, "/product/new"),
    details: (productID: number) =>
      path(ROOTS_DASHBOARD, `/product/${productID}/details`),
    update: (productID: number) =>
      path(ROOTS_DASHBOARD, `/product/${productID}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
};

const ROOTS_AUTH = "/auth";
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};
