import {
  Stack,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { VoucherService } from "../../../../api/voucher.service";
import { Vouchers } from "../../../../@types/voucher.type";
import { Addon } from "../../../../@types/addon.type";
import { AddonService } from "../../../../api/addon.service";

export default function Lenses() {
  const [coating, setCoating] = useState("");
  // const [glazing, setGlazing] = useState("");
  const [voucher, setVoucher] = useState<Vouchers>();
  const [addon, setAddon] = useState<Addon>();
  const [totalPrice, setTotalPrice] = useState<any>();
  const [selectedVoucher, setSelectedVoucher] = useState<Vouchers[]>([]);
  const [selectedAddon, setSelectedAddon] = useState<Addon[]>([]);

  const getAllVouchers = async () => {
    const voucherService = new VoucherService();
    const response = await voucherService.getAllVouchers();
    const data: Vouchers[] = response.data;
    setSelectedVoucher(data);
  };
  const getAllAddon = async () => {
    const addonService = new AddonService();
    const response = await addonService.getAllAddons();
    const data: Addon[] = response.data;
    setSelectedAddon(data);
  };

  const handleCoatingChange = (event: SelectChangeEvent) => {
    setCoating(event.target.value);
  };

  // const handleGlazingChange = (event: SelectChangeEvent) => {
  //   setGlazing(event.target.value);
  // };

  const handleVoucherChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      const selected = selectedVoucher?.find(
        (x) => x.name === event.target.value
      );
      setVoucher(selected);
      const totalPrice = (selected?.price || 0) - (addon?.price || 0);
      setTotalPrice(Math.abs(totalPrice));
    }
  };

  const handleAddonChange = (event: SelectChangeEvent) => {
    if (event.target.value){
      const selected  = selectedAddon?.find((x) => x.name === event.target.value)
      setAddon(selected);
      const totalPrice = (selected?.price || 0) - (voucher?.price || 0);
      setTotalPrice(Math.abs(totalPrice));
    } 
  };

  const handleTotalPriceChange = (event: SelectChangeEvent) => {
    setTotalPrice(event.target.value)
  }

  useEffect(() => {
    getAllVouchers();
    getAllAddon();
  }, []);
  return (
    <>
      <Stack
        sx={{
          width: "100%",
        }}
        direction="column"
        p={2}
        mr={2}
      >
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Misc</Typography>
          <Stack sx={{ ml: 15, mt: -7 }}>
            <FormControl variant="standard" sx={{ m: 1, width: "40%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Addon
              </InputLabel>
              {/* // TODO: GET ADDON LIST HERE */}

              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={addon?.name!}
                onChange={handleAddonChange}
                label="addonId"
              >
                {selectedAddon.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {/* <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={coating}
                onChange={handleCoatingChange}
                label="Coating">
                <MenuItem value="patient1">Patient1</MenuItem>
                <MenuItem value="patient1">Patient2</MenuItem>
              </Select> */}
            </FormControl>
            <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: -5 }}>
              <TextField
                variant="standard"
                sx={{ mt: 1, ml: 2 }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleAddonChange(event)
                }
                value={addon?.price}
              />
              <Typography sx={{ mt: 2 }}>Price: </Typography>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Typography variant="h6">Voucher</Typography>
          <Stack sx={{ ml: 15, mt: -7 }}>
            <FormControl variant="standard" sx={{ m: 1, width: "40%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Voucher
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={voucher?.name!}
                onChange={handleVoucherChange}
                label="voucherId"
              >
                {selectedVoucher.map((voucherItem) => (
                  <MenuItem key={voucherItem.id} value={voucherItem.name}>
                    {voucherItem.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: -5 }}>
              <TextField
                variant="standard"
                sx={{ mt: 1, ml: 2 }}
                disabled
                value={voucher?.price}
              />
              <Typography sx={{ mt: 2 }}>Price: </Typography>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Typography variant="h6">Total</Typography>
          <Stack sx={{ ml: 15 }}>
            <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: -5 }}>
              <TextField
                variant="standard"
                sx={{ mt: 1, ml: 2 }}
                value={totalPrice}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleTotalPriceChange(event)
                }
              />
              <Typography sx={{ mt: 2 }}>Price: </Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
