import axios from "../utils/axios";

export class PrescreeningService {
  getAllPrescreenings(data?: any) {
    return axios.get("/specification-history", data);
  }
  getPrescreeningById(id: any) {
    return axios.get(`/specification-history/${id}`);
  }
  createPrescreening(data: any) {
    return axios.post("/specification-history", data);
  }
  updatePrescreening(id: any, data: any) {
    return axios.put(`/specification-history/${id}`, data);
  }
  deletePrescreening(id: any, data: any) {
    return axios.delete(`/specification-history/${id}`, data);
  }
  createNewClinicalRecord(id:any,data:any){
    return axios.post(`/patient/${id}/prescreening`,data)
  }
}
