import { Card, Box, Button } from "@mui/material";
import RxOutcome from "./RxOutcome";
import Trails from "./Trails";
import { ICLFitting } from "../../../../../@types/clFitting.type";
import { ITrail } from "../../../../../@types/trail.type";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";
import { useState } from "react";

type Props = {
  clinicalRecordId: number;
  tab: (type: string) => void;
  showCLForm: boolean;
  clFitting: ICLFitting;
  trails: ITrail[];
  getCLFittingRecords: (clFittingId: number, clinicalId: number) => void;
  getTrailsRecords: (clinicalId: number) => void;
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
};

const CLFitting = ({
  clinicalRecordId,
  tab,
  showCLForm,
  clFitting,
  trails,
  getCLFittingRecords,
  getTrailsRecords,
  selectedRow,
  clinicalRecords,
}: Props) => {
  const [semiFinal, setSemiFinal] = useState({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSemiFinal((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <Box
      sx={{
        margin: "2% 5%",
      }}>
      <Box mb={10}>
        <Trails
          clinicalRecordId={clinicalRecordId}
          trails={trails}
          getTrailsRecords={getTrailsRecords}
          selectedRow={selectedRow}
          clinicalRecords={clinicalRecords}
          handleChange={handleChange}
        />
      </Box>
      <Box mb={15}>
        <RxOutcome
          clinicalRecordId={clinicalRecordId}
          tab={tab}
          clFitting={clFitting}
          showCLForm={showCLForm}
          getCLFittingRecords={getCLFittingRecords}
          semiFinal={semiFinal}
        />
      </Box>
    </Box>
  );
};

export default CLFitting;
