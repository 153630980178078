import { ICLHandover } from "../../@types/clHandover.type";
import axios from "../../utils/axios";

export class CLHandoverService {
  createCLHandover(patientId: string, data: ICLHandover) {
    return axios.post(`/patient/${patientId}/contact-lens-handover`, data);
  }

  getCLHandover(patientId: string, clHandoverId: number) {
    const queryString = clHandoverId ? `?clHandoverId=${clHandoverId}` : "";

    return axios.get(
      `/patient/${patientId}/contact-lens-handover${queryString}`
    );
  }
}
