import { IAddonRequest, Addon } from "../@types/addon.type";
import axios from "../utils/axios";

export class AddonService {
  getAllAddons() {
    return axios.get<Addon[]>("/addon");
  }
  createAddon(payload: IAddonRequest) {
    return axios.post<Addon>("/addon", payload);
  }
  updateAddon(id: number, payload: IAddonRequest) {
    return axios.patch<Addon>(`/addon/${id}`, payload);
  }
  deleteAddon(id: number) {
    return axios.delete<string>(`/addon/${id}`);
  }
}
