import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DistanceVision } from "./DistanceVision";
import { NearVision } from "./NearVision";
import { IntermediateVision } from "./IntermediateVision";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  tab: any;
};
export const AutoRefractor = ({ tab }: Props) => {
  const [fullView, setFullView] = useState(false);
  const handleFullView = () => {
    setFullView(true);
  };

  const { setValue, getValues } = useFormContext();

  const copyData = () => {
    const fieldTypes = ["distance", "near", "intermediate"];
    const sides = ["Right", "Left", "Both"];
    const params = [
      "SPH",
      "CYL",
      "Axis",
      "AidedVA",
      "Prism",
      "DistancePD",
      "Add",
      "NearPD",
      "InterPD",
    ];

    const mapValues = (type: string, side: string, param: string) => {
      const autoRefractorFields = `autoRefractor.${type}${side}${param}`;
      const subjectiveFields = `subjective.${type}${side}${param}`;

      const value = getValues(autoRefractorFields);
      setValue(subjectiveFields, value);
    };

    fieldTypes.forEach((type) => {
      sides.forEach((side) => {
        params.forEach((param) => {
          const validParam =
            (type === "distance" &&
              param !== "Add" &&
              param !== "NearPD" &&
              param !== "InterPD") ||
            (type === "near" &&
              param !== "DistancePD" &&
              param !== "InterPD") ||
            (type === "intermediate" &&
              param !== "DistancePD" &&
              param !== "NearPD");
          if (validParam) {
            mapValues(type, side, param);
          }
        });
      });
    });
    tab("subjective");
  };
  const clearFields = () => {
    const fields = {
      distanceRight: ["SPH", "CYL", "Axis", "AidedVA", "Prism", "DistancePD"],
      both: ["AidedVA", "Prism"],
      left: ["SPH", "CYL", "Axis", "AidedVA", "Prism", "DistancePD"],
      nearRight: ["Add", "SPH", "CYL", "Axis", "AidedVA", "Prism", "NearPD"],
      nearBoth: ["AidedVA", "Prism"],
      nearLeft: ["Add", "SPH", "CYL", "Axis", "AidedVA", "Prism", "NearPD"],
      intermediateRight: [
        "Add",
        "SPH",
        "CYL",
        "Axis",
        "AidedVA",
        "Prism",
        "InterPD",
      ],
      intermediateBoth: ["AidedVA", "Prism"],
      intermediateLeft: [
        "Add",
        "SPH",
        "CYL",
        "Axis",
        "AidedVA",
        "Prism",
        "InterPD",
      ],
    };

    const clearFieldsFromMappings = () => {
      Object.entries(fields).forEach(([key, attributes]) => {
        attributes.forEach((attr) => {
          const fieldName = `autoRefractor.${key}${attr}`;
          setValue(fieldName, "");
        });
      });
    };

    clearFieldsFromMappings();
  };

  const handleCompactView = () => {
    setFullView(false);
  };

  return (
    <Box>
      <DistanceVision fullView={fullView} />
      <NearVision fullView={fullView} />
      <IntermediateVision fullView={fullView} />
      <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: 5 }}>
        <LoadingButton variant="contained">Exit</LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => tab("subjective")}>
          Next
        </LoadingButton>
        <LoadingButton variant="outlined" sx={{ mr: 2 }} onClick={clearFields}>
          Clear
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={fullView ? handleCompactView : handleFullView}>
          {fullView ? "Compact View" : "Full View"}
        </LoadingButton>
        <LoadingButton variant="outlined" sx={{ mr: 2 }} onClick={copyData}>
          Copy to Subjective
        </LoadingButton>
      </Box>
    </Box>
  );
};
