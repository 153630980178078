import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AddNewTrail from "./AddNewTrail";
import { ITrail } from "../../../../../@types/trail.type";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";

type HandleChange = (e: React.ChangeEvent<HTMLInputElement>) => void;

type Props = {
  clinicalRecordId: number;
  trails: ITrail[];
  getTrailsRecords: (clinicalId: number) => void;
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
  handleChange: HandleChange;
};

const Trails = ({
  clinicalRecordId,
  trails,
  getTrailsRecords,
  selectedRow,
  clinicalRecords,
  handleChange,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const [selectedTrail, setSelectedTrail] = useState<ITrail | null>(null);

  const handleClick = (trail: ITrail | null) => {
    setSelectedTrail(trail);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTrail(null);
  };

  const formatTrailDate = (trail: ITrail) => {
    const date = new Date(trail.createdDate);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}/${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  };

  const handleCopy = (trail: ITrail) => {
    const fieldsToUpdate = [
      { name: "rightBC", value: trail.trailsRecord?.semiRightBC || "" },
      { name: "rightSPH", value: trail.trailsRecord?.semiRightSPH || "" },
      { name: "rightCYL", value: trail.trailsRecord?.semiRightCYL || "" },
      { name: "rightAxis", value: trail.trailsRecord?.semiRightAxis || "" },
      { name: "rightVA", value: trail.trailsRecord?.semiRightVA || "" },
      { name: "rightAdd", value: trail.trailsRecord?.semiRightAdd || "" },
      { name: "rightNV", value: trail.trailsRecord?.semiRightNV || "" },
      { name: "leftBC", value: trail.trailsRecord?.semiLeftBC || "" },
      { name: "leftSPH", value: trail.trailsRecord?.semiLeftSPH || "" },
      { name: "leftCYL", value: trail.trailsRecord?.semiLeftCYL || "" },
      { name: "leftAxis", value: trail.trailsRecord?.semiLeftAxis || "" },
      { name: "leftVA", value: trail.trailsRecord?.semiLeftVA || "" },
      { name: "leftAdd", value: trail.trailsRecord?.semiLeftAdd || "" },
      { name: "leftNV", value: trail.trailsRecord?.semiLeftNV || "" },
    ];

    fieldsToUpdate.forEach((field) => {
      handleChange({
        target: {
          name: field.name,
          value: field.value,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    });
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
        <Typography variant="h6">Trails</Typography>
        <Button onClick={() => handleClick(null)}>Add New Trail</Button>
      </Box>
      {trails && trails.length > 0 ? (
        trails.map((trail) => (
          <Box key={trail.id}>
            <Card
              sx={{ margin: "1% 2%", cursor: "pointer" }}
              onClick={() => handleClick(trail)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    ml: 2,
                    mt: 2,
                    display: "inline-flex",
                  }}>{`Trail # ${trail.id}`}</Typography>
                <Typography
                  variant="h6"
                  sx={{ display: "inline-flex", mr: 2, mt: 2 }}>
                  {formatTrailDate(trail)}{" "}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}>
                <Typography
                  sx={{ color: "text.secondary", marginTop: 1, marginLeft: 2 }}>
                  <strong>Supplier:</strong> {trail.trailsRecord?.supplier}
                </Typography>
                <Typography
                  sx={{ color: "text.secondary", marginTop: 1, marginLeft: 2 }}>
                  <strong>Type:</strong> {trail.trailsRecord?.type}
                </Typography>
                <Typography
                  sx={{ color: "text.secondary", marginTop: 1, marginLeft: 2 }}>
                  <strong>Freq:</strong> {trail.trailsRecord?.freq}
                </Typography>
                <Typography
                  sx={{ color: "text.secondary", marginTop: 1, marginLeft: 2 }}>
                  <strong>Lens:</strong> {trail.trailsRecord?.lens}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6" sx={{ ml: 2 }}>
                  Semi Final Rx
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopy(trail);
                  }}>
                  Copy To CL Fitting Outcome
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                mt={2}
                ml={5}>
                <Typography sx={{ mr: 1 }}>Right</Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    BC
                    <TextField
                      type="text"
                      sx={{ width: "70px" }}
                      name="rightBC"
                      value={trail.trailsRecord?.semiRightBC}
                      disabled
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "1px",
                      margin: "0 10px",
                      height: "40px",
                      background: "black",
                      position: "relative",
                      top: "30px",
                      transform: "rotate(14deg)",
                    }}></Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    SPH
                    <TextField
                      disabled
                      type="text"
                      sx={{ width: "90px" }}
                      value={trail.trailsRecord?.semiRightSPH}
                      name="rightSPH"
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    CYL
                    <TextField
                      disabled
                      type="text"
                      value={trail.trailsRecord?.semiRightCYL}
                      sx={{ width: "90px" }}
                      name="rightCYL"
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    AXIS
                    <TextField
                      disabled
                      sx={{ width: "90px" }}
                      value={trail.trailsRecord?.semiRightAxis}
                      name="rightAxis"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: 180,
                        onInput: (e: any) => {
                          if (e.target.value.length > 3) {
                            e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                          }
                        },
                      }}
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    VA
                    <TextField
                      disabled
                      type="text"
                      sx={{ width: "90px" }}
                      name="rightVA"
                      value={trail.trailsRecord?.semiRightVA}
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    ADD
                    <TextField
                      disabled
                      type="text"
                      sx={{ width: "90px" }}
                      value={trail.trailsRecord?.semiRightAdd}
                      name="rightAdd"
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    NV
                    <TextField
                      disabled
                      type="text"
                      sx={{ width: "90px" }}
                      name="rightNV"
                      value={trail.trailsRecord?.semiRightNV}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                mt={1}
                mb={2}
                ml={5}>
                <Typography sx={{ mr: 2 }}>Left</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <TextField
                      disabled
                      type="text"
                      sx={{ width: "70px" }}
                      value={trail.trailsRecord?.semiLeftBC}
                      name="leftBC"
                    />
                  </Box>
                  <Box
                    className="line1"
                    sx={{
                      width: "1px",
                      margin: "0 10px",
                      height: "40px",
                      background: "black",
                      position: "relative",
                      top: "9px",
                      transform: "rotate(14deg)",
                    }}></Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <TextField
                      disabled
                      type="text"
                      value={trail.trailsRecord?.semiLeftSPH}
                      sx={{ width: "90px" }}
                      name="leftSPH"
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <TextField
                      disabled
                      type="text"
                      value={trail.trailsRecord?.semiLeftCYL}
                      sx={{ width: "90px" }}
                      name="leftCYL"
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <TextField
                      disabled
                      type="text"
                      sx={{ width: "90px" }}
                      name="leftAxis"
                      value={trail.trailsRecord?.semiLeftAxis}
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <TextField
                      disabled
                      type="text"
                      value={trail.trailsRecord?.semiLeftVA}
                      sx={{ width: "90px" }}
                      name="leftVA"
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <TextField
                      disabled
                      value={trail.trailsRecord?.semiLeftAdd}
                      type="text"
                      sx={{ width: "90px" }}
                      name="leftAdd"
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <TextField
                      disabled
                      type="text"
                      value={trail.trailsRecord?.semiLeftNV}
                      sx={{ width: "90px" }}
                      name="leftNV"
                    />
                  </Box>
                </Box>
              </Box>
            </Card>
          </Box>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
          No Trails Found
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="lg"
        open={openModal}
        onClose={handleCloseModal}>
        <DialogTitle>
          {selectedTrail ? "View Trail" : "Add New Trail"}
        </DialogTitle>
        <AddNewTrail
          setOpenModal={(openModal: boolean) => setOpenModal(openModal)}
          clinicalRecordId={clinicalRecordId}
          getTrailsRecords={getTrailsRecords}
          trailData={selectedTrail}
          selectedRow={selectedRow}
          clinicalRecords={clinicalRecords}
          handleChange={handleChange}
        />
      </Dialog>
    </>
  );
};

export default Trails;
