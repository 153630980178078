import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { RHFTextField } from "../../../../components/hook-form";
import AddCanvas from "./AddCanvas";
import { useFormContext } from "react-hook-form";
import { PreviousRecords } from "./PreviousRecords";
import { useParams } from "react-router-dom";
import { ClinicalRecords } from "../../../../api/clinicalRecords.service";

type Props = {
  clinicalRecordId: number;
};

const External = ({ clinicalRecordId }: Props) => {
  const [openCanvasSide, setOpenCanvasSide] = useState<string>("");
  const [openRecordsModal, setOpenRecordsModal] = useState(false);
  const [clinicalRecords, setClinicalRecords] = useState<any>([]);
  const [examination, setExamination] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [leftCanvas, setLeftCanvas] = useState("");
  const [rightCanvas, setRightCanvas] = useState("");

  const id = window.location.pathname.split("/")[2];

  const getAllClinicsRecords = () => {
    const clinicalRecords = new ClinicalRecords();
    clinicalRecords.getAllClinicalRecords(+id).then((res) => {
      setClinicalRecords(res.data);
      const examinationId = res.data[0]?.examinationId;
      const clinicalId = res.data[0]?.id;
      setSelectedRow(clinicalId);

      getExaminationRecords(examinationId, clinicalId);
    });
  };
  const { patientId } = useParams();

  const getExaminationRecords = (examinationId: number, clinicalId: number) => {
    const clinicalRecords = new ClinicalRecords();
    if (examinationId !== null) {
      clinicalRecords
        .getExaminationByClinicalRecordId(patientId, examinationId)
        .then((res) => {
          setExamination(res.data[0]);
          setPreviousData();
        });
    } else {
      setExamination([]);
    }
  };

  const setPreviousData = useCallback(() => {
    if (!examination) return;
    setValue(
      "externalEyeHealth.rightConjuctiva",
      examination?.externalEyeHealth?.rightConjuctiva
    );
    setValue(
      "externalEyeHealth.rightCornea",
      examination?.externalEyeHealth?.rightCornea
    );
    setValue(
      "externalEyeHealth.rightLidsMargin",
      examination?.externalEyeHealth?.rightLidsMargin
    );
    setValue(
      "externalEyeHealth.rightTears",
      examination?.externalEyeHealth?.rightTears
    );
    setValue(
      "externalEyeHealth.rightAnteriorChamber",
      examination?.externalEyeHealth?.rightAnteriorChamber
    );
    setValue(
      "externalEyeHealth.rightIris",
      examination?.externalEyeHealth?.rightIris
    );
    setValue(
      "externalEyeHealth.rightLimbus",
      examination?.externalEyeHealth?.rightLimbus
    );
    setValue(
      "externalEyeHealth.rightPupilSize",
      examination?.externalEyeHealth?.rightPupilSize
    );
    setValue(
      "externalEyeHealth.rightRAPD",
      examination?.externalEyeHealth?.rightRAPD
    );
    setValue(
      "externalEyeHealth.rightConvergence",
      examination?.externalEyeHealth?.rightConvergence
    );
    setValue(
      "externalEyeHealth.rightMotility",
      examination?.externalEyeHealth?.rightMotility
    );
    setValue(
      "externalEyeHealth.rightConfrontation",
      examination?.externalEyeHealth?.rightConfrontation
    );
    setValue(
      "externalEyeHealth.rightAdditionalNotes",
      examination?.externalEyeHealth?.rightAdditionalNotes
    );
    setValue(
      "externalEyeHealth.leftConjuctiva",
      examination?.externalEyeHealth?.leftConjuctiva
    );
    setValue(
      "externalEyeHealth.leftCornea",
      examination?.externalEyeHealth?.leftCornea
    );
    setValue(
      "externalEyeHealth.leftLidsMargin",
      examination?.externalEyeHealth?.leftLidsMargin
    );
    setValue(
      "externalEyeHealth.leftTears",
      examination?.externalEyeHealth?.leftTears
    );
    setValue(
      "externalEyeHealth.leftAnteriorChamber",
      examination?.externalEyeHealth?.leftAnteriorChamber
    );
    setValue(
      "externalEyeHealth.leftIris",
      examination?.externalEyeHealth?.leftIris
    );
    setValue(
      "externalEyeHealth.leftLimbus",
      examination?.externalEyeHealth?.leftLimbus
    );
    setValue(
      "externalEyeHealth.leftPupilSize",
      examination?.externalEyeHealth?.rightConjuctiva
    );
    setValue(
      "externalEyeHealth.leftRAPD",
      examination?.externalEyeHealth?.leftRAPD
    );
    setValue(
      "externalEyeHealth.leftConvergence",
      examination?.externalEyeHealth?.leftConvergence
    );
    setValue(
      "externalEyeHealth.leftMotility",
      examination?.externalEyeHealth?.leftMotility
    );
    setValue(
      "externalEyeHealth.leftConfrontation",
      examination?.externalEyeHealth?.leftConfrontation
    );
    setValue(
      "externalEyeHealth.leftAdditionalNotes",
      examination?.externalEyeHealth?.leftAdditionalNotes
    );
    setValue(
      "ophthalmoscopy.rightDisc",
      examination?.ophthalmoscopy?.rightDisc
    );
    setValue("ophthalmoscopy.rightAV", examination?.ophthalmoscopy?.rightAV);
    setValue(
      "ophthalmoscopy.rightMacula",
      examination?.ophthalmoscopy?.rightMacula
    );
    setValue(
      "ophthalmoscopy.rightCDRatio",
      examination?.ophthalmoscopy?.rightCDRatio
    );
    setValue(
      "ophthalmoscopy.rightFundus",
      examination?.ophthalmoscopy?.rightFundus
    );
    setValue("ophthalmoscopy.leftDisc", examination?.ophthalmoscopy?.leftDisc);
    setValue("ophthalmoscopy.leftAV", examination?.ophthalmoscopy?.leftAV);
    setValue(
      "ophthalmoscopy.leftMacula",
      examination?.ophthalmoscopy?.leftMacula
    );
    setValue(
      "ophthalmoscopy.leftCDRatio",
      examination?.ophthalmoscopy?.leftCDRatio
    );
    setValue(
      "ophthalmoscopy.leftFundus",
      examination?.ophthalmoscopy?.leftFundus
    );
    setLeftCanvas(examination?.externalEyeHealth?.leftVisualImage);
    setRightCanvas(examination?.externalEyeHealth?.rightVisualImage);
  }, [examination]);

  const handleOpenRecordModal = () => {
    setOpenRecordsModal(true);
  };

  const handleCloseRecordModal = () => {
    setOpenRecordsModal(false);
  };

  const handleCanvasModal = (side: string) => {
    if (side === "left") {
      const leftVisualImage = getValues("externalEyeHealth.leftVisualImage");
      if (leftVisualImage && !leftCanvas) setLeftCanvas(leftVisualImage);
    }
    if (side === "right") {
      const rightVisualImage = getValues("externalEyeHealth.rightVisualImage");
      if (rightVisualImage && !rightCanvas) setRightCanvas(rightVisualImage);
    }

    setOpenCanvasSide(side);
  };

  const handleCloseCanvasModal = () => {
    setOpenCanvasSide("");
  };

  const { setValue, getValues } = useFormContext();

  const clearExternalData = () => {
    const fieldNames = [
      "externalEyeHealth.leftConjuctiva",
      "externalEyeHealth.leftLidsMargin",
      "externalEyeHealth.leftCornea",
      "externalEyeHealth.leftTears",
      "externalEyeHealth.leftAnteriorChamber",
      "externalEyeHealth.leftLimbus",
      "externalEyeHealth.leftIris",
      "externalEyeHealth.leftPupilSize",
      "externalEyeHealth.leftRAPD",
      "externalEyeHealth.leftConvergence",
      "externalEyeHealth.leftMotility",
      "externalEyeHealth.leftConfrontation",
      "externalEyeHealth.leftAdditionalNotes",
      "externalEyeHealth.rightConjuctiva",
      "externalEyeHealth.rightLidsMargin",
      "externalEyeHealth.rightCornea",
      "externalEyeHealth.rightTears",
      "externalEyeHealth.rightAnteriorChamber",
      "externalEyeHealth.rightLimbus",
      "externalEyeHealth.rightIris",
      "externalEyeHealth.rightPupilSize",
      "externalEyeHealth.rightRAPD",
      "externalEyeHealth.rightConvergence",
      "externalEyeHealth.rightMotility",
      "externalEyeHealth.rightConfrontation",
      "externalEyeHealth.rightAdditionalNotes",
    ];

    const clearedValues = { ...getValues() };
    fieldNames.forEach((fieldName) => {
      clearedValues[fieldName] = "";
    });

    Object.keys(clearedValues).forEach((fieldName) => {
      setValue(fieldName, clearedValues[fieldName]);
    });
  };

  const copyValues = () => {
    const leftConjuctiva = getValues("externalEyeHealth.leftConjuctiva");
    const leftAnteriorChamber = getValues(
      "externalEyeHealth.leftAnteriorChamber"
    );
    const leftLidsMargin = getValues("externalEyeHealth.leftLidsMargin");
    const leftCornea = getValues("externalEyeHealth.leftCornea");
    const leftTears = getValues("externalEyeHealth.leftTears");
    const leftIris = getValues("externalEyeHealth.leftIris");
    const leftLimbus = getValues("externalEyeHealth.leftLimbus");
    const leftPupilSize = getValues("externalEyeHealth.leftPupilSize");
    const leftRAPD = getValues("externalEyeHealth.leftRAPD");
    const leftConvergence = getValues("externalEyeHealth.leftConvergence");
    const leftMotility = getValues("externalEyeHealth.leftMotility");
    const leftConfrontation = getValues("externalEyeHealth.leftConfrontation");
    const leftAdditionalNotes = getValues(
      "externalEyeHealth.leftAdditionalNotes"
    );

    setValue("externalEyeHealth.rightConjuctiva", leftConjuctiva);
    setValue("externalEyeHealth.rightCornea", leftCornea);
    setValue("externalEyeHealth.rightLidsMargin", leftLidsMargin);
    setValue("externalEyeHealth.rightTears", leftTears);
    setValue("externalEyeHealth.rightAnteriorChamber", leftAnteriorChamber);
    setValue("externalEyeHealth.rightIris", leftIris);
    setValue("externalEyeHealth.rightLimbus", leftLimbus);
    setValue("externalEyeHealth.rightPupilSize", leftPupilSize);
    setValue("externalEyeHealth.rightRAPD", leftRAPD);
    setValue("externalEyeHealth.rightConvergence", leftConvergence);
    setValue("externalEyeHealth.rightMotility", leftMotility);
    setValue("externalEyeHealth.rightConfrontation", leftConfrontation);
    setValue("externalEyeHealth.rightAdditionalNotes", leftAdditionalNotes);
  };

  const setExternalDefaultValue = () => {
    setValue("externalEyeHealth.rightConjuctiva", "clear");
    setValue("externalEyeHealth.rightCornea", "clear");
    setValue("externalEyeHealth.rightLidsMargin", "lashes clear");
    setValue("externalEyeHealth.rightTears", "clear");
    setValue("externalEyeHealth.rightAnteriorChamber", "clear");
    setValue("externalEyeHealth.rightIris", "normal");
    setValue("externalEyeHealth.rightLimbus", "clear");
    setValue("externalEyeHealth.rightPupilSize", "normal");
    setValue("externalEyeHealth.rightRAPD", "PERRL");
    setValue("externalEyeHealth.rightConvergence", "within normal limits");
    setValue("externalEyeHealth.rightMotility", "full");
    setValue("externalEyeHealth.rightConfrontation", "good");
    setValue("externalEyeHealth.rightAdditionalNotes", "");
    setValue("externalEyeHealth.leftConjuctiva", "clear");
    setValue("externalEyeHealth.leftCornea", "clear");
    setValue("externalEyeHealth.leftLidsMargin", "lashes clear");
    setValue("externalEyeHealth.leftTears", "clear");
    setValue("externalEyeHealth.leftAnteriorChamber", "clear");
    setValue("externalEyeHealth.leftIris", "normal");
    setValue("externalEyeHealth.leftLimbus", "clear");
    setValue("externalEyeHealth.leftPupilSize", "normal");
    setValue("externalEyeHealth.leftRAPD", "PERRL");
    setValue("externalEyeHealth.leftConvergence", "within normal limits");
    setValue("externalEyeHealth.leftMotility", "full");
    setValue("externalEyeHealth.leftConfrontation", "good");
    setValue("externalEyeHealth.leftAdditionalNotes", "");
  };

  useEffect(() => {
    getAllClinicsRecords();
  }, []);

  useEffect(() => {
    if (rightCanvas) {
      setValue("externalEyeHealth.rightVisualImage", rightCanvas);
    }
    if (leftCanvas) {
      setValue("externalEyeHealth.leftVisualImage", leftCanvas);
    }
  }, [rightCanvas, leftCanvas]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="right"
        ml={1}
        mr={1}
        spacing={2}>
        <Button variant="contained" onClick={clearExternalData}>
          Clear
        </Button>
        <Button variant="contained" onClick={setExternalDefaultValue}>
          Default
        </Button>
        <Button variant="contained" onClick={copyValues}>
          Copy R to L
        </Button>
        <Button variant="contained" onClick={handleOpenRecordModal}>
          Previous Records
        </Button>
      </Stack>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        p={5}>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Right
          </Typography>
          <RHFTextField
            name="externalEyeHealth.leftConjuctiva"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftLidsMargin"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftCornea"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftTears"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftLimbus"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftAnteriorChamber"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftIris"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftPupilSize"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftRAPD"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftConvergence"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftMotility"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftConfrontation"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.leftAdditionalNotes"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <Button
            variant="outlined"
            onClick={() => handleCanvasModal("left")}
            sx={{ width: "90%", marginBottom: "10px" }}>
            Images
          </Button>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "60px",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}></Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Conjuctiva
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Lids Margins
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Cornea
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Tears
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Limbus
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Anterior Chamber
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Iris
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Pupil Size
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            RAPD
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Acc/Convergence
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Motility
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Confrontation
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={9}>
            Additional Notes
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Left
          </Typography>
          <RHFTextField
            name="externalEyeHealth.rightConjuctiva"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.rightLidsMargin"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.rightCornea"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.rightTears"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.rightLimbus"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.rightAnteriorChamber"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.rightIris"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="externalEyeHealth.rightPupilSize"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="externalEyeHealth.rightRAPD"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="externalEyeHealth.rightConvergence"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="externalEyeHealth.rightMotility"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="externalEyeHealth.rightConfrontation"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />{" "}
          <RHFTextField
            name="externalEyeHealth.rightAdditionalNotes"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <Button
            variant="outlined"
            onClick={() => handleCanvasModal("right")}
            sx={{ width: "90%", marginBottom: "10px" }}>
            Images
          </Button>
        </Box>
        {openCanvasSide !== "" && (
          <Dialog
            fullWidth
            maxWidth="md"
            open={Boolean(openCanvasSide)}
            onClose={handleCloseCanvasModal}>
            <DialogTitle>Add Canvas</DialogTitle>
            <AddCanvas
              canvas={
                openCanvasSide === "left"
                  ? leftCanvas
                  : openCanvasSide === "right"
                  ? rightCanvas
                  : undefined
              }
              side={openCanvasSide}
              setCanvas={(value: string) =>
                openCanvasSide === "left"
                  ? setLeftCanvas(value)
                  : openCanvasSide === "right"
                  ? setRightCanvas(value)
                  : null
              }
              type="external"

              // leftCanvas={leftCanvas}
              // rightCanvas={rightCanvas}
              // setLeftCanvas={(value: string) => setLeftCanvas(value)}
              // setRightCanvas={(value: string) => setRightCanvas(value)}
              // type="external"
            />
          </Dialog>
        )}
        <Dialog
          fullWidth
          maxWidth="md"
          open={openRecordsModal}
          onClose={handleCloseRecordModal}>
          <DialogTitle>Records</DialogTitle>

          <PreviousRecords
            selectedRow={selectedRow}
            clinicalRecords={clinicalRecords}
            getExaminationRecords={getExaminationRecords}
            setSelectedRow={setSelectedRow}
            previousRecordsRow={clinicalRecordId}
          />
        </Dialog>
      </Box>
    </>
  );
};

export default External;
