import { Box, Button, Checkbox, Typography } from "@mui/material";
import FormProvider from "../../../../../components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { ICLFitting } from "../../../../../@types/clFitting.type";
import { CLFittingService } from "../../../../../api/contact-lenses/cl-fitting.service";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "../../../../../components/snackbar";
import RHFTextField from "../../../../../components/hook-form/RHFTextField";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

type Props = {
  clinicalRecordId: number;
  tab: (typep: string) => void;
  clFitting: ICLFitting;
  showCLForm: boolean;
  getCLFittingRecords: (clFittingId: number, clinicalId: number) => void;
  semiFinal: any;
};

const RxOutcome = ({
  clinicalRecordId,
  tab,
  clFitting,
  showCLForm,
  getCLFittingRecords,
  semiFinal,
}: Props) => {
  const methods = useForm<ICLFitting>();
  const { handleSubmit, reset } = methods;

  const navigate = useNavigate();

  const { patientId } = useParams();

  const onSubmit = async (data: ICLFitting) => {
    const clFittingService = new CLFittingService();
    try {
      const response = await clFittingService.createCLFitting(patientId!, {
        ...data,
        clinicalRecordId,
      });
      enqueueSnackbar("CL Fitting Created Successfully!", {
        variant: "success",
      });
      getCLFittingRecords(response.data?.id, response.data?.clinicalRecordId);
      tab("cl_aftercare");
    } catch (error: any) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (showCLForm) {
      reset({
        rightBC: "",
        leftBC: "",
        rightSPH: "",
        leftSPH: "",
        rightCYL: "",
        leftCYL: "",
        rightAxis: "",
        leftAxis: "",
        leftVA: "",
        rightVA: "",
        leftAdd: "",
        rightAdd: "",
        leftNV: "",
        rightNV: "",
      });
    } else {
      reset({
        rightBC: clFitting?.rightBC || "",
        leftBC: clFitting?.leftBC || "",
        rightSPH: clFitting?.rightSPH || "",
        leftSPH: clFitting?.leftSPH || "",
        rightCYL: clFitting?.rightCYL || "",
        leftCYL: clFitting?.leftCYL || "",
        rightAxis: clFitting?.rightAxis || "",
        leftAxis: clFitting?.leftAxis || "",
        leftVA: clFitting?.leftVA || "",
        rightVA: clFitting?.rightVA || "",
        leftAdd: clFitting?.leftAdd || "",
        rightAdd: clFitting?.rightAdd || "",
        leftNV: clFitting?.leftNV || "",
        rightNV: clFitting?.rightNV || "",
      });
    }
  }, [showCLForm, clFitting, reset]);

  useEffect(() => {
    if (semiFinal && Object.keys(semiFinal).length > 0) {
      reset({
        rightBC: semiFinal.rightBC || "",
        leftBC: semiFinal.leftBC || "",
        rightSPH: semiFinal.rightSPH || "",
        leftSPH: semiFinal.leftSPH || "",
        rightCYL: semiFinal.rightCYL || "",
        leftCYL: semiFinal.leftCYL || "",
        rightAxis: semiFinal.rightAxis || "",
        leftAxis: semiFinal.leftAxis || "",
        leftVA: semiFinal.leftVA || "",
        rightVA: semiFinal.rightVA || "",
        leftAdd: semiFinal.leftAdd || "",
        rightAdd: semiFinal.rightAdd || "",
        leftNV: semiFinal.leftNV || "",
        rightNV: semiFinal.rightNV || "",
      });
    }
  }, [semiFinal, reset]);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {(showCLForm || !clFitting || Object.keys(clFitting).length === 0) && (
        <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
          * There's no CL Fitting added for this clinical record, please add and
          save!
        </Typography>
      )}
      <Typography variant="h6" sx={{ m: 2, ml: 3 }}>
        Final RxOutcome
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        mt={1}>
        <Typography>Right</Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            BC
            <RHFTextField type="text" sx={{ width: "70px" }} name="rightBC" />
          </Box>
          <Box
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              background: "black",
              position: "relative",
              top: "30px",
              transform: "rotate(14deg)",
            }}></Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            SPH
            <RHFTextField type="text" sx={{ width: "90px" }} name="rightSPH" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            CYL
            <RHFTextField type="text" sx={{ width: "90px" }} name="rightCYL" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            AXIS
            <RHFTextField
              sx={{ width: "90px" }}
              name="rightAxis"
              type="number"
              inputProps={{
                step: 1,
                min: 1,
                max: 180,
                onInput: (e: any) => {
                  if (e.target.value.length > 3) {
                    e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                  }
                },
              }}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            VA
            <RHFTextField type="text" sx={{ width: "90px" }} name="rightVA" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            ADD
            <RHFTextField type="text" sx={{ width: "90px" }} name="rightAdd" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            NV
            <RHFTextField type="text" sx={{ width: "90px" }} name="rightNV" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Checkbox {...label} />
            Override
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        mt={1}
        mb={2}
        ml={1}>
        <Typography>Left</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField type="text" sx={{ width: "70px" }} name="leftBC" />
          </Box>
          <Box
            className="line1"
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              background: "black",
              position: "relative",
              top: "9px",
              transform: "rotate(14deg)",
            }}></Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField type="text" sx={{ width: "90px" }} name="leftSPH" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField type="text" sx={{ width: "90px" }} name="leftCYL" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField type="text" sx={{ width: "90px" }} name="leftAxis" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField type="text" sx={{ width: "90px" }} name="leftVA" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField type="text" sx={{ width: "90px" }} name="leftAdd" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField type="text" sx={{ width: "90px" }} name="leftNV" />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Checkbox {...label} />
            Override
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          gap: 2,
          mt: 5,
        }}>
        <Button type="submit" variant="contained">
          Save And Next
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate("/diary/appointments")}>
          Book For Teach
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate("/diary/appointments")}>
          To Trail
        </Button>
      </Box>
    </FormProvider>
  );
};

export default RxOutcome;
