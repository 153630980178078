import { Container, Card, TableContainer } from "@mui/material";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";

import { PATH_DASHBOARD } from "../../../routes/paths";
import { useSettingsContext } from "../../../components/settings";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import ProductList from "../../../sections/@dashboard/product/ProductList";
import { useLocation, useParams } from "react-router-dom";
import { IProduct } from "../../../@types/product";
import ProductDetails from "../../../sections/@dashboard/product/ProductDetails";

export default function ProductDetailsPage() {
  const { themeStretch } = useSettingsContext();
  const { productId } = useParams();
  const location = useLocation();
  const selectedProduct: IProduct = location?.state?.product;
  const selectedProductId = `${selectedProduct?.id}` || productId;
  return (
    <>
      <Helmet>
        <title>Product</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Product"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Product",
              href: PATH_DASHBOARD.product.list,
            },
            { name: selectedProductId },
            {
              name: "Details",
            },
          ]}
          action={<></>}
        />
        <Card>
          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <ProductDetails productId={Number(productId)!} />
            </Scrollbar>
          </TableContainer>
        </Card>
      </Container>
    </>
  );
}
