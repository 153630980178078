import { Box, Typography } from "@mui/material";
import { IKeratometry } from "../../../../../@types/anteriorEye.type";
import { RHFTextField } from "../../../../../components/hook-form";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  keratometry: IKeratometry;
};
const Keratometry = ({ keratometry }: Props) => {
  const { reset } = useFormContext();

  useEffect(() => {
    reset({ keratometry });
  }, [keratometry, reset]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant="h6" sx={{ mt: 3 }}>
        Keratometry
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        mt={1}>
        <Typography sx={{ mr: 3 }}>Right</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            Hoz
            <RHFTextField
              name="keratometry.rightHoz1"
              type="text"
              sx={{ width: "70px" }}
            />
          </Box>
          <Box
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              position: "relative",
              top: "30px",
              right: "15px",
              fontSize: "25px",
            }}>
            @
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Box sx={{ height: "23px" }}></Box>
            <RHFTextField
              name="keratometry.rightHoz2"
              type="text"
              sx={{ width: "90px" }}
            />
          </Box>

          <Box
            className="line1"
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              background: "black",
              position: "relative",
              top: "30px",
              transform: "rotate(14deg)",
            }}></Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            Height
            <RHFTextField
              name="keratometry.rightHeight1"
              type="text"
              sx={{ width: "90px" }}
            />
          </Box>
          <Box
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              position: "relative",
              top: "30px",
              right: "15px",
              fontSize: "25px",
            }}>
            @
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Box sx={{ height: "23px" }}></Box>
            <RHFTextField
              name="keratometry.rightHeight2"
              type="text"
              sx={{ width: "90px" }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        mt={1}>
        <Typography sx={{ mr: 4 }}>Left</Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField
              name="keratometry.leftHoz1"
              type="text"
              sx={{ width: "70px" }}
            />
          </Box>
          <Box
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              position: "relative",
              top: "10px",
              right: "15px",
              fontSize: "25px",
            }}>
            @
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField
              name="keratometry.leftHoz2"
              type="text"
              sx={{ width: "90px" }}
            />
          </Box>
          <Box
            className="line1"
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              background: "black",
              position: "relative",
              top: "10px",
              transform: "rotate(14deg)",
            }}></Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField
              name="keratometry.leftHeight1"
              type="text"
              sx={{ width: "90px" }}
            />
          </Box>
          <Box
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              position: "relative",
              top: "10px",
              right: "15px",
              fontSize: "25px",
            }}>
            @
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <RHFTextField
              name="keratometry.leftHeight2"
              type="text"
              sx={{ width: "90px" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Keratometry;
