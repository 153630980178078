import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { IProduct } from "../../../@types/product";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Stack,
  Typography,
  InputAdornment,
  DialogActions,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  RHFAutocomplete,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from "../../../components/hook-form";
import FormProvider from "../../../components/hook-form/FormProvider";
import { BrandService } from "../../../api/brand.service";
import { SupplierService } from "../../../api/supplier.service";
import { ProductService } from "../../../api/product.service";
import { Brands } from "../../../@types/brand.type";
import { Suppliers } from "../../../@types/supplier.type";

type ProductProps = {
  isEdit?: boolean;
  isView?: boolean;
  selectedProduct?: IProduct;
  onCancel: (value?: boolean) => void;
};

type FormValuesProps = {
  name: string;
  sku: string;
  code: string;
  // image: string;
  price: number;
  category: string;
  gender: string;
  type: string;
  quantity: string;
  supplierId: string;
  brandId: string;
};

const GENDER_OPTION = [
  { label: "Men", value: "Men" },
  { label: "Women", value: "Women" },
  { label: "Kids", value: "Kids" },
];
const TYPE_OPTION = [
  {
    group: "Single Vision",
    category: "lens",
    categoryType: ["Distance", "Near", "Intermediate"],
  },
  {
    group: "Bifocal",
    category: "lens",
  },
  {
    group: "Varifocal",
    category: "lens",
  },
];
// const TYPE_OPTION_1 = [
//   { category: "lens", label: "Bifocal", value: "bifocal" },
//   { category: "lens", label: "Varifocal", value: "varifocal" },
//   { category: "lens", label: "Distance", value: "distance" },
//   { category: "lens", label: "Near", value: "near" },
//   { category: "frames", label: "A", value: "a" },
// ];
const CATEGORY_OPTION = [
  { label: "Frames", value: "frames" },
  { label: "Lens", value: "lens" },
  { label: "Coating", value: "Coating" },
  { label: "Glazing", value: "glazing" },
  { label: "Tints", value: "tints" },
];

export default function ProductNewEditForm({
  isEdit,
  isView,
  selectedProduct,
  onCancel,
}: ProductProps) {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [suppliers, setSuppliers] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    code: Yup.string().optional(),
    sku: Yup.string().optional(),
    quantity: Yup.number().moreThan(0, "Quantity should not be 0"),
    gender: Yup.string().optional(),
    category: Yup.string().optional(),
    type: Yup.string().optional(),
    brandId: Yup.number().optional(),
    supplierId: Yup.number().optional(),
    price: Yup.number().moreThan(0, "Price should not be $0.00"),
  });

  const defaultValues = useMemo(
    () => ({
      name: selectedProduct?.name || "",
      code: selectedProduct?.code || "",
      sku: selectedProduct?.sku || "",
      quantity: selectedProduct?.quantity || 0,
      gender: selectedProduct?.gender || GENDER_OPTION[2].value,
      category: selectedProduct?.category || "",
      type: selectedProduct?.type || "",
      brandId: selectedProduct?.brandId || undefined,
      supplierId: selectedProduct?.supplierId || undefined,
      price: selectedProduct?.price || 0,
      //   category: selectedProduct?.category || CATEGORY_OPTION[0].classify[1],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProduct]
  );

  const methods = useForm<any>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const getAllBrands = async () => {
    const brandService = new BrandService();
    const { data } = await brandService.getAllBrands();
    setBrands(data);
  };

  const getAllSuppliers = async () => {
    const supplierService = new SupplierService();
    const { data } = await supplierService.getAllSuppliers();
    setSuppliers(data);
  };

  useEffect(() => {
    getAllBrands();
    getAllSuppliers();
  }, []);

  useEffect(() => {
    if (isEdit && selectedProduct) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, selectedProduct, suppliers, brands]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const payload = {
        ...data,
        brandId: selectedBrand,
        supplierId: selectedSupplier,
      };
      const productService = new ProductService();
      if (isEdit) {
        await productService.updateProduct(selectedProduct?.id!, payload);
      } else {
        await productService.createProduct(payload);
      }
      reset();
      if (!isEdit) {
        enqueueSnackbar("Create success!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Update success!", {
          variant: "success",
        });
      }
      // navigate(PATH_DASHBOARD.product.list);
      onCancel(true);
    } catch (error: any) {
      enqueueSnackbar(error?.message[0] || "Something went wrong", {
        variant: "error",
      });
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <Box sx={{ display: "flex" }}>
          <RHFTextField
            sx={{ mr: 1 }}
            name="name"
            disabled={isView}
            label="Product Name"
          />

          <RHFTextField
            sx={{ mr: 1 }}
            name="code"
            disabled={isView}
            label="Product Code"
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <RHFTextField
            sx={{ mr: 1 }}
            name="sku"
            disabled={isView}
            label="Product SKU"
          />
          <RHFTextField
            sx={{ mr: 1 }}
            name="quantity"
            disabled={isView}
            type="number"
            label="Product Stock"
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Stack
            display="flex"
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", mr: 5 }}
            >
              Gender:
            </Typography>

            <RHFRadioGroup
              row
              name="gender"
              options={GENDER_OPTION}
              disabled={isView}
            />
          </Stack>
        </Box>
        <Box sx={{ display: "flex" }}>
          <RHFSelect
            native
            sx={{ mr: 1 }}
            name="category"
            label="Category"
            disabled={isView}
          >
            <option value="" placeholder="Please Select Category" />
            {CATEGORY_OPTION.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            sx={{ mr: 1 }}
            name="type"
            label="Type"
            disabled={isView}
          >
            <option value="" />
            {TYPE_OPTION.filter(
              (type) => type?.category === values.category
            ).map((option) =>
              option.categoryType ? (
                <optgroup key={option.group} label={option.group}>
                  {option.categoryType.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </optgroup>
              ) : (
                <option key={option.group} value={option.group}>
                  {option.group}
                </option>
              )
            )}
          </RHFSelect>

          {/* <RHFSelect native sx={{ mr: 1 }} name="type" label="Type">
            <option value="" placeholder="Please Select Category First" />
            {TYPE_OPTION_1.filter(
              (type) => type?.category === values.category
            ).map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </RHFSelect> */}
        </Box>
        <Box sx={{ display: "flex" }}>
          <RHFAutocomplete
            name="brandId"
            label="Brand"
            disabled={isView}
            sx={{ width: "100%", mr: 1 }}
            multiple={false}
            options={brands}
            getOptionLabel={(option: any) =>
              brands.find((brand: Brands) => brand.id === option)?.name
            }
            onChange={(e: any, value: any) =>
              setSelectedBrand(value ? value.id : null)
            }
            defaultValue={selectedBrand}
          />
          <RHFAutocomplete
            disabled={isView}
            name="supplierId"
            label="Supplier"
            sx={{ width: "100%", mr: 1 }}
            multiple={false}
            options={suppliers}
            getOptionLabel={(option: any) =>
              brands.find((suppliers: Suppliers) => suppliers.id === option)
                ?.name
            }
            onChange={(e: any, value: any) =>
              setSelectedSupplier(value ? value.id : null)
            }
            defaultValue={selectedSupplier}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <RHFTextField
            name="price"
            disabled={isView}
            label="Product Price"
            placeholder="0.00"
            onChange={(event: any) =>
              setValue("price", Number(event.target.value), {
                shouldValidate: true,
              })
            }
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box component="span" sx={{ color: "text.disabled" }}>
                    £
                  </Box>
                </InputAdornment>
              ),
              type: "number",
            }}
          />
        </Box>
      </Stack>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="inherit" onClick={() => onCancel()}>
          Cancel
        </Button>
        {!isView && (
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {isEdit ? "Update" : "Add"}
          </LoadingButton>
        )}
      </DialogActions>
    </FormProvider>
  );
}
