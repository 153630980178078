import { Box, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import FinalSummary from "./FinalSummary";

export const Summary = () => {
  const { getValues } = useFormContext();
  const rightLensSPH = getValues("lensMeter.distanceRightSPH");
  const rightLensCYL = getValues("lensMeter.distanceRightCYL");
  const rightLensAxis = getValues("lensMeter.distanceRightAxis");
  const nearRightAdd = getValues("lensMeter.nearRightAdd");
  const intermediateRight = getValues("lensMeter.intermediateRightAdd");
  const distanceRightPrism = getValues("lensMeter.distanceRightPrism");
  const leftLensSPH = getValues("lensMeter.distanceLeftSPH");
  const leftLensCYL = getValues("lensMeter.distanceLeftCYL");
  const leftLensAxis = getValues("lensMeter.distanceLeftAxis");
  const nearLeftAdd = getValues("lensMeter.nearLeftAdd");
  const intermediateLeft = getValues("lensMeter.intermediateLeftAdd");
  const distanceLeftPrism = getValues("lensMeter.distanceLeftPrism");

  const rightAutoSPH = getValues("autoRefractor.distanceRightSPH");
  const rightAutoCYL = getValues("autoRefractor.distanceRightCYL");
  const rightAutoAxis = getValues("autoRefractor.distanceRightAxis");
  const nearRightAddAuto = getValues("autoRefractor.nearRightAdd");
  const intermediateRightAuto = getValues("autoRefractor.intermediateRightAdd");
  const distanceRightPrismAuto = getValues("autoRefractor.distanceRightPrism");
  const leftAutoSPH = getValues("autoRefractor.distanceLeftSPH");
  const leftAutoCYL = getValues("autoRefractor.distanceLeftCYL");
  const leftAutoAxis = getValues("autoRefractor.distanceLeftAxis");
  const nearLeftAddAuto = getValues("autoRefractor.nearLeftAdd");
  const intermediateLeftAuto = getValues("autoRefractor.intermediateLeftAdd");
  const distanceLeftPrismAuto = getValues("autoRefractor.distanceLeftPrism");

  const rightSubSPH = getValues("subjective.distanceRightSPH");
  const rightSubCYL = getValues("subjective.distanceRightCYL");
  const rightSubAxis = getValues("subjective.distanceRightAxis");
  const nearRightAddSub = getValues("subjective.nearRightAdd");
  const intermediateRightSub = getValues("subjective.intermediateRightAdd");
  const distanceRightPrismSub = getValues("subjective.distanceRightPrism");
  const leftSubSPH = getValues("subjective.distanceLeftSPH");
  const leftSubCYL = getValues("subjective.distanceLeftCYL");
  const leftSubAxis = getValues("subjective.distanceLeftAxis");
  const nearLeftAddSub = getValues("subjective.nearLeftAdd");
  const intermediateLeftSub = getValues("subjective.intermediateLeftAdd");
  const distanceLeftPrismSub = getValues("subjective.distanceLeftPrism");

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr ",
          justifyContent: "space-between",
        }}>
        <Box
          sx={{
            mr: 3,
            mb: 3,
            borderRadius: "15px",
            border: "1px solid #6373813b",
            position: "relative",
          }}>
          <Typography variant="h6" sx={{ mb: 2, ml: 3, mt: 2 }}>
            Lens Meter
          </Typography>
          <Typography sx={{ mb: 5, ml: 3 }}>
            Right:
            {rightLensSPH ||
            rightLensCYL ||
            rightLensAxis ||
            nearRightAdd ||
            intermediateRight ||
            distanceRightPrism ? (
              <Typography sx={{ display: "inline", ml: 3 }}>
                {rightLensSPH && `${rightLensSPH}/`}
                {rightLensCYL && `${rightLensCYL}×`}
                {rightLensAxis}
                {nearRightAdd && ` ADD + ${nearRightAdd}`}
                {intermediateRight && `, Inter ADD + ${intermediateRight}`}
                {distanceRightPrism && ` ${distanceRightPrism}`}
              </Typography>
            ) : (
              <Typography sx={{ display: "inline", ml: 3 }}>
                No records found
              </Typography>
            )}
          </Typography>

          <Typography sx={{ mb: 5, ml: 3 }}>
            Left:
            {leftLensSPH ||
            leftLensCYL ||
            leftLensAxis ||
            nearLeftAdd ||
            intermediateLeft ||
            distanceLeftPrism ? (
              <Typography sx={{ display: "inline", ml: 3 }}>
                {leftLensSPH && `${leftLensSPH}/`}
                {leftLensCYL && `${leftLensCYL}×`}
                {leftLensAxis}
                {nearLeftAdd && ` ADD + ${nearLeftAdd}`}
                {intermediateLeft && `, Inter ADD + ${intermediateLeft}`}
                {distanceLeftPrism && ` ${distanceLeftPrism}`}
              </Typography>
            ) : (
              <Typography sx={{ display: "inline", ml: 3 }}>
                No records found
              </Typography>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 3,
            borderRadius: "15px",
            border: "1px solid #6373813b",
            position: "relative",
          }}>
          <Typography variant="h6" sx={{ mb: 2, ml: 3, mt: 2 }}>
            Auto Refractor
          </Typography>
          <Typography sx={{ mb: 5, ml: 3 }}>
            Right:
            {rightAutoSPH ||
            rightAutoCYL ||
            rightAutoAxis ||
            nearRightAddAuto ||
            intermediateRightAuto ||
            distanceRightPrismAuto ? (
              <Typography sx={{ display: "inline", ml: 3 }}>
                {rightAutoSPH && `${rightAutoSPH}/`}
                {rightAutoCYL && `${rightAutoCYL} × `}
                {rightAutoAxis}
                {nearRightAddAuto && ` ADD + ${nearRightAddAuto}`}
                {intermediateRightAuto &&
                  `, Inter ADD + ${intermediateRightAuto}`}
                {distanceRightPrismAuto && ` ${distanceRightPrismAuto}`}
              </Typography>
            ) : (
              <Typography sx={{ display: "inline", ml: 3 }}>
                No records found
              </Typography>
            )}
          </Typography>

          <Typography sx={{ mb: 5, ml: 3 }}>
            Left:
            {leftAutoSPH ||
            leftAutoCYL ||
            leftAutoAxis ||
            nearLeftAddAuto ||
            intermediateLeftAuto ||
            distanceLeftPrismAuto ? (
              <Typography sx={{ display: "inline", ml: 3 }}>
                {leftAutoSPH && `${leftAutoSPH}/`}
                {leftAutoCYL && `${leftAutoCYL} × `}
                {leftAutoAxis}
                {nearLeftAddAuto && ` ADD + ${nearLeftAddAuto}`}
                {intermediateLeftAuto &&
                  `, Inter ADD + ${intermediateLeftAuto}`}
                {distanceLeftPrismAuto && ` ${distanceLeftPrismAuto}`}
              </Typography>
            ) : (
              <Typography sx={{ display: "inline", ml: 3 }}>
                No records found
              </Typography>
            )}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          justifyContent: "space-between",
        }}>
        <Box
          sx={{
            mr: 3,
            mb: 3,
            borderRadius: "15px",
            border: "1px solid #6373813b",
            position: "relative",
          }}>
          <Typography variant="h6" sx={{ mb: 2, ml: 3, mt: 2 }}>
            Subjective
          </Typography>
          <Typography sx={{ mb: 5, ml: 3 }}>
            Right:
            {rightSubSPH ||
            rightSubCYL ||
            rightSubAxis ||
            nearRightAddSub ||
            intermediateRightSub ||
            distanceRightPrismSub ? (
              <Typography sx={{ display: "inline", ml: 3 }}>
                {rightSubSPH && `${rightSubSPH}/`}
                {rightSubCYL && `${rightSubCYL} × `}
                {rightSubAxis}
                {nearRightAddSub && ` ADD + ${nearRightAddSub}`}
                {intermediateRightSub &&
                  `, Inter ADD + ${intermediateRightSub}`}
                {distanceRightPrismSub && ` ${distanceRightPrismSub}`}
              </Typography>
            ) : (
              <Typography sx={{ display: "inline", ml: 3 }}>
                No records found
              </Typography>
            )}
          </Typography>

          <Typography sx={{ mb: 5, ml: 3 }}>
            Left:
            {leftSubSPH ||
            leftSubCYL ||
            leftSubAxis ||
            nearLeftAddSub ||
            intermediateLeftSub ||
            distanceLeftPrismSub ? (
              <Typography sx={{ display: "inline", ml: 3 }}>
                {leftSubSPH && `${leftSubSPH}/`}
                {leftSubCYL && `${leftSubCYL} × `}
                {leftSubAxis}
                {nearLeftAddSub && ` ADD + ${nearLeftAddSub}`}
                {intermediateLeftSub && `, Inter ADD + ${intermediateLeftSub}`}
                {distanceLeftPrismSub && ` ${distanceLeftPrismSub}`}
              </Typography>
            ) : (
              <Typography sx={{ display: "inline", ml: 3 }}>
                No records found
              </Typography>
            )}
          </Typography>
        </Box>
        <FinalSummary />
      </Box>
    </Box>
  );
};
