import { useRef, useState, useEffect } from "react";
import { SharedService } from "../../../../api/shared.service";
import { LoadingButton } from "@mui/lab";

type Props = {
  selectedImage: string;
  canvasName: string;
  uploading: boolean;
  uploadResult: (url: string) => void;
  setUploading: (value: boolean) => void;
};
const CanvasDrawer = ({
  selectedImage,
  canvasName,
  uploadResult,
  uploading,
  setUploading,
}: Props) => {
  const clinicalRecordId = localStorage.getItem("clinicalId");
  const canvasRef = useRef<any>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState<any>(null);

  const startDrawing = (event: any) => {
    setIsDrawing(true);
    const { offsetX, offsetY } = event.nativeEvent;
    context.beginPath();
    context.moveTo(offsetX, offsetY);
  };

  const draw = (event: any) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = event.nativeEvent;
    context.lineTo(offsetX, offsetY);
    context.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
    context.closePath();
  };

  const handleUpload = async () => {
    const dataUrl = canvasRef.current.toDataURL("image/png");
    const moduleName = "examination";
    const fileName = `${clinicalRecordId}/${canvasName}-${Math.random()}.png`;

    const blob = await fetch(dataUrl).then((res) => res.blob());
    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("name", moduleName);

    try {
      setUploading(true);
      const { data: response } = await new SharedService().upload(formData);
      uploadResult(response?.url);
      setUploading(false);
    } catch (error) {
      setUploading(false);
      console.error("Error during fetch:", error);
    }
  };

  const handleImageClick = (imageUrl: any) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imageUrl;

    img.onload = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
  };

  useEffect(() => {
    const canvas = canvasRef.current as any;
    const ctx = canvas.getContext("2d");
    setContext(ctx);
  }, []);

  useEffect(() => {
    if (selectedImage) {
      handleImageClick(selectedImage);
    }
  }, [selectedImage]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <canvas
        ref={canvasRef}
        width={550}
        height={400}
        style={{
          borderRadius: "15px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          marginTop: "20px",
        }}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={endDrawing}
        onMouseOut={endDrawing}
      />
      <br />
      <LoadingButton
        variant="outlined"
        onClick={handleUpload}
        loading={uploading}
      >
        Save
      </LoadingButton>
      <br></br>
    </div>
  );
};

export default CanvasDrawer;
