import { Box, Card } from "@mui/material";
import Teach from "./Teach";
import { ITeachScreen } from "../../../../../@types/teachscreen.type";

type Props = {
  clinicalRecordId: number;
  teachScreen: ITeachScreen;
  showCLForm: boolean;
  getTeachScreenRecords: (teachScreenId: number, clinicalId: number) => void;
};
const TeachScreen = ({
  clinicalRecordId,
  teachScreen,
  showCLForm,
  getTeachScreenRecords,
}: Props) => {
  return (
    <Box
      sx={{
        margin: "2% 10%",
      }}>
      <Teach
        clinicalRecordId={clinicalRecordId}
        teachScreen={teachScreen}
        showCLForm={showCLForm}
        getTeachScreenRecords={getTeachScreenRecords}
      />
    </Box>
  );
};

export default TeachScreen;
