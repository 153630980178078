import { IRefractionSchema } from "../@types/refraction";
import axios from "../utils/axios";

export class RefractionService {
  createRefraction(patientId: any, data: IRefractionSchema) {
    return axios.post(`/patient/${patientId}/refraction`, data);
  }

  getRefraction(patientId: any, refractionId?: any) {
    return axios.get(
      `/patient/${patientId}/refraction?refractionId=${refractionId}`
    );
  }
}
