import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import RHFTextField from "../../../../../components/hook-form/RHFTextField";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";
import { CLAftercarePreviousRecords } from "./CLAftercarePreviousRecords";

type Props = {
  getCLAftercareRecords: (clAftercareId: number, clinicalId: number) => void;
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
};

const LeftAndRightCLAftercare = ({
  getCLAftercareRecords,
  selectedRow,
  clinicalRecords,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const { getValues, setValue } = useFormContext();
  const copyData = () => {
    const leftPushUpTest = getValues("leftPushUpTest");
    const leftTBUT = getValues("leftTBUT");
    const leftNeovascularisation = getValues("leftNeovascularisation");
    const leftFluoresceinStaining = getValues("leftFluoresceinStaining");
    const leftLids = getValues("leftLids");
    const leftConjunctiva = getValues("leftConjunctiva");
    const leftSclera = getValues("leftSclera");
    const leftLidEversion = getValues("leftLidEversion");
    const leftConjunctivalRedness = getValues("leftConjunctivalRedness");
    const leftLimbalRedness = getValues("leftLimbalRedness");
    const leftEpithelialMicrocysts = getValues("leftEpithelialMicrocysts");
    const leftCornealOedema = getValues("leftCornealOedema");
    const leftBlepharitis = getValues("leftBlepharitis");
    const leftSketch = getValues("leftSketch");

    setValue("rightPushUpTest", leftPushUpTest);
    setValue("rightTBUT", leftTBUT);
    setValue("rightNeovascularisation", leftNeovascularisation);
    setValue("rightFluoresceinStaining", leftFluoresceinStaining);
    setValue("rightLids", leftLids);
    setValue("rightConjunctiva", leftConjunctiva);
    setValue("rightSclera", leftSclera);
    setValue("rightLidEversion", leftLidEversion);
    setValue("rightConjunctivalRedness", leftConjunctivalRedness);
    setValue("rightLimbalRedness", leftLimbalRedness);
    setValue("rightEpithelialMicrocysts", leftEpithelialMicrocysts);
    setValue("rightCornealOedema", leftCornealOedema);
    setValue("rightBlepharitis", leftBlepharitis);
    setValue("rightSketch", leftSketch);
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="right"
        ml={1}
        mr={1}
        spacing={2}>
        <Button variant="contained" onClick={copyData}>
          Copy R to L
        </Button>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Previous Records
        </Button>
      </Stack>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        p={3}>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Right
          </Typography>
          <RHFTextField
            name="leftPushUpTest"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftTBUT"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftNeovascularisation"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftFluoresceinStaining"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftLids"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftConjunctiva"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftSclera"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftLidEversion"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftConjunctivalRedness"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftLimbalRedness"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftEpithelialMicrocysts"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftCornealOedema"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftBlepharitis"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="leftSketch"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "10px" }}
          />
        </Box>

        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center align for labels
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}></Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Push Up Test
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            TBUT
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Neovascularisation
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Fluorescein Staining
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Lids
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Conjunctiva
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Sclera
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Lids Eversion
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Conjunctival Redness
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Limbal Redness
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Epithelial Microcysts
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Corneal Oedema
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Blepharitis
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={9}>
            Sketch
          </Typography>
        </Box>

        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Left
          </Typography>
          <RHFTextField
            name="rightPushUpTest"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightTBUT"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightNeovascularisation"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightFluoresceinStaining"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightLids"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightConjunctiva"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightSclera"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightLidEversion"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightConjunctivalRedness"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightLimbalRedness"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightEpithelialMicrocysts"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightCornealOedema"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightBlepharitis"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="rightSketch"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "10px" }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row-reverse", margin: 5 }}>
        <Button type="submit" variant="contained">
          Save and Next
        </Button>
      </Box>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openModal}
        onClose={() => setOpenModal(false)}>
        <DialogTitle>Previous Records</DialogTitle>

        <CLAftercarePreviousRecords
          selectedRow={selectedRow}
          clinicalRecords={clinicalRecords}
          getCLAftercareRecords={getCLAftercareRecords}
        />
      </Dialog>
    </>
  );
};

export default LeftAndRightCLAftercare;
