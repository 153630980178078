import { ICLFitting } from "../../@types/clFitting.type";
import axios from "../../utils/axios";

export class CLFittingService {
  createCLFitting(patientId: string, data: ICLFitting) {
    return axios.post(`/patient/${patientId}/contact-lens-fitting`, data);
  }

  getCLFittings(patientId: string, clFittingId: number) {
    const queryString = clFittingId ? `?clFittingId=${clFittingId}` : "";

    return axios.get(
      `/patient/${patientId}/contact-lens-fitting${queryString}`
    );
  }
}
