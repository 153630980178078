import { Outlet } from "react-router-dom";
// @mui
import { Box, Typography } from "@mui/material";
//
import Main from "../dashboard/Main";
import Header from "./header";
import { useAuthContext } from "../../auth/useAuthContext";

// ----------------------------------------------------------------------

export default function AppLayout() {
  return (
    <>
      <Header />

      <Box
        sx={{
          display: { lg: "flex" },
          minHeight: { lg: 1 },
        }}
      >
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
