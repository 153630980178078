import { Typography, Grid, MenuItem } from "@mui/material";
import { RHFSelect, RHFTextField } from "../../../../../components/hook-form";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useState } from "react";
import { vaValues } from "../../../../../@types/vaValues.type";
import { IVisionSchema } from "../../../../../@types/specsHistory";

type Props = {
  fullView: boolean;
};

export const NearVision = ({ fullView }: Props) => {
  const [errorMessages, setErrorMessages] = useState<{
    [key: string]: string | null;
  }>({});

  const handleInputChange = (e: any, fieldName: string) => {
    const value = Number(e.target.value);

    // Define validation for the value
    if (value < 1) {
      setErrorMessages((prev) => ({
        ...prev,
        [fieldName]: "Values less than 1 are not allowed",
      }));
      e.target.value = 1;
    } else if (value > 180) {
      setErrorMessages((prev) => ({
        ...prev,
        [fieldName]: "Values above 180 are not allowed",
      }));
      e.target.value = 180;
    } else {
      setErrorMessages((prev) => ({ ...prev, [fieldName]: null })); // Clear message if valid
    }

    if (e.target.value.length > 3) {
      e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
    }
  };
  const { control, watch } = useFormContext();

  // Watch the values of both fields
  const nearRightCYL = useWatch({ name: "lensMeter.nearRightCYL", control });
  const nearRightAxis = useWatch({ name: "lensMeter.nearRightAxis", control });
  const nearLeftAxis = useWatch({ name: "lensMeter.nearLeftAxis", control });
  const nearLeftCYL = useWatch({ name: "lensMeter.nearLeftCYL", control });

  const validateRightFields = () => {
    if ((nearRightCYL && !nearRightAxis) || (!nearRightCYL && nearRightAxis)) {
      return "Both right fields are required.";
    }
    return true;
  };

  // Validation for left fields
  const validateLeftFields = () => {
    if ((nearLeftCYL && !nearLeftAxis) || (!nearLeftCYL && nearLeftAxis)) {
      return "Both left fields are required.";
    }
    return true;
  };
  return (
    <div>
      <Typography variant="h4" sx={{ mt: 3 }}>
        Near Vision
      </Typography>

      {/* Headings Row */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={1}>
          {/* Empty space for alignment with the first column */}
        </Grid>
        <Grid item xs={1.4}>
          <Typography sx={{ ml: 4 }}>Add</Typography>
        </Grid>
        <Grid item xs={1.4}>
          <Typography sx={{ ml: 4 }}>Sph</Typography>
        </Grid>
        <Grid item xs={1.4}>
          <Typography sx={{ ml: 4 }}>Cyl</Typography>
        </Grid>
        <Grid item xs={1.4}>
          <Typography sx={{ ml: 4 }}>Axis</Typography>
        </Grid>
        <Grid item xs={1.4}>
          <Typography sx={{ ml: 2 }}>Aided VA</Typography>
        </Grid>
        {fullView && (
          <>
            <Grid item xs={1.5}>
              <Typography sx={{ ml: 3 }}>Prism</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography sx={{ ml: 2 }}>Near PD</Typography>
            </Grid>
          </>
        )}
      </Grid>

      {/* Right Eye Row */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={1}>
          <Typography>Right</Typography>
        </Grid>
        <Grid item xs={1.4}>
          <RHFTextField
            size="small"
            name="lensMeter.nearRightAdd"
            sx={{ width: "90px" }}
          />
        </Grid>
        <Grid item xs={1.4}>
          <RHFTextField
            size="small"
            name="lensMeter.nearRightSPH"
            sx={{ width: "90px" }}
            type="number"
            inputProps={{
              step: 0.25,
              min: -10,
              max: 10,
              onChange: (e: any) => {
                const value = parseFloat(e.target.value).toFixed(2);
                e.target.value = value;
              },
            }}
            InputProps={{
              startAdornment:
                Number(watch(`lensMeter.nearRightSPH`)) > 0 ? "+" : null,
            }}
          />
        </Grid>
        <Grid item xs={1.4}>
          <Controller
            name="lensMeter.nearRightCYL"
            control={control}
            rules={{ validate: validateRightFields }}
            render={({ field, fieldState }) => (
              <RHFTextField
                {...field}
                size="small"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={{ width: "90px" }}
                inputProps={{
                  step: 0.25,
                  onChange: (e: any) => {
                    let value = parseFloat(e.target.value);
                    if (value > 0) {
                      value = -value;
                    }
                    value = Math.floor(value * 4) / 4;
                    e.target.value = value.toFixed(2);
                  },
                }}
                type="number"
              />
            )}
          />
        </Grid>
        <Grid item xs={1.4}>
          <Controller
            name="lensMeter.nearRightAxis"
            control={control}
            rules={{ validate: validateRightFields }}
            render={({ field, fieldState }) => (
              <>
                <RHFTextField
                  {...field}
                  size="small"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "90px" }}
                  type="number"
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 180,
                    onInput: (e: any) => handleInputChange(e, "nearRightAxis"),
                  }}
                />
                <Grid item xs={12}>
                  {errorMessages["nearRightAxis"] && (
                    <Typography color="error" variant="caption">
                      {errorMessages["nearRightAxis"]}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          />
        </Grid>
        <Grid item xs={1.4}>
          <RHFSelect
            name="lensMeter.nearRightAidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        {fullView && (
          <>
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="lensMeter.nearRightPrism"
                sx={{ width: "90px" }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="lensMeter.nearRightNearPD"
                sx={{ width: "90px" }}
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* Both Eyes Row */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {/* Row label (Both) */}
        <Grid item xs={1}>
          <Typography>Both</Typography>
        </Grid>

        {/* Placeholder for Sph, Cyl, Axis */}
        <Grid item xs={1.4} />
        <Grid item xs={1.4} />
        <Grid item xs={1.4} />
        <Grid item xs={1.4} />

        {/* First TextField (Below Aided VA - Fourth Heading) */}
        <Grid item xs={1.4}>
          <RHFSelect
            name="lensMeter.nearBothAidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>

        {fullView && (
          <>
            {/* Second TextField (Below Prism - Fifth Heading) */}
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="lensMeter.nearBothPrism"
                sx={{ width: "90px" }}
              />
            </Grid>

            {/* Placeholder for near PD */}
            <Grid item xs={2} />
          </>
        )}
      </Grid>

      {/* Left Eye Row */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={1}>
          <Typography>Left</Typography>
        </Grid>
        <Grid item xs={1.4}>
          <RHFTextField
            size="small"
            name="lensMeter.nearLeftAdd"
            sx={{ width: "90px" }}
          />
        </Grid>
        <Grid item xs={1.4}>
          <RHFTextField
            size="small"
            name="lensMeter.nearLeftSPH"
            sx={{ width: "90px" }}
            type="number"
            inputProps={{
              step: 0.25,
              min: -10,
              max: 10,
              onChange: (e: any) => {
                const value = parseFloat(e.target.value).toFixed(2);
                e.target.value = value;
              },
            }}
            InputProps={{
              startAdornment:
                Number(watch(`lensMeter.nearLeftSPH`)) > 0 ? "+" : null,
            }}
          />
        </Grid>
        <Grid item xs={1.4}>
          <Controller
            name="lensMeter.nearLeftCYL"
            control={control}
            rules={{ validate: validateLeftFields }}
            render={({ field, fieldState }) => (
              <RHFTextField
                {...field}
                size="small"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={{ width: "90px" }}
                type="number"
                inputProps={{
                  step: 0.25,
                  onChange: (e: any) => {
                    let value = parseFloat(e.target.value);
                    if (value > 0) {
                      value = -value;
                    }
                    value = Math.floor(value * 4) / 4;
                    e.target.value = value.toFixed(2);
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={1.4}>
          <Controller
            name="lensMeter.nearLeftAxis"
            control={control}
            rules={{ validate: validateLeftFields }}
            render={({ field, fieldState }) => (
              <>
                <RHFTextField
                  {...field}
                  size="small"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "90px" }}
                  type="number"
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 180,
                    onInput: (e: any) => handleInputChange(e, "nearLeftAxis"),
                  }}
                />
                <Grid item xs={12}>
                  {errorMessages["nearLeftAxis"] && (
                    <Typography color="error" variant="caption">
                      {errorMessages["nearLeftAxis"]}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          />
        </Grid>
        <Grid item xs={1.4}>
          <RHFSelect
            name="lensMeter.nearLeftAidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        {fullView && (
          <>
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="lensMeter.nearLeftPrism"
                sx={{ width: "90px" }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="lensMeter.nearLeftNearPD"
                sx={{ width: "90px" }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
