import {
  IDispenseRequest,
  IDispenseRequestFromHandover,
} from "../@types/dispense";
import axios from "../utils/axios";

export class DispensaryService {
  getAllDispensaries() {
    return axios.get("/dispensary");
  }
  createDispense(data: IDispenseRequest) {
    return axios.post("/dispensary", data);
  }
  deleteDispensaries(id: number) {
    return axios.delete(`/dispensary/${id}`);
  }
  createDispenseFromHandover(
    patientId: string | undefined,
    data: IDispenseRequestFromHandover
  ) {
    return axios.post(`/dispensary/${patientId}`, data);
  }
}
