import React from "react";
import { IPressureSchema } from "../../../@types/specsHistory";
import { formatDate } from "@fullcalendar/core";
import { Box, Typography } from "@mui/material";

type Props = {
  data: IPressureSchema;
};
export const EyePressures = ({ data }: Props) => {
  return (
    <Box
      p={3}
      mt={1}
      sx={{
        borderRadius: "15px",
        border: "1px solid #6373813b",
        position: "relative",
      }}>
      <Box
        sx={{
          display: "flex",
          width: "90%",
          justifyContent: "space-between",
        }}>
        <Typography sx={{ color: "text.secondary" }}>
          <strong>Instrument:</strong> {data?.instrument}
        </Typography>

        <Typography sx={{ color: "text.secondary" }}>
          <strong>IOPs:</strong> {data?.IOPs}
        </Typography>

        <Typography sx={{ color: "text.secondary" }}>
          <strong>CCT:</strong> {data?.CCT}
        </Typography>
      </Box>
    </Box>
  );
};
