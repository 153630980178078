import { IProductRequest, IProduct } from "../@types/product";
import axios from "../utils/axios";

export class ProductService {
  getAllProducts() {
    return axios.get<IProduct[]>("/product");
  }
  getProductDetail(id: number) {
    return axios.get<IProduct>(`/product/${id}`);
  }
  createProduct(payload: IProductRequest) {
    return axios.post<IProduct>("/product", payload);
  }
  updateProduct(id: number, payload: IProductRequest) {
    return axios.patch<IProduct>(`/product/${id}`, payload);
  }
  deleteProduct(id: number) {
    return axios.delete<string>(`/product/${id}`);
  }
}
