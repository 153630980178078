import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { RHFTextField } from "../../../../components/hook-form";
import { useFormContext } from "react-hook-form";

const Additional = () => {
  const { setValue, getValues } = useFormContext();

  const clearExternalData = () => {
    const fieldNames = [
      "additional.leftAmslerChart",
      "additional.leftVisualFields",
      "additional.leftIshihara",
      "additional.leftColorVision",
      "additional.leftPinhole",
      "additional.rightAmslerChart",
      "additional.rightVisualFields",
      "additional.rightIshihara",
      "additional.rightColorVision",
      "additional.rightPinhole",
    ];

    const clearedValues = { ...getValues() };
    fieldNames.forEach((fieldName) => {
      clearedValues[fieldName] = "";
    });

    // Update the form values
    Object.keys(clearedValues).forEach((fieldName) => {
      setValue(fieldName, clearedValues[fieldName]);
    });
  };

  const copyData = () => {
    const leftAmslerChart = getValues("additional.leftAmslerChart");
    const leftVisualFields = getValues("additional.leftVisualFields");
    const leftIshihara = getValues("additional.leftIshihara");
    const leftColorVision = getValues("additional.leftColorVision");
    const leftPinhole = getValues("additional.leftPinhole");

    setValue("additional.rightAmslerChart", leftAmslerChart);
    setValue("additional.rightVisualFields", leftVisualFields);
    setValue("additional.rightIshihara", leftIshihara);
    setValue("additional.rightColorVision", leftColorVision);
    setValue("additional.rightPinhole", leftPinhole);
  };

  const defaultValues = () => {
    setValue("additional.leftAmslerChart", "");
    setValue("additional.leftVisualFields", "");
    setValue("additional.leftIshihara", "");
    setValue("additional.leftColorVision", "");
    setValue("additional.leftPinhole", "");
    setValue("additional.rightAmslerChart", "");
    setValue("additional.rightVisualFields", "");
    setValue("additional.rightIshihara", "");
    setValue("additional.rightColorVision", "");
    setValue("additional.rightPinhole", "");
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="right"
        ml={1}
        mr={1}
        spacing={2}>
        <Button variant="contained" onClick={clearExternalData}>
          Clear
        </Button>
        <Button variant="contained" onClick={defaultValues}>
          Default
        </Button>
        <Button variant="contained" onClick={copyData}>
          Copy R to L
        </Button>
      </Stack>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        p={5}>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Right
          </Typography>
          <RHFTextField
            name="additional.leftAmslerChart"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="additional.leftVisualFields"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="additional.leftIshihara"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="additional.leftColorVision"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="additional.leftPinhole"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}></Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Amsler Chart
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Visual Fields{" "}
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Ishihara
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Color Vision
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Pinhole
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Left
          </Typography>
          <RHFTextField
            name="additional.rightAmslerChart"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="additional.rightVisualFields"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="additional.rightIshihara"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="additional.rightColorVision"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="additional.rightPinhole"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Additional;
