import { formatDate } from "@fullcalendar/core";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Typography,
} from "@mui/material";
import { CustomAvatar } from "../../../../components/custom-avatar";
import { TableHeadCustom, TableNoData } from "../../../../components/table";

const TABLE_HEAD = [
  {
    id: "reasonForVisit",
    label: "Visit Reason",
    align: "left",
  },
  { id: "type", label: "Type", align: "center" },
  { id: "id", label: "ID", align: "left" },
  { id: "createdDate", label: "Created Date", align: "center" },
  { id: "patientId", label: "Patient Id", align: "right" },
];

type Props = {
  selectedRow: any;
  clinicalRecords: any[];
  getExaminationRecords: (examinationId: number, clinicalId: number) => void;
  setSelectedRow: (row: number) => void;
  previousRecordsRow: number;
};
export const PreviousRecords = ({
  selectedRow,
  clinicalRecords,
  getExaminationRecords,
  setSelectedRow,
  previousRecordsRow,
}: Props) => {
  const filteredRecords = clinicalRecords.filter((record) => {
    return record.type === "Eye Examination";
  });

  const filteredRow = filteredRecords.filter((record) => {
    return record.id !== previousRecordsRow;
  });

  const handleRowSelection = (item: number) => {
    setSelectedRow(item);
  };
  return (
    <Table sx={{ minWidth: 800 }}>
      <TableHeadCustom headLabel={TABLE_HEAD} />
      <TableBody>
        {filteredRow.map((item: any) => (
          <TableRow
            key={item.id}
            sx={{
              cursor: "pointer",
              backgroundColor: selectedRow === item.id ? "#e0e0e0" : "inherit",
            }}
            onClick={() => {
              getExaminationRecords(item.examinationId, item.id);
              handleRowSelection(item.id);
            }}>
            <TableCell align="left">
              <Stack direction="row" alignItems="center" spacing={2}>
                <CustomAvatar name={`${item.id}`} />
                <div>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    style={{ cursor: "pointer" }}></Typography>
                </div>
              </Stack>
            </TableCell>
            <TableCell align="center">{item.type}</TableCell>

            <TableCell align="left">{item.id}</TableCell>
            <TableCell align="center">{formatDate(item.createdDate)}</TableCell>
            <TableCell align="right">{item.patient.firstName}</TableCell>
          </TableRow>
        ))}

        {/* <TableEmptyRows
        height={63}
        emptyRows={emptyRows(1, 1, clinicalRecords.length)}
      /> */}
        <TableNoData isNotFound={clinicalRecords.length <= 0} />
      </TableBody>
    </Table>
  );
};
