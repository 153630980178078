export const vaValues = [
  "6/18",
  "6/3",
  "6/4",
  "6/4.5",
  "6/5",
  "6/5.5",
  "6/6",
  "6/6.5",
  "6/7",
  "6/7.5",
  "6/9",
  "6/12",
  "6/18",
  "6/24",
  "6/36",
  "6/60",
  "<6/60",
  "CF",
  "HM",
  "PL",
  "NPL",
];
