import { IRegisterSchema } from "../@types/register.type";
import axios from "../utils/axios";

export class AuthService {
  login(email: string, password: string) {
    return axios.post("/auth/login", {
      email,
      password,
    });
  }
  demoRegister(payload: IRegisterSchema) {
    return axios.post("/auth/demo-register", payload);
  }

  getProfile() {
    try {
      return axios.get<any>("/auth/me/profile");
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  }
  async getRefreshToken(refreshToken: string) {
    try {
      const response = await axios.post(
        `/auth/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  }
}
