import Cookies from "universal-cookie";
// routes
import { HOST_API_KEY } from "../config-global";
import { PATH_AUTH } from "../routes/paths";
// utils
import axios from "../utils/axios";

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    const cookies = new Cookies();

    try {
      const refreshToken = cookies.get(process.env.REACT_APP_REFRESH_TOKEN!);
      const { data: refreshResponse } = await axios.post(
        `${HOST_API_KEY}/auth/refresh`,
        {
          refreshToken,
        }
      );
      setSession(
        refreshResponse.data.accessToken,
        refreshResponse.data.refreshToken
      );
    } catch (error) {
      cookies.remove(process.env.REACT_APP_TOKEN!);
      cookies.remove(process.env.REACT_APP_REFRESH_TOKEN!);
      window.location.href = PATH_AUTH.login;
    }
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (
  accessToken: string | null,
  refreshToken: string | null
) => {
  const cookies = new Cookies();
  if (accessToken && refreshToken) {
    const in3Hours = new Date(new Date().getTime() + 1000 * 60 * 60 * 3);
    const in2Days = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 2);
    const optsAuthToken = {
      expires: in3Hours,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: "/",
    };

    const optsRefreshToken = {
      expires: in2Days,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: "/",
    };
    cookies.set(process.env.REACT_APP_TOKEN!, accessToken, optsAuthToken);
    cookies.set(
      process.env.REACT_APP_REFRESH_TOKEN!,
      refreshToken,
      optsRefreshToken
    );
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~1 days by minimals server
    tokenExpired(exp);
  } else {
    cookies.remove(process.env.REACT_APP_TOKEN!, {
      path: "/",
      domain: window.location.hostname,
    });
    cookies.remove(process.env.REACT_APP_REFRESH_TOKEN!, {
      path: "/",
      domain: window.location.hostname,
    });

    delete axios.defaults.headers.common.Authorization;
  }
};
