import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ILeftAndRightAnteriorEye } from "../../../../../@types/anteriorEye.type";
import { RHFTextField } from "../../../../../components/hook-form";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { AnteriorEyePreviousRecords } from "./AnteriorEyePreviousRecords";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";

type Props = {
  leftAndRightAnteriorEye: ILeftAndRightAnteriorEye;
  leftHVID: string;
  setLeftHVID: (leftHVID: string) => void;
  rightHVID: string;
  setRightHVID: (rightHVID: string) => void;
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
  getAnteriorEyeRecords: (anteriorEyeId: number, clinicalId: number) => void;
};

const anteriorEyeTextFields = [
  { label: "Pupil Size", left: "leftPupilSize", right: "rightPupilSize" },
  { label: "HVID", left: "leftHVID", right: "rightHVID", isSelect: true },
  {
    label: "Conjuctival Redness",
    left: "leftConjunctivalRedness",
    right: "rightConjunctivalRedness",
  },
  {
    label: "Corneal Infiltrates",
    left: "leftCornealInfiltrates",
    right: "rightCornealInfiltrates",
  },
  {
    label: "Corneal Neovascularisation",
    left: "leftCornealNeovascularisation",
    right: "rightCornealNeovascularisation",
  },
  {
    label: "Corneal Oedema",
    left: "leftCornealOedema",
    right: "rightCornealOedema",
  },
  {
    label: "Corneal Staining",
    left: "leftCornealStaining",
    right: "rightCornealStaining",
  },
  {
    label: "Endothelial Blebs",
    left: "leftEndothelialBlebs",
    right: "rightEndothelialBlebs",
  },
  {
    label: "Endothelial Polymegethism",
    left: "leftEndothelialPolymegethism",
    right: "rightEndothelialPolymegethism",
  },
  {
    label: "Epithelial Microcysts",
    left: "leftEpithelialMicrocysts",
    right: "rightEpithelialMicrocysts",
  },
  {
    label: "Limbal Redness",
    left: "leftLimbalRedness",
    right: "rightLimbalRedness",
  },
  {
    label: "Meibomian Dysfunction",
    left: "leftMeibomianDysfunction",
    right: "rightMeibomianDysfunction",
  },
  {
    label: "Papillary Conjunctivitis",
    left: "leftPapillaryConjunctivitis",
    right: "rightPapillaryConjunctivitis",
  },
  {
    label: "Superior Limbic Keratoconjunctivitis",
    left: "leftSuperiorLimbicKeratoconjunctivitis",
    right: "rightSuperiorLimbicKeratoconjunctivitis",
  },
];

const LeftAndRightAnteriorEye = ({
  leftAndRightAnteriorEye,
  leftHVID,
  setLeftHVID,
  rightHVID,
  setRightHVID,
  selectedRow,
  clinicalRecords,
  getAnteriorEyeRecords,
}: Props) => {
  const [previousRecords, setPreviousRecords] = useState(false);
  const { setValue, getValues } = useFormContext();

  const handleChange = (event: SelectChangeEvent, side: "left" | "right") => {
    const value = event.target.value as string;
    if (side === "left") {
      setLeftHVID(value);
    } else {
      setRightHVID(value);
    }
  };

  const copyData = () => {
    const fields = [
      "PupilSize",
      "ConjunctivalRedness",
      "CornealInfiltrates",
      "CornealNeovascularisation",
      "CornealOedema",
      "CornealStaining",
      "EndothelialBlebs",
      "EndothelialPolymegethism",
      "EpithelialMicrocysts",
      "LimbalRedness",
      "MeibomianDysfunction",
      "PapillaryConjunctivitis",
      "SuperiorLimbicKeratoconjunctivitis",
    ];

    fields.forEach((field) => {
      const leftValue = getValues(`leftAndRightAnteriorEye.left${field}`);
      setValue(`leftAndRightAnteriorEye.right${field}`, leftValue);
    });
    setRightHVID(leftHVID);
  };

  useEffect(() => {
    if (leftAndRightAnteriorEye) {
      setLeftHVID(leftAndRightAnteriorEye.leftHVID || "");
      setRightHVID(leftAndRightAnteriorEye.rightHVID || "");
    }
  }, [leftAndRightAnteriorEye, setLeftHVID, setRightHVID]);

  const handleClose = () => {
    setPreviousRecords(false);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "row-reverse", gap: 3, mr: 2 }}>
        <Button variant="contained" onClick={() => setPreviousRecords(true)}>
          From Previous Record
        </Button>
        <Button variant="contained" onClick={copyData}>
          Copy R to L
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mt: 4,
        }}>
        <Typography
          variant="h6"
          sx={{ width: "30%", textAlign: "center", mr: 2 }}>
          Right
        </Typography>
        <Typography
          variant="h6"
          sx={{ width: "30%", textAlign: "center" }}></Typography>
        <Typography
          variant="h6"
          sx={{ width: "30%", textAlign: "center", ml: 2 }}>
          Left
        </Typography>
      </Box>

      {anteriorEyeTextFields.map(({ label, left, right, isSelect }) => (
        <Box
          key={label}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}>
          {isSelect ? (
            <>
              <Box sx={{ width: "30%", mr: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>{`Right ${label}`}</InputLabel>
                  <Select
                    label={`Right ${label}`}
                    name={`leftAndRightAnteriorEye.${left}`}
                    value={leftHVID}
                    onChange={(event) => handleChange(event, "left")}>
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="abnormal">Abnormal</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Typography sx={{ width: "30%", textAlign: "center" }}>
                {label}
              </Typography>
              <Box sx={{ width: "30%", ml: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>{`Left ${label}`}</InputLabel>
                  <Select
                    label={`Left ${label}`}
                    name={`leftAndRightAnteriorEye.${right}`}
                    value={rightHVID}
                    onChange={(event) => handleChange(event, "right")}>
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="abnormal">Abnormal</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          ) : (
            <>
              <RHFTextField
                name={`leftAndRightAnteriorEye.${left}`}
                size="small"
                sx={{ width: "30%", mr: 2 }}
              />
              <Typography sx={{ width: "30%", textAlign: "center" }}>
                {label}
              </Typography>
              <RHFTextField
                name={`leftAndRightAnteriorEye.${right}`}
                size="small"
                sx={{ width: "30%", ml: 2 }}
              />
            </>
          )}
        </Box>
      ))}

      <Dialog
        fullWidth
        maxWidth="md"
        open={previousRecords}
        onClose={handleClose}>
        <DialogTitle>Previous Records</DialogTitle>

        <AnteriorEyePreviousRecords
          selectedRow={selectedRow}
          clinicalRecords={clinicalRecords}
          getAnteriorEyeRecords={getAnteriorEyeRecords}
        />
      </Dialog>
    </>
  );
};

export default LeftAndRightAnteriorEye;
