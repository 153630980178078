import { Box, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

const FinalSummary = ({ finalData }: any) => {
  const { getValues } = useFormContext();

  const rightFinalSPH = getValues("final.distanceRightSPH");
  const rightFinalCYL = getValues("final.distanceRightCYL");
  const rightFinalAxis = getValues("final.distanceRightAxis");
  const nearRightAddFinal = getValues("final.nearRightAdd");
  const intermediateRightFinal = getValues("final.intermediateRightAdd");
  const distanceRightPrismFinal = getValues("final.distanceRightPrism");

  const leftFinalSPH = getValues("final.distanceLeftSPH");
  const leftFinalCYL = getValues("final.distanceLeftCYL");
  const leftFinalAxis = getValues("final.distanceLeftAxis");
  const nearLeftAddFinal = getValues("final.nearLeftAdd");
  const intermediateLeftFinal = getValues("final.intermediateLeftAdd");
  const distanceLeftPrismFinal = getValues("final.distanceLeftPrism");

  // Check if no values exist for Right side
  const isRightEmpty =
    !rightFinalSPH &&
    !rightFinalCYL &&
    !rightFinalAxis &&
    !nearRightAddFinal &&
    !intermediateRightFinal &&
    !distanceRightPrismFinal &&
    !finalData?.distanceRightSPH &&
    !finalData?.distanceRightCYL &&
    !finalData?.distanceRightAxis &&
    !finalData?.nearRightAdd &&
    !finalData?.intermediateRightAdd &&
    !finalData?.distanceRightPrism;

  // Check if no values exist for Left side
  const isLeftEmpty =
    !leftFinalSPH &&
    !leftFinalCYL &&
    !leftFinalAxis &&
    !nearLeftAddFinal &&
    !intermediateLeftFinal &&
    !distanceLeftPrismFinal &&
    !finalData?.distanceLeftSPH &&
    !finalData?.distanceLeftCYL &&
    !finalData?.distanceLeftAxis &&
    !finalData?.nearLeftAdd &&
    !finalData?.intermediateLeftAdd &&
    !finalData?.distanceLeftPrism;

  return (
    <Box
      sx={{
        mb: 3,
        borderRadius: "15px",
        border: "1px solid #6373813b",
        position: "relative",
      }}>
      <Typography variant="h6" sx={{ mb: 2, ml: 3, mt: 2 }}>
        Final
      </Typography>

      {/* Right Side */}
      <Typography sx={{ mb: 5, ml: 3 }}>
        Right:
        {isRightEmpty ? (
          <Typography sx={{ display: "inline", ml: 3 }}>
            No records found
          </Typography>
        ) : (
          <Typography sx={{ display: "inline", ml: 3 }}>
            {rightFinalSPH || finalData?.distanceRightSPH}/
            {rightFinalCYL || finalData?.distanceRightCYL} ×{" "}
            {rightFinalAxis || finalData?.distanceRightAxis}
            {nearRightAddFinal
              ? ` ADD + ${nearRightAddFinal}`
              : finalData?.nearRightAdd
              ? ` ADD + ${finalData.nearRightAdd}`
              : ""}
            {intermediateRightFinal || finalData?.intermediateRightAdd
              ? `, Inter ADD + ${
                  intermediateRightFinal || finalData?.intermediateRightAdd
                }`
              : ""}
            {distanceRightPrismFinal || finalData?.distanceRightPrism
              ? ` ${distanceRightPrismFinal || finalData?.distanceRightPrism}`
              : ""}
          </Typography>
        )}
      </Typography>

      {/* Left Side */}
      <Typography sx={{ mb: 5, ml: 3 }}>
        Left:
        {isLeftEmpty ? (
          <Typography sx={{ display: "inline", ml: 3 }}>
            No records found
          </Typography>
        ) : (
          <Typography sx={{ display: "inline", ml: 3 }}>
            {leftFinalSPH || finalData?.distanceLeftSPH}/
            {leftFinalCYL || finalData?.distanceLeftCYL} ×{" "}
            {leftFinalAxis || finalData?.distanceLeftAxis}
            {nearLeftAddFinal
              ? ` ADD + ${nearLeftAddFinal}`
              : finalData?.nearLeftAdd
              ? ` ADD + ${finalData.nearLeftAdd}`
              : ""}
            {intermediateLeftFinal || finalData?.intermediateLeftAdd
              ? `, Inter ADD + ${
                  intermediateLeftFinal || finalData?.intermediateLeftAdd
                }`
              : ""}
            {distanceLeftPrismFinal || finalData?.distanceLeftPrism
              ? ` ${distanceLeftPrismFinal || finalData?.distanceLeftPrism}`
              : ""}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export default FinalSummary;
