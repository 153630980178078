import { Box, Stack, TextField, Typography } from "@mui/material";
import { IAxisSchema } from "../../../@types/specsHistory";
import { LoadingButton } from "@mui/lab";

type Props = {
  axisData: IAxisSchema;
  isExternalChecked?: boolean;
};

const Axis = ({ axisData, isExternalChecked }: Props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        borderRadius: "15px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        overflowY: "auto",
        marginTop: "10px",
      }}
      direction="column"
      p={2}
      mr={2}>
      <Typography variant="h6">Lens Meter</Typography>
    </Stack>
  );
};

export default Axis;
