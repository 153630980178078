import axios from "../utils/axios";

export class HistoryAndSymptomsService {
  createHistoryAndSymptoms(patientId: any, data: any) {
    return axios.post(`/patient/${patientId}/patient-symptoms`, data);
  }

  getHistoryAndSymptoms(patientId: any, patientSymptomsId:any) {
    return axios.get(`/patient/${patientId}/patient-symptoms/${patientSymptomsId}`);
  }
}
