import { Typography, Grid, MenuItem } from "@mui/material";
import { RHFSelect, RHFTextField } from "../../../../../components/hook-form";
import { useState } from "react";
import { vaValues } from "../../../../../@types/vaValues.type";
import { useFormContext } from "react-hook-form";

type Props = {
  fullView: boolean;
};

export const IntermediateVision = ({ fullView }: Props) => {
  const [errorMessages, setErrorMessages] = useState<{
    [key: string]: string | null;
  }>({});

  const { watch } = useFormContext();

  const handleInputChange = (e: any, fieldName: string) => {
    const value = Number(e.target.value);

    // Define validation for the value
    if (value < 1) {
      setErrorMessages((prev) => ({
        ...prev,
        [fieldName]: "Values less than 1 are not allowed",
      }));
      e.target.value = 1;
    } else if (value > 180) {
      setErrorMessages((prev) => ({
        ...prev,
        [fieldName]: "Values above 180 are not allowed",
      }));
      e.target.value = 180;
    } else {
      setErrorMessages((prev) => ({ ...prev, [fieldName]: null })); // Clear message if valid
    }

    if (e.target.value.length > 3) {
      e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
    }
  };
  return (
    <div>
      <Typography variant="h4" sx={{ mt: 3 }}>
        Intermediate Vision
      </Typography>

      {/* Headings Row */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={1}>
          {/* Empty space for alignment with the first column */}
        </Grid>
        <Grid item xs={1.3}>
          <Typography>Unaided VA</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <Typography sx={{ ml: 3 }}>Add</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <Typography sx={{ ml: 3 }}>Sph</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <Typography sx={{ ml: 3 }}>Cyl</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <Typography sx={{ ml: 3 }}>Axis</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <Typography sx={{ ml: 2 }}>Aided VA</Typography>
        </Grid>
        {fullView && (
          <>
            <Grid item xs={1.5}>
              <Typography sx={{ ml: 3 }}>Prism</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography sx={{ ml: 2 }}>Inter PD</Typography>
            </Grid>
          </>
        )}
      </Grid>

      {/* Right Eye Row */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={1}>
          <Typography>Right</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <RHFSelect
            name="final.intermediateRightUnaidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        <Grid item xs={1.3}>
          <RHFTextField
            size="small"
            name="final.intermediateRightAdd"
            sx={{ width: "90px" }}
          />
        </Grid>
        <Grid item xs={1.3}>
          <RHFTextField
            size="small"
            name="final.intermediateRightSPH"
            type="number"
            inputProps={{
              step: 0.25,
              min: -10,
              max: 10,
              onChange: (e: any) => {
                const value = parseFloat(e.target.value).toFixed(2);
                e.target.value = value;
              },
            }}
            InputProps={{
              startAdornment:
                Number(watch(`final.intermediateRightSPH`)) > 0 ? "+" : null,
            }}
            sx={{ width: "90px" }}
          />
        </Grid>
        <Grid item xs={1.3}>
          <RHFTextField
            size="small"
            name="final.intermediateRightCYL"
            type="number"
            inputProps={{
              step: 0.25,
              onChange: (e: any) => {
                let value = parseFloat(e.target.value);
                if (value > 0) {
                  value = -value;
                }
                value = Math.floor(value * 4) / 4;
                e.target.value = value.toFixed(2);
              },
            }}
            sx={{ width: "90px" }}
          />
        </Grid>
        <Grid item xs={1.3}>
          <RHFTextField
            size="small"
            name="final.intermediateRightAxis"
            sx={{ width: "90px" }}
            type="number"
            inputProps={{
              step: 1,
              min: 1,
              max: 180,
              onInput: (e: any) =>
                handleInputChange(e, "intermediateRightAxis"),
            }}
          />
          <Grid item xs={12}>
            {errorMessages["intermediateRightAxis"] && (
              <Typography color="error" variant="caption">
                {errorMessages["intermediateRightAxis"]}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1.3}>
          <RHFSelect
            name="final.intermediateRightAidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        {fullView && (
          <>
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="final.intermediateRightPrism"
                sx={{ width: "90px" }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="final.intermediateRightInterPD"
                sx={{ width: "90px" }}
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* Both Eyes Row */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {/* Row label (Both) */}
        <Grid item xs={1}>
          <Typography>Both</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <RHFSelect
            name="final.intermediateBothUnaidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        {/* Placeholder for Sph, Cyl, Axis */}
        <Grid item xs={1.3} />
        <Grid item xs={1.3} />
        <Grid item xs={1.3} />
        <Grid item xs={1.3} />

        {/* First TextField (Below Aided VA - Fourth Heading) */}
        <Grid item xs={1.3}>
          <RHFSelect
            name="final.intermediateBothAidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>

        {fullView && (
          <>
            {/* Second TextField (Below Prism - Fifth Heading) */}
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="final.intermediateBothPrism"
                sx={{ width: "90px" }}
              />
            </Grid>

            {/* Placeholder for inter PD */}
            <Grid item xs={2} />
          </>
        )}
      </Grid>

      {/* Left Eye Row */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={1}>
          <Typography>Left</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <RHFSelect
            name="final.intermediateLeftUnaidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        <Grid item xs={1.3}>
          <RHFTextField
            size="small"
            name="final.intermediateLeftAdd"
            sx={{ width: "90px" }}
          />
        </Grid>
        <Grid item xs={1.3}>
          <RHFTextField
            size="small"
            name="final.intermediateLeftSPH"
            sx={{ width: "90px" }}
            type="number"
            inputProps={{
              step: 0.25,
              min: -10,
              max: 10,
              onChange: (e: any) => {
                const value = parseFloat(e.target.value).toFixed(2);
                e.target.value = value;
              },
            }}
            InputProps={{
              startAdornment:
                Number(watch(`final.intermediateLeftSPH`)) > 0 ? "+" : null,
            }}
          />
        </Grid>
        <Grid item xs={1.3}>
          <RHFTextField
            size="small"
            name="final.intermediateLeftCYL"
            sx={{ width: "90px" }}
            type="number"
            inputProps={{
              step: 0.25,
              onChange: (e: any) => {
                let value = parseFloat(e.target.value);
                if (value > 0) {
                  value = -value;
                }
                value = Math.floor(value * 4) / 4;
                e.target.value = value.toFixed(2);
              },
            }}
          />
        </Grid>
        <Grid item xs={1.3}>
          <RHFTextField
            size="small"
            name="final.intermediateLeftAxis"
            sx={{ width: "90px" }}
            type="number"
            inputProps={{
              step: 1,
              min: 1,
              max: 180,
              onInput: (e: any) => handleInputChange(e, "intermediateLeftAxis"),
            }}
          />
          <Grid item xs={12}>
            {errorMessages["intermediateLeftAxis"] && (
              <Typography color="error" variant="caption">
                {errorMessages["intermediateLeftAxis"]}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1.3}>
          <RHFSelect
            name="final.intermediateLeftAidedVA"
            size="small"
            sx={{ width: "90px" }}>
            {vaValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        {fullView && (
          <>
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="final.intermediateLeftPrism"
                sx={{ width: "90px" }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <RHFTextField
                size="small"
                name="final.intermediateLeftInterPD"
                sx={{ width: "90px" }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
