import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";

//
import {
  GeneralAppPage,
  DiaryPage,
  PatientsListPage,
  LoginPage,
  ResetPasswordPage,
  NewPasswordPage,
  Page500,
  Page404,
  Page403,
  SettingsPage,
  RegisterPage,
} from "./elements";
// import PrivateRoute from "./PrivateRoute";
// import PublicRoute from "./PublicRoute";
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
import AppLayout from "../layouts/app";
import CompactLayout from "../layouts/compact";
import DispensaryListPage from "../pages/dashboard/Dispense/DispensaryListPage";
import DispenseClickPage from "../pages/dashboard/Dispense/DispenseClickPage";
import ProductListPage from "../pages/dashboard/Product/ProductListPage";
import ProductPage from "../pages/dashboard/Product/ProductPage";
import ProductDetailsPage from "../pages/dashboard/Product/ProductDetailsPage";
import PatientClinicalRecords from "../pages/dashboard/Patient/PatientClinicalRecords";
import RegisterGuard from "../auth/RegisterGuard";
// import { AppContextProvider } from "../providers/AppContext";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Dashboard
    {
      element: <CompactLayout />,
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "demo-register",
          element: (
            <RegisterGuard>
              <RegisterPage />
            </RegisterGuard>
          ),
        },
        {
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            // { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          {/* <AppContextProvider> */}
          <AppLayout />
          {/* </AppContextProvider> */}
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <DashboardLayout />,
          children: [
            {
              path: "",
              element: <GeneralAppPage />,
            },
            {
              path: "diary",
              children: [
                {
                  path: "appointments",
                  element: <DiaryPage />,
                },
              ],
            },
            {
              path: "patients",
              children: [
                {
                  path: "list",
                  element: <PatientsListPage />,
                },
                {
                  path: ":patientId",
                  element: <PatientClinicalRecords />,
                },
              ],
            },
            {
              path: "dispense",
              children: [
                {
                  path: "list",
                  element: <DispensaryListPage />,
                },
                {
                  path: "new",
                  element: <DispenseClickPage />,
                },
                {
                  path: ":dispenseId",
                  element: <DispenseClickPage />,
                },
              ],
            },
            {
              path: "product",
              children: [
                {
                  path: "list",
                  element: <ProductListPage />,
                },
                {
                  path: ":productId",
                  element: <ProductPage />,
                },
                {
                  path: ":productId/details",
                  element: <ProductDetailsPage />,
                },
                {
                  path: "new",
                  element: <ProductPage />,
                },
              ],
            },
            // {
            //   path: "user",
            //   children: [
            //     {
            //       path: "list",
            //       element: <UserAccountPage />,
            //     },
            //   ],
            // },
            {
              path: "settings",
              children: [
                {
                  path: "",
                  element: <SettingsPage />,
                },
                {
                  path: "users",
                  element: <SettingsPage route={"user"} />,
                },
                {
                  path: "appointments",
                  element: <SettingsPage route={"appointment"} />,
                },
                {
                  path: "stocks",
                  element: <SettingsPage route={"stock"} />,
                },
              ],
            },
          ],
        },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
