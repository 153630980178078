import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, BoxProps } from "@mui/material";
import optoProLogo from "../../assets/icons/optoPro.png";
import optoProSmall from "../../assets/icons/optoProSmall.png";
import mtmGlobal from "../../assets/icons/mtmGlobal.svg";
import { useSettingsContext } from "../settings";
import { useAuthContext } from "../../auth/useAuthContext";
import MtmLogo from "./MtmLogo";
import ConditionalLogo from "./ConditionalLogo";
// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  customLink?: string;
  navMini?: boolean;
  loader?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  (
    {
      disabledLink = false,
      loader = false,
      customLink = "/",
      navMini,
      sx,
      ...other
    },
    ref
  ) => {
    const { themeLayout } = useSettingsContext();
    const { user } = useAuthContext();

    const logoOrgLarge = user?.organizationId === 1 ? optoProLogo : <MtmLogo />;
    const logoOrgSmall = user?.organizationId === 1 ? optoProSmall : mtmGlobal;

    const logoSrc = themeLayout === "vertical" ? logoOrgLarge : logoOrgSmall;

    const logo = (
      <ConditionalLogo
        src={logoSrc}
        sx={{
          marginTop: "65px",
          width: "auto",
          height: "auto",
          cursor: "pointer",
          ...sx,
        }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
