import { InputAdornment, Stack, Button, TextField } from "@mui/material";
import Iconify from "../../../components/iconify";

export default function ProductTableToolbar() {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{ px: 2.5, py: 3 }}>
      <TextField
        fullWidth
        placeholder="Search Product By Name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />
      <Button color="error">Cancel</Button>
    </Stack>
  );
}
