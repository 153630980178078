import { Box, Typography } from "@mui/material";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";
import FinalSummary from "../../Refraction/FinalSummary";

type Props = {
  previousRecords: IClinicalRecordSchema;
  finalData: any;
};
const LastEyeExam = ({ previousRecords, finalData }: Props) => {
  if (previousRecords?.type !== "Eye Examination") {
    return (
      <>
        <Typography variant="h5" sx={{ mb: 4 }}>
          Last Eye Exam
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
          No Records Found
        </Box>
      </>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "98%",
        marginRight: 4,
      }}>
      {/* Last Eye Exam Section */}
      <FinalSummary finalData={finalData} />
    </Box>
  );
};

export default LastEyeExam;
