import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import { DistanceVision } from "./DistanceVision";
import { IntermediateVision } from "./IntermediateVision";
import { NearVision } from "./NearVision";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";

type Props = {
  tab: any;
};
export const Subjective = ({ tab }: Props) => {
  const [fullView, setFullView] = useState(false);

  const handleFullView = () => {
    setFullView(true);
  };

  const handleCompactView = () => {
    setFullView(false);
  };
  const { setValue, getValues } = useFormContext();

  const copyData = () => {
    const fieldTypes = ["distance", "near", "intermediate"];
    const sides = ["Right", "Left", "Both"];
    const params = [
      "SPH",
      "CYL",
      "Axis",
      "UnaidedVA",
      "AidedVA",
      "Prism",
      "DistancePD",
      "Add",
      "NearPD",
      "InterPD",
    ];

    const mapValues = (type: string, side: string, param: string) => {
      const subjectiveFields = `subjective.${type}${side}${param}`;
      const finalFields = `final.${type}${side}${param}`;

      const value = getValues(subjectiveFields);
      setValue(finalFields, value);
    };

    fieldTypes.forEach((type) => {
      sides.forEach((side) => {
        params.forEach((param) => {
          const validParam =
            (type === "distance" &&
              param !== "Add" &&
              param !== "NearPD" &&
              param !== "InterPD") ||
            (type === "near" &&
              param !== "DistancePD" &&
              param !== "InterPD") ||
            (type === "intermediate" &&
              param !== "DistancePD" &&
              param !== "NearPD");
          if (validParam) {
            mapValues(type, side, param);
          }
        });
      });
    });
    tab("final");
  };
  const clearFields = () => {
    const fieldsToClear = [
      "subjective.distanceRightUnaidedVA",
      "subjective.distanceRightSPH",
      "subjective.distanceRightCYL",
      "subjective.distanceRightAxis",
      "subjective.distanceRightAidedVA",
      "subjective.distanceRightPrism",
      "subjective.distanceRightDistancePD",
      "subjective.distanceBothUnaidedVA",
      "subjective.distanceBothAidedVA",
      "subjective.distanceBothPrism",
      "subjective.distanceLeftUnaidedVA",
      "subjective.distanceLeftSPH",
      "subjective.distanceLeftCYL",
      "subjective.distanceLeftAxis",
      "subjective.distanceLeftAidedVA",
      "subjective.distanceLeftPrism",
      "subjective.distanceLeftDistancePD",
      "subjective.nearRightUnaidedVA",
      "subjective.nearRightAdd",
      "subjective.nearRightSPH",
      "subjective.nearRightCYL",
      "subjective.nearRightAxis",
      "subjective.nearRightAidedVA",
      "subjective.nearRightPrism",
      "subjective.nearRightNearPD",
      "subjective.nearBothUnaidedVA",
      "subjective.nearBothAidedVA",
      "subjective.nearBothPrism",
      "subjective.nearLeftUnaidedVA",
      "subjective.nearLeftAdd",
      "subjective.nearLeftSPH",
      "subjective.nearLeftCYL",
      "subjective.nearLeftAxis",
      "subjective.nearLeftAidedVA",
      "subjective.nearLeftPrism",
      "subjective.nearLeftNearPD",
      "subjective.intermediateRightUnaidedVA",
      "subjective.intermediateRightAdd",
      "subjective.intermediateRightSPH",
      "subjective.intermediateRightCYL",
      "subjective.intermediateRightAxis",
      "subjective.intermediateRightAidedVA",
      "subjective.intermediateRightPrism",
      "subjective.intermediateRightInterPD",
      "subjective.intermediateBothAidedVA",
      "subjective.intermediateBothUnaidedVA",
      "subjective.intermediateBothPrism",
      "subjective.intermediateLeftAdd",
      "subjective.intermediateLeftUnaidedVA",
      "subjective.intermediateLeftSPH",
      "subjective.intermediateLeftCYL",
      "subjective.intermediateLeftAxis",
      "subjective.intermediateLeftAidedVA",
      "subjective.intermediateLeftPrism",
      "subjective.intermediateLeftInterPD",
    ];

    fieldsToClear.forEach((field) => {
      setValue(field, "");
    });
  };

  return (
    <Box>
      <DistanceVision fullView={fullView} />
      <NearVision fullView={fullView} />
      <IntermediateVision fullView={fullView} />
      <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: 5 }}>
        <LoadingButton variant="contained">Exit</LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => tab("final")}>
          Next
        </LoadingButton>
        <LoadingButton variant="outlined" sx={{ mr: 2 }} onClick={clearFields}>
          Clear
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={fullView ? handleCompactView : handleFullView}>
          {fullView ? "Compact View" : "Full View"}
        </LoadingButton>
        <LoadingButton variant="outlined" sx={{ mr: 2 }} onClick={copyData}>
          Copy to Final
        </LoadingButton>
      </Box>
    </Box>
  );
};
