import axios from "../utils/axios";

export class PatientService {
  getAllPatients() {
    return axios.get("/patients");
  }
  getPatientName(patientId: number) {
    return axios.get(`/patients/${patientId}/info`);
  }
  createPatient(data: any) {
    return axios.post("/patients", data);
  }
  updatePatient(id: any, data: any) {
    return axios.put(`/patients/${id}`, data);
  }
  deletePatient(id: any, data?: any) {
    return axios.delete(`/patients/${id}`, data);
  }
}
