import { useForm } from "react-hook-form";
import FormProvider from "../../../../../components/hook-form/FormProvider";
import RxFinal from "./RxFinal";
import TrailsForm from "./TrailsForm";
import { Box, Button } from "@mui/material";
import { ITrail } from "../../../../../@types/trail.type";
import { useParams } from "react-router-dom";
import { TrailsService } from "../../../../../api/contact-lenses/trail.service";
import { enqueueSnackbar } from "../../../../../components/snackbar";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";

type HandleChange = (e: React.ChangeEvent<HTMLInputElement>) => void;

type Props = {
  setOpenModal: (openModal: boolean) => void;
  clinicalRecordId: number;
  getTrailsRecords: (clinicalId: number) => void;
  trailData?: ITrail | null;
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
  handleChange: HandleChange;
};
const AddNewTrail = ({
  setOpenModal,
  clinicalRecordId,
  getTrailsRecords,
  trailData,
  selectedRow,
  clinicalRecords,
  handleChange,
}: Props) => {
  const methods = useForm<ITrail>();
  const { handleSubmit } = methods;

  const { patientId } = useParams();

  const onSubmit = async (data: ITrail) => {
    try {
      const trailsService = new TrailsService();
      const payload = {
        trailsRecord: { ...data, clinicalRecordId },
      };
      await trailsService.createTrail(patientId!, { ...payload });
      setOpenModal(false);
      enqueueSnackbar("Trail Added Successfully!", {
        variant: "success",
      });
      getTrailsRecords(clinicalRecordId);
    } catch (error) {
      enqueueSnackbar("Internal Server Error", {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <RxFinal />
      <TrailsForm
        getTrailsRecords={getTrailsRecords}
        trailData={trailData}
        selectedRow={selectedRow}
        clinicalRecords={clinicalRecords}
        handleChange={handleChange}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          margin: 2,
          mr: 6,
        }}>
        {!trailData && (
          <Button type="submit" variant="contained">
            Add Trail
          </Button>
        )}
      </Box>
    </FormProvider>
  );
};

export default AddNewTrail;
