import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAppPage"))
);

// DASHBOARD: INVOICE
export const DiaryPage = Loadable(
  lazy(() => import("../pages/dashboard/DiaryPage"))
);
export const PatientsListPage = Loadable(
  lazy(() => import("../pages/dashboard/PatientsListPage"))
);
export const SettingsPage = Loadable(
  lazy(() => import("../pages/dashboard/Settings/SettingsPage"))
);
export const PatientClinicalRecords = Loadable(
  lazy(() => import("../pages/dashboard/Patient/PatientClinicalRecords"))
);
export const DispensaryListPage = Loadable(
  lazy(() => import("../pages/dashboard/Dispense/DispensaryListPage"))
);
export const DispensaryClickPage = Loadable(
  lazy(() => import("../pages/dashboard/Dispense/DispenseClickPage"))
);
// BLANK PAGE
export const BlankPage = Loadable(
  lazy(() => import("../pages/dashboard/BlankPage"))
);

// MAIN

export const ComingSoonPage = Loadable(
  lazy(() => import("../pages/dashboard/ComingSoonPage"))
);

// AUTH
export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/LoginPage"))
);
export const RegisterPage = Loadable(
  lazy(() => import("../pages/auth/RegisterPage"))
);

export const NewPasswordPage = Loadable(
  lazy(() => import("../pages/auth/NewPasswordPage"))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import("../pages/auth/ResetPasswordPage"))
);

// MAIN
export const Page500 = Loadable(lazy(() => import("../pages/Page500")));
export const Page403 = Loadable(lazy(() => import("../pages/Page403")));
export const Page404 = Loadable(lazy(() => import("../pages/Page404")));

// Product
export const ProductListPage = Loadable(
  lazy(() => import("../pages/dashboard/Product/ProductListPage"))
);
export const ProductDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/Product/ProductDetailsPage"))
);
export const ProductNewUpdatePage = Loadable(
  lazy(() => import("../pages/dashboard/Product/ProductPage"))
);
