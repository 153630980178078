import { IHandoverSchema } from "../@types/handover";
import axios from "../utils/axios";

export class HandoverService {
  getAllHandoverByClinicalRecordId(patientId: any, handoverId?: number) {
    return axios.get(`/patient/${patientId}/handover?handoverId=${handoverId}`);
  }
  createHandover(patientId: any, data: IHandoverSchema) {
    return axios.post(`/patient/${patientId}/handover`, data);
  }
}
