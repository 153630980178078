export const getCharAtName = (name: string) =>
  name && name.charAt(0).toUpperCase();

export const capitalizeFirstLetter = (name: string) =>
  name.charAt(0).toUpperCase() + name.slice(1);

export const getComparator = (order: "asc" | "desc", orderBy: string) => {
  const orderName = orderBy.split(".");
  if(orderName.length > 1) {
    return order === "desc"
     ? (a: any, b: any) => (b[orderName[0]][orderName[1]] < a[orderName[0]][orderName[1]] ? -1 : 1)
      : (a: any, b: any) => (a[orderName[0]][orderName[1]] < b[orderName[0]][orderName[1]] ? -1 : 1);
  }
  return order === "desc"
    ? (a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1);
};
