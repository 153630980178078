import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RHFTextField from "../../../../../components/hook-form/RHFTextField";
import { Controller, useFormContext } from "react-hook-form";
import { ITrail } from "../../../../../@types/trail.type";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";
import { TrailsPreviousRecords } from "./TrailsPreviousRecords";

type HandleChange = (e: any) => void;

type Props = {
  getTrailsRecords: (clinicalId: number) => void;
  trailData?: ITrail | null;
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
  handleChange: HandleChange;
};

const TrailsForm = ({
  getTrailsRecords,
  trailData,
  selectedRow,
  clinicalRecords,
  handleChange,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const { getValues, setValue, reset, control } = useFormContext();

  const copyLeftToRight = () => {
    const fields = [
      "WearingTimeToday",
      "OverRefraction",
      "Movement",
      "PushUpTest",
      "TBUT",
      "Neovascularisation",
      "FluoresceinStaining",
      "Lids",
      "Conjunctiva",
      "Sclera",
      "LidsEversion",
      "ConjunctivalRedness",
      "LimbalRedness",
      "EpithelialMicrocysts",
      "CornealOedema",
      "Blepharitis",
      "LensCondition",
      "LensCentration",
      "ToricAlignment",
      "SubjectiveComfort",
      "MovementOnBlink",
      "TearQuality",
      "Sketch",
    ];

    fields.forEach((field) => {
      const leftValue = getValues(`left${field}`);
      setValue(`right${field}`, leftValue);
    });
  };

  useEffect(() => {
    const fields = [
      "supplier",
      "type",
      "freq",
      "lens",
      "leftWearingTimeToday",
      "leftOverRefraction",
      "leftMovement",
      "leftPushUpTest",
      "leftTBUT",
      "leftNeovascularisation",
      "leftFluoresceinStaining",
      "leftLids",
      "leftConjunctiva",
      "leftSclera",
      "leftLidsEversion",
      "leftConjunctivalRedness",
      "leftLimbalRedness",
      "leftEpithelialMicrocysts",
      "leftCornealOedema",
      "leftBlepharitis",
      "leftLensCondition",
      "leftLensCentration",
      "leftToricAlignment",
      "leftSubjectiveComfort",
      "leftMovementOnBlink",
      "leftTearQuality",
      "leftSketch",
      "rightWearingTimeToday",
      "rightOverRefraction",
      "rightMovement",
      "rightPushUpTest",
      "rightTBUT",
      "rightNeovascularisation",
      "rightFluoresceinStaining",
      "rightLids",
      "rightConjunctiva",
      "rightSclera",
      "rightLidsEversion",
      "rightConjunctivalRedness",
      "rightLimbalRedness",
      "rightEpithelialMicrocysts",
      "rightCornealOedema",
      "rightBlepharitis",
      "rightLensCondition",
      "rightLensCentration",
      "rightToricAlignment",
      "rightSubjectiveComfort",
      "rightMovementOnBlink",
      "rightTearQuality",
      "rightSketch",
      "rightBC",
      "rightTD",
      "rightSPH",
      "rightCYL",
      "rightAxis",
      "rightVA",
      "rightAdd",
      "rightOverRefraction",
      "rightVA2",
      "rightNV2",
      "leftBC",
      "leftTD",
      "leftSPH",
      "leftCYL",
      "leftAxis",
      "leftVA",
      "leftAdd",
      "leftVA2",
      "leftNV2",
      "semiRightBC",
      "semiRightSPH",
      "semiRightCYL",
      "semiRightAxis",
      "semiRightVA",
      "semiRightAdd",
      "semiRightNV",
      "semiLeftBC",
      "semiLeftSPH",
      "semiLeftCYL",
      "semiLeftAxis",
      "semiLeftVA",
      "semiLeftAdd",
      "semiLeftNV",
    ];

    if (!trailData || Object.keys(trailData).length === 0) {
      reset(
        fields.reduce((acc, field) => {
          acc[field] = "";
          return acc;
        }, {} as any)
      );
    } else {
      reset(
        fields.reduce((acc, field) => {
          acc[field] = trailData?.trailsRecord?.[field] || "";
          return acc;
        }, {} as any)
      );
    }
  }, [trailData, reset]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "95%",
        }}>
        <Typography variant="h6">Supplier</Typography>
        <Typography variant="h6">Type</Typography>
        <Typography variant="h6">Freq</Typography>
        <Typography variant="h6">Lens</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "95%",
          gap: "10px",
          marginLeft: "20px",
        }}>
        <RHFTextField
          name="supplier"
          size="small"
          sx={{ width: "90%", marginBottom: "10px" }}
        />
        <RHFTextField
          name="type"
          size="small"
          sx={{ width: "90%", marginBottom: "10px" }}
        />
        <RHFTextField
          name="freq"
          size="small"
          sx={{ width: "90%", marginBottom: "10px" }}
        />
        <RHFTextField
          name="lens"
          size="small"
          sx={{ width: "90%", marginBottom: "10px" }}
        />
      </Box>
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="right"
        ml={1}
        mr={5}
        spacing={2}>
        <Button variant="contained" onClick={copyLeftToRight}>
          Copy R to L
        </Button>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Previous Records
        </Button>
      </Stack>

      <Box
        sx={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: "1fr 1fr 1fr",
          justifyContent: "space-between",
        }}
        p={3}>
        {[
          "Wearing Time Today",
          "Movement",
          "Push Up Test",
          "TBUT",
          "Neovascularisation",
          "Fluorescein Staining",
          "Lids",
          "Conjunctiva",
          "Sclera",
          "Lids Eversion",
          "Conjunctival Redness",
          "Limbal Redness",
          "Epithelial Microcysts",
          "Corneal Oedema",
          "Blepharitis",
          "Lens Condition",
          "Lens Centration",
          "Toric Alignment",
          "Subjective Comfort",
          "Movement On Blink",
          "Tear Quality",
          "Sketch",
        ].map((label, index) => (
          <React.Fragment key={index}>
            <RHFTextField
              name={`left${label.replace(/ /g, "")}`}
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />
            <Typography
              color={"#637381"}
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {label}
            </Typography>
            <RHFTextField
              name={`right${label.replace(/ /g, "")}`}
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />
          </React.Fragment>
        ))}
      </Box>
      <Typography variant="h6" sx={{ ml: 3 }}>
        Semi Final
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        mt={1}>
        <Typography>Right</Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            BC
            <Controller
              name="semiRightBC"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              background: "black",
              position: "relative",
              top: "30px",
              transform: "rotate(14deg)",
            }}></Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            SPH
            <Controller
              name="semiRightSPH"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            CYL
            <Controller
              name="semiRightCYL"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            AXIS
            <Controller
              name="semiRightAxis"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{ width: "70px" }}
                  type="number"
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 180,
                    onInput: (e: any) => {
                      if (e.target.value.length > 3) {
                        e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                      }
                    },
                  }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            VA
            <Controller
              name="semiRightVA"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            ADD
            <Controller
              name="semiRightAdd"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            NV
            <Controller
              name="semiRightNV"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        mt={1}
        mb={2}
        ml={1}>
        <Typography>Left</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Controller
              name="semiLeftBC"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            className="line1"
            sx={{
              width: "1px",
              margin: "0 10px",
              height: "40px",
              background: "black",
              position: "relative",
              top: "9px",
              transform: "rotate(14deg)",
            }}></Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Controller
              name="semiLeftSPH"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Controller
              name="semiLeftCYL"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Controller
              name="semiLeftAxis"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Controller
              name="semiLeftVA"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Controller
              name="semiLeftAdd"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
          <Box
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Controller
              name="semiLeftNV"
              control={control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Ensure RHF updates
                    handleChange(e); // Your custom logic
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Box>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openModal}
        onClose={() => setOpenModal(false)}>
        <DialogTitle>Previous Records</DialogTitle>

        <TrailsPreviousRecords
          selectedRow={selectedRow}
          clinicalRecords={clinicalRecords}
          getTrailsRecords={getTrailsRecords}
        />
      </Dialog>
    </>
  );
};

export default TrailsForm;
