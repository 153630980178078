import { Box, Card, Typography } from "@mui/material";
import RxOutcome from "../CLFitting/RxOutcome";
import LeftAndRightCLAftercare from "./LeftAndRightCLAftercare";
import RxFinal from "../CLFitting/RxFinal";
import { useForm } from "react-hook-form";
import { ICLAftercare } from "../../../../../@types/clAftercare.type";
import { useParams } from "react-router-dom";
import { CLAftercareService } from "../../../../../api/contact-lenses/cl-aftercare.service";
import { enqueueSnackbar } from "../../../../../components/snackbar";
import FormProvider from "../../../../../components/hook-form/FormProvider";
import { useEffect } from "react";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";
import RxFinalAftercare from "./RxFinalAftercare";
import { ITrail } from "../../../../../@types/trail.type";

type Props = {
  clinicalRecordId: number;
  tab: (type: string) => void;
  clAftercare: ICLAftercare;
  showCLForm: boolean;
  getCLAftercareRecords: (clAftercareId: number, clinicalId: number) => void;
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
  trails: ITrail[];
};

const CLAftercare = ({
  clinicalRecordId,
  tab,
  clAftercare,
  showCLForm,
  getCLAftercareRecords,
  selectedRow,
  clinicalRecords,
  trails,
}: Props) => {
  const methods = useForm<ICLAftercare>();
  const { handleSubmit, reset } = methods;

  const { patientId } = useParams();

  const onSubmit = async (data: ICLAftercare) => {
    try {
      const clAftercareService = new CLAftercareService();
      const res = await clAftercareService.createCLAftercare(
        { ...data, clinicalRecordId },
        patientId!
      );
      enqueueSnackbar("CL Aftercare Created Successfully!", {
        variant: "success",
      });
      getCLAftercareRecords(res.data?.id, clinicalRecordId);
      tab("cl_handover");
    } catch (error) {
      enqueueSnackbar("Internal Server Error", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (showCLForm) {
      reset({
        leftPushUpTest: "",
        rightPushUpTest: "",
        leftTBUT: "",
        rightTBUT: "",
        leftNeovascularisation: "",
        rightNeovascularisation: "",
        leftFluoresceinStaining: "",
        rightFluoresceinStaining: "",
        leftLids: "",
        rightLids: "",
        leftConjunctiva: "",
        rightConjunctiva: "",
        leftSclera: "",
        rightSclera: "",
        leftLidEversion: "",
        rightLidEversion: "",
        leftConjunctivalRedness: "",
        rightConjunctivalRedness: "",
        leftLimbalRedness: "",
        rightLimbalRedness: "",
        leftEpithelialMicrocysts: "",
        rightEpithelialMicrocysts: "",
        leftCornealOedema: "",
        rightCornealOedema: "",
        leftBlepharitis: "",
        rightBlepharitis: "",
        leftSketch: "",
        rightSketch: "",
      });
    } else {
      reset({
        leftPushUpTest: clAftercare?.leftPushUpTest || "",
        rightPushUpTest: clAftercare?.rightPushUpTest || "",
        leftTBUT: clAftercare?.leftTBUT || "",
        rightTBUT: clAftercare?.rightTBUT || "",
        leftNeovascularisation: clAftercare?.leftNeovascularisation || "",
        rightNeovascularisation: clAftercare?.rightNeovascularisation || "",
        leftFluoresceinStaining: clAftercare?.leftFluoresceinStaining || "",
        rightFluoresceinStaining: clAftercare?.rightFluoresceinStaining || "",
        leftLids: clAftercare?.leftLids || "",
        rightLids: clAftercare?.rightLids || "",
        leftConjunctiva: clAftercare?.leftConjunctiva || "",
        rightConjunctiva: clAftercare?.rightConjunctiva || "",
        leftSclera: clAftercare?.leftSclera || "",
        rightSclera: clAftercare?.rightSclera || "",
        leftLidEversion: clAftercare?.leftLidEversion || "",
        rightLidEversion: clAftercare?.rightLidEversion || "",
        leftConjunctivalRedness: clAftercare?.leftConjunctivalRedness || "",
        rightConjunctivalRedness: clAftercare?.rightConjunctivalRedness || "",
        leftLimbalRedness: clAftercare?.leftLimbalRedness || "",
        rightLimbalRedness: clAftercare?.rightLimbalRedness || "",
        leftEpithelialMicrocysts: clAftercare?.leftEpithelialMicrocysts || "",
        rightEpithelialMicrocysts: clAftercare?.rightEpithelialMicrocysts || "",
        leftCornealOedema: clAftercare?.leftCornealOedema || "",
        rightCornealOedema: clAftercare?.rightCornealOedema || "",
        leftBlepharitis: clAftercare?.leftBlepharitis || "",
        rightBlepharitis: clAftercare?.rightBlepharitis || "",
        leftSketch: clAftercare?.leftSketch || "",
        rightSketch: clAftercare?.rightSketch || "",
      });
    }
  }, [showCLForm, clAftercare, reset]);
  return (
    <>
      <RxFinalAftercare trailData={trails[0]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {(showCLForm ||
          !clAftercare ||
          Object.keys(clAftercare).length === 0) && (
          <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
            * There's no CL Aftercare added for this clinical record, please add
            and save!
          </Typography>
        )}
        <Box
          sx={{
            margin: "2%",
          }}>
          <LeftAndRightCLAftercare
            getCLAftercareRecords={getCLAftercareRecords}
            selectedRow={selectedRow}
            clinicalRecords={clinicalRecords}
          />
          {/* <RxOutcome clinicalRecordId={} tab={tab}/> */}
        </Box>
      </FormProvider>
    </>
  );
};

export default CLAftercare;
