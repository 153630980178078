import axios from '../utils/axios';

export class SharedService {
  getAllAppointmentTypes() {
    return axios.get('/appointment-types');
  }
  upload(formData:FormData){
    return axios.post('/upload',formData);
  }

}
