import { Navigate } from "react-router-dom";
// routes
import { PATH_PAGE } from "../routes/paths";
// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthContext } from "./useAuthContext";

// ----------------------------------------------------------------------

type RegisterGuardProps = {
  children: React.ReactNode;
};

export default function RegisterGuard({ children }: RegisterGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  if (window.location.hostname.includes("optopro.co")) {
    return <Navigate to="/404" replace />;
  }
  if (isAuthenticated) {
    return <Navigate to={PATH_PAGE.home} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
