import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Button,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { IProduct, ProductStatus } from "../../../@types/product";
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover/MenuPopover";
import ConfirmDialog from "../../../components/confirm-dialog/ConfirmDialog";
import { capitalizeFirstLetter } from "../../../utils/util";

type Props = {
  product: IProduct;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  onPublish: VoidFunction;
  onView: VoidFunction;
};
export default function ProductTableRow({
  product,
  onEdit,
  onDelete,
  onPublish,
  onView
}: Props) {
  const navigate = useNavigate();

  // const handleProductDetails = () => {
  //   return <ProductNewEditForm
  //   onCancel={(value?: boolean) => {
  //     setOpenFormProduct(false);
  //     setSelectedProduct(undefined);
  //     if (value) getAllProducts();
  //   }}
  //   isView
  //   selectedProduct={selectedProduct}
  // />
  //   // navigate(PATH_DASHBOARD.product.details(product.id), {
  //   //   state: { product },
  //   // });
  // };

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPublishConfirm, setOpenPublishConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleOpenPublishConfirm = () => {
    setOpenPublishConfirm(true);
  };

  const handlePublishCloseConfirm = () => {
    setOpenPublishConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  return (
    <>
      <TableRow hover>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={onView}
        >
          <Typography variant="subtitle2" noWrap>
            {product.name} - {product.category}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {product.createdAt?.toUTCString()}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {product.quantity}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {product.price}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {capitalizeFirstLetter(product.status)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton
            color={openPopover ? "inherit" : "default"}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onEdit();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenPublishConfirm();
            handleClosePopover();
          }}
        >
          <Iconify
            icon={
              product.status === ProductStatus.PUBLISH
                ? "fluent-mdl2:unpublish-content"
                : "ic:baseline-publish"
            }
          />
          {product.status === ProductStatus.PUBLISH ? "UnPublish" : "Publish"}
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`Delete Product`}
        content={`Are you sure want to delete  ${product.name}?`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDelete();
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      />
      <ConfirmDialog
        open={openPublishConfirm}
        onClose={handlePublishCloseConfirm}
        title={`${
          product.status === ProductStatus.PUBLISH ? "UnPublish" : "Publish"
        } Product`}
        content={`Are you sure you want to ${
          product.status === ProductStatus.PUBLISH ? "unpublish" : "publish"
        } ${product.name}?`}
        action={
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              onPublish();
              handlePublishCloseConfirm();
            }}
          >
            Save
          </Button>
        }
      />
    </>
  );
}
