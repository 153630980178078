import { Typography, TextField, Stack } from "@mui/material";
import { IHandoverSchema } from "../../../../@types/handover";
import { IDispense } from "../../../../@types/dispense";
import { useForm } from "react-hook-form";
import FormProvider from "../../../../components/hook-form/FormProvider";
import { RHFTextField } from "../../../../components/hook-form";

type Props = {
  handoverData: IHandoverSchema;
};
export default function HandoverAndOrderingNotes({ handoverData }: Props) {
  const methods = useForm();
  return (
    <>
      <Stack
        sx={{
          width: "100%",
        }}
        direction="column"
        p={2}
        mr={2}>
        <Typography variant="h6">Handover Notes</Typography>
        <RHFTextField
          multiline
          rows={4}
          // disabled
          value={handoverData?.notes}
          name="handoverId"
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
        }}
        direction="column"
        p={2}
        mr={2}>
        <Typography variant="h6">Ordering Notes</Typography>
        <RHFTextField multiline rows={4} name="orderingNotes" />
      </Stack>
    </>
  );
}
