import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { RHFTextField } from "../../../../components/hook-form";
import { useFormContext } from "react-hook-form";
import { PreviousRecords } from "./PreviousRecords";
import { ClinicalRecords } from "../../../../api/clinicalRecords.service";
import { useParams } from "react-router-dom";
type Props = {
  clinicalRecordId: number;
};

const OMB = ({ clinicalRecordId }: Props) => {
  const [openRecordsModal, setOpenRecordsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [clinicalRecords, setClinicalRecords] = useState<any>([]);
  const [examination, setExamination] = useState<any>();

  const id = window.location.pathname.split("/")[2];

  const { setValue, getValues } = useFormContext();
  const clearExternalData = () => {
    const fieldNames = [
      "ocularMotorBalance.leftNPC",
      "ocularMotorBalance.leftAccommodation",
      "ocularMotorBalance.leftDistanceCTWithRX",
      "ocularMotorBalance.leftDistanceCTWithoutRX",
      "ocularMotorBalance.leftNearCTWithRX",
      "ocularMotorBalance.leftNearCTWithoutRX",
      "ocularMotorBalance.leftMotility",
      "ocularMotorBalance.leftConvergence",
      "ocularMotorBalance.leftMaddoxRod",
      "ocularMotorBalance.leftAdditionalNotes",
      "ocularMotorBalance.leftStereopsis",
      "ocularMotorBalance.rightNPC",
      "ocularMotorBalance.rightAccommodation",
      "ocularMotorBalance.rightDistanceCTWithRX",
      "ocularMotorBalance.rightDistanceCTWithoutRX",
      "ocularMotorBalance.rightNearCTWithRX",
      "ocularMotorBalance.rightNearCTWithoutRX",
      "ocularMotorBalance.rightMotility",
      "ocularMotorBalance.rightConvergence",
      "ocularMotorBalance.rightMaddoxRod",
      "ocularMotorBalance.rightAdditionalNotes",
      "ocularMotorBalance.rightStereopsis",
    ];

    const clearedValues = { ...getValues() };
    fieldNames.forEach((fieldName) => {
      clearedValues[fieldName] = "";
    });

    // Update the form values
    Object.keys(clearedValues).forEach((fieldName) => {
      setValue(fieldName, clearedValues[fieldName]);
    });
  };

  const getAllClinicsRecords = () => {
    const clinicalRecords = new ClinicalRecords();
    clinicalRecords.getAllClinicalRecords(+id).then((res) => {
      setClinicalRecords(res.data);
      const examinationId = res.data[0]?.examinationId;
      const clinicalId = res.data[0]?.id;
      setSelectedRow(clinicalId);

      getExaminationRecords(examinationId, clinicalId);
    });
  };

  const { patientId } = useParams();

  const getExaminationRecords = (examinationId: number, clinicalId: number) => {
    const clinicalRecords = new ClinicalRecords();
    if (examinationId !== null) {
      clinicalRecords
        .getExaminationByClinicalRecordId(patientId, examinationId)
        .then((res) => {
          setExamination(res.data[0]);
          setPreviousData();
        });
    } else {
      setExamination([]);
    }
  };

  const setPreviousData = useCallback(() => {
    if (!examination) return;
    setValue(
      "ocularMotorBalance.rightNPC",
      examination?.ocularMotorBalance?.rightNPC
    );
    setValue(
      "ocularMotorBalance.rightAccomadtion",
      examination?.ocularMotorBalance?.rightAccomadtion
    );
    setValue(
      "ocularMotorBalance.rightStereopsis",
      examination?.ocularMotorBalance?.rightStereopsis
    );
    setValue(
      "ocularMotorBalance.rightDistanceCTWithRX",
      examination?.ocularMotorBalance?.rightDistanceCTWithRX
    );
    setValue(
      "ocularMotorBalance.rightDistanceCTWithoutRX",
      examination?.ocularMotorBalance?.rightDistanceCTWithoutRX
    );
    setValue(
      "ocularMotorBalance.rightNearCTWithRX",
      examination?.ocularMotorBalance?.rightNearCTWithRX
    );
    setValue(
      "ocularMotorBalance.rightNearCTWithoutRX",
      examination?.ocularMotorBalance?.rightNearCTWithoutRX
    );
    setValue(
      "ocularMotorBalance.rightMotility",
      examination?.ocularMotorBalance?.rightMotility
    );
    setValue(
      "ocularMotorBalance.rightConvergence",
      examination?.ocularMotorBalance?.rightConvergence
    );
    setValue(
      "ocularMotorBalance.rightMaddoxRod",
      examination?.ocularMotorBalance?.rightMaddoxRod
    );
    setValue(
      "ocularMotorBalance.rightAdditionalNotes",
      examination?.ocularMotorBalance?.rightAdditionalNotes
    );
    setValue(
      "ocularMotorBalance.leftNPC",
      examination?.ocularMotorBalance?.leftNPC
    );
    setValue(
      "ocularMotorBalance.leftAccomadtion",
      examination?.ocularMotorBalance?.leftAccomadtion
    );
    setValue(
      "ocularMotorBalance.leftStereopsis",
      examination?.ocularMotorBalance?.leftStereopsis
    );
    setValue(
      "ocularMotorBalance.leftDistanceCTWithRX",
      examination?.ocularMotorBalance?.leftDistanceCTWithRX
    );
    setValue(
      "ocularMotorBalance.leftDistanceCTWithoutRX",
      examination?.ocularMotorBalance?.leftDistanceCTWithoutRX
    );
    setValue(
      "ocularMotorBalance.leftNearCTWithRX",
      examination?.ocularMotorBalance?.leftNearCTWithRX
    );
    setValue(
      "ocularMotorBalance.leftNearCTWithoutRX",
      examination?.ocularMotorBalance?.leftNearCTWithoutRX
    );
    setValue(
      "ocularMotorBalance.leftMotility",
      examination?.ocularMotorBalance?.leftMotility
    );
    setValue(
      "ocularMotorBalance.leftConvergence",
      examination?.ocularMotorBalance?.leftConvergence
    );
    setValue(
      "ocularMotorBalance.leftMaddoxRod",
      examination?.ocularMotorBalance?.leftMaddoxRod
    );
    setValue(
      "ocularMotorBalance.leftAdditionalNotes",
      examination?.ocularMotorBalance?.leftAdditionalNotes
    );
  }, [examination]);
  const copyData = () => {
    const leftNPC = getValues("ocularMotorBalance.leftNPC");
    const leftAccommodation = getValues("ocularMotorBalance.leftAccommodation");
    const leftDistanceCTWithRX = getValues(
      "ocularMotorBalance.leftDistanceCTWithRX"
    );
    const leftDistanceCTWithoutRX = getValues(
      "ocularMotorBalance.leftDistanceCTWithoutRX"
    );
    const leftNearCTWithRX = getValues("ocularMotorBalance.leftNearCTWithRX");
    const leftNearCTWithoutRX = getValues(
      "ocularMotorBalance.leftNearCTWithoutRX"
    );
    const leftMotility = getValues("ocularMotorBalance.leftMotility");
    const leftConvergence = getValues("ocularMotorBalance.leftConvergence");
    const leftMaddoxRod = getValues("ocularMotorBalance.leftMaddoxRod");
    const leftAdditionalNotes = getValues(
      "ocularMotorBalance.leftAdditionalNotes"
    );
    const leftStereopsis = getValues("ocularMotorBalance.leftStereopsis");

    setValue("ocularMotorBalance.rightNPC", leftNPC);
    setValue("ocularMotorBalance.rightAccommodation", leftAccommodation);
    setValue("ocularMotorBalance.rightStereopsis", leftStereopsis);
    setValue("ocularMotorBalance.rightDistanceCTWithRX", leftDistanceCTWithRX);
    setValue(
      "ocularMotorBalance.rightDistanceCTWithoutRX",
      leftDistanceCTWithoutRX
    );
    setValue("ocularMotorBalance.rightNearCTWithRX", leftNearCTWithRX);
    setValue("ocularMotorBalance.rightNearCTWithoutRX", leftNearCTWithoutRX);
    setValue("ocularMotorBalance.rightMotility", leftMotility);
    setValue("ocularMotorBalance.rightConvergence", leftConvergence);
    setValue("ocularMotorBalance.rightMaddoxRod", leftMaddoxRod);
    setValue("ocularMotorBalance.rightAdditionalNotes", leftAdditionalNotes);
  };

  const setDefaultValue = () => {
    setValue("ocularMotorBalance.rightNPC", "5cm");
    setValue("ocularMotorBalance.rightAccommodation", "normal");
    setValue("ocularMotorBalance.rightStereopsis", "normal");
    setValue("ocularMotorBalance.rightDistanceCTWithRX", "NMD");
    setValue("ocularMotorBalance.rightDistanceCTWithoutRX", "NMD");
    setValue("ocularMotorBalance.rightNearCTWithRX", "NMD");
    setValue("ocularMotorBalance.rightNearCTWithoutRX", "NMD");
    setValue("ocularMotorBalance.rightMotility", "full");
    setValue("ocularMotorBalance.rightConvergence", "within normal limits");
    setValue("ocularMotorBalance.rightMaddoxRod", "no slip");
    setValue("ocularMotorBalance.rightAdditionalNotes", "");
    setValue("ocularMotorBalance.leftNPC", "5cm");
    setValue("ocularMotorBalance.leftAccommodation", "normal");
    setValue("ocularMotorBalance.leftStereopsis", "normal");
    setValue("ocularMotorBalance.leftDistanceCTWithRX", "NMD");
    setValue("ocularMotorBalance.leftDistanceCTWithoutRX", "NMD");
    setValue("ocularMotorBalance.leftNearCTWithRX", "NMD");
    setValue("ocularMotorBalance.leftNearCTWithoutRX", "NMD");
    setValue("ocularMotorBalance.leftMotility", "full");
    setValue("ocularMotorBalance.leftConvergence", "within normal limits");
    setValue("ocularMotorBalance.leftMaddoxRod", "no slip");
    setValue("ocularMotorBalance.leftAdditionalNotes", "");
  };

  const handleOpenRecordModal = () => {
    setOpenRecordsModal(true);
  };

  const handleCloseRecordModal = () => {
    setOpenRecordsModal(false);
  };

  useEffect(() => {
    getAllClinicsRecords();
  }, []);

  return (
    <>
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="right"
        ml={1}
        mr={1}
        spacing={2}>
        <Button variant="contained" onClick={clearExternalData}>
          Clear
        </Button>
        <Button variant="contained" onClick={setDefaultValue}>
          Default
        </Button>
        <Button variant="contained" onClick={copyData}>
          Copy R to L
        </Button>
        <Button variant="contained" onClick={handleOpenRecordModal}>
          Previous Records
        </Button>
      </Stack>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        p={5}>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Right
          </Typography>
          <RHFTextField
            name="ocularMotorBalance.leftNPC"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftAccommodation"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftDistanceCTWithRX"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftDistanceCTWithoutRX"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftNearCTWithRX"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftNearCTWithoutRX"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftStereopsis"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftMotility"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftConvergence"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftMaddoxRod"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.leftAdditionalNotes"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "10px" }}
          />
        </Box>
        <Box
          sx={{
            marginBottom: "50px",
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}></Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            NPC
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Accommodation
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Distance CT with RX
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Distance CT without RX
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Near CT with RX
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Near CT without RX
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Stereopsis
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Motility
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Convergence
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={3}>
            Maddox Rod
          </Typography>
          <Typography color={"#637381"} variant="h6" mt={6}>
            Additional Notes
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography color={"#637381"} variant="h6" mb={3}>
            Left
          </Typography>
          <RHFTextField
            name="ocularMotorBalance.rightNPC"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightAccommodation"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightDistanceCTWithRX"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightDistanceCTWithoutRX"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightNearCTWithRX"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightNearCTWithoutRX"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightStereopsis"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightMotility"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightConvergence"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightMaddoxRod"
            size="small"
            sx={{ width: "90%", marginBottom: "10px" }}
          />
          <RHFTextField
            name="ocularMotorBalance.rightAdditionalNotes"
            size="small"
            multiline
            rows={4}
            sx={{ width: "90%", marginBottom: "1px" }}
          />
        </Box>
        <Dialog
          fullWidth
          maxWidth="md"
          open={openRecordsModal}
          onClose={handleCloseRecordModal}>
          <DialogTitle>Records</DialogTitle>

          <PreviousRecords
            selectedRow={selectedRow}
            clinicalRecords={clinicalRecords}
            getExaminationRecords={getExaminationRecords}
            setSelectedRow={setSelectedRow}
            previousRecordsRow={clinicalRecordId}
          />
        </Dialog>
      </Box>
    </>
  );
};

export default OMB;
