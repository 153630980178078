import { Box, Typography, Switch, FormControlLabel } from "@mui/material";
import { RHFTextField } from "../../../../../components/hook-form";
import { ICLHistoryAndSymptoms } from "../../../../../@types/clHistoryAndSymptoms.type";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";

type Props = {
  clHistorySymptoms: ICLHistoryAndSymptoms;
  showCLForm: boolean;
};

const General = ({ clHistorySymptoms, showCLForm }: Props) => {
  const contactLensesTextFields = [
    {
      label: "Health",
      name: "health",
    },
    {
      label: "Ocular History",
      name: "ocularHistory",
    },
    {
      label: "Medications",
      name: "medications",
    },
    {
      label: "Allergies",
      name: "allergies",
    },
    {
      label: "Reason for Visit",
      name: "reasonForVisit",
    },
    {
      label: "Current Lenses",
      name: "currentLenses",
    },
  ];

  const miscellaneousTextFields = [
    {
      label: "Reason For Visit",
      name: "reasonForVisitMisc",
    },
    {
      label: "Comfort of Current Lenses",
      name: "comfortOfCurrentLenses",
    },
  ];
  const { control, reset } = useFormContext();

  useEffect(() => {
    if (showCLForm) {
      reset({
        reasonForVisitMisc: "",
        comfortOfCurrentLenses: "",
        health: "",
        ocularHistory: "",
        medications: "",
        allergies: "",
        reasonForVisit: "",
        currentLenses: "",
        averageWearingTimeWeeks: "",
        averageWearingTimeDays: "",
        maxWearingTimeWeeks: "",
        maxWearingTimeDays: "",
        isDriver: false,
        isVDUUse: false,
        isSmoker: false,
      });
    } else {
      reset({
        reasonForVisitMisc: clHistorySymptoms?.reasonForVisitMisc || "",
        comfortOfCurrentLenses: clHistorySymptoms?.comfortOfCurrentLenses || "",
        health: clHistorySymptoms?.health || "",
        ocularHistory: clHistorySymptoms?.ocularHistory || "",
        medications: clHistorySymptoms?.medications || "",
        allergies: clHistorySymptoms?.allergies || "",
        reasonForVisit: clHistorySymptoms?.reasonForVisit || "",
        currentLenses: clHistorySymptoms?.currentLenses || "",
        averageWearingTimeWeeks:
          clHistorySymptoms?.averageWearingTimeWeeks || "",
        averageWearingTimeDays: clHistorySymptoms?.averageWearingTimeDays || "",
        maxWearingTimeWeeks: clHistorySymptoms?.maxWearingTimeWeeks || "",
        maxWearingTimeDays: clHistorySymptoms?.maxWearingTimeDays || "",
        isDriver: clHistorySymptoms?.isDriver || false,
        isVDUUse: clHistorySymptoms?.isVDUUse || false,
        isSmoker: clHistorySymptoms?.isSmoker || false,
      });
    }
  }, [showCLForm, clHistorySymptoms, reset]);

  return (
    <Box>
      {(showCLForm ||
        !clHistorySymptoms ||
        Object.keys(clHistorySymptoms).length === 0) && (
        <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
          * There's no CL H&S added for this clinical record, please edit and
          save!
        </Typography>
      )}
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Miscellaneous
        </Typography>
        {miscellaneousTextFields.map((field, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "95%",
              ml: 3,
              mr: 3,
              mt: 2,
            }}>
            <Typography sx={{ width: "150px", mr: 5.5 }}>
              {field.label}
            </Typography>
            <Controller
              name={field.name}
              control={control}
              render={({ field }) => (
                <RHFTextField
                  {...field}
                  id="outlined-adornment-amount"
                  fullWidth
                />
              )}
            />
          </Box>
        ))}
      </Box>
      <Typography variant="h5">General</Typography>

      {contactLensesTextFields.map((field, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "95%",
            ml: 3,
            mr: 3,
            mt: 2,
          }}>
          <Typography sx={{ width: "150px", mr: 5.5 }}>
            {field.label}
          </Typography>
          <Controller
            name={field.name}
            control={control}
            render={({ field }) => (
              <RHFTextField
                {...field}
                id="outlined-adornment-amount"
                fullWidth
              />
            )}
          />
        </Box>
      ))}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "95%",
          ml: 3,
          mr: 3,
          mt: 2,
        }}>
        <Typography sx={{ width: "150px", mr: 2 }}>Avg Wearing Time</Typography>
        <Controller
          name="averageWearingTimeWeeks"
          control={control}
          render={({ field }) => (
            <RHFTextField
              {...field}
              label="Weeks"
              id="outlined-start-adornment"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ m: 1, width: "25ch" }}
            />
          )}
        />
        <Controller
          name="averageWearingTimeDays"
          control={control}
          render={({ field }) => (
            <RHFTextField
              {...field}
              label="Days"
              id="outlined-start-adornment"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ m: 1, width: "25ch" }}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "95%",
          ml: 3,
          mr: 3,
          mt: 2,
        }}>
        <Typography sx={{ width: "150px", mr: 2 }}>Max Wearing Time</Typography>
        <Controller
          name="maxWearingTimeWeeks"
          control={control}
          render={({ field }) => (
            <RHFTextField
              {...field}
              InputLabelProps={{
                shrink: true,
              }}
              label="Weeks"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "25ch" }}
            />
          )}
        />
        <Controller
          name="maxWearingTimeDays"
          control={control}
          render={({ field }) => (
            <RHFTextField
              {...field}
              label="Days"
              InputLabelProps={{
                shrink: true,
              }}
              id="outlined-start-adornment"
              sx={{ m: 1, width: "25ch" }}
            />
          )}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "95%",
          ml: 3,
          mr: 3,
          mt: 2,
        }}>
        <Controller
          name="isDriver"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} />}
              label="Is Driver"
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "95%",
          ml: 3,
          mr: 3,
          mt: 2,
        }}>
        <Controller
          name="isVDUUse"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} />}
              label="Is VDU Use"
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "95%",
          ml: 3,
          mr: 3,
          mt: 2,
        }}>
        <Controller
          name="isSmoker"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} />}
              label="Is Smoker"
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default General;
