import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DistanceVision } from "./DistanceVision";
import { NearVision } from "./NearVision";
import { IntermediateVision } from "./IntermediateVision";
import { LoadingButton } from "@mui/lab";
import { useFormContext } from "react-hook-form";
import { IVisionSchema } from "../../../../../@types/specsHistory";

type Props = {
  tab: any;
};
export const LensMeter = ({ tab }: Props) => {
  const [fullView, setFullView] = useState(false);
  const handleFullView = () => {
    setFullView(true);
  };

  const clearFields = () => {
    const fields = [
      "distanceRightSPH",
      "distanceRightCYL",
      "distanceRightAxis",
      "distanceRightAidedVA",
      "distanceRightPrism",
      "distanceRightDistancePD",
      "distanceBothAidedVA",
      "distanceBothPrism",
      "distanceLeftSPH",
      "distanceLeftCYL",
      "distanceLeftAxis",
      "distanceLeftAidedVA",
      "distanceLeftPrism",
      "distanceLeftDistancePD",
      "nearRightAdd",
      "nearRightSPH",
      "nearRightCYL",
      "nearRightAxis",
      "nearRightAidedVA",
      "nearRightPrism",
      "nearRightNearPD",
      "nearBothAidedVA",
      "nearBothPrism",
      "nearLeftAdd",
      "nearLeftSPH",
      "nearLeftCYL",
      "nearLeftAxis",
      "nearLeftAidedVA",
      "nearLeftPrism",
      "nearLeftNearPD",
      "intermediateRightAdd",
      "intermediateRightSPH",
      "intermediateRightCYL",
      "intermediateRightAxis",
      "intermediateRightAidedVA",
      "intermediateRightPrism",
      "intermediateRightInterPD",
      "intermediateBothAidedVA",
      "intermediateBothPrism",
      "intermediateLeftAdd",
      "intermediateLeftSPH",
      "intermediateLeftCYL",
      "intermediateLeftAxis",
      "intermediateLeftAidedVA",
      "intermediateLeftPrism",
      "intermediateLeftInterPD",
    ];

    fields.forEach((field) => {
      setValue(`lensMeter.${field}`, "");
    });
  };

  const handleCompactView = () => {
    setFullView(false);
  };

  const { setValue, getValues } = useFormContext();

  const copyData = () => {
    const fieldTypes = ["distance", "near", "intermediate"];
    const sides = ["Right", "Left", "Both"];
    const params = [
      "SPH",
      "CYL",
      "Axis",
      "AidedVA",
      "Prism",
      "DistancePD",
      "Add",
      "NearPD",
      "InterPD",
    ];

    const mapValues = (type: string, side: string, param: string) => {
      const lensMeterField = `lensMeter.${type}${side}${param}`;
      const subjectiveFields = `subjective.${type}${side}${param}`;

      const value = getValues(lensMeterField);
      setValue(subjectiveFields, value);
    };

    fieldTypes.forEach((type) => {
      sides.forEach((side) => {
        params.forEach((param) => {
          const validParam =
            (type === "distance" &&
              param !== "Add" &&
              param !== "NearPD" &&
              param !== "InterPD") ||
            (type === "near" &&
              param !== "DistancePD" &&
              param !== "InterPD") ||
            (type === "intermediate" &&
              param !== "DistancePD" &&
              param !== "NearPD");
          if (validParam) {
            mapValues(type, side, param);
          }
        });
      });
    });
    tab("subjective");
  };

  return (
    <Box>
      <DistanceVision fullView={fullView} />
      <NearVision fullView={fullView} />
      <IntermediateVision fullView={fullView} />
      <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: 5 }}>
        <LoadingButton variant="contained">Exit</LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => tab("auto_refractor")}>
          Next
        </LoadingButton>
        <LoadingButton variant="outlined" sx={{ mr: 2 }} onClick={clearFields}>
          Clear
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={fullView ? handleCompactView : handleFullView}>
          {fullView ? "Compact View" : "Full View"}
        </LoadingButton>
        <LoadingButton variant="outlined" sx={{ mr: 2 }} onClick={copyData}>
          Copy to Subjective
        </LoadingButton>
      </Box>
    </Box>
  );
};
