import * as React from "react";
import {
  Card,
  Typography,
  Box,
  Stack,
  Grid,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormProvider from "../../../../components/hook-form/FormProvider";
import { IHandoverSchema } from "../../../../@types/handover";
import { RHFTextField } from "../../../../components/hook-form";
import { useForm } from "react-hook-form";
import { HandoverService } from "../../../../api/handover.service";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { PATH_DASHBOARD } from "../../../../routes/paths";
import { DispensaryService } from "../../../../api/dispensary.service";
import { IDispenseRequestFromHandover } from "../../../../@types/dispense";
import { useAuthContext } from "../../../../auth/useAuthContext";
import FinalSummary from "../Refraction/FinalSummary";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const monthsData = [
  { id: 1, number: 1 },
  { id: 2, number: 2 },
  { id: 3, number: 3 },
  { id: 4, number: 4 },
  { id: 5, number: 5 },
  { id: 6, number: 6 },
  { id: 7, number: 7 },
  { id: 8, number: 8 },
  { id: 9, number: 9 },
  { id: 10, number: 10 },
  { id: 11, number: 11 },
  { id: 12, number: 12 },
  { id: 13, number: 13 },
  { id: 14, number: 14 },
  { id: 15, number: 15 },
  { id: 16, number: 16 },
  { id: 17, number: 17 },
  { id: 18, number: 18 },
  { id: 19, number: 19 },
  { id: 20, number: 20 },
  { id: 21, number: 21 },
  { id: 22, number: 22 },
  { id: 23, number: 23 },
  { id: 24, number: 24 },
];

type Props = {
  selectedHandover: IHandoverSchema;
  clinicalRecordId: any;
  dispenseData: IDispenseRequestFromHandover;
  getAllHandover: (handoverId: number, clinicalId: number) => void;
  finalData: any;
};
export default function Handover({
  selectedHandover,
  clinicalRecordId,
  dispenseData,
  getAllHandover,
  finalData,
}: Props) {
  const navigate = useNavigate();

  const [outcome, setOutcome] = useState(selectedHandover?.outcome);
  const [recommendations, setRecommendations] = useState<any>([]);
  const [examType, setExamType] = useState<any>([]);
  const [furtherRecommendations, setFurtherRecommendations] = useState<any>([]);
  const [recommendationsOptions, setRecommendationsOptions] = useState<
    string[]
  >([]);
  const [examTypeOptions, setExamTypeOptions] = useState<string[]>([]);
  const [furtherRecommendationsOptions, setFurtherRecommendationsOptions] =
    useState<string[]>([]);
  const [eyeExamRecallPeriod, setEyeExamRecallPeriod] = React.useState("");

  const handleEyeExamPeriodMonthsChange = (event: any) => {
    setEyeExamRecallPeriod(event.target.value);
  };

  const recommendationsData = [
    { label: "Distance Single Vision" },
    { label: "Intermediate Single Vision" },
    { label: "Near Single Vision" },
    { label: "Bifocals" },
    { label: "Varifocals" },
    { label: "Occupational" },
    { label: "Prescription Sunglasses" },
    { label: "Contact Lenses" },
    ...recommendationsOptions.map((item) => ({ label: item })),
  ];

  const examTypeData = [
    { label: "Private Eye Exam" },
    { label: "NHS Exam" },
    { label: "Contact Lens Fit" },
    { label: "Contact Lens AfterCare" },
    { label: "Staff Eye Exam" },
    { label: "Safety Eye Exam" },
    { label: "Re-Check" },
    { label: "Other" },
    ...examTypeOptions.map((item) => ({ label: item })),
  ];

  const furtherRecommendationsData = [
    { label: "MAR" },
    { label: "1.6" },
    { label: "1.67" },
    { label: "1.74" },
    { label: "Blue Protect" },
    { label: "Tint" },
    ...furtherRecommendationsOptions.map((item) => ({ label: item })),
  ];

  const handleRecommendationsInputChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    if (
      !recommendationsOptions.some(
        (item: any) => item.toLowerCase() === value.toLowerCase()
      )
    ) {
      setRecommendationsOptions((prevRecommendations: any) => [
        ...prevRecommendations,
        value.trim(),
      ]);
    }
  };

  const handleFurtherRecommendationsInputChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    if (
      !furtherRecommendationsOptions.some(
        (item: any) => item.toLowerCase() === value.toLowerCase()
      )
    ) {
      setFurtherRecommendationsOptions((prevRecommendations: any) => [
        ...prevRecommendations,
        value.trim(),
      ]);
    }
  };

  const handleExamTypeInputChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    if (
      !examTypeOptions.some(
        (item: any) => item.toLowerCase() === value.toLowerCase()
      )
    ) {
      setExamTypeOptions((prevRecommendations: any) => [
        ...prevRecommendations,
        value.trim(),
      ]);
    }
  };

  const recommendationsArray =
    selectedHandover?.recommendations
      ?.split(",")
      .map((recommendation: any) => ({ label: recommendation })) || [];

  const examTypeArray =
    selectedHandover?.examType
      ?.split(",")
      .map((examType: any) => ({ label: examType })) || [];

  const furtherRecommendationsArray = selectedHandover?.furtherRecommendations
    ? selectedHandover.furtherRecommendations
        ?.split(",")
        .map((furtherRecommendations: any) => ({
          label: furtherRecommendations.trim(),
        }))
    : [];

  const handleChange = (event: SelectChangeEvent) => {
    setOutcome(event.target.value as string);
  };

  const { patientId } = useParams();

  const methods = useForm<IHandoverSchema>({
    defaultValues: {},
  });

  const { handleSubmit, reset, setValue } = methods;
  const onSubmit = async (data: IHandoverSchema) => {
    try {
      const recommendationsData = recommendations
        .map((item: any) => item.label)
        .join(", ");
      const examTypeData = examType.map((item: any) => item.label).join(", ");
      const furtherRecommendationsData = furtherRecommendations
        .map((item: any) => item.label)
        .join(", ");

      const handoverData = {
        ...data,
        recommendations: recommendationsData,
        examType: examTypeData,
        furtherRecommendations: furtherRecommendationsData,
        outcome,
        eyeExamRecallPeriod,
        clinicalRecordId,
      };

      const handoverService = new HandoverService();
      const handoverResponse = await handoverService.createHandover(patientId, {
        ...handoverData,
        clinicalRecordId,
      });
      const handoverId = handoverResponse.data.id;
      const clinicalId = handoverResponse.data.clinicalRecordId;
      getAllHandover(handoverId, clinicalId);
      enqueueSnackbar("Handover Created Successfully!", {
        variant: "success",
      });

      const dispensaryService = new DispensaryService();
      const dispenseResponse =
        await dispensaryService.createDispenseFromHandover(
          patientId,
          dispenseData
        );

      enqueueSnackbar(
        `Dispense For Patient Id ${patientId} Created Successfully!`,
        {
          variant: "success",
        }
      );

      navigate(PATH_DASHBOARD.dispense.list);
    } catch (error: any) {
      enqueueSnackbar(error?.message?.[0] || "An error occurred", {
        variant: "error",
      });
    }
  };

  const setHandoverData = () => {
    if (selectedHandover && Object.keys(selectedHandover).length > 0) {
      reset({
        outcome: selectedHandover?.outcome || "",
        notes: selectedHandover?.notes || "",
        examiner: selectedHandover?.examiner || user?.username,
        // other fields like eyeExamRecallPeriod can be added here
      });

      setRecommendations(
        selectedHandover.recommendations
          ? selectedHandover.recommendations
              .split(",")
              .map((item: any) => ({ label: item }))
          : []
      );
      setExamType(
        selectedHandover.examType
          ? selectedHandover.examType
              .split(",")
              .map((item: any) => ({ label: item }))
          : []
      );
      setFurtherRecommendations(
        selectedHandover.furtherRecommendations
          ? selectedHandover.furtherRecommendations
              .split(",")
              .map((item: any) => ({ label: item }))
          : []
      );
      setOutcome(selectedHandover.outcome || "");
    } else {
      reset({
        outcome: "",
        notes: "",
        examiner: user?.username,
      });

      setRecommendations([]);
      setExamType([]);
      setFurtherRecommendations([]);
      setOutcome("");
    }
  };

  const { user } = useAuthContext();

  useEffect(() => {
    setHandoverData();
  }, [selectedHandover, reset]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      key={selectedHandover?.id || "new-handover"}>
      <Box sx={{ ml: "1px", mr: "8px" }}>
        {Object.keys(selectedHandover).length === 0 && (
          <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
            * There's no Handover added for this clinical record, please edit
            and save!
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "98%",
            paddingRight: 3,
            paddingLeft: 2,
          }}>
          {/* Last Eye Exam Section */}
          <FinalSummary finalData={finalData} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}>
          <Grid item xs={12} md={4} width="50%">
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Recommendations</Typography>
              </Box>
              <Box mt={2}>
                <Stack spacing={3} sx={{ width: 500 }}>
                  <Autocomplete
                    options={recommendationsData}
                    multiple
                    defaultValue={recommendationsArray}
                    getOptionLabel={(option: any) => option?.label}
                    onChange={(event, data) => {
                      setRecommendations(data);
                    }}
                    onInputChange={(event, value) => {
                      handleRecommendationsInputChange(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        name="recommendations"
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Exam Type</Typography>
              </Box>
              <Box mt={2}>
                <Stack spacing={3} sx={{ width: 500 }}>
                  <Autocomplete
                    multiple
                    options={examTypeData}
                    defaultValue={examTypeArray}
                    getOptionLabel={(option) => option?.label}
                    onChange={(event, data) => {
                      setExamType(data);
                    }}
                    onInputChange={(event, value) => {
                      handleExamTypeInputChange(event, value);
                    }}
                    renderInput={(params) => (
                      <RHFTextField
                        {...params}
                        variant="standard"
                        name="examType"
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Outcome</Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Referred
                  </Typography>
                  <Checkbox {...label} />
                </Box>
              </Box>
              <Box sx={{ minWidth: 120, mt: 3 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Outcome</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="outcome"
                    defaultValue={selectedHandover?.outcome || ""}
                    label="Outcome"
                    onChange={handleChange}>
                    <MenuItem value={"NewRx"}>New Rx</MenuItem>
                    <MenuItem value={"NoChange"}>No Change</MenuItem>
                    <MenuItem value={"NoRx"}>No Rx</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="row"
              mt={2}
              ml={2}
              p={2}>
              <Typography variant="h6" sx={{ mt: "5px" }}>
                Eye Exam Recall Period
              </Typography>
              <FormControl sx={{ ml: "10px", width: "20%", mr: "10px" }}>
                <InputLabel id="demo-simple-select-label">Months</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Months"
                  defaultValue={selectedHandover?.eyeExamRecallPeriod || ""}
                  onChange={handleEyeExamPeriodMonthsChange}>
                  {monthsData.map((months) => {
                    return (
                      <MenuItem key={months.id} value={months.number}>
                        {months.number}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} width="50%">
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Further Recommendations</Typography>
              </Box>
              <Box mt={2}>
                <Stack spacing={3} sx={{ width: 500 }}>
                  <Autocomplete
                    multiple
                    options={furtherRecommendationsData}
                    defaultValue={furtherRecommendationsArray}
                    getOptionLabel={(option) => option?.label}
                    onChange={(event, data) => {
                      setFurtherRecommendations(data);
                    }}
                    onInputChange={(event, value) => {
                      handleFurtherRecommendationsInputChange(event, value);
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.label}</li>
                    )}
                    renderInput={(params) => (
                      <RHFTextField
                        {...params}
                        variant="standard"
                        name="furtherRecommendations"
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Notes</Typography>
              </Box>
              <Box mt={2}>
                <RHFTextField
                  placeholder="Please Add Notes"
                  multiline
                  rows={2}
                  name="notes"
                />
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "90%",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                maxHeight: "380px",
                overflowY: "auto",
              }}
              direction="column"
              mt={2}
              ml={2}
              p={2}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Typography variant="h6">Examiner</Typography>
              </Box>
              <Box mt={2}>
                <RHFTextField
                  value={user?.username}
                  multiline
                  rows={2}
                  name="examiner"
                />
              </Box>
            </Stack>
          </Grid>
        </Box>
      </Box>
      <Stack direction="row-reverse" mt="40px" gap="30px" mr="17px">
        <LoadingButton type="button" variant="contained">
          Exit
        </LoadingButton>
        {Object.keys(selectedHandover).length === 0 && (
          <LoadingButton type="submit" variant="outlined">
            Do Handover
          </LoadingButton>
        )}
      </Stack>
    </FormProvider>
  );
}
