import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

type Props = {
  selectedType: string;
  setSelectedType: (selectedType: string) => void;
};
const Type = ({ selectedType, setSelectedType }: Props) => {
  const handleChange = (e: SelectChangeEvent) => {
    setSelectedType(e.target.value);
  };

  return (
    <Box sx={{ mr: 2, display: "flex", justifyContent: "flex-end", mb: 2 }}>
      <InputLabel id="demo-simple-select-label" sx={{ mr: 1, mt: 2 }}>
        Type
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedType}
        onChange={(e) => handleChange(e)}>
        <MenuItem value={"eye_examination"}>Eye Examination</MenuItem>
        <MenuItem value={"contact_lenses"}>Contact Lenses</MenuItem>
      </Select>
    </Box>
  );
};

export default Type;
