import { IAnteriorEye } from "../../@types/anteriorEye.type";
import axios from "../../utils/axios";

export class AnteriorEyeService {
  createAnteriorEye(patientId: string, data: IAnteriorEye) {
    return axios.post(`/patient/${patientId}/anterior-eye`, data);
  }

  getAnteriorEye(patientId: string, anteriorEyeId: number) {
    const queryString = anteriorEyeId ? `?anteriorEyeId=${anteriorEyeId}` : "";

    return axios.get(`/patient/${patientId}/anterior-eye${queryString}`);
  }
}
