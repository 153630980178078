import { Grid, Typography, Box, TextField } from "@mui/material";
import { ITrail } from "../../../../../@types/trail.type";

type Props = {
  trailData: ITrail | null;
};

const RxFinalAftercare = ({ trailData }: Props) => {
  return (
    <>
      <Box mt={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={1}>
            <Typography align="center">Right</Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <Typography align="center">BC</Typography>
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  name="rightBC"
                  value={trailData?.trailsRecord?.rightBC}
                />
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    width: "1px",
                    height: "40px",
                    background: "black",
                    position: "relative",
                    top: "30px",
                    transform: "rotate(14deg)",
                  }}></Box>
              </Grid>
              <Grid item>
                <Typography align="center">TD</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightTD"
                  value={trailData?.trailsRecord?.rightTD}
                />
              </Grid>
              <Grid item>
                <Typography align="center">SPH</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightSPH"
                  value={trailData?.trailsRecord?.rightSPH}
                  inputProps={{
                    step: 0.25,
                    min: -10,
                    max: 10,
                    onChange: (e: any) => {
                      const value = parseFloat(e.target.value).toFixed(2);
                      e.target.value = value;
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography align="center">CYL</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightCYL"
                  value={trailData?.trailsRecord?.rightCYL}
                  inputProps={{
                    step: 0.25,
                    min: -10,
                    max: 0,
                    onChange: (e: any) => {
                      const value = parseFloat(e.target.value).toFixed(2);
                      e.target.value = value;
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography align="center">AXIS</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightAxis"
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 180,
                    onInput: (e: any) => {
                      if (e.target.value.length > 3) {
                        e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                      }
                    },
                  }}
                  value={trailData?.trailsRecord?.rightAxis}
                />
              </Grid>
              <Grid item>
                <Typography align="center">VA</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightVA"
                  value={trailData?.trailsRecord?.rightVA}
                />
              </Grid>
              <Grid item>
                <Typography align="center">ADD</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightAdd"
                  value={trailData?.trailsRecord?.rightAdd}
                />
              </Grid>
              <Grid item sx={{ marginLeft: "40px" }}>
                <Typography align="center">Over Refr</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightOverRefraction"
                  value={trailData?.trailsRecord?.rightOverRefraction}
                />
              </Grid>
              <Grid item>
                <Typography align="center">VA</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightVA2"
                  value={trailData?.trailsRecord?.rightVA2}
                />
              </Grid>
              <Grid item>
                <Typography align="center">NV</Typography>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="rightNV2"
                  value={trailData?.trailsRecord?.rightNV2}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box mt={1} mb={2} mr={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={1}>
            <Typography align="center">Left</Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <TextField
                  type="text"
                  sx={{ width: "70px" }}
                  name="leftBC"
                  value={trailData?.trailsRecord?.leftBC}
                />
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    width: "1px",
                    height: "40px",
                    background: "black",
                    position: "relative",
                    top: "9px",
                    transform: "rotate(14deg)",
                  }}></Box>
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftTD"
                  value={trailData?.trailsRecord?.leftTD}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftSPH"
                  value={trailData?.trailsRecord?.leftSPH}
                  inputProps={{
                    step: 0.25,
                    min: -10,
                    max: 10,
                    onChange: (e: any) => {
                      const value = parseFloat(e.target.value).toFixed(2);
                      e.target.value = value;
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftCYL"
                  value={trailData?.trailsRecord?.leftCYL}
                  inputProps={{
                    step: 0.25,
                    min: -10,
                    max: 0,
                    onChange: (e: any) => {
                      const value = parseFloat(e.target.value).toFixed(2);
                      e.target.value = value;
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftAxis"
                  value={trailData?.trailsRecord?.leftAxis}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 180,
                    onInput: (e: any) => {
                      if (e.target.value.length > 3) {
                        e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
                      }
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftVA"
                  value={trailData?.trailsRecord?.leftVA}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftAdd"
                  value={trailData?.trailsRecord?.leftAdd}
                />
              </Grid>
              <Grid item sx={{ marginLeft: "40px" }}>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftOverRefraction"
                  value={trailData?.trailsRecord?.leftOverRefraction}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftVA2"
                  value={trailData?.trailsRecord?.leftVA2}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  sx={{ width: "90px" }}
                  name="leftNV2"
                  value={trailData?.trailsRecord?.leftNV2}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RxFinalAftercare;
