import { IVoucherRequest, Vouchers } from "../@types/voucher.type";
import axios from "../utils/axios";

export class VoucherService {
  getAllVouchers() {
    return axios.get<Vouchers[]>("/voucher");
  }
  createVoucher(payload: IVoucherRequest) {
    return axios.post<Vouchers>("/voucher", payload);
  }
  updateVoucher(id: number, payload: IVoucherRequest) {
    return axios.patch<Vouchers>(`/voucher/${id}`, payload);
  }

  deleteVoucher(id: number) {
    return axios.delete<string>(`/voucher/${id}`);
  }
}
