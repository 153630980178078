import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import Axis from "../Axis";
import Pressures from "../Pressures";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import FormProvider from "../../../../components/hook-form/FormProvider";
import { IExaminationSchema } from "../../../../@types/examination";
import { ExaminationService } from "../../../../api/examination.service";
import { enqueueSnackbar } from "../../../../components/snackbar";
import { useEffect, useState } from "react";
import Scrollbar from "../../../../components/scrollbar";
import External from "./External";
import OMB from "./OMB";
import Ophthalmoscopy from "./Ophthalmoscopy";
import Additional from "./Additional";
import FinalSummary from "../Refraction/FinalSummary";

type Props = {
  setTab: any;
  clinicalRecordId: number;
  selectedExamination: IExaminationSchema;
  prescreeningData: any;
  getAllExamination: (examinationId: number, clinicalId: number) => void;
  finalData: any;
};

const CHILD_TAB = [
  { id: "external", label: "External", align: "left" },
  { id: "omb", label: "OMB", align: "left" },
  { id: "ophthalmoscopy", label: "Ophthalmoscopy", align: "left" },
  { id: "additional", label: "Additional", align: "left" },
];

export default function Examination({
  setTab,
  clinicalRecordId,
  selectedExamination,
  prescreeningData,
  getAllExamination,
  finalData,
}: Props) {
  const { patientId } = useParams();
  const methods = useForm<IExaminationSchema>({
    defaultValues: {},
  });

  const [childValue, setChildValue] = useState("external");

  const showExaminationTabs = () => {
    switch (childValue) {
      case CHILD_TAB[0].id:
        return <External clinicalRecordId={clinicalRecordId} />;
      case CHILD_TAB[1].id:
        return <OMB clinicalRecordId={clinicalRecordId} />;
      case CHILD_TAB[2].id:
        return <Ophthalmoscopy clinicalRecordId={clinicalRecordId} />;
      case CHILD_TAB[3].id:
        return <Additional />;
      default:
        break;
    }
  };

  const handleExaminationData = async (data: IExaminationSchema) => {
    if (!data) return;

    const {
      ocularMotorBalance,
      externalEyeHealth,
      ophthalmoscopy,
      additional,
    } = data;

    methods.reset({
      ocularMotorBalance: {
        leftNPC: ocularMotorBalance?.leftNPC || "",
        leftAccomadtion: ocularMotorBalance?.leftAccomadtion || "",
        leftStereopsis: ocularMotorBalance?.leftStereopsis || "",
        leftDistanceCTWithRX: ocularMotorBalance?.leftDistanceCTWithRX || "",
        leftDistanceCTWithoutRX:
          ocularMotorBalance?.leftDistanceCTWithoutRX || "",
        leftNearCTWithoutRX: ocularMotorBalance?.leftNearCTWithoutRX || "",
        leftNearCTWithRX: ocularMotorBalance?.leftNearCTWithRX || "",
        leftMotility: ocularMotorBalance?.leftMotility || "",
        leftConvergence: ocularMotorBalance?.leftConvergence || "",
        leftMaddoxRod: ocularMotorBalance?.leftMaddoxRod || "",
        leftAdditionalNotes: ocularMotorBalance?.leftAdditionalNotes || "",
        rightNPC: ocularMotorBalance?.rightNPC || "",
        rightAccomadtion: ocularMotorBalance?.rightAccomadtion || "",
        rightStereopsis: ocularMotorBalance?.rightStereopsis || "",
        rightDistanceCTWithRX: ocularMotorBalance?.rightDistanceCTWithRX || "",
        rightDistanceCTWithoutRX:
          ocularMotorBalance?.rightDistanceCTWithoutRX || "",
        rightNearCTWithoutRX: ocularMotorBalance?.rightNearCTWithoutRX || "",
        rightNearCTWithRX: ocularMotorBalance?.rightNearCTWithRX || "",
        rightMotility: ocularMotorBalance?.rightMotility || "",
        rightConvergence: ocularMotorBalance?.rightConvergence || "",
        rightMaddoxRod: ocularMotorBalance?.rightMaddoxRod || "",
        rightAdditionalNotes: ocularMotorBalance?.rightAdditionalNotes || "",
      },
      externalEyeHealth: {
        leftConjuctiva: externalEyeHealth?.leftConjuctiva || "",
        rightConjuctiva: externalEyeHealth?.rightConjuctiva || "",
        leftLidsMargin: externalEyeHealth?.leftLidsMargin || "",
        rightLidsMargin: externalEyeHealth?.rightLidsMargin || "",
        leftCornea: externalEyeHealth?.leftCornea || "",
        rightCornea: externalEyeHealth?.rightCornea || "",
        leftAnteriorChamber: externalEyeHealth?.leftAnteriorChamber || "",
        rightAnteriorChamber: externalEyeHealth?.rightAnteriorChamber || "",
        leftTears: externalEyeHealth?.leftTears || "",
        rightTears: externalEyeHealth?.rightTears || "",
        leftLimbus: externalEyeHealth?.leftLimbus || "",
        rightLimbus: externalEyeHealth?.rightLimbus || "",
        leftIris: externalEyeHealth?.leftIris || "",
        rightIris: externalEyeHealth?.rightIris || "",
        leftPupilSize: externalEyeHealth?.leftPupilSize || "",
        rightPupilSize: externalEyeHealth?.rightPupilSize || "",
        leftRAPD: externalEyeHealth?.leftRAPD || "",
        rightRAPD: externalEyeHealth?.rightRAPD || "",
        leftConvergence: externalEyeHealth?.leftConvergence || "",
        rightConvergence: externalEyeHealth?.rightConvergence || "",
        leftMotility: externalEyeHealth?.leftMotility || "",
        rightMotility: externalEyeHealth?.rightMotility || "",
        leftConfrontation: externalEyeHealth?.leftConfrontation || "",
        rightConfrontation: externalEyeHealth?.rightConfrontation || "",
        leftAdditionalNotes: externalEyeHealth?.leftAdditionalNotes || "",
        rightAdditionalNotes: externalEyeHealth?.rightAdditionalNotes || "",
        leftVisualImage: externalEyeHealth?.leftVisualImage || "",
        rightVisualImage: externalEyeHealth?.rightVisualImage || "",
      },
      ophthalmoscopy: {
        leftLens: ophthalmoscopy?.leftLens || "",
        rightLens: ophthalmoscopy?.rightLens || "",
        leftVitreous: ophthalmoscopy?.leftVitreous || "",
        rightVitreous: ophthalmoscopy?.rightVitreous || "",
        leftDiscColor: ophthalmoscopy?.leftDiscColor || "",
        rightDiscColor: ophthalmoscopy?.rightDiscColor || "",
        leftCDRatio: ophthalmoscopy?.leftCDRatio || "",
        rightCDRatio: ophthalmoscopy?.rightCDRatio || "",
        leftAV: ophthalmoscopy?.leftAV || "",
        rightAV: ophthalmoscopy?.rightAV || "",
        leftPeriphery: ophthalmoscopy?.leftPeriphery || "",
        rightPeriphery: ophthalmoscopy?.rightPeriphery || "",
        leftMacula: ophthalmoscopy?.leftMacula || "",
        rightMacula: ophthalmoscopy?.rightMacula || "",
        leftFundusApp: ophthalmoscopy?.leftFundusApp || "",
        rightFundusApp: ophthalmoscopy?.rightFundusApp || "",
        leftMedia: ophthalmoscopy?.leftMedia || "",
        rightMedia: ophthalmoscopy?.rightMedia || "",
        leftAdditionalNotes: ophthalmoscopy?.leftAdditionalNotes || "",
        rightAdditionalNotes: ophthalmoscopy?.rightAdditionalNotes || "",
        leftVisualImage: ophthalmoscopy?.leftVisualImage || "",
        rightVisualImage: ophthalmoscopy?.rightVisualImage || "",
      },
      additional: {
        leftAmslerChart: additional?.leftAmslerChart || "",
        rightAmslerChart: additional?.rightAmslerChart || "",
        leftVisualFields: additional?.leftVisualFields || "",
        rightVisualFields: additional?.rightVisualFields || "",
        leftIshihara: additional?.leftIshihara || "",
        rightIshihara: additional?.rightIshihara || "",
        leftColorVision: additional?.leftColorVision || "",
        rightColorVision: additional?.rightColorVision || "",
        leftPinhole: additional?.leftPinhole || "",
        rightPinhole: additional?.rightPinhole || "",
      },
    });
  };
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data: IExaminationSchema) => {
    try {
      const examinationService = new ExaminationService();
      const response = await examinationService.createExamination(patientId, {
        ...data,
        clinicalRecordId,
      });

      enqueueSnackbar("Examination Created Successfully!", {
        variant: "success",
      });
      const examinationId = response.data.id;
      const clinicalId = response.data.clinicalRecordId;
      getAllExamination(examinationId, clinicalId);
      setTab("handover");
    } catch (error: any) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  const clearFields = () => {
    reset();
    setValue("ocularMotorBalance.leftNPC", "");
    setValue("ocularMotorBalance.leftAccomadtion", "");
    setValue("ocularMotorBalance.leftStereopsis", "");
    setValue("ocularMotorBalance.leftDistanceCTWithRX", "");
    setValue("ocularMotorBalance.leftDistanceCTWithoutRX", "");
    setValue("ocularMotorBalance.leftNearCTWithoutRX", "");
    setValue("ocularMotorBalance.leftNearCTWithRX", "");
    setValue("ocularMotorBalance.leftMotility", "");
    setValue("ocularMotorBalance.leftConvergence", "");
    setValue("ocularMotorBalance.leftMaddoxRod", "");
    setValue("ocularMotorBalance.leftAdditionalNotes", "");
    setValue("ocularMotorBalance.rightNPC", "");
    setValue("ocularMotorBalance.rightAccomadtion", "");
    setValue("ocularMotorBalance.rightStereopsis", "");
    setValue("ocularMotorBalance.rightDistanceCTWithRX", "");
    setValue("ocularMotorBalance.rightDistanceCTWithoutRX", "");
    setValue("ocularMotorBalance.rightNearCTWithoutRX", "");
    setValue("ocularMotorBalance.rightNearCTWithRX", "");
    setValue("ocularMotorBalance.rightMotility", "");
    setValue("ocularMotorBalance.rightConvergence", "");
    setValue("ocularMotorBalance.rightMaddoxRod", "");
    setValue("ocularMotorBalance.rightAdditionalNotes", "");
    setValue("externalEyeHealth.leftConjuctiva", "");
    setValue("externalEyeHealth.rightConjuctiva", "");
    setValue("externalEyeHealth.leftLidsMargin", "");
    setValue("externalEyeHealth.rightLidsMargin", "");
    setValue("externalEyeHealth.leftCornea", "");
    setValue("externalEyeHealth.rightCornea", "");
    setValue("externalEyeHealth.leftAnteriorChamber", "");
    setValue("externalEyeHealth.rightAnteriorChamber", "");
    setValue("externalEyeHealth.leftTears", "");
    setValue("externalEyeHealth.rightTears", "");
    setValue("externalEyeHealth.leftLimbus", "");
    setValue("externalEyeHealth.rightLimbus", "");
    setValue("externalEyeHealth.leftIris", "");
    setValue("externalEyeHealth.rightIris", "");
    setValue("externalEyeHealth.leftPupilSize", "");
    setValue("externalEyeHealth.rightPupilSize", "");
    setValue("externalEyeHealth.leftRAPD", "");
    setValue("externalEyeHealth.rightRAPD", "");
    setValue("externalEyeHealth.leftConvergence", "");
    setValue("externalEyeHealth.rightConvergence", "");
    setValue("externalEyeHealth.leftMotility", "");
    setValue("externalEyeHealth.rightMotility", "");
    setValue("externalEyeHealth.leftConfrontation", "");
    setValue("externalEyeHealth.rightConfrontation", "");
    setValue("externalEyeHealth.leftAdditionalNotes", "");
    setValue("externalEyeHealth.rightAdditionalNotes", "");
    setValue("ophthalmoscopy.leftAV", "");
    setValue("ophthalmoscopy.leftMacula", "");
    setValue("ophthalmoscopy.leftCDRatio", "");
    setValue("ophthalmoscopy.rightAV", "");
    setValue("ophthalmoscopy.rightMacula", "");
    setValue("ophthalmoscopy.rightCDRatio", "");
  };

  const handleChildChange = (event: React.SyntheticEvent, newValue: string) => {
    setChildValue(newValue);
  };

  useEffect(() => {
    if (selectedExamination) {
      handleExaminationData(selectedExamination);
    } else {
      methods.reset({
        ocularMotorBalance: {
          leftNPC: "",
          leftAccomadtion: "",
          leftStereopsis: "",
          leftDistanceCTWithRX: "",
          leftDistanceCTWithoutRX: "",
          leftNearCTWithoutRX: "",
          leftNearCTWithRX: "",
          leftMotility: "",
          leftConvergence: "",
          leftMaddoxRod: "",
          leftAdditionalNotes: "",
          rightNPC: "",
          rightAccomadtion: "",
          rightStereopsis: "",
          rightDistanceCTWithRX: "",
          rightDistanceCTWithoutRX: "",
          rightNearCTWithoutRX: "",
          rightNearCTWithRX: "",
          rightMotility: "",
          rightConvergence: "",
          rightMaddoxRod: "",
          rightAdditionalNotes: "",
        },
        externalEyeHealth: {
          leftConjuctiva: "",
          rightConjuctiva: "",
          leftLidsMargin: "",
          rightLidsMargin: "",
          leftCornea: "",
          rightCornea: "",
          leftAnteriorChamber: "",
          rightAnteriorChamber: "",
          leftTears: "",
          rightTears: "",
          leftLimbus: "",
          rightLimbus: "",
          leftIris: "",
          rightIris: "",
          leftPupilSize: "",
          rightPupilSize: "",
          leftRAPD: "",
          rightRAPD: "",
          leftConvergence: "",
          rightConvergence: "",
          leftMotility: "",
          rightMotility: "",
          leftConfrontation: "",
          rightConfrontation: "",
          leftAdditionalNotes: "",
          rightAdditionalNotes: "",
          leftVisualImage: "",
          rightVisualImage: "",
        },
        ophthalmoscopy: {
          leftLens: "",
          rightLens: "",
          leftVitreous: "",
          rightVitreous: "",
          leftDiscColor: "",
          rightDiscColor: "",
          leftCDRatio: "",
          rightCDRatio: "",
          leftAV: "",
          rightAV: "",
          leftPeriphery: "",
          rightPeriphery: "",
          leftMacula: "",
          rightMacula: "",
          leftFundusApp: "",
          rightFundusApp: "",
          leftMedia: "",
          rightMedia: "",
          leftAdditionalNotes: "",
          rightAdditionalNotes: "",
          leftVisualImage: "",
          rightVisualImage: "",
        },
        additional: {
          leftAmslerChart: "",
          rightAmslerChart: "",
          leftVisualFields: "",
          rightVisualFields: "",
          leftIshihara: "",
          rightIshihara: "",
          leftColorVision: "",
          rightColorVision: "",
          leftPinhole: "",
          rightPinhole: "",
        },
      });
    }
  }, [selectedExamination]);

  return (
    <Box sx={{ ml: 1, mt: 5 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "330px",
          }}>
          {" "}
          {/* Fixed height */}
          <Box sx={{ flex: 1, height: "100%", mr: 3 }}>
            <Stack
              sx={{
                width: "100%", // Remove 50% since it's handled by the parent container
                height: "300px",
                borderRadius: "15px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                marginBottom: "10px",
                marginRight: "10px",
              }}
              direction="column"
              p={7}>
              <FinalSummary finalData={finalData} />
            </Stack>
          </Box>
          <Box sx={{ flex: 1, height: "100%", mr: 3 }}>
            <Pressures
              eyePressureData={prescreeningData?.eyePressures}
              averages={null}
            />
          </Box>
        </Box>

        {Object.keys(selectedExamination).length === 0 && (
          <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
            * There's no Examination added for this clinical record, please edit
            and save!
          </Typography>
        )}
        <Stack p={1} direction="column" mr={2}>
          <Scrollbar>
            <Tabs value={childValue} onChange={handleChildChange}>
              {CHILD_TAB.map((head) => (
                <Tab key={head.id} value={head.id} label={head.label} />
              ))}
            </Tabs>
            {showExaminationTabs()}
          </Scrollbar>
        </Stack>
        <Stack direction="row-reverse">
          {Object.keys(selectedExamination).length === 0 && (
            <LoadingButton type="submit" sx={{ mr: 3 }} variant="contained">
              Save and Next
            </LoadingButton>
          )}
          <Button onClick={clearFields} sx={{ mr: 2 }} variant="outlined">
            Clear
          </Button>
        </Stack>
      </FormProvider>
    </Box>
  );
}
