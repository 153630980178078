import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, BoxProps } from "@mui/material";
import optoProSmall from "../../assets/icons/optoProSmall.png";
import { useSettingsContext } from "../settings";
// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  customLink?: string;
  navMini?: boolean;
  loader?: boolean;
}

const LoadingLogo = forwardRef<HTMLDivElement, LogoProps>(
  (
    {
      disabledLink = false,
      loader = false,
      customLink = "/",
      navMini = false,
      sx,
      ...other
    },
    ref
  ) => {
    const { themeLayout } = useSettingsContext();

    const logo = (
      <Box
        component="img"
        src={optoProSmall}
        sx={{
          width: "auto",
          height: "auto",
          // marginTop: 9,
          cursor: "pointer",
          ...sx,
        }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

export default LoadingLogo;
