import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { IProduct } from "../../../@types/product";
import { ProductService } from "../../../api/product.service";
import { useEffect, useState } from "react";
import Image from "../../../components/image";
import Label from "../../../components/label";
import { sentenceCase } from "change-case";
import { fCurrency } from "../../../utils/formatNumber";

export type Props = {
  productId: number;
};

export default function ProductDetails({ productId }: Props) {
  const [product, setProduct] = useState<IProduct>();
  // const [isLoading, setLoading] = useState<boolean>();
  const getProductDetail = async () => {
    const productService = new ProductService();
    try {
      const { data } = await productService.getProductDetail(productId);
      setProduct(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    if (productId) {
      // setLoading(true);
      getProductDetail();
    }
  }, [productId]);

  return (
    <>
      {product && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={7}>
              <Image
                key="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
                alt="product"
                src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
                // src={product.picture}
                ratio="1/1"
                onClick={() => {}}
                // onClick={() => handleOpenLightbox(img)}
                sx={{ cursor: "zoom-in" }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={5}>
              {/* <ProductDetailsSummary
                product={product}
                cart={checkout.cart}
                onAddCart={handleAddCart}
                onGotoStep={handleGotoStep}
              /> */}
              <Stack
                spacing={3}
                sx={{
                  p: (theme) => ({
                    md: theme.spacing(5, 5, 0, 2),
                  }),
                }}
              >
                <Stack spacing={2}>
                  <Label
                    variant="soft"
                    color={
                      product.quantity > 1 && product.quantity < 10
                        ? "warning"
                        : product.quantity > 1
                        ? "success"
                        : "error"
                    }
                    sx={{ textTransform: "uppercase", mr: "auto" }}
                  >
                    {sentenceCase(
                      product.quantity > 1 && product.quantity < 10
                        ? "Low Stock"
                        : product.quantity > 1
                        ? "In Stock"
                        : "Out of Stock" || ""
                    )}
                  </Label>
                  {/* <Typography
                    variant="overline"
                    component="div"
                    sx={{
                      color: status === "sale" ? "error.main" : "info.main",
                    }}
                  >
                    {status}
                  </Typography> */}
                  <Typography variant="h5">{product.name}</Typography>6
                  <Typography variant="h6">{product.sku}</Typography>
                  <Typography variant="h5">{product.supplierId}</Typography>
                  {/* <Stack direction="row" alignItems="center" spacing={1}>
                    <Rating value={totalRating} precision={0.1} readOnly />

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      ({fShortenNumber(totalReview)}
                      reviews)
                    </Typography>
                  </Stack> */}
                  <Typography variant="h4">
                    {product.price && (
                      <Box
                        component="span"
                        sx={{
                          color: "text.disabled",
                          textDecoration: "line-through",
                          mr: 0.5,
                        }}
                      >
                        {fCurrency(product.price)}
                      </Box>
                    )}
                    $ {fCurrency(product.price)}
                  </Typography>
                </Stack>

                <Divider sx={{ borderStyle: "dashed" }} />
                <Typography variant="body1">{product.description}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}

      {/* {isLoading && <SkeletonProductDetails />} */}
    </>
  );
}
