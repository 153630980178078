import { Container, Card, TableContainer } from "@mui/material";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";

import { PATH_DASHBOARD } from "../../../routes/paths";
import { useSettingsContext } from "../../../components/settings";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import ProductList from "../../../sections/@dashboard/product/ProductList";
import ProductNewEditForm from "../../../sections/@dashboard/product/ProductAddEditForm";
import { useNavigate } from "react-router";

export default function ProductPage() {
  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Product</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Product"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Product",
              href: PATH_DASHBOARD.product.list,
            },
            {
              name: "New",
              href: PATH_DASHBOARD.product.new,
            },
          ]}
          action={<></>}
        />

        <Card>
          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <ProductNewEditForm
                onCancel={() => {
                  navigate(PATH_DASHBOARD.product.list);
                }}
              />
            </Scrollbar>
          </TableContainer>
        </Card>
      </Container>
    </>
  );
}
