import { IPatientSearchSchema } from "./calendar";
import { IPatientSchema } from "./patient";

export type IDispense = {
  id: number;
  dispense: string;
  name: string;
  email: string;
  status: DispenseOrderStatus;
  number: number;
  orderId: number;
  phone: number;
  patientId: number;
  axisId?: number;
  refractionId?: number;
  handoverNotes?: string;
  isExternal: boolean;
  voucherId: number;
  addonId: number;
  orderingNotes: string;
  totalPrice: number;
  patient: IPatientSchema;
};

export type IDispenseRequest = {
  specType: string;
  status?: string;
  patientId: number | IPatientSearchSchema;
  isExternal?: boolean;
  voucherId?: number;
  orderingNotes?: string;
  totalPrice?: number;
  productId?: number;
};

export type IDispenseRequestFromHandover = {
  status: string;
  axisId: number;
  refractionId: number;
  handoverId: number;
  clinicalRecordId: number;
};

export type statusOptionsProps = {
  label: string;
  value: DispenseOrderStatus;
};

export enum DispenseOrderStatus {
  UNCANCELLED = "uncancelled",
  UNPROCESSED = "unprocessed",
  OUTSTANDING = "outstanding",
  OVERDUE = "overdue",
  UNCOLLECTED = "uncollected",
}
