import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { RefractionService } from "../../../../api/refraction.service";
import { enqueueSnackbar } from "../../../../components/snackbar";
import FormProvider from "../../../../components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Scrollbar from "../../../../components/scrollbar";
import { LensMeter } from "./LensMeter/LensMeter";
import { AutoRefractor } from "./AutoRefractor/AutoRefractor";
import { Subjective } from "./Subjective/Subjective";
import { Summary } from "./Summary";
import { Final } from "./Final/Final";
import { IRefractionSchema } from "../../../../@types/refraction";
import { LoadingButton } from "@mui/lab";
import {
  IAutoRefractorSchema,
  IVisionSchema,
} from "../../../../@types/specsHistory";

type Props = {
  selectedRefraction: IRefractionSchema;
  tab: any;
  clinicalRecordId: string;
  getAllRefraction: (refractionId: number, clinicalId: number) => void;
  visionData: {
    nearVision: IVisionSchema;
    distanceVision: IVisionSchema;
  };
  autoRefractorData: IAutoRefractorSchema;
};
const REFRACTION_TABS = [
  { id: "lens_meter", label: "Lens Meter", align: "left" },
  { id: "auto_refractor", label: "Auto Refractor", align: "left" },
  { id: "subjective", label: "Subjective", align: "left" },
  { id: "final", label: "Final", align: "left" },
  { id: "summary", label: "Summary", align: "left" },
];

export default function Refraction({
  selectedRefraction,
  tab,
  clinicalRecordId,
  getAllRefraction,
  visionData,
  autoRefractorData,
}: Props) {
  const { patientId } = useParams();
  const [childValue, setChildValue] = useState("lens_meter");

  const showRefractionTabs = () => {
    switch (childValue) {
      case REFRACTION_TABS[0].id:
        return <LensMeter tab={(tab: string) => setChildValue(tab)} />;
      case REFRACTION_TABS[1].id:
        return <AutoRefractor tab={(tab: string) => setChildValue(tab)} />;
      case REFRACTION_TABS[2].id:
        return <Subjective tab={(tab: string) => setChildValue(tab)} />;
      case REFRACTION_TABS[3].id:
        return <Final tab={(tab: string) => setChildValue(tab)} />;
      case REFRACTION_TABS[4].id:
        return <Summary />;
      default:
        break;
    }
  };

  const handleChildChange = (event: React.SyntheticEvent, newValue: string) => {
    setChildValue(newValue);
  };

  const methods = useForm<IRefractionSchema>({
    defaultValues: {
      autoRefractor: {
        distanceRightSPH: autoRefractorData?.rightDistancePD || "",
      },
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isLoading },
  } = methods;

  const onSubmit = async (data: IRefractionSchema) => {
    try {
      const refractionService = new RefractionService();
      const response = await refractionService.createRefraction(patientId, {
        ...data,
        clinicalRecordId,
      });

      enqueueSnackbar("Refraction Created Successfully!", {
        variant: "success",
      });

      const refractionId = response.data.id;
      const clinicalId = response.data.clinicalRecordId;
      getAllRefraction(refractionId, clinicalId);

      tab("examination");
    } catch (error: any) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  const setRefractionData = (data: IRefractionSchema) => {
    if (!data) return;

    const { lensMeter, autoRefractor, subjective, final } = data;

    methods.reset({
      lensMeter: {
        distanceRightSPH: lensMeter?.distanceRightSPH || "",
        distanceRightCYL: lensMeter?.distanceRightCYL || "",
        distanceRightAxis: lensMeter?.distanceRightAxis || "",
        distanceRightAidedVA: lensMeter?.distanceRightAidedVA || "",
        distanceRightPrism: lensMeter?.distanceRightPrism || "",
        distanceRightDistancePD: lensMeter?.distanceRightDistancePD || "",
        distanceBothAidedVA: lensMeter?.distanceBothAidedVA || "",
        distanceBothPrism: lensMeter?.distanceBothPrism || "",
        distanceLeftSPH: lensMeter?.distanceLeftSPH || "",
        distanceLeftCYL: lensMeter?.distanceLeftCYL || "",
        distanceLeftAxis: lensMeter?.distanceLeftAxis || "",
        distanceLeftAidedVA: lensMeter?.distanceLeftAidedVA || "",
        distanceLeftPrism: lensMeter?.distanceLeftPrism || "",
        distanceLeftDistancePD: lensMeter?.distanceLeftDistancePD || "",
        nearRightAdd: lensMeter?.nearRightAdd || "",
        nearRightSPH: lensMeter?.nearRightSPH || "",
        nearRightCYL: lensMeter?.nearRightCYL || "",
        nearRightAxis: lensMeter?.nearRightAxis || "",
        nearRightAidedVA: lensMeter?.nearRightAidedVA || "",
        nearRightPrism: lensMeter?.nearRightPrism || "",
        nearRightNearPD: lensMeter?.nearRightNearPD || "",
        nearBothAidedVA: lensMeter?.nearBothAidedVA || "",
        nearBothPrism: lensMeter?.nearBothPrism || "",
        nearLeftAdd: lensMeter?.nearLeftAdd || "",
        nearLeftSPH: lensMeter?.nearLeftSPH || "",
        nearLeftCYL: lensMeter?.nearLeftCYL || "",
        nearLeftAxis: lensMeter?.nearLeftAxis || "",
        nearLeftAidedVA: lensMeter?.nearLeftAidedVA || "",
        nearLeftPrism: lensMeter?.nearLeftPrism || "",
        nearLeftNearPD: lensMeter?.nearLeftNearPD || "",
        intermediateRightAdd: lensMeter?.intermediateRightAdd || "",
        intermediateRightSPH: lensMeter?.intermediateRightSPH || "",
        intermediateRightCYL: lensMeter?.intermediateRightCYL || "",
        intermediateRightAxis: lensMeter?.intermediateRightAxis || "",
        intermediateRightAidedVA: lensMeter?.intermediateRightAidedVA || "",
        intermediateRightPrism: lensMeter?.intermediateRightPrism || "",
        intermediateRightInterPD: lensMeter?.intermediateRightInterPD || "",
        intermediateBothAidedVA: lensMeter?.intermediateBothAidedVA || "",
        intermediateBothPrism: lensMeter?.intermediateBothPrism || "",
        intermediateLeftAdd: lensMeter?.intermediateLeftAdd || "",
        intermediateLeftSPH: lensMeter?.intermediateLeftSPH || "",
        intermediateLeftCYL: lensMeter?.intermediateLeftCYL || "",
        intermediateLeftAxis: lensMeter?.intermediateLeftAxis || "",
        intermediateLeftAidedVA: lensMeter?.intermediateLeftAidedVA || "",
        intermediateLeftPrism: lensMeter?.intermediateLeftPrism || "",
        intermediateLeftInterPD: lensMeter?.intermediateLeftInterPD || "",
      },
      autoRefractor: {
        distanceRightSPH: autoRefractor?.distanceRightSPH || "",
        distanceRightCYL: autoRefractor?.distanceRightCYL || "",
        distanceRightAxis: autoRefractor?.distanceRightAxis || "",
        distanceRightAidedVA: autoRefractor?.distanceRightAidedVA || "",
        distanceRightPrism: autoRefractor?.distanceRightPrism || "",
        distanceRightDistancePD: autoRefractor?.distanceRightDistancePD || "",
        distanceBothAidedVA: autoRefractor?.distanceBothAidedVA || "",
        distanceBothPrism: autoRefractor?.distanceBothPrism || "",
        distanceLeftSPH: autoRefractor?.distanceLeftSPH || "",
        distanceLeftCYL: autoRefractor?.distanceLeftCYL || "",
        distanceLeftAxis: autoRefractor?.distanceLeftAxis || "",
        distanceLeftAidedVA: autoRefractor?.distanceLeftAidedVA || "",
        distanceLeftPrism: autoRefractor?.distanceLeftPrism || "",
        distanceLeftDistancePD: autoRefractor?.distanceLeftDistancePD || "",
        nearRightAdd: autoRefractor?.nearRightAdd || "",
        nearRightSPH: autoRefractor?.nearRightSPH || "",
        nearRightCYL: autoRefractor?.nearRightCYL || "",
        nearRightAxis: autoRefractor?.nearRightAxis || "",
        nearRightAidedVA: autoRefractor?.nearRightAidedVA || "",
        nearRightPrism: autoRefractor?.nearRightPrism || "",
        nearRightNearPD: autoRefractor?.nearRightNearPD || "",
        nearBothAidedVA: autoRefractor?.nearBothAidedVA || "",
        nearBothPrism: autoRefractor?.nearBothPrism || "",
        nearLeftAdd: autoRefractor?.nearLeftAdd || "",
        nearLeftSPH: autoRefractor?.nearLeftSPH || "",
        nearLeftCYL: autoRefractor?.nearLeftCYL || "",
        nearLeftAxis: autoRefractor?.nearLeftAxis || "",
        nearLeftAidedVA: autoRefractor?.nearLeftAidedVA || "",
        nearLeftPrism: autoRefractor?.nearLeftPrism || "",
        nearLeftNearPD: autoRefractor?.nearLeftNearPD || "",
        intermediateRightAdd: autoRefractor?.intermediateRightAdd || "",
        intermediateRightSPH: autoRefractor?.intermediateRightSPH || "",
        intermediateRightCYL: autoRefractor?.intermediateRightCYL || "",
        intermediateRightAxis: autoRefractor?.intermediateRightAxis || "",
        intermediateRightAidedVA: autoRefractor?.intermediateRightAidedVA || "",
        intermediateRightPrism: autoRefractor?.intermediateRightPrism || "",
        intermediateRightInterPD: autoRefractor?.intermediateRightInterPD || "",
        intermediateBothAidedVA: autoRefractor?.intermediateBothAidedVA || "",
        intermediateBothPrism: autoRefractor?.intermediateBothPrism || "",
        intermediateLeftAdd: autoRefractor?.intermediateLeftAdd || "",
        intermediateLeftSPH: autoRefractor?.intermediateLeftSPH || "",
        intermediateLeftCYL: autoRefractor?.intermediateLeftCYL || "",
        intermediateLeftAxis: autoRefractor?.intermediateLeftAxis || "",
        intermediateLeftAidedVA: autoRefractor?.intermediateLeftAidedVA || "",
        intermediateLeftPrism: autoRefractor?.intermediateLeftPrism || "",
        intermediateLeftInterPD: autoRefractor?.intermediateLeftInterPD || "",
      },
      subjective: {
        distanceRightSPH: subjective?.distanceRightSPH || "",
        distanceRightCYL: subjective?.distanceRightCYL || "",
        distanceRightAxis: subjective?.distanceRightAxis || "",
        distanceRightAidedVA: subjective?.distanceRightAidedVA || "",
        distanceRightPrism: subjective?.distanceRightPrism || "",
        distanceRightDistancePD: subjective?.distanceRightDistancePD || "",
        distanceBothAidedVA: subjective?.distanceBothAidedVA || "",
        distanceBothPrism: subjective?.distanceBothPrism || "",
        distanceLeftSPH: subjective?.distanceLeftSPH || "",
        distanceLeftCYL: subjective?.distanceLeftCYL || "",
        distanceLeftAxis: subjective?.distanceLeftAxis || "",
        distanceLeftAidedVA: subjective?.distanceLeftAidedVA || "",
        distanceLeftPrism: subjective?.distanceLeftPrism || "",
        distanceLeftDistancePD: subjective?.distanceLeftDistancePD || "",
        nearRightAdd: subjective?.nearRightAdd || "",
        nearRightSPH: subjective?.nearRightSPH || "",
        nearRightCYL: subjective?.nearRightCYL || "",
        nearRightAxis: subjective?.nearRightAxis || "",
        nearRightAidedVA: subjective?.nearRightAidedVA || "",
        nearRightPrism: subjective?.nearRightPrism || "",
        nearRightNearPD: subjective?.nearRightNearPD || "",
        nearBothAidedVA: subjective?.nearBothAidedVA || "",
        nearBothPrism: subjective?.nearBothPrism || "",
        nearLeftAdd: subjective?.nearLeftAdd || "",
        nearLeftSPH: subjective?.nearLeftSPH || "",
        nearLeftCYL: subjective?.nearLeftCYL || "",
        nearLeftAxis: subjective?.nearLeftAxis || "",
        nearLeftAidedVA: subjective?.nearLeftAidedVA || "",
        nearLeftPrism: subjective?.nearLeftPrism || "",
        nearLeftNearPD: subjective?.nearLeftNearPD || "",
        intermediateRightAdd: subjective?.intermediateRightAdd || "",
        intermediateRightSPH: subjective?.intermediateRightSPH || "",
        intermediateRightCYL: subjective?.intermediateRightCYL || "",
        intermediateRightAxis: subjective?.intermediateRightAxis || "",
        intermediateRightAidedVA: subjective?.intermediateRightAidedVA || "",
        intermediateRightPrism: subjective?.intermediateRightPrism || "",
        intermediateRightInterPD: subjective?.intermediateRightInterPD || "",
        intermediateBothAidedVA: subjective?.intermediateBothAidedVA || "",
        intermediateBothPrism: subjective?.intermediateBothPrism || "",
        intermediateLeftAdd: subjective?.intermediateLeftAdd || "",
        intermediateLeftSPH: subjective?.intermediateLeftSPH || "",
        intermediateLeftCYL: subjective?.intermediateLeftCYL || "",
        intermediateLeftAxis: subjective?.intermediateLeftAxis || "",
        intermediateLeftAidedVA: subjective?.intermediateLeftAidedVA || "",
        intermediateLeftPrism: subjective?.intermediateLeftPrism || "",
        intermediateLeftInterPD: subjective?.intermediateLeftInterPD || "",
      },
      final: {
        distanceRightSPH: final?.distanceRightSPH || "",
        distanceRightCYL: final?.distanceRightCYL || "",
        distanceRightAxis: final?.distanceRightAxis || "",
        distanceRightAidedVA: final?.distanceRightAidedVA || "",
        distanceRightPrism: final?.distanceRightPrism || "",
        distanceRightDistancePD: final?.distanceRightDistancePD || "",
        distanceBothAidedVA: final?.distanceBothAidedVA || "",
        distanceBothPrism: final?.distanceBothPrism || "",
        distanceLeftSPH: final?.distanceLeftSPH || "",
        distanceLeftCYL: final?.distanceLeftCYL || "",
        distanceLeftAxis: final?.distanceLeftAxis || "",
        distanceLeftAidedVA: final?.distanceLeftAidedVA || "",
        distanceLeftPrism: final?.distanceLeftPrism || "",
        distanceLeftDistancePD: final?.distanceLeftDistancePD || "",
        nearRightAdd: final?.nearRightAdd || "",
        nearRightSPH: final?.nearRightSPH || "",
        nearRightCYL: final?.nearRightCYL || "",
        nearRightAxis: final?.nearRightAxis || "",
        nearRightAidedVA: final?.nearRightAidedVA || "",
        nearRightPrism: final?.nearRightPrism || "",
        nearRightNearPD: final?.nearRightNearPD || "",
        nearBothAidedVA: final?.nearBothAidedVA || "",
        nearBothPrism: final?.nearBothPrism || "",
        nearLeftAdd: final?.nearLeftAdd || "",
        nearLeftSPH: final?.nearLeftSPH || "",
        nearLeftCYL: final?.nearLeftCYL || "",
        nearLeftAxis: final?.nearLeftAxis || "",
        nearLeftAidedVA: final?.nearLeftAidedVA || "",
        nearLeftPrism: final?.nearLeftPrism || "",
        nearLeftNearPD: final?.nearLeftNearPD || "",
        intermediateRightAdd: final?.intermediateRightAdd || "",
        intermediateRightSPH: final?.intermediateRightSPH || "",
        intermediateRightCYL: final?.intermediateRightCYL || "",
        intermediateRightAxis: final?.intermediateRightAxis || "",
        intermediateRightAidedVA: final?.intermediateRightAidedVA || "",
        intermediateRightPrism: final?.intermediateRightPrism || "",
        intermediateRightInterPD: final?.intermediateRightInterPD || "",
        intermediateBothAidedVA: final?.intermediateBothAidedVA || "",
        intermediateBothPrism: final?.intermediateBothPrism || "",
        intermediateLeftAdd: final?.intermediateLeftAdd || "",
        intermediateLeftSPH: final?.intermediateLeftSPH || "",
        intermediateLeftCYL: final?.intermediateLeftCYL || "",
        intermediateLeftAxis: final?.intermediateLeftAxis || "",
        intermediateLeftAidedVA: final?.intermediateLeftAidedVA || "",
        intermediateLeftPrism: final?.intermediateLeftPrism || "",
        intermediateLeftInterPD: final?.intermediateLeftInterPD || "",
      },
    });
  };

  useEffect(() => {
    if (selectedRefraction) {
      setRefractionData(selectedRefraction);
    } else {
      methods.reset({
        lensMeter: {
          distanceRightSPH: "",
          distanceRightCYL: "",
          distanceRightAxis: "",
          distanceRightAidedVA: "",
          distanceRightPrism: "",
          distanceRightDistancePD: "",
          distanceBothAidedVA: "",
          distanceBothPrism: "",
          distanceLeftSPH: "",
          distanceLeftCYL: "",
          distanceLeftAxis: "",
          distanceLeftAidedVA: "",
          distanceLeftPrism: "",
          distanceLeftDistancePD: "",
          nearRightAdd: "",
          nearRightSPH: "",
          nearRightCYL: "",
          nearRightAxis: "",
          nearRightAidedVA: "",
          nearRightPrism: "",
          nearRightNearPD: "",
          nearBothAidedVA: "",
          nearBothPrism: "",
          nearLeftAdd: "",
          nearLeftSPH: "",
          nearLeftCYL: "",
          nearLeftAxis: "",
          nearLeftAidedVA: "",
          nearLeftPrism: "",
          nearLeftNearPD: "",
          intermediateRightAdd: "",
          intermediateRightSPH: "",
          intermediateRightCYL: "",
          intermediateRightAxis: "",
          intermediateRightAidedVA: "",
          intermediateRightPrism: "",
          intermediateRightInterPD: "",
          intermediateBothAidedVA: "",
          intermediateBothPrism: "",
          intermediateLeftAdd: "",
          intermediateLeftSPH: "",
          intermediateLeftCYL: "",
          intermediateLeftAxis: "",
          intermediateLeftAidedVA: "",
          intermediateLeftPrism: "",
          intermediateLeftInterPD: "",
        },
        autoRefractor: {
          distanceRightSPH: "",
          distanceRightCYL: "",
          distanceRightAxis: "",
          distanceRightAidedVA: "",
          distanceRightPrism: "",
          distanceRightDistancePD: "",
          distanceBothAidedVA: "",
          distanceBothPrism: "",
          distanceLeftSPH: "",
          distanceLeftCYL: "",
          distanceLeftAxis: "",
          distanceLeftAidedVA: "",
          distanceLeftPrism: "",
          distanceLeftDistancePD: "",
          nearRightAdd: "",
          nearRightSPH: "",
          nearRightCYL: "",
          nearRightAxis: "",
          nearRightAidedVA: "",
          nearRightPrism: "",
          nearRightNearPD: "",
          nearBothAidedVA: "",
          nearBothPrism: "",
          nearLeftAdd: "",
          nearLeftSPH: "",
          nearLeftCYL: "",
          nearLeftAxis: "",
          nearLeftAidedVA: "",
          nearLeftPrism: "",
          nearLeftNearPD: "",
          intermediateRightAdd: "",
          intermediateRightSPH: "",
          intermediateRightCYL: "",
          intermediateRightAxis: "",
          intermediateRightAidedVA: "",
          intermediateRightPrism: "",
          intermediateRightInterPD: "",
          intermediateBothAidedVA: "",
          intermediateBothPrism: "",
          intermediateLeftAdd: "",
          intermediateLeftSPH: "",
          intermediateLeftCYL: "",
          intermediateLeftAxis: "",
          intermediateLeftAidedVA: "",
          intermediateLeftPrism: "",
          intermediateLeftInterPD: "",
        },
        subjective: {
          distanceRightSPH: "",
          distanceRightCYL: "",
          distanceRightAxis: "",
          distanceRightAidedVA: "",
          distanceRightPrism: "",
          distanceRightDistancePD: "",
          distanceBothAidedVA: "",
          distanceBothPrism: "",
          distanceLeftSPH: "",
          distanceLeftCYL: "",
          distanceLeftAxis: "",
          distanceLeftAidedVA: "",
          distanceLeftPrism: "",
          distanceLeftDistancePD: "",
          nearRightAdd: "",
          nearRightSPH: "",
          nearRightCYL: "",
          nearRightAxis: "",
          nearRightAidedVA: "",
          nearRightPrism: "",
          nearRightNearPD: "",
          nearBothAidedVA: "",
          nearBothPrism: "",
          nearLeftAdd: "",
          nearLeftSPH: "",
          nearLeftCYL: "",
          nearLeftAxis: "",
          nearLeftAidedVA: "",
          nearLeftPrism: "",
          nearLeftNearPD: "",
          intermediateRightAdd: "",
          intermediateRightSPH: "",
          intermediateRightCYL: "",
          intermediateRightAxis: "",
          intermediateRightAidedVA: "",
          intermediateRightPrism: "",
          intermediateRightInterPD: "",
          intermediateBothAidedVA: "",
          intermediateBothPrism: "",
          intermediateLeftAdd: "",
          intermediateLeftSPH: "",
          intermediateLeftCYL: "",
          intermediateLeftAxis: "",
          intermediateLeftAidedVA: "",
          intermediateLeftPrism: "",
          intermediateLeftInterPD: "",
        },
        final: {
          distanceRightSPH: "",
          distanceRightCYL: "",
          distanceRightAxis: "",
          distanceRightAidedVA: "",
          distanceRightPrism: "",
          distanceRightDistancePD: "",
          distanceBothAidedVA: "",
          distanceBothPrism: "",
          distanceLeftSPH: "",
          distanceLeftCYL: "",
          distanceLeftAxis: "",
          distanceLeftAidedVA: "",
          distanceLeftPrism: "",
          distanceLeftDistancePD: "",
          nearRightAdd: "",
          nearRightSPH: "",
          nearRightCYL: "",
          nearRightAxis: "",
          nearRightAidedVA: "",
          nearRightPrism: "",
          nearRightNearPD: "",
          nearBothAidedVA: "",
          nearBothPrism: "",
          nearLeftAdd: "",
          nearLeftSPH: "",
          nearLeftCYL: "",
          nearLeftAxis: "",
          nearLeftAidedVA: "",
          nearLeftPrism: "",
          nearLeftNearPD: "",
          intermediateRightAdd: "",
          intermediateRightSPH: "",
          intermediateRightCYL: "",
          intermediateRightAxis: "",
          intermediateRightAidedVA: "",
          intermediateRightPrism: "",
          intermediateRightInterPD: "",
          intermediateBothAidedVA: "",
          intermediateBothPrism: "",
          intermediateLeftAdd: "",
          intermediateLeftSPH: "",
          intermediateLeftCYL: "",
          intermediateLeftAxis: "",
          intermediateLeftAidedVA: "",
          intermediateLeftPrism: "",
          intermediateLeftInterPD: "",
        },
      });
    }
  }, [selectedRefraction]);

  useEffect(() => {
    if (autoRefractorData) {
      methods.setValue(
        "autoRefractor.distanceRightSPH",
        autoRefractorData?.rightSphericalPower || ""
      );
      methods.setValue(
        "autoRefractor.distanceRightCYL",
        autoRefractorData?.rightCylinder || ""
      );
      methods.setValue(
        "autoRefractor.distanceRightAxis",
        autoRefractorData?.rightAxis || ""
      );
      methods.setValue(
        "autoRefractor.distanceRightDistancePD",
        autoRefractorData?.rightDistancePD || ""
      );
      methods.setValue(
        "autoRefractor.distanceLeftSPH",
        autoRefractorData?.leftSphericalPower || ""
      );
      methods.setValue(
        "autoRefractor.distanceLeftCYL",
        autoRefractorData?.leftCylinder || ""
      );
      methods.setValue(
        "autoRefractor.distanceLeftAxis",
        autoRefractorData?.leftAxis || ""
      );
      methods.setValue(
        "autoRefractor.distanceLeftDistancePD",
        autoRefractorData?.leftDistancePD || ""
      );
    }
  }, [autoRefractorData, methods]);

  useEffect(() => {
    if (visionData) {
      methods.setValue(
        "lensMeter.distanceRightSPH",
        visionData?.distanceVision?.rightSphericalPower || ""
      );
      methods.setValue(
        "lensMeter.distanceRightCYL",
        visionData?.distanceVision?.rightCylinder || ""
      );
      methods.setValue(
        "lensMeter.distanceRightAxis",
        visionData?.distanceVision?.rightAxis || ""
      );
      methods.setValue(
        "lensMeter.distanceRightDistancePD",
        visionData?.distanceVision?.rightDistancePD || ""
      );
      methods.setValue(
        "lensMeter.distanceLeftSPH",
        visionData?.distanceVision?.leftSphericalPower || ""
      );
      methods.setValue(
        "lensMeter.distanceLeftCYL",
        visionData?.distanceVision?.leftCylinder || ""
      );
      methods.setValue(
        "lensMeter.distanceLeftAxis",
        visionData?.distanceVision?.leftAxis || ""
      );
      methods.setValue(
        "lensMeter.distanceLeftDistancePD",
        visionData?.distanceVision?.leftDistancePD || ""
      );
      methods.setValue(
        "lensMeter.nearRightSPH",
        visionData?.nearVision?.rightSphericalPower || ""
      );
      methods.setValue(
        "lensMeter.nearRightCYL",
        visionData?.nearVision?.rightCylinder || ""
      );
      methods.setValue(
        "lensMeter.nearRightAxis",
        visionData?.nearVision?.rightAxis || ""
      );
      methods.setValue(
        "lensMeter.nearRightNearPD",
        visionData?.nearVision?.rightDistancePD || ""
      );
      methods.setValue(
        "lensMeter.nearLeftSPH",
        visionData?.nearVision?.leftSphericalPower || ""
      );
      methods.setValue(
        "lensMeter.nearLeftCYL",
        visionData?.nearVision?.leftCylinder || ""
      );
      methods.setValue(
        "lensMeter.nearLeftAxis",
        visionData?.nearVision?.leftAxis || ""
      );
      methods.setValue(
        "lensMeter.nearLeftNearPD",
        visionData?.nearVision?.leftDistancePD || ""
      );
    }
  }, [visionData, methods]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {Object.keys(selectedRefraction).length === 0 && (
        <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
          * There's no Refraction added for this clinical record, please edit
          and save!
        </Typography>
      )}
      <Stack
        sx={{
          minHeight: "300px",
        }}
        p={1}
        direction="column"
        mr={2}>
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
            }}>
            <Tabs value={childValue} onChange={handleChildChange}>
              {REFRACTION_TABS.map((head) => (
                <Tab key={head.id} value={head.id} label={head.label} />
              ))}
            </Tabs>
          </Box>
          {showRefractionTabs()}
        </Scrollbar>
      </Stack>
      <Stack direction="row-reverse" mt="40px" gap="30px" mr="17px">
        {Object.keys(selectedRefraction).length === 0 && (
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting || isLoading}>
            Save and Next
          </LoadingButton>
        )}
      </Stack>
    </FormProvider>
  );
}
