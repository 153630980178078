import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { IPressureSchema } from "../../../@types/specsHistory";
import { EyePressures } from "./EyePressures";

type Props = {
  eyePressureData: IPressureSchema[];
  averages: {
    IOPs: number;
    CCTs: number;
  } | null;
};

const Pressures = ({ eyePressureData }: Props) => {
  const averages = useMemo(() => {
    if (eyePressureData?.length === 0) {
      return { IOPs: 0, CCTs: 0 };
    }

    const totalIOPs = eyePressureData?.reduce(
      (acc, curr) => acc + Number(curr.IOPs),
      0
    );

    const totalCCTs = eyePressureData?.reduce(
      (acc, curr) => acc + Number(curr.CCT),
      0
    );
    const length = eyePressureData?.length;

    return {
      IOPs: totalIOPs / length,
      CCTs: totalCCTs / length,
    };
  }, [eyePressureData]);

  return (
    <Stack
      sx={{
        width: "100%",
        height: "300px",
        borderRadius: "15px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        overflowY: "auto",
        marginBottom: "10px",
      }}
      direction="column"
      mr={2}
      ml={1}
      p={2}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}>
        <Typography variant="h6">Eye Pressures</Typography>
      </Box>
      {eyePressureData?.length > 0 ? (
        <Box sx={{ width: "100%", height: "100%" }}>
          {eyePressureData.map((item: IPressureSchema, index: number) => (
            <EyePressures key={index} data={item} />
          ))}

          <Box
            p={3}
            mt={1}
            sx={{
              borderRadius: "15px",
              border: "1px solid #6373813b",
              position: "relative",
            }}>
            <Box
              sx={{
                display: "flex",
                width: "90%",
                justifyContent: "space-around",
              }}>
              <Typography sx={{ color: "text.secondary" }}>
                <strong>Avg IOPs:</strong> {averages.IOPs.toFixed(2)}
              </Typography>

              <Typography sx={{ color: "text.secondary" }}>
                <strong>Avg CCT:</strong> {averages.CCTs.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "300px",
          }}>
          No Records Found
        </Box>
      )}
    </Stack>
  );
};

export default Pressures;
