// // @mui
// import { useTheme } from '@mui/material/styles';
// import { Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// // utils
// import { bgBlur } from '../../../utils/cssStyles';
// // hooks
// import useOffSetTop from '../../../hooks/useOffSetTop';
// import useResponsive from '../../../hooks/useResponsive';
// // config
// import { HEADER, NAV } from '../../../config-global';
// // components
// import Logo from '../../../components/logo';
// import Iconify from '../../../components/iconify';
// import { useSettingsContext } from '../../../components/settings';
// //

// @mui
import { useTheme } from "@mui/material/styles";
import { Stack, AppBar, Toolbar, Box, Typography, Button } from "@mui/material";

// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER } from "../../../config-global";
//
import AccountPopover from "../../dashboard/header/AccountPopover";
import { useAuthContext } from "../../../auth/useAuthContext";
import { useSettingsContext } from "../../../components/settings";
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "lg");
  const { user } = useAuthContext();

  const { themeColorPresets } = useSettingsContext();

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP);

  const renderContent = (
    <Stack
      sx={{ marginTop: `${!user?.isActiveOrganization && "110px"}` }}
      flexGrow={1}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={{ xs: 0.5, sm: 1.5 }}
    >
      <>
        {/* <NotificationsPopover /> */}

        <AccountPopover />
      </>
    </Stack>
  );

  return (
    <>
      {!user?.isActiveOrganization && (
        <Box
          sx={{
            position: "absolute",
            zIndex: "99999",
            width: "100%",
            background:
              themeColorPresets === "default" ? "green" : themeColorPresets,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Iconify mr={1} color={"white"} icon="eva:info-fill" />
          <Typography
            sx={{
              fontSize: "16px",
              textAlign: "center",
              color: "white",
            }}
          >
            You are using demo of OptoFusion
          </Typography>
          <Button
            onClick={() =>
              window.open("https://www.mtmglobal.tech/contact#contact")
            }
            sx={{ marginLeft: "15px" }}
            variant="contained"
          >
            <span>Contact Us</span>
          </Button>
        </Box>
      )}
      <AppBar
        sx={{
          boxShadow: "none",
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(["height"], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(isDesktop && {
            width: "100%",
            height: HEADER.H_DASHBOARD_DESKTOP,
            ...(isOffset && {
              height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            }),
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
          }}
        >
          {renderContent}
        </Toolbar>
      </AppBar>
    </>
  );
}
