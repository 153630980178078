import {
  Container,
  Button,
  Card,
  TableContainer,
  Table,
  TableBody,
  Tooltip,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";

import { Link as RouterLink } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useSettingsContext } from "../../../components/settings";
import Iconify from "../../../components/iconify";
import DispenseTableToolbar from "./DispenseTableToolbar";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import {
  TableHeadCustom,
  TableEmptyRows,
  TableNoData,
  emptyRows,
  useTable,
} from "../../../components/table";
import DispenseTableRow from "./DispenseTableRow";
import { IDispense, statusOptionsProps } from "../../../@types/dispense";
import { DispensaryService } from "../../../api/dispensary.service";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useAuthContext } from "../../../auth/useAuthContext";

export default function DispensaryListPage() {
  const { user } = useAuthContext();

  const [searchValue, setSearchValue] = useState("");
  const [statusValues, setStatusValues] = useState<statusOptionsProps[]>([]);

  const [dispensaryData, setDispensaryData] = useState<IDispense[]>([]);
  const [allDispenseData, setAllDispenseData] = useState<IDispense[]>([]);

  const dispenseData = async () => {
    const dispensaryService = new DispensaryService();

    try {
      const res = await dispensaryService.getAllDispensaries();
      const dispensaries: IDispense[] = res.data;
      setDispensaryData(dispensaries);
      setAllDispenseData(dispensaries);
    } catch (error) {
      console.error("Error");
    }
  };

  const { themeStretch } = useSettingsContext();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectAllRows,
    onSort,
  } = useTable({ defaultOrderBy: "startTime" });

  const TABLE_HEAD = [
    { id: "firstName", label: "Patient Name", align: "center" },
    { id: "order_id", label: "Order ID", align: "center" },
    { id: "phone", label: "Patient Phone", align: "center" },
    { id: "email", label: "Patient Email", align: "center" },
    { id: "status", label: "Status", align: "center" },
    { id: "", label: "Actions", align: "center" },
  ];

  const handleDeleteDispense = (id: number) => {
    const dispensaryService = new DispensaryService();

    dispensaryService
      .deleteDispensaries(id)
      .then((res) => {
        enqueueSnackbar("Dispensary Deleted Successfully!", {
          variant: "success",
        });
        dispenseData();
      })
      .catch(function (error) {
        enqueueSnackbar(error.message[0], {
          variant: "error",
        });
      });
  };

  const restrictAddDispense =
    !user?.isActiveOrganization && allDispenseData?.length === 1;
  const demoLimitContent = "If you want to add more please contact us.";

  useEffect(() => {
    if (!searchValue && !statusValues?.length) {
      setDispensaryData(allDispenseData);
      return;
    }
    let list: IDispense[] = [];
    if (searchValue) {
      list = allDispenseData.filter(
        (item: IDispense) =>
          item.patient.firstName
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.patient.phone
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.patient.email?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    if (statusValues?.length) {
      const status = statusValues?.map(
        (option: statusOptionsProps) => option.value
      );

      list = searchValue
        ? list.filter((item: IDispense) => status.includes(item.status))
        : allDispenseData.filter((item: IDispense) =>
            status.includes(item.status)
          );
    }
    setDispensaryData(list);
  }, [searchValue, statusValues]);
  useEffect(() => {
    dispenseData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dispense</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Dispense"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Dispense",
              href: PATH_DASHBOARD.dispense.list,
            },
          ]}
          action={
            <Tooltip title={restrictAddDispense ? demoLimitContent : ""}>
              <span>
                <Button
                  component={RouterLink}
                  to={PATH_DASHBOARD.dispense.new}
                  disabled={restrictAddDispense}
                  sx={{ marginRight: "15px" }}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  <span>New Dispense</span>
                </Button>
              </span>
            </Tooltip>
          }
        />

        <Card>
          <DispenseTableToolbar
            onSearchFilter={(value: string) => setSearchValue(value)}
            searchValue={searchValue}
            statusValues={statusValues}
            onResetFilter={() => {
              setSearchValue("");
            }}
            onStatusFilter={(options: any) => {
              setStatusValues(options);
            }}
          />
          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dispensaryData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      dispensaryData.map((row: any) => row.id)
                    )
                  }
                />
                <TableBody>
                  {dispensaryData.map((dispense) => (
                    <DispenseTableRow
                      dispense={dispense}
                      onDeleteRow={() => handleDeleteDispense(dispense.id)}
                    />
                  ))}
                  <TableEmptyRows
                    height={63}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      dispensaryData.length
                    )}
                  />
                  {!dispensaryData.length && (
                    <TableNoData isNotFound={dispensaryData.length <= 0} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        </Card>
      </Container>
    </>
  );
}
