import axios from "../utils/axios";

export class ClinicalRecords {
  getAllClinicalRecords(patientId: number, clinicalRecordId?: string) {
    const queryString = clinicalRecordId ? `?id=${clinicalRecordId}` : "";
    return axios.get(`/clinical-record/patient/${patientId}/${queryString}`);
  }

  getLastRecord(patientId: string) {
    return axios.get(`/clinical-record/patient/${patientId}/lastIndex`);
  }

  getPrescreeningById(id: any, prescreeningId: number) {
    return axios.get(
      `/patient/${id}/prescreening?prescreeningId=${prescreeningId}`
    );
  }
  getRefractionById(patientId: any, refractionId: any) {
    return axios.get(
      `/patient/${patientId}/refraction?refractionId=${refractionId}`
    );
  }
  getExaminationByClinicalRecordId(patientId: any, examinationId: number) {
    return axios.get(
      `/patient/${patientId}/examination?examinationId=${examinationId}`
    );
  }
  getClinicalRecordsById(id: number) {
    return axios.get(`/clinical-record/${id}`);
  }
  createSpecificationHistory(data: any) {
    return axios.post("/specification-history", data);
  }
  updateSpecificationHistory(id: any, data: any) {
    return axios.put(`/specification-history/${id}`, data);
  }
  deleteSpecificationHistory(id: any, data: any) {
    return axios.delete(`/specification-history/${id}`, data);
  }
}
