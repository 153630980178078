import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  MenuItem,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import SpecificationHistory from "./SpecificationHistory";
import Pressures from "./Pressures";
import ClinicRecordsMisc from "./ClinicRecordsMisc";
import { useEffect, useRef, useState } from "react";
import AddNewPrescreening from "./AddNewPrescreening";
import { PrescreeningService } from "../../../api/prescreening.service";
import { enqueueSnackbar } from "notistack";
import { UploadIllustration } from "../../../assets/illustrations";
import { RHFTextField } from "../../../components/hook-form";
import { useForm } from "react-hook-form";
import FormProvider from "../../../components/hook-form/FormProvider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AutoRefractor from "./AutoRefractor";

type Props = {
  data: any;
  tab: any;
  clinicalRecordId: string | null;
  onUpdate: VoidFunction;
  setNewClinicalId: (newClinicalId: string) => void;
  onUpdatePayload: (payload: any) => void;
  setSelectedType: (type: string) => void;
  setShowCLForm: (type: boolean) => void;
};

type ImageState = {
  [key: string]: string | undefined;
};
const options = [
  { id: "eye_examination", label: "Eye Examination" },
  { id: "contact_lenses", label: "Contact Lenses" },
];

export default function PatientPrescreening({
  tab,
  data,
  clinicalRecordId,
  onUpdate,
  setNewClinicalId,
  onUpdatePayload,
  setSelectedType,
  setShowCLForm,
}: Props) {
  const [showAddPrescreeningModal, setShowAddPrescreeningModal] =
    useState(false);
  const [dataPrescreening, setDataPrescreening] = useState<any>({});
  const [newRecord, setNewRecord] = useState(false);
  const [openNewRecord, setOpenNewRecord] = useState(false);
  const id = window.location.pathname.split("/")[2];
  const [selectedImage, setSelectedImage] = useState<ImageState>({});
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [open, setOpen] = useState(false);
  const [averages, setAverages] = useState({
    IOPs: 0,
    CCTs: 0,
  });
  const [selectedChips, setSelectedChips] = useState<{
    reasonForVisit: string[];
    hobbies: string[];
  }>({
    reasonForVisit: [],
    hobbies: [],
  });

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputId: string
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage((prevImages) => ({
          ...prevImages,
          [inputId]: reader.result as string,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    data
      ? setDataPrescreening(data)
      : localStorage.getItem("draftPrescreening") ??
        setDataPrescreening(
          JSON.parse(localStorage.getItem("draftPrescreening") as any)
        );
  }, [data]);

  const handleOpen = () => {
    setShowAddPrescreeningModal(true);
  };

  const handleClose = () => {
    setShowAddPrescreeningModal(false);
    setOpenNewRecord(false);
  };

  const handleData = (_data: any) => {
    setDataPrescreening(_data);
  };

  const methods = useForm({
    defaultValues: {
      description: "",
    },
  });

  const createClinicalRecord = () => {
    const payload: any = { ...dataPrescreening };

    // Join selectedChips data for hobbies and reasonForVisit
    if (Array.isArray(selectedChips.hobbies)) {
      payload.hobbies = selectedChips.hobbies.join(","); // Use selectedChips.hobbies instead of payload.hobbies
    }

    if (Array.isArray(selectedChips.reasonForVisit)) {
      payload.reasonForVisit = selectedChips.reasonForVisit.join(","); // Use selectedChips.reasonForVisit instead of payload.reasonForVisit
    }

    payload.eyePressures?.forEach((pressure: any) => delete pressure.id);
    payload.specificationHistories?.forEach((history: any) => {
      delete history.id;
    });

    // Remove unwanted fields from the payload
    delete payload.createdDate;
    delete payload.updatedDate;
    delete payload.id;
    delete payload.createdBy;
    delete payload.updatedBy;

    // Add clinicalRecordId if it exists
    if (clinicalRecordId && !newRecord) {
      payload.clinicalRecordId = clinicalRecordId;
    }

    const preScreeningService = new PrescreeningService();

    preScreeningService
      .createNewClinicalRecord(id, payload)
      .then(function (response: any) {
        enqueueSnackbar(
          "Prescreening Created, Please add History And Symptoms",
          {
            variant: "success",
          }
        );
        setNewRecord(false);
        onUpdate();
        tab("history_and_symptoms");
        setNewClinicalId(response?.data?.clinicalRecordId);
        localStorage.removeItem("draftPrescreening");
        onUpdatePayload(payload?.axis);
      })
      .catch(function (error) {
        enqueueSnackbar("Something Went Wrong", {
          variant: "error",
        });
      });
  };

  const setAverageEyePressures = (type: string, value: number) => {
    setAverages((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setShowCLForm(true);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    selectedType: string
  ) => {
    setSelectedType(selectedType);
    setSelectedIndex(index);
    setOpen(false);
  };
  return (
    <Grid mt={3} item xs={8} lg={8}>
      <Box></Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
        mb={2}>
        <Typography sx={{ ml: 1 }} color={"red"}>
          {dataPrescreening
            ? "* Adding a new Pre Screening against a clinical record, please save and continue!"
            : ""}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}>
          {dataPrescreening && Object.keys(dataPrescreening).length ? (
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpen();
              }}>
              {dataPrescreening ? "Edit" : "Add New Clinical Record"}
            </Button>
          ) : (
            <></>
          )}
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="Button group with a nested menu">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenNewRecord(true);
                setNewRecord(true);
                setShowCLForm(true);
                handleOpen();
              }}>
              Add Clinical Record
            </Button>{" "}
            <Button
              sx={{ mr: 2, paddingBottom: 4 }}
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}>
              <ArrowDropDownIcon sx={{ mt: 3, fontSize: "30px" }} />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={index}
                          selected={index === 0}
                          onClick={(event) =>
                            handleMenuItemClick(event, index, option.id)
                          }>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>

      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="column"
        useFlexGap
        flexWrap="wrap">
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <SpecificationHistory
            specsData={
              dataPrescreening
                ? [dataPrescreening]
                : data !== undefined
                ? data
                : []
            }
          />
          <AutoRefractor data={dataPrescreening?.autoRefractor} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Pressures
            eyePressureData={
              dataPrescreening
                ? dataPrescreening.eyePressures
                : data?.eyePressures
            }
            averages={averages}
          />
          <ClinicRecordsMisc
            misc={dataPrescreening ? [dataPrescreening] : data}
          />
        </Box>
      </Stack>
      <Box
        sx={{
          width: "97%",
          margin: "10px",
          padding: "10px",
          borderRadius: "15px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}>
        <Typography variant="h6">Visuals</Typography>
        <FormProvider methods={methods}>
          <RHFTextField
            sx={{
              width: "100%",
              margin: "0 auto",
            }}
            name="description"
            label="Description"
          />
        </FormProvider>
        <Stack
          spacing={5}
          alignItems="center"
          justifyContent="center"
          direction={{
            xs: "column",
            md: "row",
          }}
          sx={{
            width: "98%",
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input1"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput1">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput1"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input1")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input2"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput2">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput2"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input2")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input3"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput3">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput3"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input3")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input4"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput4">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput4"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input4")}
            />
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          width: "97%",
          margin: "10px",
          padding: "10px",
          borderRadius: "15px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}>
        <Typography variant="h6">OCT</Typography>
        <Stack
          spacing={5}
          alignItems="center"
          justifyContent="center"
          direction={{
            xs: "column",
            md: "row",
          }}
          sx={{
            width: "98%",
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input5"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput5">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput5"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input5")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input6"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput6">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput6"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input6")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input7"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput7">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput7"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input7")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input8"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput8">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput8"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input8")}
            />
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          width: "97%",
          margin: "10px",
          padding: "10px",
          borderRadius: "15px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}>
        <Typography variant="h6">Fundus Photography</Typography>
        <Stack
          spacing={5}
          alignItems="center"
          justifyContent="center"
          direction={{
            xs: "column",
            md: "row",
          }}
          sx={{
            width: "98%",
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input9"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput9">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput9"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input9")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input10"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput10">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput10"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input10")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input11"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput11">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput11"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input11")}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              margin: "10px",
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {selectedImage ? (
              <img
                src={selectedImage["input12"] || ""}
                alt="Preview"
                style={{
                  width: "150px",
                  height: "112.5px",
                  marginBottom: "10px",
                }}
              />
            ) : (
              <UploadIllustration sx={{ width: 150 }} />
            )}
            <label htmlFor="imageInput12">
              <Typography
                gutterBottom
                variant="body2"
                style={{ cursor: "pointer" }}>
                Select Image
              </Typography>
            </label>
            <input
              type="file"
              id="imageInput12"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, "input12")}
            />
          </Box>
        </Stack>
      </Box>

      {/* <Box
        sx={{
          height: "200px",
          width: "97%",
          margin: "10px",
          borderRadius: "15px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Paper style={{ width: 300, height: 200, padding: 16 }}>
          {selectedImage ? (
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Uploaded Preview"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : (
            <Typography variant="body1">Select an image to preview</Typography>
          )}
        </Paper>
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </Box> */}
      {dataPrescreening && (
        <Button
          sx={{ mt: 2, mr: 2, float: "right" }}
          variant="contained"
          color="primary"
          onClick={createClinicalRecord}>
          Create
        </Button>
      )}
      <Button
        sx={{ mt: 2, mr: 2, float: "right" }}
        variant="outlined"
        color="primary"
        onClick={() => {
          tab("history_and_symptoms");
        }}>
        Next
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showAddPrescreeningModal}
        onClose={handleClose}>
        <DialogTitle>Add Pre Screening</DialogTitle>
        <AddNewPrescreening
          onCancel={handleClose}
          onSubmitForm={(data: any) => {
            handleData(data);
          }}
          draftPrescreening={
            openNewRecord ? null : dataPrescreening && dataPrescreening
          }
          setAverages={setAverageEyePressures}
          averages={averages}
          selectedChips={selectedChips}
          setSelectedChips={setSelectedChips}
        />
      </Dialog>
    </Grid>
  );
}
