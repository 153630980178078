import { useEffect, useState } from "react";
import {
  Stack,
  Table,
  Tabs,
  Tab,
  Box,
  Typography,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  TableHeadCustom,
  TableNoData,
  useTable,
} from "../../../components/table";
import Scrollbar from "../../../components/scrollbar";
import { CustomAvatar } from "../../../components/custom-avatar";
import PatientInfo from "./PatientInfo";
import PatientPrescreening from "./PatientPrescreening";
import { ClinicalRecords } from "../../../api/clinicalRecords.service";
import { formatDate } from "@fullcalendar/core";
import Refraction from "./Refraction/Refraction";
import AddHistoryAndSymptoms from "./HistoryAndSymptoms/AddHistoryAndSymptoms";
import { useParams } from "react-router-dom";
import { PatientService } from "../../../api/patient.service";
import { HistoryAndSymptomsService } from "../../../api/historyAndSymptoms.service";
import Examination from "./Examination/Examination";
import Handover from "./Handover/Handover";
import { HandoverService } from "../../../api/handover.service";
import CLHistoryAndSymptoms from "./ContactLenses/CLHistoryAndSymptoms/CLHistoryAndSymptoms";
import AnteriorEye from "./ContactLenses/AnteriorEye/AnteriorEye";
import CLFitting from "./ContactLenses/CLFitting/CLFitting";
import CLAftercare from "./ContactLenses/CLAftercare/CLAftercare";
import TeachScreen from "./ContactLenses/TeachScreen/TeachScreen";
import { ICLHistoryAndSymptoms } from "../../../@types/clHistoryAndSymptoms.type";
import { CLHistoryAndSymptomsService } from "../../../api/contact-lenses/cl-history-and-symptoms.service";
import { IClinicalRecordSchema } from "../../../@types/clinicalRecord.type";
import { IRefractionSchema } from "../../../@types/refraction";
import { IExaminationSchema } from "../../../@types/examination";
import { IHandoverSchema } from "../../../@types/handover";
import { IPatientSymptomsSchema } from "../../../@types/patientSymptoms";
import { IPatientInfo } from "../../../@types/patientInfo.type";
import { AnteriorEyeService } from "../../../api/contact-lenses/anterior-eye.service";
import { IAnteriorEye } from "../../../@types/anteriorEye.type";
import CLHandover from "./ContactLenses/Handover/CLHandover";
import { CLFittingService } from "../../../api/contact-lenses/cl-fitting.service";
import { ICLFitting } from "../../../@types/clFitting.type";
import { TrailsService } from "../../../api/contact-lenses/trail.service";
import { ITrail } from "../../../@types/trail.type";
import { CLAftercareService } from "../../../api/contact-lenses/cl-aftercare.service";
import { ICLAftercare } from "../../../@types/clAftercare.type";
import { CLHandoverService } from "../../../api/contact-lenses/cl-handover.service";
import { ICLHandover } from "../../../@types/clHandover.type";
import { TeachScreenService } from "../../../api/contact-lenses/teachscreen.service";
import { ITeachScreen } from "../../../@types/teachscreen.type";
import {
  IAutoRefractorSchema,
  IAxisSchema,
  IVisionSchema,
} from "../../../@types/specsHistory";

const EYE_EXAMINATION_TABS = [
  { id: "info", label: "Info", align: "left" },
  { id: "prescreening", label: "Prescreening", align: "left" },
  { id: "history_and_symptoms", label: "History And Symptoms", align: "left" },
  {
    id: "refraction",
    label: "Refraction",
    align: "left",
  },
  { id: "examination", label: "Examination", align: "center" },
  { id: "handover", label: "Handover", align: "center" },
];

const CONTACT_LENSES_TABS = [
  { id: "info", label: "Info", align: "left" },
  { id: "cl_h_and_s", label: "CL H&S", align: "left" },
  { id: "anterior_eye", label: "Anterior Eye", align: "left" },
  {
    id: "cl_fitting",
    label: "CL Fitting",
    align: "left",
  },
  { id: "cl_aftercare", label: "CL Aftercare", align: "center" },
  { id: "cl_handover", label: "Handover", align: "center" },
  { id: "teach_screen", label: "Teach Screen", align: "center" },
];

const TABLE_HEAD = [
  { id: "id", label: "ID", align: "left" },
  {
    id: "reasonForVisit",
    label: "Visit Reason",
    align: "left",
  },
  {
    id: "type",
    label: "Type",
    align: "left",
  },
  { id: "createdDate", label: "Created Date", align: "left" },
];

export default function PatientClinicalRecords() {
  const { dense } = useTable({ defaultOrderBy: "startTime" });
  const [eyeExamination, setEyeExamination] = useState("info");
  const [contactLenses, setContactLenses] = useState("info");
  const [clinicalRecords, setClinicalRecords] = useState<
    IClinicalRecordSchema[]
  >([]);
  const [selectedClinicalRecord, setSelectedClinicalRecord] = useState<any>({});
  const [refraction, setRefraction] = useState({} as IRefractionSchema);
  const [examination, setExamination] = useState({} as IExaminationSchema);
  const [handover, setHandover] = useState({} as IHandoverSchema);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [patientInfo, setPatientInfo] = useState<IPatientInfo>();
  const [history, setHistory] = useState({} as IPatientSymptomsSchema);
  const [anteriorEye, setAnteriorEye] = useState({} as IAnteriorEye);
  const [payloadState, setPayloadState] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<string>("eye_examination");
  const [clHistoryAndSymptoms, setClHistoryAndSymptoms] = useState(
    {} as ICLHistoryAndSymptoms
  );
  const [showCLForm, setShowCLForm] = useState(false);
  const [clinicalRecordId, setClinicalRecordId] = useState<number>();
  const [clFitting, setClFitting] = useState({} as ICLFitting);
  const [trails, setTrails] = useState<ITrail[]>([]);
  const [clAftercare, setClAftercare] = useState({} as ICLAftercare);
  const [clHandover, setClHandover] = useState({} as ICLHandover);
  const [teachScreen, setTeachScreen] = useState({} as ITeachScreen);
  const [visionData, setVisionData] = useState({
    nearVision: {} as IVisionSchema,
    distanceVision: {} as IVisionSchema,
  });
  const [autoRefractorData, setAutoRefractorData] = useState(
    {} as IAutoRefractorSchema
  );
  const [finalData, setFinalData] = useState({});

  const { patientId } = useParams();

  const handlePayloadUpdate = (payload: any) => {
    setPayloadState(payload);
  };

  const handleChildChange = (event: React.SyntheticEvent, newValue: string) => {
    setEyeExamination(newValue);
  };

  const handleContactLensChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setContactLenses(newValue);
  };

  const handleCallClinicalRecord = async () => {
    const clinicalRecords = new ClinicalRecords();
    const res = await clinicalRecords.getAllClinicalRecords(+patientId!);
    setClinicalRecords(res.data);
  };

  const setNewClinicalRecordId = (id: string) => {
    if (selectedRow !== id) {
      setSelectedRow(id);
    }
  };

  const showEyeExaminationTabs = () => {
    switch (eyeExamination) {
      case EYE_EXAMINATION_TABS[0].id:
        return patientInfo && <PatientInfo patientInfo={patientInfo} />;
      case EYE_EXAMINATION_TABS[1].id:
        return (
          <PatientPrescreening
            tab={(tab: string) => setEyeExamination(tab)}
            clinicalRecordId={selectedRow}
            data={selectedClinicalRecord}
            setNewClinicalId={setNewClinicalRecordId}
            onUpdate={handleCallClinicalRecord}
            onUpdatePayload={handlePayloadUpdate}
            setSelectedType={(selectedType: string) =>
              setSelectedType(selectedType)
            }
            setShowCLForm={(showCLForm: boolean) => setShowCLForm(showCLForm)}
          />
        );
      case EYE_EXAMINATION_TABS[2].id:
        return (
          <AddHistoryAndSymptoms
            history={history}
            clinicalRecordId={selectedRow}
            tab={(tab: string) => setEyeExamination(tab)}
            getAllHistoryAndSymptoms={getHistoryRecords}
          />
        );
      case EYE_EXAMINATION_TABS[3].id:
        return (
          <Refraction
            tab={(tab: string) => setEyeExamination(tab)}
            selectedRefraction={refraction}
            clinicalRecordId={selectedRow}
            getAllRefraction={getRefractionRecords}
            visionData={visionData}
            autoRefractorData={autoRefractorData}
          />
        );
      case EYE_EXAMINATION_TABS[4].id:
        return (
          <Examination
            setTab={(tab: string) => setEyeExamination(tab)}
            clinicalRecordId={selectedRow}
            selectedExamination={examination}
            prescreeningData={selectedClinicalRecord}
            getAllExamination={getExaminationRecords}
            finalData={finalData}
          />
        );
      case EYE_EXAMINATION_TABS[5].id:
        return (
          <Handover
            selectedHandover={handover}
            clinicalRecordId={selectedRow}
            dispenseData={payloadState}
            getAllHandover={getHandoverRecords}
            finalData={finalData}
          />
        );
      default:
        break;
    }
  };

  const showContactLensesTabs = () => {
    switch (contactLenses) {
      case CONTACT_LENSES_TABS[0].id:
        return patientInfo && <PatientInfo patientInfo={patientInfo} />;
      case CONTACT_LENSES_TABS[1].id:
        return (
          <CLHistoryAndSymptoms
            selectedType={selectedType}
            clHistorySymptoms={clHistoryAndSymptoms}
            setSelectedType={(selectedType: string) =>
              setSelectedType(selectedType)
            }
            showCLForm={showCLForm}
            tab={(tab: string) => setContactLenses(tab)}
            onUpdate={handleCallClinicalRecord}
            setClinicalRecordId={(id: number) => setClinicalRecordId(id)}
            getAllCLHistoryAndSymptoms={getAllCLHistoryAndSymptoms}
            setSelectedRow={(selectedRow: string) =>
              setSelectedRow(selectedRow)
            }
            finalData={finalData}
          />
        );
      case CONTACT_LENSES_TABS[2].id:
        return (
          <AnteriorEye
            anteriorEye={anteriorEye}
            clinicalRecordId={clinicalRecordId ? clinicalRecordId : selectedRow}
            tab={(tab: string) => setContactLenses(tab)}
            showCLForm={showCLForm}
            getAnteriorEyeRecords={getAnteriorEyeRecords}
            selectedRow={selectedRow}
            clinicalRecords={clinicalRecords}
          />
        );
      case CONTACT_LENSES_TABS[3].id:
        return (
          <CLFitting
            clinicalRecordId={clinicalRecordId ? clinicalRecordId : selectedRow}
            tab={(tab: string) => setContactLenses(tab)}
            showCLForm={showCLForm}
            clFitting={clFitting}
            trails={trails}
            getCLFittingRecords={getCLFittingRecords}
            getTrailsRecords={getTrailsRecords}
            selectedRow={selectedRow}
            clinicalRecords={clinicalRecords}
          />
        );
      case CONTACT_LENSES_TABS[4].id:
        return (
          <CLAftercare
            clinicalRecordId={clinicalRecordId ? clinicalRecordId : selectedRow}
            tab={(tab: string) => setContactLenses(tab)}
            clAftercare={clAftercare}
            showCLForm={showCLForm}
            getCLAftercareRecords={getCLAftercareRecords}
            selectedRow={selectedRow}
            clinicalRecords={clinicalRecords}
            trails={trails}
          />
        );
      case CONTACT_LENSES_TABS[5].id:
        return (
          <CLHandover
            clinicalRecordId={clinicalRecordId ? clinicalRecordId : selectedRow}
            tab={(tab: string) => setContactLenses(tab)}
            clHandover={clHandover}
            showCLForm={showCLForm}
            getCLHandoverRecords={getCLHandoverRecords}
          />
        );
      case CONTACT_LENSES_TABS[6].id:
        return (
          <TeachScreen
            clinicalRecordId={clinicalRecordId ? clinicalRecordId : selectedRow}
            teachScreen={teachScreen}
            showCLForm={showCLForm}
            getTeachScreenRecords={getTeachScreenRecords}
          />
        );
      default:
        break;
    }
  };

  const getAllCLHistoryAndSymptoms = async (
    clHistoryId: number,
    clinicalId: number
  ) => {
    if (clHistoryId === null) {
      setClHistoryAndSymptoms({} as ICLHistoryAndSymptoms);
      return;
    }
    const clHistoryAndSymptomsService = new CLHistoryAndSymptomsService();

    try {
      const res = await clHistoryAndSymptomsService.getHistoryAndSymptoms(
        patientId!,
        clHistoryId
      );
      const data = res.data[0];
      setClHistoryAndSymptoms(data);
    } catch (error) {
      console.error("Error fetching CL history and symptoms:", error);
      setClHistoryAndSymptoms({} as ICLHistoryAndSymptoms);
    }
  };

  const getAnteriorEyeRecords = async (
    anteriorEyeId: number | null,
    clinicalId: number
  ) => {
    if (anteriorEyeId === null) {
      setAnteriorEye({} as IAnteriorEye);
      return;
    }
    const anteriorEyeService = new AnteriorEyeService();

    try {
      const res = await anteriorEyeService.getAnteriorEye(
        patientId!,
        anteriorEyeId
      );
      const data = res.data[0];
      setAnteriorEye(data);
    } catch (error) {
      console.error("Error fetching CL anteriorEye:", error);
      setAnteriorEye({} as IAnteriorEye);
    }
  };

  const getCLFittingRecords = async (
    clFittingId: number | null,
    clinicalId: number
  ) => {
    if (clFittingId === null) {
      setClFitting({} as ICLFitting);
      return;
    }

    const clFittingService = new CLFittingService();

    try {
      const res = await clFittingService.getCLFittings(patientId!, clFittingId);
      const data = res.data[0];
      setClFitting(data);
    } catch (error) {
      console.error("Error fetching CL fitting records:", error);
      setClFitting({} as ICLFitting);
    }
  };

  const getTrailsRecords = async (clinicalId: number) => {
    if (selectedType === "contact_lenses") {
      const trailsService = new TrailsService();

      try {
        const res = await trailsService.getTrails(patientId!, clinicalId);
        const data = res.data;
        setTrails(data);
      } catch (error) {
        console.error("Error fetching trails records:", error);
        setTrails([]);
      }
    }
  };

  const getCLAftercareRecords = async (
    clAftercareId: number | null,
    clinicalId: number
  ) => {
    if (clAftercareId === null) {
      setClAftercare({} as ICLAftercare);
      return;
    }
    const clAftercareService = new CLAftercareService();

    try {
      const res = await clAftercareService.getCLAftercare(
        patientId!,
        clAftercareId
      );
      const data = res.data[0];
      setClAftercare(data);
    } catch (error) {
      console.error("Error fetching CL aftercare records:", error);
      setClAftercare({} as ICLAftercare);
    }
  };

  const getCLHandoverRecords = async (
    clHandoverId: number | null,
    clinicalId: number
  ) => {
    if (clHandoverId === null) {
      setClHandover({} as ICLHandover);
      return;
    }
    const clHandoverService = new CLHandoverService();

    try {
      const res = await clHandoverService.getCLHandover(
        patientId!,
        clHandoverId
      );
      const data = res.data[0];
      setClHandover(data);
    } catch (error) {
      console.error("Error fetching CL handover records:", error);
      setClHandover({} as ICLHandover);
    }
  };

  const getTeachScreenRecords = async (
    teachScreenId: number | null,
    clinicalId: number
  ) => {
    if (teachScreenId === null) {
      setTeachScreen({} as ITeachScreen);
      return;
    }
    const teachScreenService = new TeachScreenService();

    try {
      const res = await teachScreenService.getTeachScreen(
        patientId!,
        teachScreenId
      );
      const data = res.data[0];
      setTeachScreen(data);
    } catch (error) {
      console.error("Error fetching teach screen records:", error);
      setTeachScreen({} as ITeachScreen);
    }
  };

  const getAllClinicsRecords = () => {
    const clinicalRecords = new ClinicalRecords();
    clinicalRecords.getAllClinicalRecords(+patientId!).then((res) => {
      setClinicalRecords(res.data);
      const clinicalId = res.data[0]?.id;
      const prescreeningId = res.data[0]?.prescreeningId;

      setSelectedRow(clinicalId);
      getClinicalRecord(prescreeningId, selectedRow);
    });
  };

  const getClinicalRecord = async (
    prescreeningId: number | null,
    clinicalId: number
  ) => {
    if (prescreeningId === null) {
      setSelectedClinicalRecord({});
      return;
    }
    const clinicalRecords = new ClinicalRecords();

    try {
      const res = await clinicalRecords.getPrescreeningById(
        patientId!,
        prescreeningId
      );
      const data = res.data[0];
      setAutoRefractorData(data?.autoRefractor);
      const specsHistory = data?.specificationHistories;
      if (specsHistory) {
        specsHistory.forEach((spec: any) => {
          if (spec?.specifications === "SVN") {
            setVisionData((prevData) => ({
              ...prevData,
              nearVision: {
                rightSphericalPower: spec?.rightSphericalPower,
                leftSphericalPower: spec?.leftSphericalPower,
                rightCylinder: spec?.rightCylinder,
                leftCylinder: spec?.leftCylinder,
                rightAxis: spec?.rightAxis,
                leftAxis: spec?.leftAxis,
                rightDistancePD: spec?.rightDistancePD,
                leftDistancePD: spec?.leftDistancePD,
                rightBVD: spec?.rightBVD,
                leftBVD: spec?.leftBVD,
              },
            }));
          } else if (spec?.specifications === "SVD") {
            setVisionData((prevData) => ({
              ...prevData,
              distanceVision: {
                rightSphericalPower: spec?.rightSphericalPower,
                leftSphericalPower: spec?.leftSphericalPower,
                rightCylinder: spec?.rightCylinder,
                leftCylinder: spec?.leftCylinder,
                rightAxis: spec?.rightAxis,
                leftAxis: spec?.leftAxis,
                rightDistancePD: spec?.rightDistancePD,
                leftDistancePD: spec?.leftDistancePD,
                rightBVD: spec?.rightBVD,
                leftBVD: spec?.leftBVD,
              },
            }));
          }
        });
      }
      setSelectedClinicalRecord(data);
    } catch (error) {
      console.error("Error fetching Prescreening Records:", error);
      setSelectedClinicalRecord({});
    }
  };

  const getRefractionRecords = async (
    refractionId: number | null,
    clinicalId: number
  ) => {
    if (refractionId === null) {
      setRefraction({} as IRefractionSchema);
      return;
    }
    const refractionService = new ClinicalRecords();

    try {
      const res = await refractionService.getRefractionById(
        patientId!,
        refractionId
      );
      const data = res.data[0];
      setFinalData(data?.final);
      setRefraction(data);
    } catch (error) {
      console.error("Error fetching Refraction Records:", error);
      setRefraction({} as IRefractionSchema);
    }
  };

  const getExaminationRecords = async (
    examinationId: number | null,
    clinicalId: number
  ) => {
    if (examinationId === null) {
      setExamination({} as IExaminationSchema);
      return;
    }
    const clinicalRecords = new ClinicalRecords();

    try {
      const res = await clinicalRecords.getExaminationByClinicalRecordId(
        patientId!,
        examinationId
      );
      const data = res.data[0];
      setExamination(data);
    } catch (error) {
      console.error("Error fetching Examination:", error);
      setExamination({} as IExaminationSchema);
    }
  };

  const getHandoverRecords = async (
    handoverId: number | null,
    clinicalId: number
  ) => {
    if (handoverId === null) {
      setHandover({} as IHandoverSchema);
      return;
    }
    const handoverRecords = new HandoverService();

    try {
      const res = await handoverRecords.getAllHandoverByClinicalRecordId(
        patientId!,
        handoverId
      );
      const data = res.data[0];
      setHandover(data);
    } catch (error) {
      console.error("Error fetching Handover:", error);
      setHandover({} as IHandoverSchema);
    }
  };

  const getHistoryRecords = async (
    symptomsId: number | null,
    clinicalId: number
  ) => {
    if (symptomsId === null) {
      setHistory({} as IPatientSymptomsSchema);
      return;
    }
    const historyRecord = new HistoryAndSymptomsService();

    try {
      const res = await historyRecord.getHistoryAndSymptoms(
        patientId!,
        symptomsId
      );
      setHistory(res.data);
    } catch (error) {
      console.error("Error fetching H&S:", error);
      setHistory({} as IPatientSymptomsSchema);
    }
  };

  const handleRowClick = (prescreeningId: number, id: any) => {
    const selectedRecord = clinicalRecords.find((record) => record.id === id);

    if (selectedRecord) {
      if (selectedRecord.type === "Contact Lenses") {
        setSelectedType("contact_lenses");
      } else if (selectedRecord.type === "Eye Examination") {
        setSelectedType("eye_examination");
      }
    }
    setSelectedRow(id);
    getClinicalRecord(prescreeningId, id);
  };

  const getPatientInfo = (patientId: any) => {
    const patientService = new PatientService();
    patientService.getPatientName(patientId).then((res) => {
      setPatientInfo(res.data);
    });
  };

  useEffect(() => {
    getAllClinicsRecords();
    getPatientInfo(patientId);
  }, []);

  return (
    <Stack
      alignItems="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{
        borderRadius: "15px",
        position: "relative",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      }}
      p={2}>
      <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
        <Box
          sx={{
            p: 1,
            display: "flex",
            width: "100%",
          }}>
          <Box
            sx={{
              pl: 2,
              pr: 1,
              flexGrow: 1,
              width: "20%",
              mt: "20px",
            }}>
            <Stack spacing={0.1} direction="column" gap={2}>
              <Typography variant="body1" noWrap>
                {patientInfo?.firstName}
              </Typography>
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "text.secondary" }}>
                {patientInfo?.postCode}
              </Typography>
              <Typography
                variant="subtitle1"
                noWrap
                sx={{ color: "text.secondary" }}>
                {patientInfo?.date_of_birth}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: "15px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              maxHeight: "200px",
              width: "80%",
              overflowY: "auto",
            }}>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {clinicalRecords.map((item: any) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedRow === item.id ? "#e0e0e0" : "inherit",
                    }}
                    onClick={() => {
                      setShowCLForm(false);
                      handleRowClick(item.prescreeningId, item.id);
                      getRefractionRecords(item.refractionId, item.id);
                      getHistoryRecords(item.symptomsId, item.id);
                      getExaminationRecords(item.examinationId, item.id);
                      getHandoverRecords(item.handoverId, item.id);
                      getAnteriorEyeRecords(item.anteriorEyeId, item.id);
                      getAllCLHistoryAndSymptoms(
                        item.clHistoryAndSymptomsId,
                        item.id
                      );
                      getCLFittingRecords(item.contactLensFittingId, item.id);
                      getTrailsRecords(item.id);
                      getCLAftercareRecords(
                        item.contactLensAfterCareId,
                        item.id
                      );
                      getCLHandoverRecords(item.contactLensHandoverId, item.id);
                      getTeachScreenRecords(item.teachScreenId, item.id);
                    }}>
                    <TableCell align="left">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <CustomAvatar name={`${item.id}`} />
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {item?.prescreening?.reasonForVisit ||
                        item?.contactLensHistoryAndSymptoms?.reasonForVisit}
                    </TableCell>
                    <TableCell align="left">{item.type}</TableCell>
                    <TableCell align="left">
                      {formatDate(item.createdDate)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableNoData isNotFound={clinicalRecords.length <= 0} />
              </TableBody>
            </Table>
          </Box>
        </Box>
        <Scrollbar>
          {selectedType === "eye_examination" && (
            <>
              <Tabs
                sx={{ mt: 1 }}
                value={eyeExamination}
                onChange={handleChildChange}>
                {EYE_EXAMINATION_TABS.map((head) => (
                  <Tab
                    key={head.id}
                    value={head.id}
                    label={head.label}
                    sx={{ ml: "80px" }}
                  />
                ))}
              </Tabs>
              {showEyeExaminationTabs()}
            </>
          )}
          {selectedType === "contact_lenses" && (
            <>
              <Tabs
                sx={{ mt: 1 }}
                value={contactLenses}
                onChange={handleContactLensChange}>
                {CONTACT_LENSES_TABS.map((head) => (
                  <Tab
                    key={head.id}
                    value={head.id}
                    label={head.label}
                    sx={{ ml: "80px" }}
                  />
                ))}
              </Tabs>
              {showContactLensesTabs()}
            </>
          )}
        </Scrollbar>
      </Table>
    </Stack>
  );
}
