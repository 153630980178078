import { useState } from "react";
import {
  Container,
  Button,
  Card,
  TableContainer,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";

import { Link as RouterLink } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useSettingsContext } from "../../../components/settings";
import Iconify from "../../../components/iconify";
import ProductTableToolbar from "../../../sections/@dashboard/product/ProductTableToolbar";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import ProductList from "../../../sections/@dashboard/product/ProductList";
import ProductNewEditForm from "../../../sections/@dashboard/product/ProductAddEditForm";

export default function ProductListPage() {
  const { themeStretch } = useSettingsContext();
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const handleCloseModal = () => {
    setOpenAddProduct(false);
  };
  return (
    <>
      <Helmet>
        <title>Product</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Product"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Product",
              href: PATH_DASHBOARD.product.list,
            },
          ]}
          action={
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.product.list}
              onClick={() => setOpenAddProduct(true)}
              sx={{ marginRight: "15px" }}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Product
            </Button>
          }
        />

        <Card>
          <ProductTableToolbar />
          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <ProductList />
            </Scrollbar>
          </TableContainer>
          <Dialog
            fullWidth
            maxWidth="md"
            open={openAddProduct}
            onClose={handleCloseModal}
          >
            <DialogTitle>Add Product</DialogTitle>
            <ProductNewEditForm onCancel={handleCloseModal} />

            {/* <AddPatient
              Fields={showFields}
              currentPatient={currentPatient}
              isEdit={isEditBool}
              onCancel={handleCloseModalPatient}
              onSubmitForm={getAllData}
            /> */}
          </Dialog>
        </Card>
      </Container>
    </>
  );
}
