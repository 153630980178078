export type IProduct = {
  id: number;
  sku: string;
  code: string;
  name: string;
  image: string;
  gender: string;
  description: string;
  price: number;
  category: string;
  type?: string;
  quantity: number;
  brandId?: number;
  supplierId?: number;
  status: string;
  createdAt: Date;
};

export type IProductRequest = {};

export enum ProductStatus {
  DRAFT = "draft",
  PUBLISH = "published",
  UNPUBLISH = "unpublished",
}
