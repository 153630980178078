import { Card, Box, Typography, Stack, Select, MenuItem } from "@mui/material";
import RxOutcome from "../CLFitting/RxOutcome";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormProvider from "../../../../../components/hook-form/FormProvider";
import { Controller, useForm } from "react-hook-form";
import RHFTextField from "../../../../../components/hook-form/RHFTextField";
import { ICLHandover } from "../../../../../@types/clHandover.type";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { CLHandoverService } from "../../../../../api/contact-lenses/cl-handover.service";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "../../../../../components/snackbar";

type Props = {
  clinicalRecordId: number;
  tab: (type: string) => void;
  clHandover: ICLHandover;
  showCLForm: boolean;
  getCLHandoverRecords: (clHandoverId: number, clinicalId: number) => void;
};

const CLHandover = ({
  clinicalRecordId,
  tab,
  clHandover,
  showCLForm,
  getCLHandoverRecords,
}: Props) => {
  const [visitType, setVisitType] = useState(
    clHandover?.visitType ? clHandover?.visitType : ""
  );
  const methods = useForm<ICLHandover>();

  const { handleSubmit, control, reset } = methods;

  const handleChange = (e: any) => {
    setVisitType(e.target.value);
  };

  const { patientId } = useParams();

  const onSubmit = async (data: ICLHandover) => {
    const handoverData = { ...data, visitType, clinicalRecordId };
    const formattedData = {
      ...handoverData,
      recallDate: dayjs(data.recallDate).isValid()
        ? dayjs(data.recallDate).format("YYYY-MM-DD")
        : new Date(),
      systemRecallDate: dayjs(data.systemRecallDate).isValid()
        ? dayjs(data.systemRecallDate).format("YYYY-MM-DD")
        : new Date(),
    };
    try {
      const clHandoverService = new CLHandoverService();
      const response = await clHandoverService.createCLHandover(
        patientId!,
        formattedData
      );
      enqueueSnackbar("CL Handover Created Successfully!", {
        variant: "success",
      });
      getCLHandoverRecords(response.data?.id, clinicalRecordId);
      tab("teach_screen");
    } catch (error) {
      enqueueSnackbar("Internal Server Error", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (showCLForm) {
      reset({
        supplier: "",
        type: "",
        freq: "",
        lens: "",
        solution: "",
        visitType: "",
        recallDate: new Date(),
        systemRecallDate: new Date(),
        patientAdvise: "",
        orderLenses: "",
        handoverNotes: "",
      });
    } else {
      reset({
        supplier: clHandover?.supplier || "",
        type: clHandover?.type || "",
        freq: clHandover?.freq || "",
        lens: clHandover?.lens || "",
        solution: clHandover?.solution || "",
        visitType: clHandover?.visitType || "",
        recallDate: dayjs(clHandover?.recallDate).isValid()
          ? dayjs(clHandover?.recallDate).toDate()
          : new Date(),
        systemRecallDate: dayjs(clHandover?.systemRecallDate).isValid()
          ? dayjs(clHandover?.systemRecallDate).toDate()
          : new Date(),
        patientAdvise: clHandover?.patientAdvise || "",
        orderLenses: clHandover?.orderLenses || "",
        handoverNotes: clHandover?.handoverNotes || "",
      });
    }
  }, [showCLForm, clHandover, reset]);

  useEffect(() => {
    if (clHandover) {
      setVisitType(clHandover?.visitType || "");
    }
  }, [clHandover, setVisitType]);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {(showCLForm || !clHandover || Object.keys(clHandover).length === 0) && (
        <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
          * There's no CL Handover added for this clinical record, please add
          and save!
        </Typography>
      )}
      <Box
        sx={{
          margin: "2% 10%",
        }}>
        {/* <RxOutcome /> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          ml={1}
          mr={1}
          spacing={2}>
          <Box
            sx={{
              width: "33.33%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "60px",
            }}>
            <Typography color={"#637381"} variant="h6" mb={3}></Typography>
            <Typography color={"#637381"} variant="h6" mt={3}>
              Supplier
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={3}>
              Type
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={3}>
              Freq
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={3}>
              Lens
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={3}>
              Solution
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={3}>
              Visit Type
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={5}>
              Recall Date
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={5}>
              System Recall Date
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={3}>
              Patient Advise
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={3}>
              Order Lenses
            </Typography>
            <Typography color={"#637381"} variant="h6" mt={4} mb={6}>
              Handover Notes
            </Typography>
          </Box>
          <Box
            sx={{
              width: "33.33%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <RHFTextField
              name="supplier"
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />
            <RHFTextField
              name="type"
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />
            <RHFTextField
              name="freq"
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />
            <RHFTextField
              name="lens"
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />{" "}
            <RHFTextField
              name="solution"
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />{" "}
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={visitType}
              onChange={handleChange}
              sx={{ width: "90%", marginBottom: "10px" }}>
              <MenuItem value="New CL RX">New CL RX</MenuItem>
              <MenuItem value="Update CL RX">Update CL RX</MenuItem>
              <MenuItem value="Aftercare">Aftercare</MenuItem>
              <MenuItem value="Replacement">Replacement</MenuItem>
              <MenuItem value="Trails Evaluation">Trails Evaluation</MenuItem>
              <MenuItem value="Emergency">Emergency</MenuItem>
            </Select>
            <Controller
              name="recallDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(date) => field.onChange(date)}
                  label="Recall Date"
                  sx={{ width: "90%", marginBottom: "10px" }}
                />
              )}
            />
            <Controller
              name="systemRecallDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  sx={{ width: "90%", marginBottom: "10px" }}
                  onChange={(date) => field.onChange(date)}
                  label="System Recall Date"
                />
              )}
            />
            <RHFTextField
              name="patientAdvise"
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />{" "}
            <RHFTextField
              name="orderLenses"
              size="small"
              sx={{ width: "90%", marginBottom: "10px" }}
            />{" "}
            <RHFTextField
              name="handoverNotes"
              size="small"
              multiline
              rows={2}
              sx={{ width: "90%", marginBottom: "10px" }}
            />
          </Box>
        </Stack>
        <Box sx={{ display: "flex", flexDirection: "row-reverse", m: 3 }}>
          <LoadingButton variant="contained" type="submit">
            Save And Next
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default CLHandover;
