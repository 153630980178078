import { ISupplierRequest, Suppliers } from "../@types/supplier.type";
import axios from "../utils/axios";

export class SupplierService {
  getAllSuppliers() {
    return axios.get<Suppliers[]>("/supplier");
  }
  createSupplier(payload: ISupplierRequest) {
    return axios.post<Suppliers>("/supplier", payload);
  }
  updateSupplier(id: number, payload: ISupplierRequest) {
    return axios.patch<Suppliers>(`/supplier/${id}`, payload);
  }

  deleteSupplier(id: number) {
    return axios.delete<string>(`/supplier/${id}`);
  }
}
