import { useForm } from "react-hook-form";
import { IAnteriorEye } from "../../../../../@types/anteriorEye.type";
import FormProvider from "../../../../../components/hook-form/FormProvider";
import Keratometry from "./Keratometry";
import LeftAndRightAnteriorEye from "./LeftAndRightAnteriorEye";
import { Box, Button, Typography } from "@mui/material";
import { AnteriorEyeService } from "../../../../../api/contact-lenses/anterior-eye.service";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IClinicalRecordSchema } from "../../../../../@types/clinicalRecord.type";

type Props = {
  anteriorEye: IAnteriorEye;
  clinicalRecordId: number;
  tab: (type: string) => void;
  showCLForm: boolean;
  getAnteriorEyeRecords: (anteriorEyeId: number, clinicalId: number) => void;
  selectedRow: number;
  clinicalRecords: IClinicalRecordSchema[];
};

const AnteriorEye = ({
  anteriorEye,
  clinicalRecordId,
  tab,
  showCLForm,
  getAnteriorEyeRecords,
  selectedRow,
  clinicalRecords,
}: Props) => {
  const [leftHVID, setLeftHVID] = useState("");
  const [rightHVID, setRightHVID] = useState("");
  const methods = useForm<IAnteriorEye>();
  const { handleSubmit, reset } = methods;

  const { patientId } = useParams();

  const onSubmit = async (data: IAnteriorEye) => {
    const anteriorEyeService = new AnteriorEyeService();
    try {
      const updatedData: IAnteriorEye = {
        ...data,
        leftAndRightAnteriorEye: {
          ...data.leftAndRightAnteriorEye,
          leftHVID,
          rightHVID,
        },
      };
      const response = await anteriorEyeService.createAnteriorEye(patientId!, {
        ...updatedData,
        clinicalRecordId,
      });
      enqueueSnackbar("Anterior Eye Created Successfully!", {
        variant: "success",
      });
      getAnteriorEyeRecords(response.data?.id, clinicalRecordId);
      tab("cl_fitting");
    } catch (error: any) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (showCLForm || !anteriorEye || Object.keys(anteriorEye).length === 0) {
      reset({
        keratometry: {
          rightHoz1: "",
          rightHoz2: "",
          rightHeight1: "",
          rightHeight2: "",
          leftHoz1: "",
          leftHoz2: "",
          leftHeight1: "",
          leftHeight2: "",
        },
        leftAndRightAnteriorEye: {
          leftPupilSize: "",
          rightPupilSize: "",
          leftHVID: "",
          rightHVID: "",
          leftConjunctivalRedness: "",
          rightConjunctivalRedness: "",
          leftCornealInfiltrates: "",
          rightCornealInfiltrates: "",
          leftCornealNeovascularisation: "",
          rightCornealNeovascularisation: "",
          leftCornealOedema: "",
          rightCornealOedema: "",
          leftCornealStaining: "",
          rightCornealStaining: "",
          leftEndothelialBlebs: "",
          rightEndothelialBlebs: "",
          leftEndothelialPolymegethism: "",
          rightEndothelialPolymegethism: "",
          leftEpithelialMicrocysts: "",
          rightEpithelialMicrocysts: "",
          leftLimbalRedness: "",
          rightLimbalRedness: "",
          leftMeibomianDysfunction: "",
          rightMeibomianDysfunction: "",
          leftPapillaryConjunctivitis: "",
          rightPapillaryConjunctivitis: "",
          leftSuperiorLimbicKeratoconjunctivitis: "",
          rightSuperiorLimbicKeratoconjunctivitis: "",
        },
      });
    } else {
      reset({
        keratometry: {
          rightHoz1: anteriorEye?.keratometry?.rightHoz1,
          rightHoz2: anteriorEye?.keratometry?.rightHoz2,
          rightHeight1: anteriorEye?.keratometry?.rightHeight1,
          rightHeight2: anteriorEye?.keratometry?.rightHeight2,
          leftHoz1: anteriorEye?.keratometry?.leftHoz1,
          leftHoz2: anteriorEye?.keratometry?.leftHoz2,
          leftHeight1: anteriorEye?.keratometry?.leftHeight1,
          leftHeight2: anteriorEye?.keratometry?.leftHeight2,
        },
        leftAndRightAnteriorEye: {
          leftPupilSize: anteriorEye?.leftAndRightAnteriorEye?.leftPupilSize,
          rightPupilSize: anteriorEye?.leftAndRightAnteriorEye?.rightPupilSize,
          leftHVID: anteriorEye?.leftAndRightAnteriorEye?.leftHVID,
          rightHVID: anteriorEye?.leftAndRightAnteriorEye?.rightHVID,
          leftConjunctivalRedness:
            anteriorEye?.leftAndRightAnteriorEye?.leftConjunctivalRedness,
          rightConjunctivalRedness:
            anteriorEye?.leftAndRightAnteriorEye?.rightConjunctivalRedness,
          leftCornealInfiltrates:
            anteriorEye?.leftAndRightAnteriorEye?.leftCornealInfiltrates,
          rightCornealInfiltrates:
            anteriorEye?.leftAndRightAnteriorEye?.rightCornealInfiltrates,
          leftCornealNeovascularisation:
            anteriorEye?.leftAndRightAnteriorEye?.leftCornealNeovascularisation,
          rightCornealNeovascularisation:
            anteriorEye?.leftAndRightAnteriorEye
              ?.rightCornealNeovascularisation,
          leftCornealOedema:
            anteriorEye?.leftAndRightAnteriorEye?.leftCornealOedema,
          rightCornealOedema:
            anteriorEye?.leftAndRightAnteriorEye?.rightCornealOedema,
          leftCornealStaining:
            anteriorEye?.leftAndRightAnteriorEye?.leftCornealStaining,
          rightCornealStaining:
            anteriorEye?.leftAndRightAnteriorEye?.rightCornealStaining,
          leftEndothelialBlebs:
            anteriorEye?.leftAndRightAnteriorEye?.leftEndothelialBlebs,
          rightEndothelialBlebs:
            anteriorEye?.leftAndRightAnteriorEye?.rightEndothelialBlebs,
          leftEndothelialPolymegethism:
            anteriorEye?.leftAndRightAnteriorEye?.leftEndothelialPolymegethism,
          rightEndothelialPolymegethism:
            anteriorEye?.leftAndRightAnteriorEye?.rightEndothelialPolymegethism,
          leftEpithelialMicrocysts:
            anteriorEye?.leftAndRightAnteriorEye?.leftEpithelialMicrocysts,
          rightEpithelialMicrocysts:
            anteriorEye?.leftAndRightAnteriorEye?.rightEpithelialMicrocysts,
          leftLimbalRedness:
            anteriorEye?.leftAndRightAnteriorEye?.leftLimbalRedness,
          rightLimbalRedness:
            anteriorEye?.leftAndRightAnteriorEye?.rightLimbalRedness,
          leftMeibomianDysfunction:
            anteriorEye?.leftAndRightAnteriorEye?.leftMeibomianDysfunction,
          rightMeibomianDysfunction:
            anteriorEye?.leftAndRightAnteriorEye?.rightMeibomianDysfunction,
          leftPapillaryConjunctivitis:
            anteriorEye?.leftAndRightAnteriorEye?.leftPapillaryConjunctivitis,
          rightPapillaryConjunctivitis:
            anteriorEye?.leftAndRightAnteriorEye?.rightPapillaryConjunctivitis,
          leftSuperiorLimbicKeratoconjunctivitis:
            anteriorEye?.leftAndRightAnteriorEye
              ?.leftSuperiorLimbicKeratoconjunctivitis,
          rightSuperiorLimbicKeratoconjunctivitis:
            anteriorEye?.leftAndRightAnteriorEye
              ?.rightSuperiorLimbicKeratoconjunctivitis,
        },
      });
    }
  }, [showCLForm, anteriorEye, reset]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {(showCLForm ||
        !anteriorEye ||
        Object.keys(anteriorEye).length === 0) && (
        <Typography sx={{ ml: 1, mt: 2, mb: 2 }} color={"red"}>
          * There's no Anterior Eye added for this clinical record, please add
          and save!
        </Typography>
      )}
      <Keratometry keratometry={anteriorEye?.keratometry} />
      <LeftAndRightAnteriorEye
        leftAndRightAnteriorEye={anteriorEye?.leftAndRightAnteriorEye}
        leftHVID={leftHVID}
        setLeftHVID={(leftHVID: string) => setLeftHVID(leftHVID)}
        rightHVID={rightHVID}
        setRightHVID={(rightHVID: string) => setRightHVID(rightHVID)}
        selectedRow={selectedRow}
        clinicalRecords={clinicalRecords}
        getAnteriorEyeRecords={getAnteriorEyeRecords}
      />
      <Box sx={{ display: "flex", flexDirection: "row-reverse", mr: 2, mt: 2 }}>
        <Button type="submit" variant="contained">
          Save and Next
        </Button>
      </Box>
    </FormProvider>
  );
};
export default AnteriorEye;
