import merge from "lodash/merge";
import { Box, Stack, Button, DialogActions } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ISpecsHistorySchema } from "../../../@types/specsHistory";
import FormProvider from "../../../components/hook-form/FormProvider";
import { RHFSelect, RHFTextField } from "../../../components/hook-form";
import { PrescreeningService } from "../../../api/prescreening.service";
import { enqueueSnackbar } from "notistack";

type Props = {
  onCancel: VoidFunction;
  currentSpecsHistory: any;
  onSubmitForm: VoidFunction;
};

type FormValuesProps = ISpecsHistorySchema;

const getInitialValues = (currentSpecsHistory: any | null | undefined) => {
  const initialEvent: FormValuesProps = {
    condition: "",
    notes: "",
    specifications: "",
    rightSphericalPower: "",
    leftSphericalPower: "",
    rightCylinder: "",
    leftCylinder: "",
    rightAxis: "",
    leftAxis: "",
    rightDistancePD: "",
    leftDistancePD: "",
    rightBVD: "",
    leftBVD: "",
  };

  if (currentSpecsHistory) {
    return merge({}, initialEvent, currentSpecsHistory);
  }

  return initialEvent;
};

const AddSpecsHistoryModal = ({
  onCancel,
  currentSpecsHistory,
  onSubmitForm,
}: Props) => {
  const id = window.location.pathname.split("/")[2];
  const methods = useForm({
    // resolver: yupResolver(PatientSchema),
    defaultValues: getInitialValues(currentSpecsHistory),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const preScreeningService = new PrescreeningService();
    const payload = {
      ...data,
      patientId: +id,
    };
    preScreeningService
      .createPrescreening(payload)
      .then(function (response) {
        onCancel();
        enqueueSnackbar("Specification Created Successfully!", {
          variant: "success",
        });
      })
      .catch(function (error) {
        enqueueSnackbar("Something Went Wrong", {
          variant: "error",
        });
      });
  };

  const onEdit = async (data: FormValuesProps) => {
    const preScreeningService = new PrescreeningService();
    const id = data.id;
    const payload = {
      specifications: data.specifications,
      condition: data.condition,
      notes: data.notes,
    };
    preScreeningService
      .updatePrescreening(id, payload)
      .then(function (response) {
        onCancel();
        enqueueSnackbar("Specification Edited Successfully!", {
          variant: "success",
        });
      })
      .catch(function (error) {
        enqueueSnackbar("Something Went Wrong", {
          variant: "error",
        });
      });
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(currentSpecsHistory ? onEdit : onSubmit)}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <Box sx={{ display: "flex" }}>
          <RHFSelect
            native
            name="condition"
            label="Condition"
            InputLabelProps={{ shrink: true }}>
            <option key="average" defaultValue="average">
              Average
            </option>
            <option key="new" defaultValue="new">
              New
            </option>
          </RHFSelect>
          <RHFSelect
            sx={{ ml: 2 }}
            native
            name="specifications"
            label="Specifications"
            InputLabelProps={{ shrink: true }}>
            <option key="SVD" defaultValue="SVD">
              Svd
            </option>
            <option key="SVN" defaultValue="SVN">
              Svn
            </option>
            <option key="bifocal" defaultValue="bifocal">
              Bifocal
            </option>
            <option key="varifocal" defaultValue="varifocal">
              Varifocal
            </option>
          </RHFSelect>
        </Box>
        <Box sx={{ display: "flex" }}>
          <RHFTextField name="notes" label="Notes" multiline rows={2} />
        </Box>
      </Stack>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {`${currentSpecsHistory ? "Edit" : "Add"}`}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
};

export default AddSpecsHistoryModal;
