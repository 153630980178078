import merge from "lodash/merge";
import {
  Box,
  Stack,
  Button,
  DialogActions,
  Typography,
  IconButton,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import FormProvider from "../../../components/hook-form/FormProvider";
import {
  RHFCheckbox,
  RHFSelect,
  RHFTextField,
} from "../../../components/hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { IPrescreeningSchema } from "../../../@types/specsHistory";
import { ChangeEvent, useEffect, useState } from "react";
import { Tags } from "../../../@types/tags.type";
import { TagsService } from "../../../api/tags.service";
import ConfirmDialog from "../../../components/confirm-dialog/ConfirmDialog";
import { enqueueSnackbar } from "notistack";

type Props = {
  onCancel: VoidFunction;
  draftPrescreening: IPrescreeningSchema;
  onSubmitForm: any;
  setAverages: (type: string, value: number) => void;
  averages: {
    IOPs: number;
    CCTs: number;
  };
  selectedChips: {
    reasonForVisit: string[];
    hobbies: string[];
  };
  setSelectedChips: React.Dispatch<
    React.SetStateAction<{
      reasonForVisit: string[];
      hobbies: string[];
    }>
  >;
};

type FormValuesProps = IPrescreeningSchema;

const getInitialValues = (draftPrescreening: IPrescreeningSchema) => {
  const initialEvent: FormValuesProps = {
    reasonForVisit: "",
    hobbies: "",
    isDriver: false,
    isVduUse: false,
    isSmoker: false,
    autoRefractor: {
      rightSphericalPower: "",
      leftSphericalPower: "",
      rightCylinder: "",
      leftCylinder: "",
      rightAxis: "",
      leftAxis: "",
      rightDistancePD: "",
      leftDistancePD: "",
      rightBVD: "",
      leftBVD: "",
    },
    eyePressures: [],
    specificationHistories: [
      {
        condition: "Average",
        notes: "",
        specifications: "",
        rightSphericalPower: "",
        leftSphericalPower: "",
        rightCylinder: "",
        leftCylinder: "",
        rightAxis: "",
        leftAxis: "",
        rightDistancePD: "",
        leftDistancePD: "",
        rightBVD: "",
        leftBVD: "",
      },
    ],
  };

  if (draftPrescreening) {
    return merge({}, initialEvent, draftPrescreening);
  }

  return initialEvent;
};

const AddSpecificationHistoryButton = ({ fields, append }: any) => {
  const handleAddSpecificationHistory = () => {
    append({
      condition: "Average",
      notes: "",
      specifications: "",
    });
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleAddSpecificationHistory}>
      Add Specification History
    </Button>
  );
};

const AddEyePressuresButton = ({ fields, append }: any) => {
  const handleEyePressures = () => {
    append({
      IOPs: 0,
      CCT: 0,
      instrument: "",
    });
  };

  return (
    <Button variant="outlined" color="primary" onClick={handleEyePressures}>
      Add Eye Pressures
    </Button>
  );
};
const AddNewPrescreening = ({
  onCancel,
  draftPrescreening,
  onSubmitForm,
  setAverages,
  averages,
  selectedChips,
  setSelectedChips,
}: Props) => {
  const [errorMessages, setErrorMessages] = useState<{
    [key: string]: string | null;
  }>({});

  const [availableChips, setAvailableChips] = useState<Tags[]>([]);
  const [currentFreeSoloValue, setCurrentFreeSoloValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [inputValues, setInputValues] = useState<{
    reasonForVisit: "";
    hobbies: "";
  }>({
    reasonForVisit: "",
    hobbies: "",
  });
  const [currentFieldName, setCurrentFieldName] = useState<
    keyof typeof inputValues | null
  >(null);

  const getAllChips = async () => {
    try {
      const category = "Prescreening";
      const tagsService = new TagsService();
      const res = await tagsService.getAllTags(category);
      setAvailableChips(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllChips();
  }, []);

  const handleInputChange = (e: any, fieldName: string) => {
    const value = Number(e.target.value);

    // Define validation for the value
    if (value < 1) {
      setErrorMessages((prev) => ({
        ...prev,
        [fieldName]: "Values less than 1 are not allowed",
      }));
      e.target.value = 1;
    } else if (value > 180) {
      setErrorMessages((prev) => ({
        ...prev,
        [fieldName]: "Values above 180 are not allowed",
      }));
      e.target.value = 180;
    } else {
      setErrorMessages((prev) => ({ ...prev, [fieldName]: null })); // Clear message if valid
    }

    if (e.target.value.length > 3) {
      e.target.value = e.target.value.slice(0, 3); // Enforce a max of 3 digits
    }
  };
  const methods = useForm({
    defaultValues: getInitialValues(draftPrescreening),
  });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const draftPrescreening = {
      ...data,
      reasonForVisit: selectedChips?.reasonForVisit?.join(", "),
      hobbies: selectedChips?.hobbies?.join(", "),
    };
    onSubmitForm(draftPrescreening);

    localStorage.setItem(
      "draftPrescreening",
      JSON.stringify(draftPrescreening)
    );
    onCancel();
  };

  const {
    fields: specificationHistoryFields,
    append: appendSpecificationHistory,
    remove: removeSpecificationHistory,
  } = useFieldArray({
    control,
    name: "specificationHistories",
  });

  const {
    fields: eyePressuresFields,
    append: appendEyePressure,
    remove: removeEyePressure,
  } = useFieldArray({
    control,
    name: "eyePressures",
  });

  const watchedFields = useWatch({
    control,
    name: "eyePressures",
  });

  const calculateAverages = () => {
    if (!watchedFields || watchedFields.length === 0) return;

    const totalIOP = watchedFields.reduce(
      (acc, curr) => acc + (parseFloat(String(curr?.IOPs)) || 0),
      0
    );
    const totalCCT = watchedFields.reduce(
      (acc, curr) => acc + (parseFloat(String(curr?.CCT)) || 0),
      0
    );

    setAverages("IOPs", totalIOP / watchedFields.length || 0);
    setAverages("CCTs", totalCCT / watchedFields.length || 0);
  };

  useEffect(() => {
    calculateAverages();
  }, [watchedFields]);
  const handleTranspose = () => {
    const rightSphericalPowerValue = methods.getValues(
      "specificationHistories.0.rightSphericalPower"
    );
    const rightCylinderValue = +methods.getValues(
      "specificationHistories.0.rightCylinder"
    );

    const rightAxisValue = +methods.getValues(
      "specificationHistories.0.rightAxis"
    );
    const leftSphericalPowerValue = methods.getValues(
      "specificationHistories.1.leftSphericalPower"
    );
    const leftCylinderValue = +methods.getValues(
      "specificationHistories.1.leftCylinder"
    );

    const leftAxisValue = +methods.getValues(
      "specificationHistories.1.leftAxis"
    );

    const newRightSphericalPower =
      rightSphericalPowerValue + rightCylinderValue;
    const newRightCylinder = -rightCylinderValue;

    const newLeftSphericalPower = leftSphericalPowerValue + leftCylinderValue;
    const newLeftCylinder = -leftCylinderValue;

    let newRightAxis;
    if (rightAxisValue + 90 < 181) {
      newRightAxis = rightAxisValue + 90;
    } else {
      newRightAxis = rightAxisValue - 90;
    }

    let newLeftAxis;
    if (leftAxisValue + 90 < 181) {
      newLeftAxis = leftAxisValue + 90;
    } else {
      newLeftAxis = leftAxisValue - 90;
    }

    methods.setValue(
      "specificationHistories.0.rightSphericalPower",
      newRightSphericalPower
    );
    methods.setValue(
      "specificationHistories.0.rightCylinder",
      newRightCylinder.toString()
    );
    methods.setValue(
      "specificationHistories.0.rightAxis",
      newRightAxis.toString()
    );
    methods.setValue(
      "specificationHistories.1.leftSphericalPower",
      newLeftSphericalPower
    );
    methods.setValue(
      "specificationHistories.1.leftCylinder",
      newLeftCylinder.toString()
    );
    methods.setValue(
      "specificationHistories.1.leftAxis",
      newLeftAxis.toString()
    );
  };

  const [selectedSpecifications, setSelectedSpecifications] = useState({});

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newValue = e.target.value;

    // Update the selected specifications for the current index
    setSelectedSpecifications((prev) => ({
      ...prev,
      [index]: newValue,
    }));

    // You may call your original handleChange function if needed
    // originalHandleChange(e, index);
  };

  const handleTagsInput = (
    event: React.ChangeEvent<{}>,
    value: string,
    fieldName: keyof typeof inputValues
  ) => {
    setInputValues((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    fieldName: keyof typeof inputValues
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const isFreeSolo = !availableChips.some(
        (chip) => chip.tagName === inputValues[fieldName]
      );
      if (inputValues[fieldName] && isFreeSolo) {
        setCurrentFreeSoloValue(inputValues[fieldName]);
        setCurrentFieldName(fieldName);
        setOpenModal(true);
      }
    }
  };

  const handleAddTag = async (
    value: string,
    fieldName: keyof typeof inputValues
  ) => {
    const category = "Prescreening";
    const tagName = value;
    const data = {
      tagName: tagName,
      category: category,
    };
    const tagsService = new TagsService();
    try {
      await tagsService.createTag(data);
      setInputValues((prev) => ({ ...prev, [fieldName]: "" }));
      getAllChips();
      enqueueSnackbar("New Tag Added Successfully", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(
        error?.message?.length ? error?.message[0] : error?.message,
        { variant: "error" }
      );
    }
    setOpenModal(false);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Autocomplete
            sx={{ width: "50%" }}
            multiple
            id="tags-filled"
            disableCloseOnSelect
            options={availableChips.map((chip) => chip.tagName)}
            freeSolo
            value={
              draftPrescreening
                ? draftPrescreening?.reasonForVisit.split(",")
                : selectedChips?.reasonForVisit
            }
            onChange={(event, newValue) => {
              setSelectedChips((prev) => ({
                ...prev,
                reasonForVisit: newValue as string[],
              }));
            }}
            filterOptions={(options, state) => {
              return state.inputValue
                ? options.filter((item) =>
                    item.toLowerCase().includes(state.inputValue.toLowerCase())
                  )
                : [];
            }}
            inputValue={inputValues?.reasonForVisit}
            onInputChange={(event, value) =>
              handleTagsInput(event, value, "reasonForVisit")
            }
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    variant="outlined"
                    label={option}
                    key={key}
                    {...tagProps}
                  />
                );
              })
            }
            renderInput={(params) => (
              <RHFTextField
                {...params}
                name="reasonForVisit"
                label="Reason For Visit"
                multiline
                rows={2}
                onKeyDown={(event) =>
                  handleKeyDown(
                    event as React.KeyboardEvent<HTMLInputElement>,
                    "reasonForVisit"
                  )
                }
              />
            )}
          />
          <Autocomplete
            sx={{ width: "50%" }}
            disableCloseOnSelect
            options={availableChips.map((chip) => chip.tagName)}
            multiple
            id="tags-filled"
            freeSolo
            value={
              draftPrescreening
                ? draftPrescreening.hobbies.split(",")
                : selectedChips?.hobbies
            }
            onChange={(event, newValue) => {
              setSelectedChips((prev) => ({
                ...prev,
                hobbies: newValue as string[],
              }));
            }}
            filterOptions={(options, state) => {
              return state.inputValue
                ? options.filter((item) =>
                    item.toLowerCase().includes(state.inputValue.toLowerCase())
                  )
                : [];
            }}
            inputValue={inputValues.hobbies}
            onInputChange={(event, value) =>
              handleTagsInput(event, value, "hobbies")
            }
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    variant="outlined"
                    label={option}
                    key={key}
                    {...tagProps}
                  />
                );
              })
            }
            renderInput={(params) => (
              <RHFTextField
                {...params}
                name="hobbies"
                label="Hobbies"
                multiline
                rows={2}
                onKeyDown={(event) =>
                  handleKeyDown(
                    event as React.KeyboardEvent<HTMLInputElement>,
                    "hobbies"
                  )
                }
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <RHFCheckbox sx={{ ml: 1, mt: 1 }} name="isDriver" label="Driver" />
          <RHFCheckbox sx={{ ml: 1, mt: 1 }} name="isSmoker" label="Smoker" />
          <RHFCheckbox sx={{ ml: 1, mt: 1 }} name="isVduUse" label="VDU Use" />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <Typography variant="h6">Lens Meter/Specs History:</Typography>
            <Box>
              <AddSpecificationHistoryButton
                fields={specificationHistoryFields}
                append={appendSpecificationHistory}
              />
              <Button
                variant="outlined"
                color="primary"
                sx={{ ml: 2 }}
                onClick={handleTranspose}>
                Transpose Values
              </Button>
            </Box>
          </Box>
          {specificationHistoryFields.map((specification, index) => (
            <>
              <Box key={specification.id} mt={2} sx={{ display: "flex" }}>
                <RHFSelect
                  native
                  name={`specificationHistories.${index}.condition`}
                  label="Condition"
                  InputLabelProps={{ shrink: true }}>
                  <option key="Average" value="Average">
                    Average
                  </option>
                  <option key="New" value="New">
                    New
                  </option>
                </RHFSelect>
                <RHFSelect
                  sx={{ ml: 2 }}
                  native
                  name={`specificationHistories.${index}.specifications`}
                  value={watch(
                    `specificationHistories.${index}.specifications`
                  )}
                  label="Specifications"
                  onChange={(e) => {
                    handleChange(e, index);
                    setValue(
                      `specificationHistories.${index}.specifications`,
                      e.target.value
                    );
                  }}
                  InputLabelProps={{ shrink: true }}>
                  <option value="" disabled>
                    Please select
                  </option>

                  {["SVD", "SVN", "Bifocal", "Varifocal"].map((option) => {
                    const isDisabled =
                      (option === "SVD" || option === "SVN") &&
                      Object.values(selectedSpecifications).includes(option);
                    return (
                      <option key={option} value={option} disabled={isDisabled}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}{" "}
                        {/* Capitalize first letter */}
                      </option>
                    );
                  })}
                </RHFSelect>
                <IconButton onClick={() => removeSpecificationHistory(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", alignItems: "stretch" }}>
                <RHFTextField
                  sx={{ mt: 2, mr: 3 }}
                  name={`specificationHistories.${index}.notes`}
                  label="Notes"
                  multiline
                  rows={2}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}></Box>
                <Box
                  sx={{
                    width: "100%",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    mt={1}>
                    <Typography>Right:</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box
                        mr={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}>
                        Sph
                        <RHFTextField
                          sx={{ width: "150px" }}
                          name={`specificationHistories.${index}.rightSphericalPower`}
                          type="number"
                          inputProps={{
                            step: 0.25,
                            min: -10,
                            max: 10,
                            onChange: (e: any) => {
                              const value = parseFloat(e.target.value).toFixed(
                                2
                              );
                              e.target.value = value;
                            },
                          }}
                          InputProps={{
                            startAdornment:
                              Number(
                                watch(
                                  `specificationHistories.${index}.rightSphericalPower`
                                )
                              ) > 0
                                ? "+"
                                : null,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "1px",
                          margin: "0 10px",
                          height: "40px",
                          background: "black",
                          position: "relative",
                          top: "30px",
                          transform: "rotate(14deg)",
                        }}></Box>
                      <Box
                        mr={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}>
                        Cyl
                        <RHFTextField
                          sx={{ width: "180px" }}
                          name={`specificationHistories.${index}.rightCylinder`}
                          type="number"
                          inputProps={{
                            step: 0.25,
                            onChange: (e: any) => {
                              let value = parseFloat(e.target.value);
                              if (value > 0) {
                                value = -value;
                              }
                              value = Math.floor(value * 4) / 4;
                              e.target.value = value.toFixed(2);
                            },
                          }}
                        />
                      </Box>
                      <Box
                        mr={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}>
                        Axis
                        <RHFTextField
                          sx={{ width: "180px" }}
                          name={`specificationHistories.${index}.rightAxis`}
                          type="number"
                          inputProps={{
                            step: 1,
                            min: 1,
                            max: 180,
                            onInput: (e: any) =>
                              handleInputChange(e, "rightAxis"),
                          }}
                        />
                        {errorMessages["rightAxis"] && (
                          <Typography color="error" variant="caption">
                            {errorMessages["rightAxis"]}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        mr={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}>
                        Dist PD
                        <RHFTextField
                          sx={{ width: "180px" }}
                          name={`specificationHistories.${index}.rightDistancePD`}
                          type="number"
                        />
                      </Box>
                      <Box
                        mr={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}>
                        BVD
                        <RHFTextField
                          sx={{ width: "180px" }}
                          name={`specificationHistories.${index}.rightBVD`}
                          type="number"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  mt={1}>
                  <Typography>Left:</Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      mr={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <RHFTextField
                        sx={{ width: "150px" }}
                        name={`specificationHistories.${index}.leftSphericalPower`}
                        type="number"
                        inputProps={{
                          step: 0.25,
                          min: -10,
                          max: 10,
                          onChange: (e: any) => {
                            const value = parseFloat(e.target.value).toFixed(2);
                            e.target.value = value;
                          },
                        }}
                        InputProps={{
                          startAdornment:
                            Number(
                              watch(
                                `specificationHistories.${index}.leftSphericalPower`
                              )
                            ) > 0
                              ? "+"
                              : null,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "1px",
                        margin: "0 10px",
                        height: "40px",
                        background: "black",
                        position: "relative",
                        top: "10px",
                        transform: "rotate(14deg)",
                      }}></Box>
                    <Box
                      mr={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <RHFTextField
                        sx={{ width: "180px" }}
                        name={`specificationHistories.${index}.leftCylinder`}
                        type="number"
                        inputProps={{
                          step: 0.25,
                          onChange: (e: any) => {
                            let value = parseFloat(e.target.value);
                            if (value > 0) {
                              value = -value;
                            }
                            value = Math.floor(value * 4) / 4;
                            e.target.value = value.toFixed(2);
                          },
                        }}
                      />
                    </Box>
                    <Box
                      mr={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <RHFTextField
                        sx={{ width: "180px" }}
                        name={`specificationHistories.${index}.leftAxis`}
                        type="number"
                        inputProps={{
                          step: 1,
                          min: 1,
                          max: 180,
                          onInput: (e: any) => handleInputChange(e, "leftAxis"),
                        }}
                      />
                      {errorMessages["leftAxis"] && (
                        <Typography color="error" variant="caption">
                          {errorMessages["leftAxis"]}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      mr={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <RHFTextField
                        sx={{ width: "180px" }}
                        name={`specificationHistories.${index}.leftDistancePD`}
                        type="number"
                      />
                    </Box>
                    <Box
                      mr={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <RHFTextField
                        sx={{ width: "180px" }}
                        name={`specificationHistories.${index}.leftBVD`}
                        type="number"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}>
          <Typography variant="h6">Eye Pressure:</Typography>
          <Box>
            <AddEyePressuresButton
              fields={eyePressuresFields}
              append={appendEyePressure}
            />
          </Box>
        </Box>{" "}
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          {eyePressuresFields.map((pressure, index) => (
            <Box
              key={pressure.id}
              sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <RHFTextField
                sx={{ mt: 2 }}
                type="number"
                name={`eyePressures.${index}.IOPs`}
                label="IOPs"
              />
              <RHFTextField
                sx={{ mt: 2 }}
                name={`eyePressures.${index}.CCT`}
                type="number"
                label="CCT"
              />
              <RHFTextField
                sx={{ mt: 2 }}
                name={`eyePressures.${index}.instrument`}
                label="Instrument"
              />
              <IconButton
                onClick={() => removeEyePressure(index)}
                sx={{ width: "20%", margin: "auto" }}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          {eyePressuresFields.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <RHFTextField
                sx={{ mt: 2 }}
                value={averages?.IOPs}
                InputProps={{
                  readOnly: true,
                }}
                label="Average IOPs"
                name="avgIOP"
              />
              <RHFTextField
                sx={{ mt: 2 }}
                value={averages?.CCTs}
                InputProps={{
                  readOnly: true,
                }}
                label="Average CCT"
                name="avgCCT"
              />
            </Box>
          )}
        </Box>
        <Typography variant="h6">Auto Refractor:</Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}></Box>
          <Box
            sx={{
              width: "100%",
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mt={1}>
              <Typography>Right:</Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  Sph
                  <RHFTextField
                    sx={{ width: "150px" }}
                    name="autoRefractor.rightSphericalPower"
                    type="number"
                    inputProps={{
                      step: 0.25,
                      min: -10,
                      max: 10,
                      onChange: (e: any) => {
                        const value = parseFloat(e.target.value).toFixed(2);
                        e.target.value = value;
                      },
                    }}
                    InputProps={{
                      startAdornment:
                        Number(watch("autoRefractor.rightSphericalPower")) > 0
                          ? "+"
                          : null,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "1px",
                    margin: "0 10px",
                    height: "40px",
                    background: "black",
                    position: "relative",
                    top: "30px",
                    transform: "rotate(14deg)",
                  }}></Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  Cyl
                  <RHFTextField
                    sx={{ width: "180px" }}
                    name="autoRefractor.rightCylinder"
                    type="number"
                    inputProps={{
                      step: 0.25,
                      onChange: (e: any) => {
                        let value = parseFloat(e.target.value);
                        if (value > 0) {
                          value = -value;
                        }
                        value = Math.floor(value * 4) / 4;
                        e.target.value = value.toFixed(2);
                      },
                    }}
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  Axis
                  <RHFTextField
                    sx={{ width: "180px" }}
                    name="autoRefractor.rightAxis"
                    type="number"
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 180,
                      onInput: (e: any) => handleInputChange(e, "rightAxis"),
                    }}
                  />
                  {errorMessages["rightAxis"] && (
                    <Typography color="error" variant="caption">
                      {errorMessages["rightAxis"]}
                    </Typography>
                  )}
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  Dist PD
                  <RHFTextField
                    sx={{ width: "180px" }}
                    name="autoRefractor.rightDistancePD"
                    type="number"
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  BVD
                  <RHFTextField
                    sx={{ width: "180px" }}
                    name="autoRefractor.rightBVD"
                    type="number"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={1}>
            <Typography>Left:</Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box
                mr={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <RHFTextField
                  sx={{ width: "150px" }}
                  name="autoRefractor.leftSphericalPower"
                  type="number"
                  inputProps={{
                    step: 0.25,
                    min: -10,
                    max: 10,
                    onChange: (e: any) => {
                      const value = parseFloat(e.target.value).toFixed(2);
                      e.target.value = value;
                    },
                  }}
                  InputProps={{
                    startAdornment:
                      Number(watch("autoRefractor.leftSphericalPower")) > 0
                        ? "+"
                        : null,
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "1px",
                  margin: "0 10px",
                  height: "40px",
                  background: "black",
                  position: "relative",
                  top: "10px",
                  transform: "rotate(14deg)",
                }}></Box>
              <Box
                mr={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <RHFTextField
                  sx={{ width: "180px" }}
                  name="autoRefractor.leftCylinder"
                  type="number"
                  inputProps={{
                    step: 0.25,
                    onChange: (e: any) => {
                      let value = parseFloat(e.target.value);
                      if (value > 0) {
                        value = -value;
                      }
                      value = Math.floor(value * 4) / 4;
                      e.target.value = value.toFixed(2);
                    },
                  }}
                />
              </Box>
              <Box
                mr={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <RHFTextField
                  sx={{ width: "180px" }}
                  name="autoRefractor.leftAxis"
                  type="number"
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 180,
                    onInput: (e: any) => handleInputChange(e, "leftAxis"),
                  }}
                />
                {errorMessages["leftAxis"] && (
                  <Typography color="error" variant="caption">
                    {errorMessages["leftAxis"]}
                  </Typography>
                )}
              </Box>
              <Box
                mr={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <RHFTextField
                  sx={{ width: "180px" }}
                  name="autoRefractor.leftDistancePD"
                  type="number"
                />
              </Box>
              <Box
                mr={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <RHFTextField
                  sx={{ width: "180px" }}
                  name="autoRefractor.leftBVD"
                  type="number"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Add
        </LoadingButton>
      </DialogActions>
      <ConfirmDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Add a New Tag"
        content={
          <>
            Are you sure want to add <strong> {currentFreeSoloValue} </strong>{" "}
            to tags{" "}
          </>
        }
        action={
          <Button
            variant="contained"
            color="success"
            onClick={() =>
              currentFreeSoloValue &&
              currentFieldName &&
              handleAddTag(currentFreeSoloValue, currentFieldName)
            }>
            Add
          </Button>
        }
      />
    </FormProvider>
  );
};

export default AddNewPrescreening;
