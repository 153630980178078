import {
  TableHeadCustom,
  TableEmptyRows,
  TableNoData,
  emptyRows,
  useTable,
} from "../../../components/table";
import ProductTableRow from "./ProductTableRow";
import { IProduct, ProductStatus } from "../../../@types/product";
import { ProductService } from "../../../api/product.service";
import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Table, TableBody } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import ProductNewEditForm from "./ProductAddEditForm";

export default function ProductList() {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [openFormProduct, setOpenFormProduct] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<boolean>(false);

  const getAllProducts = async () => {
    const productService = new ProductService();
    try {
      const { data } = await productService.getAllProducts();
      setProducts(data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectAllRows,
    onSort,
  } = useTable({ defaultOrderBy: "startTime" });

  const TABLE_HEAD = [
    { id: "name", label: "Product", align: "center" },
    { id: "createdAt", label: "Created At", align: "center" },
    { id: "quantity", label: "Stock", align: "center" },
    { id: "price", label: "Price", align: "center" },
    { id: "status", label: "Status", align: "center" },
    { id: "", label: "Actions", align: "center" },
  ];

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleDeleteProduct = async (id: number) => {
    try {
      const productService = new ProductService();
      await productService.deleteProduct(id);
      getAllProducts();
      enqueueSnackbar("Product Deleted Successfully!", {
        variant: "success",
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message[0] || "something went wrong", {
        variant: "error",
      });
    }
  };

  const handlePublishProduct = async (id: number, status: string) => {
    try {
      const productService = new ProductService();
      const payload = {
        status:
          status === ProductStatus.PUBLISH
            ? ProductStatus.UNPUBLISH
            : ProductStatus.PUBLISH,
      };
      await productService.updateProduct(id, payload);
      getAllProducts();
      enqueueSnackbar(
        `Product ${
          status === ProductStatus.PUBLISH
            ? ProductStatus.UNPUBLISH
            : ProductStatus.PUBLISH
        } Successfully!`,
        {
          variant: "success",
        }
      );
    } catch (error: any) {
      enqueueSnackbar(error?.message[0] || "something went wrong", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={products.length}
          numSelected={selected.length}
          onSort={onSort}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              products.map((row: any) => row.id)
            )
          }
        />
        <TableBody>
          {products.map((product, key) => (
            <ProductTableRow
              product={product}
              key={key}
              onDelete={() => handleDeleteProduct(product.id)}
              onPublish={() => handlePublishProduct(product.id, product.status)}
              onEdit={() => {
                setSelectedProduct(product);                
                setViewMode(false);
                setOpenFormProduct(true);
              }}
              onView={() => {
                setSelectedProduct(product);
                setViewMode(true);
                setOpenFormProduct(true);
              }}
            />
          ))}
          <TableEmptyRows
            height={63}
            emptyRows={emptyRows(page, rowsPerPage, products.length)}
          />
          {!products.length && (
            <TableNoData isNotFound={products.length <= 0} />
          )}
        </TableBody>
      </Table>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openFormProduct}
        onClose={() => setOpenFormProduct(false)}
      >
        <DialogTitle>{viewMode? 'View':'Edit'} Product</DialogTitle>
        <ProductNewEditForm
          onCancel={(value?: boolean) => {
            setOpenFormProduct(false);
            
            setSelectedProduct(undefined);
            if (value) getAllProducts();
            setViewMode(false);
          }}
          isEdit={!viewMode}
          isView={viewMode}
          selectedProduct={selectedProduct}
        />
      </Dialog>
    </>
  );
}
