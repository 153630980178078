import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AddSpecsHistoryModal from "./AddSpecsHistoryModal";
import SpecsHistoryBox from "./SpecsHistoryBox";
import { IAxisSchema, ISpecsHistorySchema } from "../../../@types/specsHistory";
import Axis from "./Axis";

interface Props {
  title?: string;
  specsData: any;
}
export default function SpecificationHistory({ title, specsData }: Props) {
  const [showSpecsHistory, setShowSpecsHistory] = useState(false);
  const [currentHistory, setCurrentHistory] = useState<ISpecsHistorySchema>();

  const handleShow = (data: any) => {
    setShowSpecsHistory(true);
    setCurrentHistory(data);
  };

  const handleClose = () => {
    setShowSpecsHistory(false);
  };

  useEffect(() => {}, []);

  return (
    <Stack
      sx={{
        width: "50%",
        borderRadius: "15px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        maxHeight: "380px",
        overflowY: "auto",
      }}
      direction="column"
      mr={2}
      ml={1}
      p={2}>
      {specsData[0] && specsData[0]?.specificationHistories?.length > 0 ? (
        specsData[0]?.specificationHistories?.map(
          (item: ISpecsHistorySchema, index: number) => {
            return (
              <Box sx={{ width: "100%" }}>
                <SpecsHistoryBox
                  key={index}
                  data={item}
                  handleOpen={(data: ISpecsHistorySchema) => handleShow(data)}
                />
              </Box>
            );
          }
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
          No Records Found
        </Box>
      )}
      {}
      <Dialog
        fullWidth
        maxWidth="md"
        open={showSpecsHistory}
        onClose={handleClose}>
        <DialogTitle>Add Specs History</DialogTitle>
        <AddSpecsHistoryModal
          onCancel={handleClose}
          onSubmitForm={() => console.log("hey")}
          currentSpecsHistory={{
            ...currentHistory,
            // purchasedDate: new Date(currentHistory?.purchasedDate),
          }}
        />
      </Dialog>
    </Stack>
  );
}
