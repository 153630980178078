import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import { CustomTextField } from "../../../components/custom-input";

type Props = {
  misc: any;
};

export default function ClinicRecordsMisc({ misc }: Props) {
  return (
    <Stack
      sx={{
        width: "100%",
        borderRadius: "15px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        minHeight: "300px",
        marginBottom: "10px",
        overflowY: "auto",
      }}
      direction="column"
      mr={2}
      ml={1}
      p={2}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}>
        <Typography variant="h6">Miscellaneous</Typography>
        <Box>
          {/* <Button variant="contained" color="primary">
            Add
          </Button> */}
        </Box>
      </Box>
      {misc ? (
        <>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography>Visit Reason:</Typography>
            <CustomTextField
              sx={{ width: "80%" }}
              disabled
              value={
                misc[0]?.reasonForVisit
                  ? misc[0].reasonForVisit.split(",").join(", ")
                  : ""
              }
            />
          </Box>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography>Hobbies:</Typography>
            <CustomTextField
              sx={{ width: "80%" }}
              disabled
              value={
                misc[0]?.hobbies ? misc[0].hobbies.split(",").join(", ") : ""
              }
            />
          </Box>
          <Box mt={2} sx={{ display: "flex" }}>
            <FormControlLabel
              control={<Switch disabled checked={misc[0]?.isDriver} />}
              label={"Is Driver"}
            />
            <FormControlLabel
              control={<Switch disabled checked={misc[0]?.isVduUse} />}
              label={"Is VDU Use"}
            />
            <FormControlLabel
              control={<Switch disabled checked={misc[0]?.isSmoker} />}
              label={"Is Smoker"}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
          No Records Found
        </Box>
      )}
    </Stack>
  );
}

// Save And Next Button
// Local Storage Save
// Upper Table Clinical Records Api
// Transpose
// Disable Axis fields
