import {
  InputAdornment,
  Stack,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Iconify from "../../../components/iconify";
import {
  DispenseOrderStatus,
  statusOptionsProps,
} from "../../../@types/dispense";

type Props = {
  searchValue: string;
  statusValues: statusOptionsProps[];
  onResetFilter: Function;
  onSearchFilter: Function;
  onStatusFilter: Function;
};

const statusOption: statusOptionsProps[] = [
  {
    label:
      DispenseOrderStatus.UNCANCELLED.charAt(0).toUpperCase() +
      DispenseOrderStatus.UNCANCELLED.slice(1),
    value: DispenseOrderStatus.UNCANCELLED,
  },
  {
    label:
      DispenseOrderStatus.UNPROCESSED.charAt(0).toUpperCase() +
      DispenseOrderStatus.UNPROCESSED.slice(1),
    value: DispenseOrderStatus.UNPROCESSED,
  },
  {
    label:
      DispenseOrderStatus.OUTSTANDING.charAt(0).toUpperCase() +
      DispenseOrderStatus.OUTSTANDING.slice(1),
    value: DispenseOrderStatus.OUTSTANDING,
  },
  {
    label:
      DispenseOrderStatus.OVERDUE.charAt(0).toUpperCase() +
      DispenseOrderStatus.OVERDUE.slice(1),
    value: DispenseOrderStatus.OVERDUE,
  },
  {
    label:
      DispenseOrderStatus.UNCOLLECTED.charAt(0).toUpperCase() +
      DispenseOrderStatus.UNCOLLECTED.slice(1),
    value: DispenseOrderStatus.UNCOLLECTED,
  },
];

export default function DispenseTableToolbar({
  searchValue,
  statusValues,
  onResetFilter,
  onSearchFilter,
  onStatusFilter,
}: Props) {
  const theme = useTheme();
  const handleChange = (_e: any, options: any) => {
    onStatusFilter(options);
  };
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        fullWidth
        value={searchValue}
        onChange={(e) => onSearchFilter(e.target.value)}
        placeholder="Search Dispense By Name, Phone and Email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />
      <Autocomplete
        multiple
        id="statusOption"
        options={statusOption}
        limitTags={1}
        disableCloseOnSelect
        onChange={handleChange}
        sx={{
          width: 234,
          overflow: "hidden",
          minWidth: 234,
          // height: 40,
          // borderRadius: "10px",
          backgroundColor: theme.palette.background.paper,
          "& .MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap !important",
          },
          // "& .MuiOutlinedInput-root": {
          //   height: 40,
          // },
          // "& .MuiOutlinedInput-root fieldset": {
          //   border: "none",
          // },
          "& .MuiAutocomplete-endAdornment": {
            right: 13,
            top: "calc(50% - 12px)",
          },
          // "& .MuiIconButton-root": {
          //   "& svg": {
          //     fill: theme.palette.mode !== "dark" ? "#000000" : "#e9f2f2",
          //   },
          // },
        }}
        value={statusValues}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 4 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder="Order Status" />
        )}
      />
      {searchValue ? (
        <Button color="error" onClick={() => onResetFilter()}>
          Cancel
        </Button>
      ) : null}
    </Stack>
  );
}
