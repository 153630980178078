import React from "react";
import { Box } from "@mui/material";

interface ConditionalLogoProps {
  src: string | React.ReactNode;
  sx?: object;
}

const ConditionalLogo: React.FC<ConditionalLogoProps> = ({ src, sx }) => {
  return typeof src === "string" ? (
    <Box component="img" src={src} sx={sx} />
  ) : (
    <Box sx={sx}>{src}</Box>
  );
};

export default ConditionalLogo;